import React, { useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import "./Reports.css";
import DateRangeComponent from "shared/components/DateRangeComponent";
import ActionsComponent from "shared/components/Reports/ActionsComponent";
import useReports from "shared/hooks/useReports";
import { ReportNames, ReservationByEmployeeReport } from "shared/models/Report";
import reportService from "shared/services/ReportService";
import { Button, Fade, LinearProgress } from "@mui/material";
import {
    DataGridPro,
    GridCellParams,
    GridColDef,
    GridToolbarContainer,
    GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { CustomNoRowsOverlay } from "./NoDataComponent";
import ReportName from "./ReportName";
import useGlobal from "shared/hooks/GlobalHook";
import { formatCurrencyCell, formatDateCell } from "shared/utils/Formats";
import { ReportAccess } from "shared/models/AccessRights";
import useNotification, { NotificationsType } from "shared/hooks/useNotification";
import { ForbiddenError, APIError } from "shared/http/errors";
import { useAuthContext } from "modules/Auth/AuthProvider";

interface DataRow {
    id?: number | string;
    salesPerson: string;
    eventName: string;
    eventDateTime: Date | string;
    reservationId: number;
    tickets: number;
    paidAmount: number;
}

const ReservationByEmployee = () => {
    const { addReservationPanelPayload } = useGlobal();
    const {
        rows,
        timeType,
        dateRange,
        isLoading,
        setRows,
        changeTimeType,
        parseDateByTimeType,
        setStartDate,
        setEndDate,
        setIsLoading,
        setShowNoDataLabel,
        handlePrintReport,
        saveDateRange,
        loadDateRange,
    } = useReports<DataRow>();
    const { getReportAccess } = useAuthContext();
    const { showNotification } = useNotification();
    const columns: GridColDef[] = [
        // { field: 'id', headerName: 'ID' }, // Mandatory DataGrid Column
        {
            field: "salesPerson",
            headerName: "Sales person",
            type: "string",
            flex: 1,
        },
        { field: "eventName", headerName: "Event", type: "string", flex: 2 },
        {
            field: "eventDateTime",
            headerName: "Date of reservation",
            type: "date",
            flex: 2,
            valueFormatter: (param) => formatDateCell(param.value),
        },
        {
            field: "reservationId",
            headerName: "Res#",
            type: "string",
            flex: 1,
            renderCell: (params) => {
                if (params.value && Number(params.value) > 0) {
                    return (
                        <Button
                            variant="text"
                            color={"primary"}
                            onClick={() => openReservationPanel(params.value)}
                        >
                            {params.value}
                        </Button>
                    );
                }
                return "--";
            },
        },
        { field: "tickets", headerName: "Tickets", type: "number", flex: 1 },
        {
            field: "paidAmount",
            headerName: "Paid amount",
            type: "number",
            flex: 1,
            valueFormatter: (param) => formatCurrencyCell(param.value),
        },
    ];
    useEffect(() => {
        loadDateRange(ReportNames.ReservationsByEmployee);
        //eslint-disable-next-line
    }, []);

    const openReservationPanel = (reservationId: number) => {
        addReservationPanelPayload(reservationId, 0);
    };

    const generateReport = async () => {
        setIsLoading(true);
        saveDateRange(ReportNames.Delivery);
        try {
            const result = await reportService.getReservationByEmployeeReport(
                parseDateByTimeType(dateRange.start),
                parseDateByTimeType(dateRange.end),
            );
            const items = result || [];
            if (items.length === 0) {
                setShowNoDataLabel(true);
            }
            setRows(buildRows(items));
        } catch (e) {
            if (e instanceof ForbiddenError) {
                showNotification({
                    message:
                        "You do not have access to reservation by employee report, please contact your company administrator.",
                    type: NotificationsType.warning,
                });
            }

            if (e instanceof APIError) {
                showNotification({
                    message:
                        "Error to generate reservation by employee report, please contact your company administrator.",
                    type: NotificationsType.warning,
                });
            }
        }
        setIsLoading(false);
    };

    const buildRows = (list: ReservationByEmployeeReport[]): DataRow[] => {
        let new_list = list.sort((a, b) => {
            if (a.userId < b.userId) return -1;
            if (a.userId > b.userId) return 1;
            return new Date(b.reservationDate).getTime() - new Date(a.reservationDate).getTime();
        });
        const totals = getTotals(new_list);
        const groupList = new_list.map((item) => item.userId);
        const uniqueIds = [...new Set(groupList)];
        for (const iterator of uniqueIds) {
            new_list.push(totals[iterator]);
        }
        return new_list
            .sort((a, b) => {
                if (a.userId < b.userId) return -1;
                if (a.userId > b.userId) return 1;
                return (
                    new Date(b.reservationDate).getTime() - new Date(a.reservationDate).getTime()
                );
            })
            .map((item, index) => {
                return {
                    ...item,
                    salesPerson: `${item.firstName} ${item.lastName}`,
                    eventDateTime: item.eventName === "--" ? "--" : new Date(item.reservationDate),
                    id: index,
                };
            });
    };
    const getTotals = (list: ReservationByEmployeeReport[]) => {
        let totals: { [key: string]: ReservationByEmployeeReport } = {};

        for (let row of list) {
            if (!totals[row.userId]) {
                totals[row.userId] = {
                    userId: "",
                    firstName: "",
                    lastName: "",
                    eventName: "--",
                    reservationId: -1,
                    reservationDate: "--",
                    paidAmount: 0,
                    tickets: 0,
                };
            }
            totals[row.userId].paidAmount += row.paidAmount;
            totals[row.userId].tickets += row.tickets;
            totals[row.userId].userId = row.userId;
            totals[row.userId].reservationDate = row.reservationDate;
            totals[row.userId].firstName = row.firstName;
            totals[row.userId].lastName = row.lastName;
        }
        return totals;
    };
    const canGenerateReport = getReportAccess(ReportAccess.ReservationsByEmployee);
    return (
        <div style={{ backgroundColor: "white", margin: 2, padding: 10 }}>
            <Grid container spacing={1.5}>
                <Grid xs={12}>
                    <ReportName name="Reservations by employees" />
                </Grid>
                <Grid xs={12}>
                    <div className="actions">
                        <DateRangeComponent
                            getStartDate={setStartDate}
                            getEndDate={setEndDate}
                            values={{ start: dateRange.start, end: dateRange.end }}
                            useTime={false}
                            displayTimeType={true}
                            timeType={timeType}
                            handleUTCChange={(value) => changeTimeType(value)}
                        />
                        <ActionsComponent
                            generateReport={generateReport}
                            handlePrintReport={() => handlePrintReport("printable-delivery-report")}
                            displayPrint={false}
                            disableReport={!canGenerateReport}
                        />
                    </div>
                    <Fade in={isLoading}>
                        <LinearProgress />
                    </Fade>
                </Grid>
                <Grid xs={12}>
                    <div className={"report-table"} style={{ height: 650, width: "100%" }}>
                        <DataGridPro
                            rows={rows}
                            columns={columns}
                            loading={isLoading}
                            slots={{
                                toolbar: ExportToolbar,
                                noRowsOverlay: CustomNoRowsOverlay,
                            }}
                            sx={{ "--DataGrid-overlayHeight": "300px" }}
                            getCellClassName={(params: GridCellParams) => {
                                if (params.row.reservationId === -1) {
                                    return "bold";
                                }
                                return "";
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};
const ExportToolbar = () => (
    <GridToolbarContainer>
        <GridToolbarExport csvOptions={{ fileName: "ReservationByEmployee" }} />
    </GridToolbarContainer>
);
export default ReservationByEmployee;
