import React, { FunctionComponent, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Fade, LinearProgress, Typography } from "@mui/material";
import { MathEx } from "../../../shared/utils/MathEx";
import { FlatButton } from "../../../shared/components/FlatButton";
import { GiftCardRecipient } from "../../../shared/models/Cart";
import { GiftCardGeneralData } from "../../../shared/models/GiftCardRecipientsData";
import shoppingCartService from "../../../shared/services/ShoppingCartService";
import { useShoppingCartSlice } from "../../../store/ShoppingCart/useShoppingCartSlice";
import { GiftCardRecipientEditable } from "./GiftCardRecipientEditable";
import useNotification, { NotificationsType } from "shared/hooks/useNotification";

interface GiftCardRecipientEditableProps {
    recipient: GiftCardRecipient;
    giftCardData: GiftCardGeneralData;
    index: number;
    isNew?: boolean;
    showError: boolean;
    onChange: (form: GiftCardRecipient, isValid: boolean) => void;
    onRemove: (recipient: GiftCardRecipient) => void;
}

/**
 * Entry component for gift card recipient, it's a wrapper on GiftCardRecipientEditable,
 * intercepts the methods of the parent component if the recipient exists,
 * otherwise propagates the method from the child to the parent component
 * @param props
 * @constructor
 */
export const GiftCardRecipientEntry: FunctionComponent<GiftCardRecipientEditableProps> = (
    props,
) => {
    const { showNotification } = useNotification();
    const { cart, removeGiftCardRecipient, editGiftCardRecipient } = useShoppingCartSlice();
    const [edit, setEdit] = useState(false);
    const [recipient, setRecipient] = useState<GiftCardRecipient>(props.recipient);
    const [loading, setLoading] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const handleEdit = async () => {
        if (
            props.recipient?.giftCardRecipientId &&
            props.recipient?.giftCardRecipientId > 0 &&
            cart !== null
        ) {
            setLoading(true);
            const data = await shoppingCartService.getGiftCardRecipient(
                cart.cartId,
                props.giftCardData.catalogId,
                props.recipient.giftCardRecipientId,
            );
            setRecipient((s) => ({ ...s, ...data }));
            setEdit(true);
            setLoading(false);
        }
    };
    const handleSave = async () => {
        if (!isValid) {
            return;
        }
        setLoading(true);
        try {
            await editGiftCardRecipient(props.giftCardData.catalogId, recipient);
            showNotification({
                message: "Gift card recipient saved",
                type: NotificationsType.success,
            });
            setEdit(false);
        } catch (e) {
            showNotification({
                message: "Error saving gift card recipient, try again later",
                type: NotificationsType.warning,
            });
        } finally {
            setLoading(false);
        }
    };
    const handleChange = (recipient: GiftCardRecipient, isValid: boolean) => {
        if (!props.isNew) {
            setIsValid(isValid);
            setRecipient(recipient);
        } else {
            // if is new propagate changes to parent
            props.onChange(recipient, isValid);
        }
    };
    const handleRemove = async (recipient: GiftCardRecipient) => {
        if (!props.isNew && recipient.giftCardRecipientId && recipient?.giftCardRecipientId > 0) {
            setLoading(true);
            try {
                await removeGiftCardRecipient(
                    props.giftCardData.catalogId,
                    recipient.giftCardRecipientId,
                );
                showNotification({
                    message: "Gift card recipient removed",
                    type: NotificationsType.success,
                });
            } catch (e) {
                showNotification({
                    message: "Error removing gift card recipient, try again later",
                    type: NotificationsType.warning,
                });
            } finally {
                setLoading(false);
            }
        } else {
            // Local remove
            props.onRemove(recipient);
        }
    };

    return (
        <Grid container spacing={1} style={{ marginBottom: "1rem" }}>
            <Grid xs={12}>
                <Typography variant={"inputLabel"}>
                    {MathEx.ordinal(props.index + 1)} Recipient
                </Typography>
            </Grid>
            <Fade in={loading}>
                <Grid xs={12}>
                    <LinearProgress />
                </Grid>
            </Fade>
            {!edit && !props.isNew ? (
                <Grid xs={12}>
                    <Typography variant={"inputLabel"}>{recipient.recipientName}</Typography>
                    <Typography variant={"commentContent"}>{recipient.recipientEmail}</Typography>
                </Grid>
            ) : (
                <GiftCardRecipientEditable
                    {...props}
                    recipient={recipient}
                    onChange={handleChange}
                    onRemove={handleRemove}
                />
            )}
            <Grid
                xs={12}
                style={{
                    display: "flex",
                    gap: "1rem",
                }}
            >
                <FlatButton color={"error"} onClick={() => handleRemove(recipient)}>
                    Remove
                </FlatButton>
                {!edit && !props.isNew ? (
                    <FlatButton color={"primary"} onClick={handleEdit}>
                        Edit
                    </FlatButton>
                ) : null}

                {edit && !props.isNew ? (
                    <FlatButton color={"primary"} onClick={handleSave}>
                        Save
                    </FlatButton>
                ) : null}
            </Grid>
        </Grid>
    );
};
