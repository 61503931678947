import React, { FunctionComponent, useState } from "react";
import { Button, Fade, LinearProgress } from "@mui/material";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import { IconActionButton } from "shared/components/IconActionButton";
import DialogComponent, { DialogHooks } from "shared/components/DialogComponent";
import { ReactComponent as RefundIconOutlined } from "../../../assets/icons/amend_outlined.svg";
import { LoadingButton } from "@mui/lab";
import useNotification, { NotificationsType } from "shared/hooks/useNotification";
import { useQueryClient } from "@tanstack/react-query";
import refundService from "../../../shared/services/RefundService";

interface RefundCompleteReservationProps {
    reservationId: number;
    refundAmount: number;
}

export const RefundCompleteReservation: FunctionComponent<RefundCompleteReservationProps> = (
    props,
) => {
    const { showNotification } = useNotification();
    const [open, handleClickOpen, handleClose] = DialogHooks();
    const queryClient = useQueryClient();
    const [loading, setLoading] = useState(false);

    const handleRefund = async () => {
        if (props.refundAmount === 0) {
            showNotification({
                type: NotificationsType.warning,
                message: "Nothing to refund",
            });
            return;
        }
        handleClickOpen();
    };
    const executeRefund = async () => {
        setLoading(true);
        try {
            const result = await refundService.refundFullReservation(props.reservationId);
            showNotification({
                type: NotificationsType.success,
                message: "Refund completed.",
            });
            queryInvalidation();
        } catch (e) {
            console.error(e);
            showNotification({
                type: NotificationsType.success,
                message: "Error refunding reservation, try again later",
            });
        } finally {
            setLoading(false);
            handleClose();
        }
    };

    const queryInvalidation = () => {
        queryClient.invalidateQueries({
            queryKey: ["event-date-details"],
        });
        queryClient.invalidateQueries({
            queryKey: ["reservation-details"],
        });
        queryClient.invalidateQueries({
            queryKey: ["calendar-by-event"],
        });
    };

    return (
        <>
            <IconActionButton
                icon={<RemoveShoppingCartIcon />}
                onClick={handleRefund}
                label="Refund"
                size="small"
                color={"primary"}
                disabled={props.refundAmount === 0}
                tooltip={props.refundAmount === 0 ? "Nothing to refund" : ""}
            />
            <DialogComponent
                open={open}
                DialogText="Refund reservation"
                handleClose={handleClose}
                customButtons={
                    <>
                        <Button onClick={handleClose} color="primary" autoFocus>
                            Cancel
                        </Button>
                        <LoadingButton
                            onClick={executeRefund}
                            color="primary"
                            variant={"contained"}
                            startIcon={<RefundIconOutlined />}
                            loading={loading}
                        >
                            Refund
                        </LoadingButton>
                    </>
                }
            >
                Are you sure you want to completely refund this reservation?
                <Fade in={loading}>
                    <LinearProgress />
                </Fade>
            </DialogComponent>
        </>
    );
};
