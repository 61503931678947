import React, { ReactElement, createContext, useEffect, useState, useRef } from "react";
import SlidePanel from "shared/components/SlidePanel";
import { LocalStorageService } from "../../shared/services/LocalStorageService";
import { ShoppingCart } from "./ShoppingCart";
import { useShoppingCartSlice } from "../../store/ShoppingCart/useShoppingCartSlice";
import { EMPTY_CART_ID } from "../../store/ShoppingCart/ShoppingCartSlice";
import { useAppSelector } from "../../store/Store";

interface ShoppingCartContextProps {
    children?: ReactElement;
}
interface ShoppingCartModalProps {
    toggleShoppingCartModal: (open: boolean) => void;
}
const ShoppingCartContext = createContext<ShoppingCartModalProps | undefined>(undefined);

const ShoppingCartProvider = ({ children }: ShoppingCartContextProps) => {
    const [openPanel, setOpenPanel] = useState(false);
    const { fetchShoppingCart, initializeShoppingCart, cart } = useShoppingCartSlice();
    const cartIdRef = useRef(EMPTY_CART_ID);

    const company = useAppSelector((s) => s.company.company);
    useEffect(() => {
        const result = LocalStorageService.getShoppingCartId();
        if (result && result?.shoppingCartId !== "Empty") {
            fetchShoppingCart(result.shoppingCartId);
        } else {
            initializeShoppingCart();
        }
        //eslint-disable-next-line
    }, [company]);
    useEffect(() => {
        // useEffect and useRef combined to save the shopping cart into local storage
        if (cart && cart.cartId !== cartIdRef.current && cart.cartId !== "Empty") {
            cartIdRef.current = cart.cartId;
            LocalStorageService.setShoppingCartId(cart.cartId);
        }
    }, [cart]);
    const toggleShoppingCartModal = (open: boolean) => setOpenPanel(open);

    return (
        <ShoppingCartContext.Provider
            value={{
                toggleShoppingCartModal,
            }}
        >
            {children}
            <SlidePanel
                title={"Shopping cart"}
                open={openPanel}
                onClose={() => setOpenPanel(false)}
                padding={"0"}
                children={<ShoppingCart />}
            />
        </ShoppingCartContext.Provider>
    );
};

export default ShoppingCartProvider;
export const useShoppingCartProvider = () => {
    const context = React.useContext(ShoppingCartContext);
    if (context === undefined) {
        throw new Error("useReservationCalendarContext must be used within a ContextProvider");
    }
    return context;
};
