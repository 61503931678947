import React from "react";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import parsePhoneNumberFromString from "libphonenumber-js";
import messageService from "shared/services/MessageService";
import { TextMessage } from "shared/models/SignalR";
import { LinearProgress } from "@mui/material";
import ContinueConversation from "./ContinueConversation";
import StartConversation from "./StartConversation";
import { Nullable } from "shared/models/Generals";
import SlidePanel from "shared/components/SlidePanel";
import useNotification, { NotificationsType } from "shared/hooks/useNotification";

interface IDialogProps {
    phoneNumber?: Nullable<string>;
    renderButton?: (callback: any) => React.ReactNode;
    open?: boolean;
    onClose?: () => void;
}

const ConversationDialog = (props: IDialogProps) => {
    const { showNotification } = useNotification();
    const messages: MutableRefObject<TextMessage[]> = useRef([]);
    const [conversationNumber, setConversationNumber] = useState<string | null>(null);
    const [allMessages, setAllMessages] = useState<TextMessage[]>([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (props.open !== undefined) {
            setOpen(props.open);
        }
    }, [props.open]);

    useEffect(() => {
        if (open && props.phoneNumber) {
            setConversationNumber(props.phoneNumber);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        if (conversationNumber !== null) {
            startConversation(conversationNumber);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversationNumber]);

    const handleClose = () => {
        messages.current = [];
        setAllMessages([]);
        setConversationNumber(null);
        endConversation();
        if (props.onClose) {
            props.onClose();
        }
    };

    const sortDescendingByDate = (a: TextMessage, b: TextMessage): number => {
        if (!a.sendDate && !b.sendDate) {
            return 0;
        } else if (!a.sendDate) {
            return -1;
        } else if (!b.sendDate) {
            return 1;
        } else {
            return new Date(a.sendDate).getTime() - new Date(b.sendDate).getTime();
        }
    };

    const startConversation = async (phone: string) => {
        setLoading(true);
        try {
            await messageService.startConversation(phone);
            getMessages(phone);
        } catch {
            showNotification({
                message: "Unable to start conversation",
                type: NotificationsType.error,
            });
            setLoading(false);
        }
    };

    const endConversation = async () => {
        if (conversationNumber !== null) {
            await messageService.endConversation(conversationNumber);
        }
    };

    const reload = () => {
        if (conversationNumber) {
            getMessages(conversationNumber);
        }
    };

    const getMessages = async (phone: string): Promise<void> => {
        messages.current = await messageService.getTextMessagesByPhone(phone);
        const combined = [...messages.current].sort(sortDescendingByDate);
        const list: string[] = [];
        combined.filter((m) => m.isUnread === true).forEach((x) => list.push(x.smsSid));
        setAllMessages(combined);
        if (list.length) {
            await messageService.setMessagesRead(list);
        }
        setLoading(false);
    };

    const formatPhone = (phone: string) => {
        const phoneNumber = parsePhoneNumberFromString(phone);
        if (!phoneNumber || !phoneNumber.isValid()) {
            return phone;
        }
        return phoneNumber.formatNational();
    };

    return (
        <React.Fragment>
            {props.renderButton ? props.renderButton(() => setOpen(true)) : null}
            <SlidePanel
                title={
                    conversationNumber
                        ? `Send text message ${formatPhone(conversationNumber)}`
                        : "Enter phone number to start conversation"
                }
                open={open}
                onClose={() => [setOpen(false), handleClose()]}
            >
                <div className="textMessageDialog">
                    {loading ? <LinearProgress /> : null}
                    {conversationNumber ? (
                        <ContinueConversation
                            phoneNumber={conversationNumber}
                            messages={allMessages}
                            reload={() => reload()}
                        />
                    ) : (
                        <StartConversation
                            setPhone={(phoneNumber: string) => {
                                setConversationNumber(phoneNumber);
                            }}
                        />
                    )}
                </div>
            </SlidePanel>
        </React.Fragment>
    );
};

export default ConversationDialog;
