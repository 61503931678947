import { Skeleton, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

const NewDetailsSkeleton = () => {
    return (
        <Grid container spacing={3}>
            <Grid xs={12}>
                <Typography variant={"h5"}>
                    <Skeleton variant="text" width={"60%"} />
                </Typography>
                <Typography variant={"h6"}>
                    <Skeleton variant="text" width={"40%"} />
                </Typography>
                <Typography variant={"subtitle1"}>
                    <Skeleton variant="text" width={"30%"} />
                </Typography>
            </Grid>
            <Grid xs={12} container spacing={1}>
                <Grid xs={6}>
                    <Typography variant={"subtitle1"} gutterBottom>
                        <Skeleton variant="text" width={"100%"} />
                    </Typography>
                </Grid>
                <Grid xs={6}>
                    <Typography variant={"subtitle1"} gutterBottom>
                        <Skeleton variant="text" width={"100%"} />
                    </Typography>
                </Grid>
                <Grid xs={6}>
                    <Typography variant={"subtitle1"} gutterBottom>
                        <Skeleton variant="text" width={"100%"} />
                    </Typography>
                </Grid>
                <Grid xs={6}>
                    <Typography variant={"subtitle1"} gutterBottom>
                        <Skeleton variant="text" width={"100%"} />
                    </Typography>
                </Grid>
                <Grid xs={12}>
                    <Typography variant={"subtitle1"} gutterBottom>
                        <Skeleton variant="text" width={"100%"} />
                    </Typography>
                </Grid>
                <Grid xs={12}>
                    <Skeleton variant="rectangular" width={"100%"} height={120} />
                </Grid>
            </Grid>
        </Grid>
    );
};
export default NewDetailsSkeleton;
