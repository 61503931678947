import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { NotificationsType } from "shared/hooks/useNotification";

interface NotificationPayload {
    open?: boolean;
    message: string;
    type: NotificationsType;
    duration?: number;
}
const initialState: NotificationPayload = {
    open: false,
    message: "",
    type: NotificationsType.success,
};

const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        setNotification: (state, action: PayloadAction<NotificationPayload>) => {
            const { message, duration, type } = action.payload;
            state.open = true;
            state.message = message;
            state.duration = duration;
            state.type = type;
        },
        clearNotification: (state) => {
            state.open = false;
            state.message = "";
        },
    },
});

export const { setNotification, clearNotification } = notificationSlice.actions;

const notificationSliceReducer = notificationSlice.reducer;
export default notificationSliceReducer;
