import { CombinedReservation, CompanyResources, TimelineEvent } from "shared/models/Resources";
import { z } from "zod";
import { InterfaceToZod } from "./UtilsTypes";
import { ResourceItems, ResourceTypes, TypeGroup } from "./Resources";
import { Schedules } from "./Schedules";

export const ResourceItemSchema = z.object<InterfaceToZod<ResourceItems>>({
    resourceItemID: z.number(),
    available: z.boolean(),
    dirty: z.boolean(),
    inStock: z.number(),
    name: z.string().nullable(),
    scheduleId: z.number().nullable(),
    startDateTime: z.string().nullable(),
    turnaroundSeconds: z.number(),
    ud: z.string().nullable(),
    uu: z.string().nullable(),
});

export const TypeGroupSchema = z.object<InterfaceToZod<TypeGroup>>({
    available: z.boolean(),
    resourceTypeId: z.number().optional(),
    resourceItemID: z.number(),
    dirty: z.boolean(),
    ud: z.string().nullable(),
    uu: z.string().nullable(),
    priority: z.number(),
    remove: z.boolean(),
    tickets: z.number(),
});
export const ResourceTypesSchema = z.object<InterfaceToZod<ResourceTypes>>({
    dirty: z.boolean(),
    name: z.string(),
    ud: z.string().nullable(),
    uu: z.string().nullable(),
    fitOnSingle: z.boolean(),
    resourceTypeId: z.number(),
    typeGroup: z.array(TypeGroupSchema),
});
export const SchedulesSchema = z.object<InterfaceToZod<Schedules>>({
    name: z.string(),
    scheduleId: z.number(),
});
export const CompanyResourcesSchema = z.object<InterfaceToZod<CompanyResources>>({
    resourceItems: z.array(ResourceItemSchema),
    resourceTypes: z.array(ResourceTypesSchema),
    schedules: z.array(SchedulesSchema),
});
export const CombinedReservationSchema = z.object<InterfaceToZod<CombinedReservation>>({
    name: z.string(),
    reservationId: z.number(),
    phone: z.string().nullable(),
});
export const TimeLineEventSchema = z.object<InterfaceToZod<TimelineEvent>>({
    startDateTime: z.string(),
    resourceTypeId: z.number(),
    id: z.number(),
    className: z.string(),
    phoneFormatted: z.string().nullable(),
    resourceItemId: z.number(),
    resourceItemName: z.string(),
    endDateTime: z.string(),
    resourceTypeName: z.string(),
    comment: z.string().nullable(),
    eventId: z.number(),
    reservationId: z.number(),
    eventCode: z.string().nullable(),
    reservationHolderName: z.string().nullable(),
    reservationHolderPhone: z.string().nullable(),
    combinedReservations: z.array(CombinedReservationSchema).nullable(),
});
export const TimeLineEventListSchema = z.array(TimeLineEventSchema);
