import { CartDTO } from "../../shared/models/Cart";

export default class CartUtils {
    static calculateCartTaxesAndFees(cart: CartDTO): number {
        const tax: number = cart?.tax || 0;
        const buyerServiceCharge: number = cart?.buyerServiceCharge || 0;
        return tax + buyerServiceCharge;
    }
    static calculateGiftCardTotal(cart: CartDTO) {
        let appliedGiftCards = 0;
        cart.appliedGiftCards.forEach((giftCard) => (appliedGiftCards += giftCard.balance));
        return appliedGiftCards;
    }
    static calculateCatalogItemsDiscount(cart: CartDTO) {
        return cart.catalogItems.reduce((sum, item) => sum + item.discount, 0);
    }
    static calculateReservationsDiscount(cart: CartDTO) {
        let discount = 0;
        cart.reservations.forEach((reservation) => {
            discount += reservation.discount ?? 0;
            reservation.addOns.forEach((addOn) => (discount += addOn.discount ?? 0));
        });
        return discount;
    }
    static calculateDiscount(cart: CartDTO) {
        return this.calculateReservationsDiscount(cart) + this.calculateCatalogItemsDiscount(cart);
    }
    static calculateCartTotal(cart: CartDTO): number {
        const charges = this.calculateCartTaxesAndFees(cart);
        const appliedGiftCards = this.calculateGiftCardTotal(cart);
        return cart.subTotal + charges - appliedGiftCards;
    }

    static getNumItems(cart: CartDTO): number {
        if (!cart) return 0;
        const catalogItems = cart.catalogItems.length;
        const reservations = cart.reservations.length;
        return Number(catalogItems + reservations);
    }
}
