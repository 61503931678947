import React, { FunctionComponent, useEffect, useState } from "react";
import {
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
    Typography,
} from "@mui/material";
import { Financial } from "shared/utils/Financials";
import DialogComponent from "shared/components/DialogComponent";
import { InnerTransactionRow } from "shared/components/InnerTransactionRow";
import { TransactionReservationDetail } from "shared/models/Reservation";
import { RefundAmountField } from "./RefundAmountField";
import { updateOrAppendToArray } from "shared/utils/Common";
import { CurrencyComponent } from "../../Reports/components/CurrencyComponent";

export interface RefundAmounts {
    amount: number;
    tax: number;
    serviceCharge: number;
}

interface Props {
    maxRefund: RefundAmounts;
    taxRate: number;
    onSet: (arg: RefundAmounts, refundTickets: TransactionReservationDetail[]) => void;
    onCancel: () => void;
    open: boolean;
    ticketEdits?: TransactionReservationDetail[];
}

type RefundType = "amount" | "tickets";
const RefundReservationDialog: FunctionComponent<Props> = (props) => {
    const { maxRefund, taxRate, open } = props;
    const ticketEdits = props.ticketEdits || [];
    const [refundType, setRefundType] = useState<RefundType>("amount");
    const [refundData, setRefundData] = useState<RefundAmounts>({
        amount: 0,
        tax: 0,
        serviceCharge: 0,
    });
    const [refundTickets, setRefundTickets] = useState<TransactionReservationDetail[]>([]);
    useEffect(() => {
        setRefundData({
            amount: 0,
            tax: 0,
            serviceCharge: 0,
        });
    }, [props.maxRefund]);

    const handleSetRefundAmounts = () => {
        props.onSet(refundData, refundTickets);
    };
    const handleUpdateRefundAmount = (value: number) => {
        const val = Math.min(value, maxRefund.amount);
        const temp: Partial<RefundAmounts> = {
            amount: val,
            tax: Financial.CalculateTax(val, 1, taxRate),
        };
        setRefundData((s) => ({ ...s, ...temp }));
    };
    const handleTicketsRefund = (quantity: number, ticket: TransactionReservationDetail) => {
        const idx = refundTickets.findIndex((el) => el.rateId === ticket.rateId);
        const newTickets = updateOrAppendToArray(
            refundTickets,
            { ...ticket, tickets: quantity },
            idx,
        );
        const amount = newTickets.reduce((sum, curr) => {
            sum += curr.tickets * curr.rate;
            return sum;
        }, 0);

        setRefundData((s) => {
            s.amount = amount;
            s.tax = Financial.CalculateTax(amount, 1, taxRate);
            return s;
        });
        setRefundTickets(newTickets);
    };
    return (
        <DialogComponent
            open={open}
            handleClose={props.onCancel}
            DialogText="Set the amount you want to refund"
            ButtonActions={{
                handleSuccessActionText: "Set",
                handleSuccessActionFunction: handleSetRefundAmounts,
                handleCancelActionText: "Cancel",
                handleCancelActionFunction: props.onCancel,
            }}
        >
            <Typography variant="inputLabel" gutterBottom>
                Maximum refundable amounts
            </Typography>
            <AmountsTable
                data={{
                    amount: maxRefund.amount,
                    tax: maxRefund.tax,
                    serviceCharge: maxRefund.serviceCharge,
                }}
            />
            {ticketEdits.length > 0 ? (
                <Tabs
                    value={refundType}
                    sx={{
                        margin: "0.5rem 0",
                    }}
                    onChange={(el, val) => setRefundType(val)}
                >
                    <Tab label="Refund by amount" value={"amount"} />
                    <Tab label="Refund by tickets" value={"tickets"} />
                </Tabs>
            ) : null}
            {refundType === "amount" ? (
                <>
                    <Typography
                        variant="commentContent"
                        component={"p"}
                        sx={{ marginBottom: "0.5rem" }}
                    >
                        Enter the amounts you want to refund
                    </Typography>
                    <Typography variant="idReservation">Refund amount</Typography>
                    <RefundAmountField
                        value={refundData.amount}
                        max={maxRefund.amount}
                        onChange={handleUpdateRefundAmount}
                    />
                </>
            ) : null}
            {refundType === "tickets" && ticketEdits.length > 0 ? (
                <InnerTransactionRow
                    dispute={false}
                    transactionTickets={ticketEdits}
                    open={true}
                    handleTicketSelection={handleTicketsRefund}
                    hasRequirtedTickets={false}
                    getStacked={true}
                />
            ) : null}
            {maxRefund.serviceCharge > 0 ? (
                <>
                    <Typography variant="idReservation">Refund service charge</Typography>

                    <RefundAmountField
                        value={refundData.serviceCharge}
                        max={maxRefund.serviceCharge}
                        onChange={(d) => setRefundData((s) => ({ ...s, serviceCharge: d }))}
                    />
                </>
            ) : null}

            <Typography variant="inputLabel" gutterBottom>
                You'll refund
            </Typography>
            <AmountsTable data={refundData} />
        </DialogComponent>
    );
};

export default RefundReservationDialog;

interface AmountsTableProps {
    data: RefundAmounts;
}

const AmountsTable = (props: AmountsTableProps) => {
    return (
        <Table className="transaction-table">
            <TableHead>
                <TableRow>
                    <TableCell>Amount</TableCell>
                    <TableCell>Tax</TableCell>
                    <TableCell>Fees</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>
                        <CurrencyComponent value={props.data.amount} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <CurrencyComponent value={props.data.serviceCharge} />
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};
