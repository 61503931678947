import { ReservationNotifedName, ReservationNotified } from "shared/models/Reservation";
import { useIndexicTheme } from "theme/useIndexicTheme";
import DialogComponent, { DialogHooks } from "./DialogComponent";
import {
    Badge,
    Box,
    Chip,
    ChipProps,
    IconButton,
    List,
    ListItem,
    ListItemText,
    useMediaQuery,
} from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import { getAllStatusByNotification, getStatusNotifedName } from "shared/utils/ReservationUtils";
import ThreePOutlinedIcon from "@mui/icons-material/ThreePOutlined";

interface NotificationStatusProps {
    data: number;
}

const NotificationStatus = (props: NotificationStatusProps) => {
    const highlighted = [
        ReservationNotified.FollowUpEmailSent,
        ReservationNotified.StopFollowUpEmail,
        ReservationNotified.SmsConfirmed,
        ReservationNotified.SmsRejected,
    ];
    const isMobileSM = useMediaQuery("(max-width:450px)");
    const [open, handleClickOpen, handleClose] = DialogHooks();
    const notifications = getAllStatusByNotification(props.data);
    if (notifications.length === 0) return null;
    let highlightedNotifications = notifications.filter((el) => highlighted.includes(el));
    if (isMobileSM && highlightedNotifications.length > 0) {
        highlightedNotifications = highlightedNotifications.slice(0, 1);
    }
    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                }}
            >
                {!isMobileSM ? (
                    <IconButton color={"primary"} onClick={() => handleClickOpen()} sx={{ mr: 1 }}>
                        <Badge badgeContent={notifications.length} color={"primary"}>
                            <ThreePOutlinedIcon />
                        </Badge>
                    </IconButton>
                ) : null}
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {highlightedNotifications.map((notification, index) => (
                        <ChipStatus key={index} label={getStatusNotifedName(notification)} />
                    ))}
                    {isMobileSM ? (
                        <ChipStatus
                            onClick={handleClickOpen}
                            main={true}
                            label={`View ${notifications.length} notification${
                                notifications.length > 1 ? "s" : ""
                            }`}
                        />
                    ) : null}
                </div>
            </div>
            <DialogComponent open={open} DialogText="Notifications" handleClose={handleClose}>
                <Box display="flex" flexDirection="column" justifyContent="center">
                    <List>
                        {Object.keys(ReservationNotifedName).map((value) => (
                            <ListNotificationItem
                                key={value}
                                notification={props.data}
                                notificationType={value}
                            />
                        ))}
                    </List>
                </Box>
            </DialogComponent>
        </>
    );
};

interface ChipStatusProps extends Omit<ChipProps, "size"> {
    main?: boolean;
}
const ChipStatus = (props: ChipStatusProps) => {
    const theme = useIndexicTheme();
    if (!props.label) {
        return null;
    }
    return (
        <Chip
            {...props}
            size="small"
            sx={{
                borderRadius: "4px",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: theme.palette.primary.main,
                background: props.main ? theme.palette.primary.main : theme.palette.grey[100],
                color: props.main ? theme.palette.common.white : theme.palette.primary.main,
                fontSize: "14px",
                mt: 1,
            }}
        />
    );
};
interface ListNotificationItemProps {
    notification: number; // Comes from the server
    notificationType: string; // It's the type of given item
}
const ListNotificationItem = (props: ListNotificationItemProps) => {
    const notificationIsSet = (props.notification & Number(props.notificationType)) > 0;
    const message = getStatusNotifedName(parseInt(props.notificationType));
    return (
        <ListItem>
            {notificationIsSet ? (
                <CheckCircle color={"primary"} />
            ) : (
                <CheckCircle color={"primary"} style={{ opacity: 0 }} />
            )}
            <ListItemText primary={message} style={{ marginLeft: "4px" }} />
        </ListItem>
    );
};

export default NotificationStatus;
