import React from "react";

import { Typography, Grid } from "@mui/material";
import { DateTimeUtils } from "shared/utils/DateTimeUtils";
import { formatDistanceToNow, isMatch } from "date-fns";

export interface AnswerProps {
    answer: string[];
    question: string;
}

const Answer = (props: AnswerProps) => {
    const castResponse = (value: string): string => {
        switch (value) {
            case "true":
                return "Yes";
            case "false":
                return "No";
            default:
                return value;
        }
    };

    const isAnswerDate = (date: string) => {
        return (
            <>
                {DateTimeUtils.formatDateWithoutTime(date.trim())}{" "}
                <strong>
                    {formatDistanceToNow(new Date(date.trim()).setHours(24, 0, 0, 0), {
                        addSuffix: true,
                    })}
                </strong>
            </>
        );
    };

    const BuildAnswers = () => {
        let answers: string = props.answer?.reduce((acc, value, idx) => {
            acc = acc + " " + castResponse(value);
            if (idx < props.answer?.length - 1) {
                acc += ",";
            }
            return acc;
        }, "");
        if (isMatch(answers.trim(), "yyyy-MM-dd")) {
            //validation to identify if the string match with the format of a date
            return (
                <Typography style={{ lineHeight: 1.2 }} color={"textSecondary"}>
                    {isAnswerDate(answers)}
                </Typography>
            );
        } else {
            return (
                <Typography style={{ lineHeight: 1.2 }} color={"textSecondary"}>
                    {answers}
                </Typography>
            );
        }
    };

    return (
        <>
            <Grid container direction="row" style={{ paddingBottom: 4 }}>
                <Grid item xs={6} style={{ paddingRight: 5 }}>
                    <Typography style={{ lineHeight: 1.2 }}>{props.question}</Typography>
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 5 }}>
                    {BuildAnswers()}
                </Grid>
            </Grid>
        </>
    );
};

export default Answer;
