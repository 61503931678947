import React, { FunctionComponent } from "react";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import { Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useIndexicTheme } from "theme/useIndexicTheme";

interface EmptyShoppingCartIndicatorProps {
    showRedirect?: boolean;
}

export const EmptyShoppingCartIndicator: FunctionComponent<EmptyShoppingCartIndicatorProps> = (
    props,
) => {
    const theme = useIndexicTheme();
    return (
        <div
            style={{
                padding: "1.5rem",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "1rem",
                paddingTop: "5rem",
            }}
        >
            <ProductionQuantityLimitsIcon fontSize={"large"} />
            <Typography
                variant={"h2"}
                sx={{
                    color: theme.palette.grey["600"],
                    fontStyle: "italic",
                    fontWeight: "400",
                }}
            >
                Shopping cart empty
            </Typography>
            {props.showRedirect ? (
                <Link component={RouterLink} to={"/event/calendar"}>
                    Go to calendar
                </Link>
            ) : null}
        </div>
    );
};
