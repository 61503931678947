// TASK-2535 - Remove
import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    styled,
    IconButton,
    DialogProps,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const DialogHooks = () => {
    const [open, setOpen] = useState<boolean>(false);

    const handleClickOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };
    return [open, handleClickOpen, handleClose] as const;
};
export interface DialogSettings {
    open: boolean;
    handleClose: (reason?: string) => void;
    children?: any;
    DialogText?: string;
    ButtonActions?: {
        handleCancelActionText?: string;
        handleCancelActionFunction?: () => void;
        handleSuccessActionText?: string;
        handleSuccessActionFunction?: () => void;
        loadingSuccess?: boolean;
    };
    isFullSize?: boolean;
    maxWidth?: "lg" | "md" | "sm" | "xl" | "xs" | false;
    customButtons?: React.ReactNode;
    showUnderDrawer?: boolean;
}

const BootstrapDialog = styled(Dialog, {
    shouldForwardProp: (prop) => prop !== "showUnderDrawer",
})<DialogProps & { showUnderDrawer?: boolean }>(({ theme, showUnderDrawer }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
    zIndex: showUnderDrawer ? "1150!important" : undefined,
}));

const DialogComponent = ({
    open,
    handleClose,
    ButtonActions,
    children,
    DialogText,
    isFullSize = false,
    maxWidth,
    customButtons,
    showUnderDrawer,
}: DialogSettings) => {
    const handleDialogClose: DialogProps["onClose"] = (target, reason) => {
        handleClose(reason);
    };
    return (
        <BootstrapDialog
            fullScreen={isFullSize}
            open={open}
            onClose={handleDialogClose}
            aria-labelledby={DialogText}
            maxWidth={maxWidth ?? "lg"}
            showUnderDrawer={showUnderDrawer}
        >
            <DialogTitle>{DialogText}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => handleClose("click")}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent
                style={{
                    backgroundColor: "white",
                }}
            >
                {children}
            </DialogContent>
            {ButtonActions ? (
                <DialogActions>
                    {ButtonActions.handleCancelActionFunction && (
                        <Button
                            onClick={ButtonActions.handleCancelActionFunction}
                            color="primary"
                            autoFocus
                        >
                            {ButtonActions.handleCancelActionText}
                        </Button>
                    )}
                    {ButtonActions.handleSuccessActionFunction && (
                        <Button
                            disabled={ButtonActions.loadingSuccess}
                            onClick={ButtonActions.handleSuccessActionFunction}
                            color="primary"
                            variant={"contained"}
                        >
                            {ButtonActions.loadingSuccess
                                ? "...Please Wait"
                                : ButtonActions.handleSuccessActionText}
                        </Button>
                    )}
                </DialogActions>
            ) : (
                customButtons && <DialogActions>{customButtons}</DialogActions>
            )}
        </BootstrapDialog>
    );
};

export default DialogComponent;
