import React from 'react'
import { RadioGroup, FormControlLabel, Radio } from '@mui/material'
import { Locale } from 'shared/utils/Locale'

export interface IBooleanQuestionProps {
  value: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur: () => void
}

export function BooleanQuestion(props: IBooleanQuestionProps) {
  return (
    <RadioGroup
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
    >
      <FormControlLabel
        value="true"
        control={<Radio />}
        label={Locale.yes}
      />
      <FormControlLabel
        value="false"
        control={<Radio />}
        label={Locale.no}
      />
    </RadioGroup>
  )
}
