import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useUserSettingsContext } from "./UserSettingsContext";
import {
    FormControl,
    FormLabel,
    Select,
    MenuItem,
    TextField,
    SelectChangeEvent,
    InputLabel,
} from "@mui/material";
import resourceService from "shared/services/ResourceService";
import { useQuery } from "@tanstack/react-query";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";

const UserResourceCalendarSection = () => {
    const { companyUser, updateUserPreferences, updateCompanyPreferences } =
        useUserSettingsContext();
    const [eventResources, setEventResources] = useState<string[]>([]);
    const [minCalendarHour, setMinCalendarHour] = useState<number>(0);
    const [maxCalendarHour, setMaxCalendarHour] = useState<number>(24);

    const resourceTypes = useQuery(["resource-types"], () => resourceService.GetCompanyResources());

    useEffect(() => {
        if (
            companyUser.companyPreferences &&
            companyUser.companyPreferences.resourceCalendarDefaultTypes
        ) {
            let result = resourceTypes.data?.resourceTypes
                .filter((obj) =>
                    companyUser.companyPreferences?.resourceCalendarDefaultTypes!.includes(
                        obj.resourceTypeId,
                    ),
                )
                .map((obj) => obj.name);
            if (result) {
                setEventResources(result);
            }
        }
    }, [companyUser, resourceTypes.data]);

    const handleChange = (event: SelectChangeEvent<typeof eventResources>) => {
        const { value } = event.target;
        const resource = typeof value === "string" ? value.split(",") : value;
        setEventResources(resource);
        let result = resourceTypes.data?.resourceTypes
            .filter((obj) => resource.includes(obj.name))
            .map((obj) => obj.resourceTypeId);
        //to avoid unnecessary renders and go into a loop, we need to look up for the sizes of each array if they are different it means that there was a change
        updateCompanyPreferences({ resourceCalendarDefaultTypes: result });
    };

    return (
        <Grid container spacing={2}>
            <Grid xs={12}>
                <FormControl component="fieldset" fullWidth>
                    <FormLabel component="legend">First hour to display on calendar</FormLabel>
                    <TextField
                        id="calendarFirstHour"
                        type="number"
                        name="calendarFirstHour"
                        value={companyUser.userPreferences?.calendarFirstHour}
                        InputProps={{
                            inputProps: {
                                max: maxCalendarHour - 1,
                                min: 0,
                                maxLength: 2,
                            },
                        }}
                        onChange={(event) => {
                            if (
                                event.target.value.length < 3 &&
                                Number(event.target.value) < maxCalendarHour &&
                                Number(event.target.value) >= 0
                            ) {
                                setMinCalendarHour(Number(event.target.value));
                                updateUserPreferences({
                                    ...companyUser,
                                    calendarFirstHour: Number(event.target.value),
                                });
                            }
                        }}
                        onFocus={(e) => e.target.select()}
                        fullWidth
                        size="small"
                    />
                </FormControl>
            </Grid>
            <Grid xs={12}>
                <FormControl component="fieldset" fullWidth>
                    <FormLabel component="legend">Last hour to display on calendar</FormLabel>
                    <TextField
                        id="calendarLastHour"
                        name="calendarLastHour"
                        type="number"
                        value={companyUser.userPreferences?.calendarLastHour!}
                        error={
                            Number(companyUser.userPreferences?.calendarLastHour!) <=
                            minCalendarHour
                        }
                        helperText={
                            Number(companyUser.userPreferences?.calendarLastHour!) <=
                            minCalendarHour
                                ? "You can not set Last hour less than First hour"
                                : ""
                        }
                        inputProps={{ max: 24, min: minCalendarHour + 1, maxLength: 2 }}
                        onChange={(event) => {
                            if (event.target.value.length < 3 && Number(event.target.value) <= 24) {
                                setMaxCalendarHour(Number(event.target.value));
                                updateUserPreferences({
                                    ...companyUser,
                                    calendarLastHour: Number(event.target.value),
                                });
                            }
                        }}
                        onFocus={(e) => e.target.select()}
                        fullWidth
                        size="small"
                    />
                </FormControl>
            </Grid>
            <Grid xs={12}>
                {!resourceTypes.isLoading && (
                    <FormControl fullWidth>
                        <FormLabel component="legend">Default resource groups</FormLabel>
                        <Select
                            multiple
                            size="small"
                            value={eventResources}
                            onChange={handleChange}
                            input={<OutlinedInput />}
                            MenuProps={MenuProps}
                            renderValue={(selected) => (
                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} />
                                    ))}
                                </Box>
                            )}
                        >
                            <MenuItem disabled value="">
                                <em>Default resource groups</em>
                            </MenuItem>
                            {resourceTypes.data?.resourceTypes!.map((types) => (
                                <MenuItem key={types.resourceTypeId} value={types.name}>
                                    {types.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </Grid>
        </Grid>
    );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
export default UserResourceCalendarSection;
