import { HttpService, createUrl } from "shared/http/http";

class ReceiptReportService extends HttpService {
    constructor() {
        super("/Receipt");
    }

    async getReceiptReport(transactionId: string) {
        const uri = createUrl([transactionId]);
        const result = await this.get<string>(uri);
        return result.data;
        // return ValidateSchema<string>(result.data, z.literal());
    }
}

const receiptReportService = new ReceiptReportService();
export default receiptReportService;
