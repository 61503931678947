import { HttpService, createUrl } from "shared/http/http";
import { formatISO } from "date-fns";
import {
    CommissionReportItem,
    DeliveryReportReport,
    SalesByEmployeeReport,
    SalesByEmployeeOption,
    TicketTypeSummaryReport,
    ReservationByEmployeeReport,
    PayoutSummaryReport,
    PayoutDetailReport,
    TransactionReport,
    CashCategorySummary,
    TipReportSummary,
    SalesByDateRange,
    PartnerReportSummary,
    DailyReservationReportEvent,
} from "shared/models/Report";
import { EventsByPersonnel } from "shared/models/Event";
import { ValidateSchema } from "shared/utils/ZodValidations";
import {
    CashCategorySummariesSchema,
    CommissionReportSchema,
    DailyReservationReportEventSchema,
    DeliveryReportReportSchema,
    PartnerReportSummarySchema,
    PayoutDetailReportSchema,
    PayoutSummaryReportSchema,
    ReservationByEmployeeReportSchema,
    SalesByDateRangeSchema,
    SalesByEmployeeReportSchema,
    TicketTypeSummaryReportSchema,
    TipReportSummaries,
    TransactionReportSchema,
} from "shared/models/Report.schema";
import { EventsByPersonnelSchema } from "shared/models/Event.schema";
import { z } from "zod";

class ReportsService extends HttpService {
    constructor() {
        super("/portal/Report");
    }

    async getReservationByEmployeeReport(start: Date, end: Date) {
        const uri = createUrl(["ReservationsByEmployee", formatISO(start), formatISO(end)]);
        const result = await this.get<Array<ReservationByEmployeeReport>>(uri);
        return ValidateSchema(result.data, z.array(ReservationByEmployeeReportSchema));
    }

    async getPayoutSummaryReport(start: Date, end: Date) {
        const uri = createUrl(["PayoutSummaryReport", start, end]);
        const result = await this.get<Array<PayoutSummaryReport>>(uri);
        return ValidateSchema<Array<PayoutSummaryReport>>(
            result.data,
            z.array(PayoutSummaryReportSchema),
        );
    }

    async getPayoutDetailsReport(payoutId: string) {
        const uri = createUrl(["PayoutDetailReport", payoutId]);
        const result = await this.get<Array<PayoutDetailReport>>(uri);
        return ValidateSchema<Array<PayoutDetailReport>>(
            result.data,
            z.array(PayoutDetailReportSchema),
        );
    }

    async getDeliveryReport(queryDate: Date) {
        const uri = createUrl(["DeliveryReport", queryDate.toISOString()]);
        const result = await this.get<Array<DeliveryReportReport>>(uri);
        return ValidateSchema<Array<DeliveryReportReport>>(
            result.data,
            z.array(DeliveryReportReportSchema),
        );
    }

    async getAssignedPersonnelSummaryReport(start: Date, end: Date) {
        const uri = createUrl(["AssignedPersonnelSummaryReport", formatISO(start), formatISO(end)]);
        const result = await this.get<Array<EventsByPersonnel>>(uri);
        return ValidateSchema<Array<EventsByPersonnel>>(
            result.data,
            z.array(EventsByPersonnelSchema),
        );
    }

    async getSalesByReport(start: Date, end: Date, option: SalesByEmployeeOption) {
        const uri = createUrl([
            "SalesByEmployeeReport",
            formatISO(start),
            formatISO(end),
            option.toString(),
        ]);
        const result = await this.get<Array<SalesByEmployeeReport>>(uri);
        return ValidateSchema<Array<SalesByEmployeeReport>>(
            result.data,
            z.array(SalesByEmployeeReportSchema),
        );
    }

    async getCommissionReport(start: Date, end: Date, isReservationDate = false) {
        const uri = createUrl(["CommissionReport", start, end, isReservationDate]);
        const result = await this.get<Array<CommissionReportItem>>(uri);
        return ValidateSchema<Array<CommissionReportItem>>(
            result.data,
            z.array(CommissionReportSchema),
        );
    }

    async getTicketTypeSummaryReport(start: Date, end: Date) {
        const uri = createUrl(["TicketTypeSummary", formatISO(start), formatISO(end)]);
        const result = await this.get<Array<TicketTypeSummaryReport>>(uri);
        return ValidateSchema<Array<TicketTypeSummaryReport>>(
            result.data,
            z.array(TicketTypeSummaryReportSchema),
        );
    }

    async getTransactionReport(start: Date, end: Date) {
        const uri = createUrl(["TransactionReport", start, end]);
        const result = await this.get<TransactionReport>(uri);
        return ValidateSchema<TransactionReport>(result.data, TransactionReportSchema);
    }

    async getCashCategoryReport(
        start: Date,
        end: Date,
        categories: number[],
    ): Promise<CashCategorySummary[]> {
        let uri = createUrl(["CashCategoryReport", start, end]);
        if (categories.length > 0) {
            uri += "/" + categories.join();
        }
        const result = await this.get<CashCategorySummary[]>(uri);
        return ValidateSchema(result.data, CashCategorySummariesSchema);
    }

    async getTipReport(start: Date, end: Date) {
        const uri = createUrl(["TipReport", start, end]);
        const result = await this.get<TipReportSummary[]>(uri);
        return ValidateSchema(result.data, TipReportSummaries);
    }

    async getSalesByDateReport(start: Date, end: Date, eventGroupId: number) {
        const uri = createUrl(["SalesByDateRange", start, end]);
        let result;
        if (eventGroupId === -1) {
            result = await this.get<SalesByDateRange>(uri);
        } else {
            result = await this.get<SalesByDateRange>(uri, { params: { eventGroupId } });
        }
        return ValidateSchema(result.data, SalesByDateRangeSchema);
    }

    async getPartnerReport(start: Date, end: Date, byReservationDate: boolean) {
        const uri = createUrl(["PartnerReport", start, end, byReservationDate]);
        const result = await this.get<PartnerReportSummary>(uri);
        return ValidateSchema<PartnerReportSummary>(result.data, PartnerReportSummarySchema);
    }

    async getDailyReservationsReport(
        start: Date,
        onlyActive: boolean,
    ): Promise<DailyReservationReportEvent[]> {
        const uri = createUrl(["DailyReservations", start]);
        const result = await this.get<DailyReservationReportEvent[]>(uri, {
            params: { onlyActive },
        });
        return ValidateSchema(result.data, z.array(DailyReservationReportEventSchema));
    }
}

const reportService = new ReportsService();
export default reportService;
