import { Button, Tooltip } from "@mui/material";
import React, { ReactElement, useState } from "react";

interface Props {
    generateReport: () => void;
    handlePrintReport?: () => void;
    displayPrint?: boolean;
    disableReport?: boolean;
    extraComponent?: ReactElement;
}

const ActionsComponent = (props: Props) => {
    const [tooltip, setTooltip] = useState("");
    const handleGenerateReport = () => {
        if (props.disableReport) {
            setTooltip("You don't have access to the current report");
            return;
        }
        setTooltip("");
        props.generateReport();
    };
    return (
        <div style={{ display: "flex", flexDirection: "row", gap: 5, width: "100%" }}>
            <Tooltip title={tooltip}>
                <Button variant={"contained"} color={"primary"} onClick={handleGenerateReport}>
                    Generate report
                </Button>
            </Tooltip>
            {props.handlePrintReport && props.displayPrint ? (
                <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={() => props.handlePrintReport!()}
                >
                    Print report
                </Button>
            ) : null}
            {props.extraComponent ? props.extraComponent : null}
        </div>
    );
};

export default ActionsComponent;
