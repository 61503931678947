import { z } from "zod";
import { InterfaceToZod } from "./UtilsTypes";
import { AssignedSeat, AssignedSeatsCategory, SelectedSeats } from "./Seats";

export const AssignedSeatSchema = z.object<InterfaceToZod<AssignedSeat>>({
    seat: z.string(),
});
export const AssignedSeatsCategorySchema = z.object<InterfaceToZod<AssignedSeatsCategory>>({
    categoryName: z.string(),
    assignedSeats: z.array(AssignedSeatSchema),
    seatAssignmentCategoryId: z.number(),
});
export const SelectedSeatsSchema = z.object<InterfaceToZod<SelectedSeats>>({
    seats: z.array(z.string()),
    category: z.number(),
});

export const AssignedSeatListSchema = z.array(AssignedSeatSchema);
