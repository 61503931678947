import React, { FunctionComponent } from "react";
import { Button } from "@mui/material";
import useGlobal from "../../../shared/hooks/GlobalHook";

interface ShowTransactionPanelProps {
    transactionId: number;
}

export const ShowTransactionPanel: FunctionComponent<ShowTransactionPanelProps> = (props) => {
    const { setTransactionID, setOpenTransactionDetails } = useGlobal();
    const handleTransactionClick = () => {
        setTransactionID(props.transactionId);
        setOpenTransactionDetails(true);
    };
    return (
        <Button color={"primary"} onClick={handleTransactionClick}>
            {props.transactionId}
        </Button>
    );
};
