import {
    PayloadAction,
    createSlice,
    createAsyncThunk,
    SerializedError,
} from "@reduxjs/toolkit";
import { UserCredentials } from "shared/models/Creds";
import { Nullable } from "shared/models/Generals";
import CredentialsService from "shared/services/CredentialsService";
import { AsyncThunkStatus } from "shared/models/UtilsTypes";

export interface CredentialsState {
    credentials: Nullable<UserCredentials>;
    error: Nullable<SerializedError>;
    status: AsyncThunkStatus;
}

const initialState: CredentialsState = {
    credentials: null,
    error: null,
    status: "initial",
};

export const fetchCredentialsThunk = createAsyncThunk(
    "credentials/fetch",
    async () => CredentialsService.loadUserCredentials(),
);

const slice = createSlice({
    name: "credentials",
    initialState,
    reducers: {
        setCredentials: (state, param: PayloadAction<UserCredentials>) => {
            state.credentials = param.payload;
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(fetchCredentialsThunk.fulfilled, (state, action) => {
            // Add user to the state array
            state.credentials = action.payload;
            state.status = "fulfilled";
        });
        builder.addCase(fetchCredentialsThunk.rejected, (state, action) => {
            state.error = action.error;
            state.status = "rejected";
        });
    },
});
export const credentialsReducer = slice.reducer;

export const { setCredentials } = slice.actions;

export default credentialsReducer;
