import { OverrideComponent } from "../interfaces";
const TabsOverrides: OverrideComponent<"MuiTabs"> = () => {
    return {
        MuiTabs: {
            styleOverrides: {
                vertical: {
                    overflow: "visible",
                },
            },
        },
    };
};
export default TabsOverrides;
