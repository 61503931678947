import React, { useState } from "react";
import UserSection from "./UserSection";
import UserGeneralSection from "./UserGeneralSection";
import { LinearProgress } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import UserSettingsProvider, { useUserSettingsContext } from "./UserSettingsContext";
import { SectionTabContainer, SectionTab } from "shared/components/SectionTabs";
import UserResourceCalendarSection from "./UserResourceCalendarSection";

const UserSettingsMain = () => {
    const { loadingCompanyUser, isUpdating } = useUserSettingsContext();
    const [tabs, setTabs] = useState(0);

    const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
        setTabs(newValue);
    };

    return loadingCompanyUser ? (
        <LinearProgress />
    ) : (
        <Grid container>
            {isUpdating && (
                <Grid xs={12}>
                    <LinearProgress />
                </Grid>
            )}
            <Grid xs={12}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: 2,
                    }}
                >
                    <SectionTabContainer value={tabs} onChange={handleChangeTabs}>
                        <SectionTab label="My information" />
                        <SectionTab label="View options" />
                        <SectionTab label="Resource calendar options" />
                    </SectionTabContainer>
                </div>
            </Grid>
            <Grid xs={12}>
                {tabs === 0 ? <UserSection /> : null}
                {tabs === 1 ? <UserGeneralSection /> : null}
                {tabs === 2 ? <UserResourceCalendarSection /> : null}
            </Grid>

            {/* <Grid sm={12}>
                        TODO: Implement after remove EBM security preferences
                        <UserSecuritySection
                            data={companyUser}
                            handleChange={updateCompanyPreferences}
                        />
                    </Grid> */}
        </Grid>
    );
};

const UserSettings = () => {
    return (
        <UserSettingsProvider>
            <UserSettingsMain />
        </UserSettingsProvider>
    );
};
export default UserSettings;
