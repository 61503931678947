import { z } from "zod";
import { InterfaceToZod } from "./UtilsTypes";
import {
    Company,
    DtoCompany,
    GiftCardInfo,
    ICompanySearch,
    ICompanyUser,
    IPublicNote,
    IRefundReason,
    SeatAssignmentSettings,
    SelectionItem,
} from "./Company";
import { DateTimeISOValidation } from "../utils/ZodValidations";
import { CompanyPreferencesSchema, UserPrefencesSchema } from "./Creds.schema";

export const SeatAssignmentSettingsSchema = z.object<InterfaceToZod<SeatAssignmentSettings>>({
    workspaceKey: z.string().nullable(),
    secretKey: z.string().nullable(),
});
export const CompanySchema = z.object<InterfaceToZod<Company>>({
    companyId: z.number(),
    name: z.string(),
    seatAssignmentModuleExp: DateTimeISOValidation,
    seatAssignmentSettings: z.nullable(SeatAssignmentSettingsSchema),
    textingModuleEnabled: z.boolean(),
    personnelModuleEnabled: z.boolean(),
    resourceModuleEnabled: z.boolean(),
    waiverModuleEnabled: z.boolean(),
    websiteModuleEnabled: z.boolean(),
    thresholdModuleEnabled: z.boolean(),
    depositModuleEnabled: z.boolean(),
    photoModuleEnabled: z.boolean(),
    seatAssignmentModuleEnabled: z.boolean(),
});

export const DtoCompanySchema = z.object<InterfaceToZod<DtoCompany>>({
    companyId: z.number(),
    name: z.string(),
    address: z.string(),
    city: z.string(),
    state: z.string(),
    zip: z.string(),
    accountingPhone: z.string(),
    accountingContact: z.string(),
    accountingEmail: z.string(),
    reservationContact: z.string(),
    reservationPhone: z.string(),
    reservationEmail: z.string(),
    timeZone: z.string(),
    taxId: z.string(),
    financialAccount: z.string(),
    vendorId: z.string(),
    phoneNumber: z.string().nullable(),
    receiptHeader: z.string(),
    surchargeLabel: z.string().nullable(),
    customerId: z.string().nullable(),
    sendZeroTransReports: z.boolean(),
    resellOtherEvents: z.boolean(),
    sendReceiptCopy: z.boolean(),
    externalProcessor: z.boolean(),
    preventRefunds: z.boolean(),
    offerTripInsurance: z.boolean(),
    active: z.boolean(),
    websiteModuleIsEnabled: z.boolean(),
    taxRate: z.number(),
    cartPercentMarkup: z.number(),
    ticketServiceCharge: z.number(),
    percentCommission: z.number(),
    transactionFee: z.number(),
    smsRate: z.number(),
    voiceRate: z.number(),
    emailRate: z.number(),
    maxServiceCharge: z.number(),
    clientTicketServiceCharge: z.number(),
    surchargePercent: z.number(),
    surchargeTax: z.number(),
    paymentTransactionFee: z.number(),
    processorFeeMarkupPct: z.number(),
    gatewayMonthlyFee: z.number(),
    gatewayTransactionFee: z.number(),
    catalogItemServiceFeePct: z.number(),
    paymentMethodStatus: z.number(),
    aWaiverCompanyId: z.number(),
    personnelModuleExp: DateTimeISOValidation,
    resourceModuleExp: DateTimeISOValidation,
    waiverModuleExp: DateTimeISOValidation,
    websiteModuleExp: DateTimeISOValidation,
    smsModuleExp: DateTimeISOValidation,
    thresholdModuleExp: DateTimeISOValidation,
    depositModuleExp: DateTimeISOValidation,
    photoModuleExp: DateTimeISOValidation,
    seatAssignmentModuleExp: DateTimeISOValidation,
    seatAssignmentSettings: z.nullable(SeatAssignmentSettingsSchema),
});

export const CompanyUserSchema = z.object<InterfaceToZod<ICompanyUser>>({
    userId: z.string(),
    companyId: z.number(),
    firstName: z.string().nullable(),
    lastName: z.string().nullable(),
    email: z.string().nullable(),
    phone: z.string().nullable(),
    systemAccess: z.number(),
    reportAccess: z.number(),
    userPreferences: z.nullable(UserPrefencesSchema),
    companyPreferences: z.nullable(CompanyPreferencesSchema),
    active: z.boolean(),
});

export const CompanySearch = z.object<InterfaceToZod<ICompanySearch>>({
    companyId: z.number(),
    name: z.string(),
});

export const PublicNote = z.object<InterfaceToZod<IPublicNote>>({
    note: z.string(),
    publicNoteId: z.number(),
});

export const RefundReasonsSchema = z.array(
    z.object<InterfaceToZod<IRefundReason>>({
        refundReasonID: z.number(),
        reason: z.string(),
    }),
);
export const SelectionItemSchema = z.object<InterfaceToZod<SelectionItem>>({
    text: z.string().nullable(),
    typeId: z.number(),
});
export const GiftCardInfoSchema = z.object<InterfaceToZod<GiftCardInfo>>({
    code: z.string(),
    transactionDate: z.string(),
    transactionId: z.number(),
    balance: z.number(),
    originalValue: z.number(),
    firstName: z.string().nullable(),
    lastName: z.string().nullable(),
    email: z.string().nullable(),
    phone: z.string().nullable(),
    address: z.string().nullable(),
    address2: z.string().nullable(),
    city: z.string().nullable(),
    state: z.string().nullable(),
    zip: z.string().nullable(),
    country: z.string().nullable(),
    recipientName: z.string().nullable(),
    recipientEmail: z.string().nullable(),
    lastEmailAttempt: z.string().nullable(),
});
export const CompanyUsersSchema = z.array(CompanyUserSchema);
export const CompanySearchSchema = z.array(CompanySearch);
export const PublicNotesSchema = z.array(PublicNote);
export const SelectionItemsSchema = z.array(SelectionItemSchema);
export const GiftCardsInfoSchema = z.array(GiftCardInfoSchema);
