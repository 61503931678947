import React, { FunctionComponent, PropsWithChildren, ReactElement, useState } from "react";
import { Divider, useMediaQuery } from "@mui/material";
import {
    CardMembershipOutlined,
    LocalAtmOutlined,
    ShoppingBagOutlined,
    VolunteerActivismOutlined,
} from "@mui/icons-material";
import styles from "./ButtonGroup.module.css";
import Merchandise from "../../Merchandise/Merchandise";
import { Theme } from "@mui/material/styles";
import Tipping from "../../Tipping/Tipping";
import Coupon from "../../Coupons/CouponsDialog";
import { useShoppingCartSlice } from "store/ShoppingCart/useShoppingCartSlice";
import { useNavigate } from "react-router-dom";

export const ShoppingCartButtonGroup: FunctionComponent = () => {
    const { cart } = useShoppingCartSlice();
    const navigate = useNavigate();
    const smallScreen = useMediaQuery<Theme>("(max-width:525px)");
    const [showMerchandise, setShowMerchandise] = useState(false);
    const [showTips, setShowTips] = useState(false);
    const [showCoupons, setShowCoupons] = useState(false);

    const handleNavigateGiftCards = () => {
        navigate("/giftcards");
    };
    return (
        <>
            <div className={styles.buttonGroup}>
                <ButtonGroupItem
                    icon={<LocalAtmOutlined />}
                    onClick={() => setShowCoupons(true)}
                    disabled={cart?.isEmpty}
                >
                    {smallScreen ? "Coupons" : "Add coupons"}
                </ButtonGroupItem>
                <ButtonGroupDivider />
                <ButtonGroupItem
                    icon={<CardMembershipOutlined />}
                    onClick={handleNavigateGiftCards}
                >
                    Gift cards
                </ButtonGroupItem>
                <ButtonGroupDivider />
                <ButtonGroupItem
                    icon={<ShoppingBagOutlined />}
                    onClick={() => setShowMerchandise(true)}
                >
                    {smallScreen ? "Merch" : "Merchandise"}
                </ButtonGroupItem>
                <ButtonGroupDivider />
                <ButtonGroupItem
                    icon={<VolunteerActivismOutlined />}
                    onClick={() => setShowTips(true)}
                    disabled={cart?.isEmpty}
                >
                    {smallScreen ? "Tips" : "Add tips"}
                </ButtonGroupItem>
            </div>
            <Merchandise open={showMerchandise} onClose={() => setShowMerchandise(false)} />
            <Tipping open={showTips} onClose={() => setShowTips(false)} />
            <Coupon open={showCoupons} onClose={() => setShowCoupons(false)} />
        </>
    );
};

interface ButtonGroupItemProps {
    onClick?: () => void;
    icon: ReactElement;
    disabled?: boolean;
}
const ButtonGroupItem = (props: PropsWithChildren<ButtonGroupItemProps>) => {
    const handleOnClick = () => {
        if (props.onClick && !props.disabled) {
            props.onClick();
        }
    };
    return (
        <div
            className={`${styles.button} ${props.disabled ? styles.disabled : ""}`}
            onClick={handleOnClick}
        >
            {props.icon}
            <span className={styles.buttonGroupText}>{props.children}</span>
        </div>
    );
};

const ButtonGroupDivider = () => <Divider orientation={"vertical"} style={{ height: "1.5rem" }} />;
