import signalRService from "shared/services/SignalR";
import useGlobal from "./GlobalHook";
import {
    ICartSuccessMessage,
    ICheckInStatusUpdate,
    IEventDateUpdate,
    IPaymentFailureMessage,
    ITextMessageUpdate,
} from "shared/models/SignalR";
import { useShoppingCartSlice } from "../../store/ShoppingCart/useShoppingCartSlice";
import MessageService from "shared/services/MessageService";

const useSignalRConnection = () => {
    //TODO: implementation of the rest of the event handlers will come when later, when we get done each component
    const { globalState, reloadEventDateDetails, setUnreadMessages, reloadTextMessages } =
        useGlobal();
    const { setCartSuccessMessage, setCartErrorMessage } = useShoppingCartSlice();
    const connectSignalR = async (userOid: string, companyId: number) => {
        try {
            const connection = await signalRService.initializeConnection(userOid);
            if (connection !== null) {
                /* setting up an event handler for the
                    "EventDateUpdate" event in the SignalR connection. */
                connection.on("EventDateUpdate", (message: IEventDateUpdate) => {
                    reloadEventDateDetails(true);
                });
                connection.on("CheckInStatusUpdate", (message: ICheckInStatusUpdate) => {});
                connection.on("IncomingTextMessage", (message: ITextMessageUpdate) => {
                    setUnreadMessages(message.unreadMessageCount);
                    reloadTextMessages(!globalState.isReloadTextMessages);
                });
                connection.on("CartSuccess", (message: ICartSuccessMessage) =>
                    setCartSuccessMessage(message),
                );
                connection.on("PaymentFailure", (message: IPaymentFailureMessage) => {
                    setCartErrorMessage(message);
                });
                await connection.start();

                if (companyId !== 0) {
                    await signalRService.joinCompanyGroup(userOid, companyId);
                    const count = await MessageService.getUnreadMessageCount();
                    setUnreadMessages(count);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };
    return {
        connectSignalR,
    };
};

export default useSignalRConnection;
