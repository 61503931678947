import { OverrideComponent } from "../interfaces";
// import React from "react";
// TODO: verify if work without this
// declare module "@mui/material/styles" {
//     interface ButtonVariants {
//         refundBtn: React.CSSProperties;
//     }
//     interface ButtonVariantsOptions {
//         refundBtn?: React.CSSProperties;
//     }
// }
declare module "@mui/material/Button" {
    interface ButtonPropsVariantOverrides {
        refundBtn: true;
    }
}
const ButtonOverrides: OverrideComponent<"MuiButton"> = (theme) => {
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "var(--global-radius)",
                },
            },
            variants: [
                {
                    props: { variant: "refundBtn" },
                    style: {
                        fontSize: "12px",
                        borderRadius: "4px",
                        fontWeight: 500,
                        fontStyle: "bold",
                        background: theme.palette.grey["200"],
                        color: theme.palette.grey["800"],
                    },
                },
            ],
        },
    };
};
export default ButtonOverrides;
