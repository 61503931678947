import { createAsyncThunk } from "@reduxjs/toolkit";
import { CartId } from "../../shared/models/Cart";
import ShoppingCartService from "../../shared/services/ShoppingCartService";

type CartThunks = "fetch" | "add-existing-reservation" | "remove-reservation" | "apply-coupon";
const createKeyThunk = (thunk: CartThunks) => {
    const prefix = "cart";
    return `${prefix}/${thunk}`;
};
export const fetchCartThunk = createAsyncThunk(createKeyThunk("fetch"), async (cartId: CartId) =>
    ShoppingCartService.getCart(cartId),
);
