import { ReactNode } from "react";
import {
    BarChart,
    CalendarMonth,
    CardMembership,
    FormatListBulleted,
    People,
    Sms,
} from "@mui/icons-material";
import WebIcon from "@mui/icons-material/Web";
import HelpIcon from "@mui/icons-material/Help";
import { ReportAccess, SystemAccess } from "../../../shared/models/AccessRights";
import { PRODUCTION } from "../../../environment/environment";

export enum MenuItemType {
    group = "group",
    item = "item",
    collapse = "collapse",
}

export interface MenuItems {
    path: string;
    icon: ReactNode;
    name: string;
    displayOrder: number;
    display: boolean;
    active: boolean;
    hasChildren: boolean;
    type: MenuItemType;
    menu?: MenuItems[];
    reportAccess?: ReportAccess;
    systemAccess?: SystemAccess;
}

const menuItems: MenuItems[] = [
    // { //remove this comment when we have the approval and enough information for metrics dashboard
    //     icon: <HomeIcon />,
    //     path: "/",
    //     name: "Home",
    //     displayOrder: 9,
    //     display: true,
    //     active: true,
    //     hasChildren: false,
    //     type: MenuItemType.item,
    // },
    {
        icon: <CalendarMonth />,
        path: "/event/calendar",
        name: "Reservations",
        displayOrder: 0,
        display: true,
        active: true,
        hasChildren: false,
        type: MenuItemType.item,
    },
    {
        icon: <FormatListBulleted />,
        path: "/event/list",
        name: "Event List",
        displayOrder: 1,
        display: true,
        active: true,
        hasChildren: false,
        type: MenuItemType.item,
    },
    {
        icon: <CalendarMonth />,
        path: "/resources/calendar",
        name: "Resources",
        displayOrder: 2,
        display: true,
        active: true,
        hasChildren: false,
        type: MenuItemType.item,
    },
    {
        icon: <Sms />,
        path: "/textMessages",
        name: "Text Messages",
        displayOrder: 3,
        display: true,
        active: true,
        hasChildren: false,
        type: MenuItemType.item,
    },
    {
        icon: <BarChart />,
        path: "/reports",
        name: "Reports",
        displayOrder: 4,
        display: true,
        active: true,
        hasChildren: true,
        type: MenuItemType.group,
        menu: [
            // Reports are going to be alphabetical
            {
                icon: <BarChart />,
                path: "/reports/cash-category",
                name: "Cash category",
                displayOrder: 0,
                display: true,
                active: true,
                reportAccess: ReportAccess.CashCategory || ReportAccess.ReportAdmin,
                hasChildren: false,
                type: MenuItemType.item,
            },
            {
                icon: <BarChart />,
                path: "/reports/commission",
                name: "Commission report",
                displayOrder: 8,
                display: true,
                active: true,
                reportAccess: ReportAccess.Commission || ReportAccess.ReportAdmin,
                hasChildren: false,
                type: MenuItemType.item,
            },
            {
                icon: <BarChart />,
                path: "/reports/daily-reservations",
                name: "Daily reservations report",
                displayOrder: 8,
                display: true,
                active: true,
                reportAccess: ReportAccess.DailyReservations || ReportAccess.ReportAdmin,
                hasChildren: false,
                type: MenuItemType.item,
            },
            {
                icon: <BarChart />,
                path: "/reports/delivery",
                name: "Delivery",
                displayOrder: 1,
                display: true,
                active: true,
                reportAccess: ReportAccess.Delivery || ReportAccess.ReportAdmin,
                hasChildren: false,
                type: MenuItemType.item,
            },
            {
                icon: <BarChart />,
                path: "/reports/partner-report",
                name: "Partner report",
                displayOrder: 2,
                display: true,
                active: true,
                reportAccess: ReportAccess.Partner || ReportAccess.ReportAdmin,
                hasChildren: false,
                type: MenuItemType.item,
            },
            {
                icon: <BarChart />,
                path: "/reports/payout-summary",
                name: "Payout summary",
                displayOrder: 3,
                display: true,
                active: true,
                reportAccess: ReportAccess.PayoutReport || ReportAccess.ReportAdmin,
                hasChildren: false,
                type: MenuItemType.item,
            },
            {
                icon: <BarChart />,
                path: "/reports/reservations-employees",
                name: "Reservation by employees",
                displayOrder: 4,
                display: true,
                active: true,
                reportAccess: ReportAccess.ReservationsByEmployee || ReportAccess.ReportAdmin,
                hasChildren: false,
                type: MenuItemType.item,
            },
            {
                icon: <BarChart />,
                path: "/reports/sales-by-date",
                name: "Sales by date",
                displayOrder: 5,
                display: true,
                active: true,
                reportAccess: ReportAccess.SalesByDay || ReportAccess.ReportAdmin,
                hasChildren: false,
                type: MenuItemType.item,
            },
            {
                icon: <BarChart />,
                path: "/reports/sales-tax",
                name: "Sales tax",
                displayOrder: 6,
                display: true,
                active: true,
                reportAccess: ReportAccess.Transaction || ReportAccess.ReportAdmin,
                hasChildren: false,
                type: MenuItemType.item,
            },
            {
                icon: <BarChart />,
                path: "/reports/tips",
                name: "Tips",
                displayOrder: 7,
                display: true,
                active: true,
                reportAccess: ReportAccess.Tips || ReportAccess.ReportAdmin,
                hasChildren: false,
                type: MenuItemType.item,
            },
            {
                icon: <BarChart />,
                path: "/reports/transactions",
                name: "Transaction report",
                displayOrder: 8,
                display: true,
                active: true,
                reportAccess: ReportAccess.Transaction || ReportAccess.ReportAdmin,
                hasChildren: false,
                type: MenuItemType.item,
            },
        ],
    },
    {
        icon: <People />,
        path: "/admin/users",
        name: "Manage",
        displayOrder: 5,
        display: true,
        active: true,
        systemAccess: SystemAccess.UserAccess,
        hasChildren: false,
        type: MenuItemType.item,
    },
    {
        icon: <CardMembership />,
        path: "/giftcards",
        name: "Gift cards",
        displayOrder: 6,
        display: true,
        active: true,
        hasChildren: false,
        type: MenuItemType.item,
    },
    {
        icon: <CalendarMonth />,
        path: "/",
        name: "Work schedule",
        displayOrder: 7,
        display: true,
        active: false,
        hasChildren: false,
        type: MenuItemType.item,
    },
    {
        icon: <WebIcon />,
        path: "/company-weblink-manager",
        name: "Weblink",
        displayOrder: 8,
        display: true,
        active: true,
        hasChildren: false,
        type: MenuItemType.item,
        systemAccess: SystemAccess.SysAdmin,
    },
    {
        icon: <HelpIcon />,
        path: "/help",
        name: "Help",
        displayOrder: 9,
        display: true,
        active: true,
        hasChildren: true,
        type: MenuItemType.group,
        menu: [
            {
                icon: <HelpIcon />,
                path: "/help/Help Documents",
                name: "Help Documents",
                displayOrder: 0,
                display: true,
                active: true,
                hasChildren: false,
                type: MenuItemType.item,
            },
            {
                icon: <HelpIcon />,
                path: "/help/Help Videos",
                name: "Help Videos",
                displayOrder: 1,
                display: true,
                active: true,
                hasChildren: false,
                type: MenuItemType.item,
            },
        ],
    },
];

export default menuItems;
