import React, { FunctionComponent } from "react";
import { ListItemButton, ListItemText } from "@mui/material";
import { MenuItems } from "./menuItems";
import { useMatch, useNavigate } from "react-router-dom";
import { alpha } from "@mui/material/styles";
import { useIndexicTheme } from "../../../theme/useIndexicTheme";

const NavigationGroupChild: FunctionComponent<MenuItems> = (props) => {
    const navigation = useNavigate();
    const match = useMatch(props.path);
    const active = match !== null;
    const theme = useIndexicTheme();
    const handleAction = (path: string) => {
        navigation(path);
    };
    return (
        <ListItemButton
            disabled={!props.active}
            sx={{
                // pl: 4,
                backgroundColor: active ? alpha(theme.palette.primary.bright, 0.3) : "",
                textAlign: "center",
            }}
            onClick={() => handleAction(props.path)}
        >
            {/*{props.icon ? <ListItemIcon>{props.icon}</ListItemIcon> : null}*/}
            <ListItemText
                color="inherit"
                primaryTypographyProps={{
                    variant: "idReservation",
                    sx: {
                        // maxWidth: 70,
                        color: active ? theme.palette.primary.main : "",
                    },
                }}
                primary={props.name}
            />
        </ListItemButton>
    );
};

export default NavigationGroupChild;
