import { Typography } from "@mui/material";
import React from "react";

interface Props {
    name: string;
}

const ReportName = (props: Props) => {
    return <Typography variant="h3">{props.name}</Typography>;
};

export default ReportName;
