import React, { FunctionComponent, lazy, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import { PRODUCTION } from "./environment/environment";
import LogoPage from "./pages/LogoPage";
import ComponentsList from "./shared/components/ComponentsList";
import UserSettings from "modules/UserSettings/UserSettings";
import { useAuthContext } from "modules/Auth/AuthProvider";
// Lazy Loading did not work with this page
import UserAdmin from "modules/UserAdmin/UserAdmin";
import { useCredentials } from "store/Credentials/CredencialHook";
import { StartPage } from "shared/models/Creds";
// import Delivery from "modules/Reports/Delivery";
import { useNavigate } from "react-router-dom";
import ReservationByEmployee from "modules/Reports/ReservationByEmployee";
import PayoutSummary from "modules/Reports/PayoutSummary";
import AcceptCompanyInvite from "pages/AcceptCompanyInvite";
import InvalidUser from "pages/InvalidUser";
import SalesByDatePrintable from "./modules/Reports/SalesByDate/SalesByDatePrintable";

const CheckoutComplete = lazy(() => import("./modules/Checkout/CheckoutComplete/CheckoutComplete"));
const TestPage = lazy(() => import("./test/modules/TestPage"));
const GeneralCalendar = lazy(() => import("./modules/GeneralCalendar/GeneralCalendar"));
const EventCalendar = lazy(() => import("./modules/EventDateDetails/EventCalendar"));
const DeliveryReport = lazy(() => import("./modules/Reports/Delivery"));
const EventList = lazy(() => import("./modules/EventList/EventList"));
const Checkout = lazy(() => import("./modules/Checkout/Checkout"));
const GiftCards = lazy(() => import("./modules/GiftCards/GiftCards"));
const TextMessages = lazy(() => import("./modules/TextMessages/TextMessages"));
const TransactionsReport = lazy(() => import("./modules/Reports/Transactions/TransactionsReport"));
const SalesTaxReport = lazy(() => import("./modules/Reports/Transactions/SalesTaxReport"));
const CashCategoryReport = lazy(() => import("./modules/Reports/CashCategory"));
const CompanyWebLinkManager = lazy(
    () => import("./modules/WebpageManagement/CompanyWebLinkManager"),
);
const CompanyWebLinkImageManager = lazy(
    () => import("./modules/CompanyWeblinkImageManager/CompanyWebLinkImageManagement"),
);
const TipReport = lazy(() => import("./modules/Reports/TipReport"));
const SalesByDateReport = lazy(() => import("./modules/Reports/SalesByDate/SalesByDateReport"));
const PartnerReport = lazy(() => import("./modules/Reports/PartnerReport"));
const CommissionReport = lazy(() => import("./modules/Reports/CommissionReport"));
const ResourceCalendar = lazy(() => import("./modules/ResourceCalendar/ResourceCalendar"));
const Event = lazy(() => import("./modules/Event/Event"));
const HelpPage = lazy(() => import("./modules/Help/Help"));
const DailyReservationsReport = lazy(
    () => import("./modules/Reports/DailyReservationReport/DailyReservationReport"),
);
const DailyReservationsReportPrintable = lazy(
    () => import("./modules/Reports/DailyReservationReport/DailyReservationReportPrintable"),
);

const AppRouter: FunctionComponent<Object> = () => {
    const aReservationUser = useAuthContext();
    const { credentialsState } = useCredentials();
    const navigate = useNavigate();

    const getPreferenceStartingPage = () => {
        const defaultStaringPagePreference =
            credentialsState.credentials && credentialsState.credentials.companyPreferences
                ? credentialsState.credentials.companyPreferences.defaultPage
                : StartPage.Calendar;
        switch (defaultStaringPagePreference) {
            case StartPage.EventList:
                return navigate("/event/list");
            case StartPage.Calendar:
                return navigate("/event/calendar");
        }
    };

    useEffect(() => {
        if (aReservationUser.userValid) {
            getPreferenceStartingPage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [credentialsState.credentials?.companyPreferences, aReservationUser.userValid]);

    return (
        <>
            {aReservationUser.status === "WaitingOnApiRequest" ? (
                <Routes>
                    <Route path={"*"} element={<LogoPage message="Validating...." />} />
                </Routes>
            ) : null}
            {aReservationUser.userValid ? (
                <Routes>
                    <Route path="/*" element={<LogoPage message="Not Found" />} />
                    <Route path={"/reports/print"}>
                        <Route path={"sales-by-date"} element={<SalesByDatePrintable />}></Route>
                        <Route
                            path={"daily-reservations"}
                            element={<DailyReservationsReportPrintable />}
                        ></Route>
                    </Route>
                    <Route path={"/"} element={<MainLayout />}>
                        <Route path="event">
                            <Route path={"calendar"} element={<GeneralCalendar />} />
                            <Route
                                path={"calendar/:eventIdParam/:eventDateIdParam?"}
                                element={<EventCalendar />}
                            />
                            <Route path={"list"} element={<EventList />} />
                            <Route path={":eventId"} element={<Event />} />
                        </Route>
                        <Route path={"reports"}>
                            <Route path={"delivery"} element={<DeliveryReport />} />
                            <Route
                                path={"reservations-employees"}
                                element={<ReservationByEmployee />}
                            />
                            <Route path={"transactions"} element={<TransactionsReport />} />
                            <Route path={"payout-summary"} element={<PayoutSummary />} />
                            <Route path={"cash-category"} element={<CashCategoryReport />}></Route>
                            <Route path={"tips"} element={<TipReport />}></Route>
                            <Route path={"sales-tax"} element={<SalesTaxReport />}></Route>
                            <Route path={"sales-by-date"} element={<SalesByDateReport />}></Route>
                            <Route path={"partner-report"} element={<PartnerReport />}></Route>
                            <Route path={"commission"} element={<CommissionReport />}></Route>
                            <Route
                                path={"daily-reservations"}
                                element={<DailyReservationsReport />}
                            ></Route>
                        </Route>

                        <Route path={"checkout"}>
                            <Route path={"complete"} element={<CheckoutComplete />} />
                            <Route path={""} element={<Checkout />} />
                        </Route>
                        {!PRODUCTION ? (
                            <Route path={"components"} element={<ComponentsList />} />
                        ) : null}
                        <Route path={"tests/*"} element={<TestPage />} />
                        <Route
                            path={"company-weblink-manager"}
                            element={<CompanyWebLinkManager />}
                        />
                        <Route
                            path={"weblink-image-managment"}
                            element={<CompanyWebLinkImageManager />}
                        />
                        <Route path="help/:folderName" element={<HelpPage />} />
                        <Route path={"admin"}>
                            <Route path={"users"} element={<UserAdmin />} />
                        </Route>
                        <Route path={"preferences"} element={<UserSettings />} />
                        <Route
                            path={"no-credentials"}
                            element={<LogoPage message="Error: User Credentials Failed" />}
                        />
                        <Route path={"giftcards"} element={<GiftCards />} />
                        <Route path={"textMessages"} element={<TextMessages />} />
                        <Route path="resources">
                            <Route path={"calendar"} element={<ResourceCalendar />} />
                        </Route>
                        <Route
                            path={"AcceptCompanyInvite/:inviteId"}
                            element={<AcceptCompanyInvite />}
                        ></Route>
                    </Route>
                </Routes>
            ) : (
                <Routes>
                    <Route path={"*"} element={<InvalidUser />} />
                    <Route
                        path={"AcceptCompanyInvite/:inviteId"}
                        element={<AcceptCompanyInvite />}
                    ></Route>
                </Routes>
            )}
        </>
    );
};

export default AppRouter;
