import * as React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Locale } from "shared/utils/Locale";
import { isValid } from "date-fns";
import { PickerChangeHandlerContext, DateTimeValidationError } from "@mui/x-date-pickers";

interface BasicDatePickerProps {
    value: string | Date | null;
    min?: Date;
    max?: Date;
    label: string;
    disablePast?: boolean;
    onDateChanged: (vaue: Date | null) => void;
}
export default function BasicDatePicker(props: BasicDatePickerProps) {
    const onChangeDate = (
        value: Date | null,
        context: PickerChangeHandlerContext<DateTimeValidationError>,
    ) => {
        if (isValid(value)) {
            return props.onDateChanged(value);
        }
        return props.onDateChanged(null);
    };
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                value={typeof props.value === "string" ? new Date(props.value) : props.value}
                format={Locale.dateFormat}
                minDate={props.min}
                maxDate={props.max}
                onChange={onChangeDate}
                label={props.label}
                slotProps={{
                    textField: {
                        size: "small",
                    },
                }}
                disablePast={props.disablePast}
                defaultValue={props.value ? new Date(props.value) : new Date()}
            />
        </LocalizationProvider>
    );
}
