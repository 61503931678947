import { OverrideComponent } from "../interfaces";

const LinearProgressOverrides: OverrideComponent<"MuiLinearProgress"> = () => {
    return {
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    height: 6,
                    borderRadius: 100,
                },
                bar: {
                    borderRadius: 100,
                },
            },
        },
    };
};
export default LinearProgressOverrides;
