//the reason to use nocheck in this file is due to Seatsio types and support for typescript
import React, { useCallback, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { ReservationDetails } from "shared/models/Reservation";
import SeatsManager from "./SeatsManager";
import { Button, Divider, Typography } from "@mui/material";
import { AssignedSeat, AssignedSeatsCategory } from "shared/models/Seats";
import { ExtraConfig, SeatProps } from "@seatsio/seatsio-types";
import { useReservationModalContext } from "modules/Reservation/context/ReservationModalProvider";
import { NotificationsType } from "shared/hooks/useNotification";
import { ArrowRightAlt, EventSeat } from "@mui/icons-material";
import LoadingButtons from "../LoadingButton";
import seatsService from "shared/services/SeatsService";
import { useQueryClient } from "@tanstack/react-query";

interface Props {
    workspaceKey: string;
    event: string;
    reservation: ReservationDetails;
}
interface DisplaySelectedSeatsProps {
    bookedSeats: Array<AssignedSeatsCategory>;
    seatsToChange: ReAssignSeats;
    loading: boolean;
    confirmSeatsChange: () => void;
    cancel: () => void;
}

export interface Seat {
    seatId: string;
    objectType: string;
    status: string;
    selected: boolean;
    categoryId: number;
}

export interface ReAssignSeats {
    reserved: string;
    free: string;
}
var reservedSeat: SeatProps | null = null;
var seatsCategoryKey = 0;
const SeatSelector = (props: Props) => {
    const [seatsToChange, setSeatsToChange] = useState<ReAssignSeats>({ reserved: "", free: "" });
    const [categoryKey, setCategory] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const { showNotification } = useReservationModalContext();
    const queryClient = useQueryClient();
    const seatsCategory = useCallback(() => {
        seatsCategoryKey = categoryKey;
        return seatsCategoryKey;
    }, [categoryKey]);

    const handleSelectFreeSeat = (seat: Seat | null) => {
        if (seat === null) {
            setSeatsToChange((object) => {
                return {
                    ...object,
                    free: "",
                };
            });
            return;
        }

        setSeatsToChange((object) => {
            return {
                ...object,
                free: seat.seatId,
            };
        });
    };
    const handleOnClicked = (object: SeatProps) => {
        if (object.status === "booked") {
            // @ts-ignore
            const extraData: ExtraConfig = object.extraData; //cast type since seatsio can not infer the type
            if (Number(extraData.reservationId) !== Number(props.reservation.reservationId)) {
                showNotification({
                    message:
                        "You can't select a seat that does not belong to the current reservation",
                    type: NotificationsType.error,
                });
                object.deselect(); //this function remove the check icon over the seat
            } else {
                if (reservedSeat !== null) {
                    reservedSeat.unpulse();
                }
                if (categoryKey === 0 || object.category?.key! === categoryKey) {
                    reservedSeat = object;
                    setSeatsToChange((seat) => {
                        return {
                            ...seat,
                            reserved: object.label,
                        };
                    });
                    try {
                        var interval = setInterval(() => {
                            object.pulse();
                        }, 1500);

                        setTimeout(() => {
                            clearInterval(interval);
                        }, 10000);
                    } catch (error) {}
                } else {
                    showNotification({
                        message: "Before choosing a new seat, please unselect your current one",
                        type: NotificationsType.warning,
                    });
                    object.deselect();
                }
            }
        }
    };

    const handleFreeSeat = (object: SeatProps) => {
        //Validate if is not selected a booked seat first
        if (reservedSeat === null) {
            showNotification({
                message: "Please choose your reserved seat first, before selecting a free one",
                type: NotificationsType.error,
            });
            object.deselect(); //this function remove the check icon over the seat
            return;
        } else if (reservedSeat.category!.key !== object.category!.key) {
            // Validate if the booked seat and the selected seat are in the same category, if not will throw an exception
            // TODO: in the future allow the option to change seats even in different categories
            showNotification({
                message:
                    "You can not select a free seat of a different category than the reserved one",
                type: NotificationsType.error,
            });
            object.deselect(); //this function remove the check icon over the seat
            return;
        }
        setCategory(object.category!.key as number);
        handleSelectFreeSeat({
            seatId: object.label,
            objectType: object.objectType,
            status: object.status as string,
            selected: object.selected,
            categoryId: object.category?.key! as number,
        });
    };

    const handleRemoveSelection = () => {
        setCategory(0);
        handleSelectFreeSeat(null);
    };

    const clearSelection = () => {
        setSeatsToChange({ reserved: "", free: "" });
    };

    const confirmSeatsChange = async () => {
        try {
            setLoading(true);
            const category = seatsCategory();
            const oldBookedSeats = await seatsService.getReservedSeats(
                props.reservation.reservationId,
                category,
            );
            if (oldBookedSeats.length === 0) {
                showNotification({
                    message: "You are not available to switch the seats in this category",
                    type: NotificationsType.error,
                });
                return;
            }
            // change the booked seat for the free seat and update the row at the database
            // [{ seat : value }]
            let assignedSeats = oldBookedSeats.map((item: AssignedSeat) => {
                if (item.seat === seatsToChange.reserved) {
                    item.seat = seatsToChange.free;
                }
                return item;
            });
            /**
             * @seatsToChange.free : is the seat that will be booked
             * @seatsToChange.reserved : is the seat that will be released
             */
            await seatsService.reAssignSeats(
                props.reservation.reservationId,
                props.reservation.eventDateId,
                category,
                {
                    assignedSeats,
                    seatToBook: [seatsToChange.free],
                    seatToRelease: [seatsToChange.reserved],
                },
            );
            showNotification({
                message: "Seats correctly assigned ",
                type: NotificationsType.success,
            });
            setLoading(false);
            // we call for reservation details to get the new information
            queryClient.refetchQueries({ queryKey: ["reservation-details"] });
            clearSelection();
        } catch (error) {
            showNotification({
                message: "Error trying to reassign the seats",
                type: NotificationsType.error,
            });
            setLoading(false);
        }
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                width: "100%",
            }}
        >
            <div
                style={{
                    width: "100%",
                    height: 350,
                }}
            >
                <SeatsManager
                    workspaceKey={props.workspaceKey}
                    event={props.event}
                    extraConfig={{
                        reservationId: props.reservation.reservationId,
                        readOnly: false,
                        seatAssignmentsCategories: props.reservation.seatAssignments.map((item) => {
                            return {
                                seatAssignmentCategoryId: item.seatAssignmentCategoryId,
                            };
                        }),
                    }}
                    extraProps={{
                        maxSelectedObjects: 1,
                        onObjectClicked: (object: any) => handleOnClicked(object),
                        onObjectSelected: (object: any) => handleFreeSeat(object),
                        onObjectDeselected: () => handleRemoveSelection(),
                    }}
                />
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    width: "100%",
                }}
            >
                <DisplaySelectedSeats
                    bookedSeats={props.reservation.seatAssignments}
                    seatsToChange={seatsToChange}
                    confirmSeatsChange={confirmSeatsChange}
                    loading={loading}
                    cancel={() => clearSelection()}
                />
            </div>
        </div>
    );
};

const DisplaySelectedSeats = (props: DisplaySelectedSeatsProps) => {
    return (
        <Grid container spacing={2}>
            {props.bookedSeats.map((section) => (
                <React.Fragment key={section.categoryName}>
                    <Grid xs={12}>
                        <Typography variant="h5">Booked Seats {section.categoryName}:</Typography>
                    </Grid>
                    <Grid xs={12}>
                        <div
                            style={{
                                display: "flex",
                                gridTemplateColumns: "repeat(auto-fill, 40px)",
                                gap: 2,
                            }}
                        >
                            <Typography variant="button">
                                {section.assignedSeats.map((seat) => seat.seat).join(",")}
                            </Typography>
                        </div>
                        <Divider />
                    </Grid>
                </React.Fragment>
            ))}
            {props.seatsToChange.free !== "" || props.seatsToChange.reserved !== "" ? (
                <>
                    <Grid xs={12}>
                        <Typography variant="h5">Editing seats:</Typography>
                    </Grid>
                    <Grid xs={12}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-evenly",
                            }}
                        >
                            <div>
                                <Typography variant="idReservation">Previous</Typography>
                                {props.seatsToChange.reserved}
                            </div>
                            <ArrowRightAlt />
                            <EventSeat color="primary" />
                            <div>
                                <Typography variant="idReservation">New Selection</Typography>
                                {props.seatsToChange.free}
                            </div>
                        </div>
                    </Grid>
                    <Grid xs={12}>
                        <LoadingButtons
                            label="Confirm"
                            loading={props.loading}
                            disabled={
                                props.seatsToChange.free === "" ||
                                props.seatsToChange.reserved === ""
                            }
                            fullWidth={true}
                            handleClick={() => props.confirmSeatsChange()}
                        />
                        <br />
                        <Button variant="text" onClick={() => props.cancel()}>
                            Cancel
                        </Button>
                    </Grid>
                </>
            ) : null}
        </Grid>
    );
};

export default SeatSelector;
