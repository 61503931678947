import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { acquireTokenSilent } from "shared/auth/MSALAuth";
import { APIError, APIErrorCode, BadRequestError, ForbiddenError, NotFoundError } from "./errors";
import { RolesEventHandler } from "shared/auth/RolesEventHandler";

const COMPANY_HEADER = "x-indexic-c";
const ROLES_HEADER = "x-indexic-p";
type ResponseInterceptor = (response: AxiosResponse) => AxiosResponse;
type RequestInterceptor = (
    req: InternalAxiosRequestConfig<any>,
) => Promise<InternalAxiosRequestConfig<any>>;
export const AuthRequestInterceptor: RequestInterceptor = async (req) => {
    const companyId = getCompanyHeader();
    if (companyId) {
        req.headers[COMPANY_HEADER] = companyId;
    }

    let authentication = await acquireTokenSilent();

    if (!!authentication) {
        req.headers.Authorization = `Bearer ${authentication.idToken}`;
    }

    return req;
};

export const HeadersResponseInterceptor: ResponseInterceptor = (response) => {
    if (response.headers[COMPANY_HEADER]) {
        setCompanyHeader(response.headers[COMPANY_HEADER]);
    }
    if (response.headers[ROLES_HEADER]) {
        setRolesHeader(response.headers[ROLES_HEADER]);
    }
    return response;
};
const HandleAxiosError = (err: AxiosError) => {
    // Sets new roles and company header
    if (err.response) {
        HeadersResponseInterceptor(err.response);
    }
    const status = err.response?.status || 500;
    const data = err.response?.data;
    switch (status) {
        // authentication (token related issues)
        case 401: {
            return Promise.reject(new APIError(err.message, APIErrorCode.Unauthorized, data));
        }

        // forbidden (permission related issues)
        case 403: {
            return Promise.reject(new ForbiddenError(err.message, data));
        }

        // bad request
        case 400: {
            return Promise.reject(new BadRequestError(err.message, data));
        }

        // not found
        case 404: {
            return Promise.reject(new NotFoundError(err.message, data));
        }

        // conflict
        case 409: {
            return Promise.reject(new APIError(err.message, APIErrorCode.Conflict, data));
        }

        // unprocessable
        case 422: {
            return Promise.reject(new APIError(err.message, APIErrorCode.Unprocessable, data));
        }

        // generic api error (server related) unexpected
        default: {
            return Promise.reject(
                new APIError(err.message, APIErrorCode.InternalServerError, data),
            );
        }
    }
};

const HandleGeneralError = (err: any) => {
    const status = err.response?.status || 500;
    const data = err.response?.data;
    switch (status) {
        // authentication (token related issues)
        case 400: {
            return Promise.reject(new APIError(err.message, APIErrorCode.Unauthorized, data));
        }

        // generic api error (server related) unexpected
        default: {
            return Promise.reject(
                new APIError(err.message, APIErrorCode.InternalServerError, data),
            );
        }
    }
};

export const ErrorResponseInterceptor = (err: any) => {
    if (err instanceof AxiosError) {
        return HandleAxiosError(err);
    } else if (err instanceof Error) {
        return HandleGeneralError(err);
    }

    // we can handle global errors here
};
export const getCompanyHeader = (): string | null => localStorage.getItem(COMPANY_HEADER);
export const getRolesHeader = (): string | null => localStorage.getItem(ROLES_HEADER);
export const setCompanyHeader = (header?: string) => {
    if (header) {
        localStorage.setItem(COMPANY_HEADER, header);
    }
};
export const setRolesHeader = (header?: string) => {
    if (header) {
        localStorage.setItem(ROLES_HEADER, header);
        RolesEventHandler.emit(header);
    }
};
