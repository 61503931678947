import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { SxProps, Theme } from "@mui/material/styles";
import { ButtonPropsColorOverrides } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";

interface Props {
    label: string;
    loading: boolean;
    disabled: boolean;
    handleClick: () => void;
    sx?: SxProps<Theme>;
    fullWidth?: boolean;
    startIcon?: React.ReactNode;
    color?: OverridableStringUnion<
        "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning",
        ButtonPropsColorOverrides
    >;
}
export default function LoadingButtons(props: Props) {
    return (
        <LoadingButton
            size="small"
            disabled={props.disabled}
            loading={props.loading}
            loadingPosition="start"
            startIcon={props.startIcon ?? <SaveIcon />}
            color={props.color ?? "primary"}
            variant="contained"
            onClick={props.handleClick}
            sx={props.sx ?? null}
            fullWidth={props.fullWidth}
        >
            <span>{props.label}</span>
        </LoadingButton>
    );
}
