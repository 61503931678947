import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { AccountCircle, MailOutline } from "@mui/icons-material";
import { TextField, InputAdornment } from "@mui/material";
import { MuiTelInput, MuiTelInputInfo } from "mui-tel-input";
import { Locale } from "shared/utils/Locale";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { IContactInfo, IContactInfoTemplate } from "shared/models/ContactInfo";
import { Validation } from "shared/utils/Validation";

interface Props {
    outsideContactInfo: IContactInfo;
    isInformationPasted?: boolean;
    addContactInfo: (contact: IContactInfo, isFormValid: boolean) => void;
    undo?: boolean;
}

const ContactForm = (props: Props) => {
    const isFirstRender = useRef(true);
    const [innerForm, setInnerForm] = useState<IContactInfoTemplate<string>>({
        firstName: props.outsideContactInfo.firstName ?? "",
        lastName: props.outsideContactInfo.lastName ?? "",
        phone: props.outsideContactInfo.phone ?? "",
        email: props.outsideContactInfo.email ?? "",
    });
    const [phoneNotValid, setPhoneValidity] = useState("");

    useEffect(() => {
        if (isFirstRender.current) {
            setInnerForm({
                firstName: props.outsideContactInfo.firstName ?? "",
                lastName: props.outsideContactInfo.lastName ?? "",
                phone: props.outsideContactInfo.phone ?? "",
                email: props.outsideContactInfo.email ?? "",
            });
        }
    }, [props.outsideContactInfo]);

    useEffect(() => {
        if (!isFirstRender.current) {
            setInnerForm(props.outsideContactInfo as IContactInfoTemplate<string>);
        }
        //eslint-disable-next-line
    }, [props.isInformationPasted]);

    useEffect(() => {
        if (!isFirstRender.current) {
            setInnerForm(props.outsideContactInfo as IContactInfoTemplate<string>);
        }
    }, [props.undo]);

    useEffect(() => {
        let isValid = true;
        if (
            innerForm.lastName === "" ||
            phoneNotValid.length > 0 ||
            Validation.Email(innerForm.email).length > 0
        ) {
            isValid = false;
        }
        props.addContactInfo(innerForm, isValid);
        isFirstRender.current = false;
    }, [innerForm]);

    const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setInnerForm({
            ...innerForm,
            [ev.target.name]: ev.target.value,
        });
    };

    const handlePhoneChange = (value: string, info: MuiTelInputInfo) => {
        let isEmpty = false;
        //nationalNumber returns only the number without country code, if the input is empty and the only change is the country code this property will be empty
        //having an empty string is valid
        if (info.nationalNumber === "") {
            isEmpty = true;
            setPhoneValidity("");
        }
        if (!isEmpty) {
            const validation = Validation.PhoneNumber(value);
            setPhoneValidity(validation);
        }
        setInnerForm({ ...innerForm, phone: value });
    };
    return (
        <Grid container spacing={2}>
            <Grid xs={12} sm={12} md={6}>
                <TextField
                    data-cy="firstName"
                    id="firstName"
                    name="firstName"
                    value={innerForm.firstName}
                    label={Locale.firstName}
                    onChange={handleChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AccountCircle />
                            </InputAdornment>
                        ),
                    }}
                    fullWidth
                    size="small"
                />
            </Grid>
            <Grid xs={12} sm={12} md={6}>
                <TextField
                    data-cy="lastName"
                    id="lastName"
                    name="lastName"
                    value={innerForm.lastName}
                    label={Locale.lastName}
                    required={true}
                    onChange={handleChange}
                    error={innerForm.lastName?.length === 0}
                    helperText={
                        innerForm.lastName?.length === 0
                            ? "Lastname is required, please fill this field"
                            : ""
                    }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AccountCircle />
                            </InputAdornment>
                        ),
                    }}
                    fullWidth
                    size="small"
                />
            </Grid>
            <Grid xs={12} sm={12} md={6}>
                <TextField
                    data-cy="email"
                    id="email"
                    name="email"
                    type="email"
                    value={innerForm.email}
                    label={Locale.email}
                    onChange={handleChange}
                    error={Validation.Email(innerForm.email).length > 0}
                    helperText={Validation.Email(innerForm.email) ?? ""}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MailOutline />
                            </InputAdornment>
                        ),
                    }}
                    fullWidth
                    size="small"
                />
            </Grid>
            <Grid xs={12} sm={12} md={6}>
                <MuiTelInput
                    value={innerForm.phone}
                    label={Locale.phone}
                    onChange={handlePhoneChange}
                    defaultCountry="US"
                    variant="outlined"
                    disableFormatting
                    forceCallingCode
                    fullWidth
                    error={phoneNotValid.length > 0}
                    helperText={phoneNotValid}
                    InputProps={{
                        autoComplete: "off",
                    }}
                    size="small"
                />
            </Grid>
        </Grid>
    );
};

export default ContactForm;
