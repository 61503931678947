import React, { FunctionComponent } from "react";
import { AddOnTransaction, ReservationTransaction } from "shared/models/Reservation";
import {
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { Each } from "shared/components/Each";
import { DateTimeCellComponent } from "../../../Reports/components/DateTimeCellComponent";
import { TimeType } from "shared/models/Report";
import { CurrencyComponent } from "../../../Reports/components/CurrencyComponent";
import { ShowTransactionPanel } from "../../../Reports/components/ShowTransactionPanel";
import SeverityChip from "shared/components/SeverityChip/SeverityChip";
import shoppingCartService from "shared/services/ShoppingCartService";
import useNotification, { NotificationsType } from "shared/hooks/useNotification";
import { useShoppingCartSlice } from "../../../../store/ShoppingCart/useShoppingCartSlice";
import { useIndexicTheme } from "../../../../theme/useIndexicTheme";

interface TransactionTable_v2Props {
    transactions?: Array<ReservationTransaction>;
    addOnTransactions?: AddOnTransaction[];
}

const TransactionTable_v2: FunctionComponent<TransactionTable_v2Props> = (props) => {
    if (!props.transactions && !props.addOnTransactions) {
        return null;
    }
    return (
        <Grid container style={{ minWidth: "90%", verticalAlign: "top", overflow: "auto" }}>
            <ReservationTransactionsTable transactions={props.transactions} />
            <AddOnsTransactionsTable addOnTransactions={props.addOnTransactions} />
        </Grid>
    );
};

interface UsePaymentButtonProps {
    transactionId: number;
    hasSavedPayment: boolean;
}

const UsePaymentButton = (props: UsePaymentButtonProps) => {
    const { showNotification } = useNotification();
    const shoppingCart = useShoppingCartSlice();
    const addTransactionPayment = async () => {
        const cartId = shoppingCart.cart?.cartId;
        if (!cartId || cartId === "Empty") {
            return null;
        }
        try {
            await shoppingCartService.addTransactionPaymentMethod(cartId, props.transactionId);
            showNotification({
                message: "Payment correctly added",
                type: NotificationsType.success,
            });
        } catch {
            showNotification({
                message: "Payment can not be added to the cart",
                type: NotificationsType.error,
            });
        }
    };
    if (
        shoppingCart.cart?.isEmpty ||
        shoppingCart.cart?.cartId === "Empty" ||
        !props.hasSavedPayment
    ) {
        return null;
    }
    return (
        <Button variant="outlined" color="primary" size="small" onClick={addTransactionPayment}>
            Use Payment
        </Button>
    );
};
const ReservationTransactionsTable: FunctionComponent<
    Pick<TransactionTable_v2Props, "transactions">
> = (props) => {
    const transactions = props.transactions ?? [];
    if (transactions.length === 0) {
        return null;
    }
    return (
        <Table style={{ minWidth: "90%" }}>
            <TableHead>
                <TableRow>
                    <TableCell style={{ textAlign: "left" }}>
                        <Typography variant={"subtitle1"} component={"p"}>
                            Reservation
                        </Typography>
                        <Typography variant={"subtitle1"} component={"p"}>
                            Transactions
                        </Typography>
                    </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                        <Typography variant={"subtitle1"}>Date</Typography>
                    </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                        <Typography variant={"subtitle1"}>Amount</Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <Each
                    of={transactions}
                    render={(tran, key) => (
                        <ReservationTransactionRow key={key} transaction={tran} />
                    )}
                />
            </TableBody>
        </Table>
    );
};

interface ReservationTransactionRowProps {
    transaction: ReservationTransaction;
}

const ReservationTransactionRow: FunctionComponent<ReservationTransactionRowProps> = (props) => {
    const tran = props.transaction;
    return (
        <TableRow>
            <TableCell style={{ textAlign: "left" }}>
                <UsePaymentButton
                    transactionId={tran.transactionId}
                    hasSavedPayment={tran.hasSavedPayment}
                />
                <ShowTransactionPanel transactionId={tran.transactionId} />

                {tran.cash ? <SeverityChip severity={"success"}>CASH</SeverityChip> : null}
                {tran.dispute ? <SeverityChip severity={"error"}>DISPUTE</SeverityChip> : null}
            </TableCell>
            <TableCell style={{ textAlign: "right" }}>
                <DateTimeCellComponent dateStr={tran.transactionDate} timeType={TimeType.UTC} />
            </TableCell>
            <TableCell style={{ textAlign: "right" }}>
                <CurrencyComponent value={tran.unitPrice} />
            </TableCell>
        </TableRow>
    );
};
const AddOnsTransactionsTable: FunctionComponent<
    Pick<TransactionTable_v2Props, "addOnTransactions">
> = (props) => {
    const addOnTransactions = props.addOnTransactions ?? [];
    if (addOnTransactions.length === 0) {
        return null;
    }
    return (
        <Table className="transaction-table" style={{ minWidth: "90%" }}>
            <TableHead>
                <TableRow>
                    <TableCell style={{ textAlign: "left" }}>
                        <Typography variant={"subtitle1"} component={"p"}>
                            Add On
                        </Typography>
                        <Typography variant={"subtitle1"} component={"p"}>
                            Transactions
                        </Typography>
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                        <Typography variant={"subtitle1"}>Description</Typography>
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                        <Typography variant={"subtitle1"}>Qty</Typography>
                    </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                        <Typography variant={"subtitle1"}>Unit price</Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <Each
                    of={addOnTransactions}
                    render={(tran, key) => <AddOnTransactionRow key={key} transaction={tran} />}
                />
            </TableBody>
        </Table>
    );
};

interface AddOnTransactionRowProps {
    transaction: AddOnTransaction;
}

const AddOnTransactionRow: FunctionComponent<AddOnTransactionRowProps> = (props) => {
    const theme = useIndexicTheme();
    const tran = props.transaction;
    return (
        <TableRow>
            <TableCell style={{ textAlign: "left" }}>
                <ShowTransactionPanel transactionId={tran.transactionId} />
                {tran.cash ? <SeverityChip severity={"success"}>CASH</SeverityChip> : null}
            </TableCell>
            <TableCell>{tran.description}</TableCell>
            <TableCell>
                <span style={tran.quantity < 0 ? { color: theme.palette.error.main } : undefined}>
                    {tran.quantity}
                </span>
            </TableCell>
            <TableCell style={{ textAlign: "right" }}>
                <CurrencyComponent value={tran.quantity < 0 ? -tran.price : tran.price} />
            </TableCell>
        </TableRow>
    );
};
export default TransactionTable_v2;
