import React, { FunctionComponent, useState } from "react";
import { useMeasureElement } from "shared/hooks/useMeasureElement";
import styles from "./Merchandise.module.css";
import { MiscellaneousCatalogItemEntry } from "./MiscellaneousCatalogItemEntry";
import { SelectReservationControl } from "../components/SelectReservationControl";
import { CatalogListSearch } from "./CatalogListSearch";
import SlidePanel from "../../../shared/components/SlidePanel";
interface MerchandiseComponentProps {
    closePanel: () => void;
}

const MerchandiseContent: FunctionComponent<MerchandiseComponentProps> = () => {
    const [selectedReservationId, setSelectedReservationId] = useState<number>(0);
    const [miscRef, miscMeasures] = useMeasureElement<HTMLDivElement>([]);
    const onSelectedReservationChange = (reservationId: number) => {
        setSelectedReservationId(reservationId);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.reservationSelect}>
                <SelectReservationControl
                    selectedReservation={selectedReservationId}
                    onSelectReservation={onSelectedReservationChange}
                />
            </div>
            <CatalogListSearch
                wrapperHeight={miscMeasures?.height ?? 0}
                selectedReservationId={selectedReservationId}
            />
            <div
                className={styles.wrapperFooter}
                ref={miscRef}
                style={{ height: miscMeasures?.height ?? "auto" }}
            >
                <MiscellaneousCatalogItemEntry selectedReservationId={selectedReservationId} />
            </div>
        </div>
    );
};

interface MerchandiseProps {
    open: boolean;
    onClose: () => void;
}
const Merchandise = (props: MerchandiseProps) => {
    return (
        <SlidePanel
            title={"Merchandise "}
            relativeWidth={90}
            padding={"0"}
            open={props.open}
            onClose={props.onClose}
            children={<MerchandiseContent closePanel={props.onClose} />}
        />
    );
};
export default Merchandise;
