import { z } from "zod";
import { InterfaceToZod } from "./UtilsTypes";

import { IUpdateUserPermission, IUser, IUserCompany } from "./User";
import { CompanyPreferences } from "./Creds";
import { UserPrefencesSchema } from "./Creds.schema";

export const UserSchema = z.object<InterfaceToZod<IUser>>({
    companies: z
        .array(
            z.object<InterfaceToZod<IUserCompany>>({
                active: z.boolean(),
                companyId: z.number(),
                companyName: z.string(),
                reportAccess: z.number(),
                systemAccess: z.number(),
            }),
        )
        .nullable(),
    companyId: z.number(),
    companyPreferences: z
        .object<InterfaceToZod<CompanyPreferences>>({
            defaultPage: z.number(),
            defaultEventGroupId: z.number(),
            resourceCalendarDefaultTypes: z.array(z.number()).nullable(),
        })
        .nullable(),
    email: z.string().nullable(),
    firstName: z.string(),
    lastAccess: z.string(),
    lastName: z.string(),
    phone: z.string().nullable(),
    reportAccess: z.number(),
    systemAccess: z.number(),
    userId: z.string(),
    userPreferences: UserPrefencesSchema.nullable(),
    active: z.boolean(),
});

export const UsersSchema = z.array(
    z.object<InterfaceToZod<IUser>>({
        companies: z.string().nullable(),
        companyId: z.number(),
        companyPreferences: z.string().nullable(),
        email: z.string().nullable(),
        firstName: z.string(),
        lastAccess: z.string(),
        lastName: z.string(),
        phone: z.string().nullable(),
        reportAccess: z.number(),
        systemAccess: z.number(),
        userId: z.string(),
        userPreferences: z.string().nullable(),
        active: z.boolean(),
    }),
);

export const UpdateUserPermission = z.object<InterfaceToZod<IUpdateUserPermission>>({
    systemAccess: z.number(),
    reportAccess: z.number(),
});
