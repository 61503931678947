import { FormLabel, InputAdornment, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { ICompanyUser } from "shared/models/Company";
import { IContactInfoTemplate } from "shared/models/ContactInfo";
import { Locale } from "shared/utils/Locale";
import { Validation } from "shared/utils/Validation";
import { AccountCircle, MailOutline, Phone } from "@mui/icons-material";
import "./UserSettings.css";
import { useUserSettingsContext } from "./UserSettingsContext";
import { UserPreferences } from "shared/models/Creds";

//TODO: this component needs to be rewritted
const UserSection = () => {
    const { companyUser, updateUser } = useUserSettingsContext();
    const { firstName, lastName, phone, email } = companyUser;

    const [hasBlurred, setHasBlurred] = useState<IContactInfoTemplate<boolean>>({
        email: false,
        lastName: false,
        phone: false,
        firstName: false,
    } as IContactInfoTemplate<boolean>);

    const [isValid, setIsValid] = useState<IContactInfoTemplate<boolean>>({
        email: false,
        lastName: false,
        phone: false,
        firstName: false,
    } as IContactInfoTemplate<boolean>);

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        const firstNameValid: boolean = name === "firstName" && value.length === 0;
        const lastNameValid: boolean = name === "lastName" && value.length === 0;
        const phoneValid: boolean = name === "phone" && (value.length === 0 || value.length === 9);
        const emailValid: boolean =
            name === "email" && (Validation.Email(value).length !== 0 || value.length === 0);

        const newValidity = {
            ...isValid,
            firstName: firstNameValid,
            lastName: lastNameValid,
            phone: phoneValid,
            email: emailValid,
        };
        setIsValid(newValidity);
        const data = { [name]: value };
        updateUser(data as Partial<ICompanyUser>);
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const newValue = { ...hasBlurred };
        (newValue as any)[event.target.name] = true;
        setHasBlurred(newValue);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h5">Account</Typography>

            <div>
                <FormLabel component="legend">{Locale.firstName}</FormLabel>
                <TextField
                    required
                    id="firstName"
                    name="firstName"
                    value={firstName}
                    error={isValid.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AccountCircle />
                            </InputAdornment>
                        ),
                    }}
                    fullWidth
                    size="small"
                />
            </div>
            <div>
                <FormLabel component="legend">{Locale.lastName}</FormLabel>
                <TextField
                    id="lastName"
                    name="lastName"
                    value={lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={isValid.lastName}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AccountCircle />
                            </InputAdornment>
                        ),
                    }}
                    required
                    fullWidth
                    size="small"
                />
            </div>
            <div>
                <FormLabel component="legend">{Locale.phone}</FormLabel>
                <TextField
                    id="phone"
                    name="phone"
                    value={phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={isValid.phone}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Phone />
                            </InputAdornment>
                        ),
                    }}
                    fullWidth
                    size="small"
                />
            </div>
            <div>
                <FormLabel component="legend">Notification e-mail address</FormLabel>
                <TextField
                    id="email"
                    name="email"
                    type="email"
                    value={email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={isValid.email}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MailOutline />
                            </InputAdornment>
                        ),
                    }}
                    fullWidth
                    size="small"
                />
            </div>
        </div>
    );
};

export default UserSection;
