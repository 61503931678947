import { APIError } from "shared/http/errors";

/**
 * @description This is a dynamic error message function where are two needed params
 * @param _error 4
 * @param position this position is used in the case where the error is comming as array, so you can pick the position that you need to display
 * @returns error message string
 */
export const ManageErrorMessage = (_error: any, position: number = 0): string => {
    switch (true) {
        case _error instanceof TypeError:
            return _error.message;
        case _error instanceof RangeError:
            return "";
        case _error instanceof EvalError:
            return "";
        case typeof _error === "string":
            return _error as string;
        case _error instanceof APIError:
            const { data } = _error as APIError;
            if (typeof data === "string") {
                return data;
            } else if (Array.isArray(data)) {
                return data[position > data.length ? data.length - 1 : position];
            }
            return data as string;
        case _error instanceof Error:
            return _error.message as string;
        default:
            return "An unknown error occurred.";
    }
};
