import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
    ISystemAccess,
    SystemAccess,
    SystemAccessFields,
    systemAccessLabels,
} from "shared/models/AccessRights";

interface ISystemProps {
    onHandleReturnForm: (form: SystemAccess) => void;
    systemAccess: number;
    resetForm?: boolean;
}

const defaultValues: ISystemAccess = Object.keys(systemAccessLabels).reduce((obj, el) => {
    const key = el as SystemAccessFields;
    obj[key] = false;
    return obj;
}, {} as ISystemAccess);
const SystemAccessForm = (props: ISystemProps) => {
    const [access, setAccess] = useState<ISystemAccess>(defaultValues);

    useEffect(() => {
        functionAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.resetForm]);

    const functionAccess = () => {
        const valuesSystem: ISystemAccess = { ...defaultValues };
        Object.keys(valuesSystem).forEach((option) => {
            const key = option as keyof ISystemAccess;
            const systemAccessKey = (SystemAccess as any)[key];
            if (props.systemAccess !== SystemAccess.SysAdmin) {
                let resultSystemAccess = (props.systemAccess & systemAccessKey) > 0;
                if (
                    props.systemAccess === SystemAccess.None &&
                    systemAccessKey === SystemAccess.None
                ) {
                    resultSystemAccess = true;
                }

                if (option === "SysAdmin") {
                    resultSystemAccess = false;
                }
                valuesSystem[key] = resultSystemAccess;
            } else {
                let resultSystemAccess = true;
                if (systemAccessKey === SystemAccess.None) {
                    resultSystemAccess = false;
                }
                valuesSystem[key] = resultSystemAccess;
            }
        });

        setAccess(valuesSystem);
    };

    useEffect(() => {
        handleRoles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [access]);

    const handleRoles = () => {
        let _role = props.systemAccess;
        const maxValue = 1073745919; // Max value of all permissions: Change when ENUM system change
        if (_role === SystemAccess.SysAdmin) {
            _role = maxValue;
        }
        Object.keys(access).forEach((option) => {
            const key = option as keyof ISystemAccess;
            if (option === "SysAdmin" && access[key]) {
                _role = SystemAccess.SysAdmin;
            }

            if (option !== "SysAdmin") {
                _role = access[key]
                    ? _role | (SystemAccess as any)[key]
                    : (_role &= ~(SystemAccess as any)[key]);
            }

            if (option === "None" && access[key]) {
                _role = SystemAccess.None;
            }
        });

        props.onHandleReturnForm(_role);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        let state: ISystemAccess = defaultValues;
        switch (name) {
            case "ReservationAdd":
                if (checked) {
                    state = {
                        ...access,
                        ReservationAdd: true,
                        ReservationView: true,
                        SysAdmin: false,
                        None: false,
                    };
                } else {
                    state = {
                        ...access,
                        ReservationAdd: false,
                    };
                }
                break;
            case "ReservationModify":
                if (checked) {
                    state = {
                        ...access,
                        ReservationModify: true,
                        ReservationView: true,
                        ReservationAdd: true,
                        SysAdmin: false,
                        None: false,
                    };
                } else {
                    state = {
                        ...access,
                        ReservationModify: false,
                    };
                }
                break;
            case "ReservationDelete":
                if (checked) {
                    state = {
                        ...access,
                        ReservationDelete: true,
                        ReservationView: true,
                        ReservationModify: true,
                        SysAdmin: false,
                        None: false,
                    };
                } else {
                    state = {
                        ...access,
                        ReservationDelete: false,
                    };
                }
                break;
            case "None":
                state = { ...defaultValues, None: true };
                break;
            case "SysAdmin":
                if (checked) {
                    state = {
                        None: false,
                        ReservationView: true,
                        ReservationAdd: true,
                        ReservationModify: true,
                        ReservationDelete: true,
                        Refund: true,
                        EventEdit: true,
                        PersonnelManage: true,
                        ResourcesManage: true,
                        CouponsManage: true,
                        CompanyEditFinancials: true,
                        CompanyEditNotFinancials: true,
                        AddDeleteEventDates: true,
                        UpdateEventDates: true,
                        WebsiteManage: true,
                        AdhocPriceAdjustments: true,
                        UserAccess: true,
                        SysAdmin: true,
                    };
                } else {
                    state = { ...access, SysAdmin: false };
                }
                break;
            default:
                if (checked) {
                    state = { ...access, [name]: checked, None: false };
                } else {
                    state = { ...access, [name]: checked, SysAdmin: false };
                }
                break;
        }

        setAccess(state);
    };

    return (
        <Grid container style={{ display: "flex" }}>
            <Grid item md={12}>
                <FormControl component="fieldset" style={{ margin: 3 }}>
                    <FormLabel component="legend">System Access</FormLabel>
                    <FormGroup>
                        {Object.keys(systemAccessLabels).map((el) => {
                            let key = el as SystemAccessFields;
                            return (
                                <FormControlLabel
                                    key={key}
                                    control={
                                        <Checkbox
                                            onChange={handleChange}
                                            checked={access[key]}
                                            name={key}
                                        />
                                    }
                                    label={systemAccessLabels[key]}
                                />
                            );
                        })}
                    </FormGroup>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default SystemAccessForm;
