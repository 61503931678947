import { HttpService } from "shared/http/http";
import {
    CalendarParams,
    CompanyResources,
    ManualAllocation,
    MoveResource,
    ReservedOn,
    TimelineEvent,
} from "shared/models/Resources";
import { CompanyResourcesSchema, TimeLineEventListSchema } from "shared/models/ResourcesSchemas";
import { ValidateSchema } from "shared/utils/ZodValidations";

//TODO: add zod validations
class ResourceService extends HttpService {
    constructor() {
        super("/portal/resources/");
    }

    async returnResorceItem(resourceItemId: number, startDateTime: string) {
        const result = await this.post<string>(
            `ReturnResource/${resourceItemId}/${startDateTime}`,
            {},
        );
        return result.data;
    }
    async GetCompanyResources() {
        const result = await this.get<CompanyResources>("ResourceCalendar");
        return ValidateSchema<CompanyResources>(result.data, CompanyResourcesSchema);
    }
    async GetResourceAllocations(params: CalendarParams) {
        const result = await this.post<TimelineEvent[]>("ResourceAllocation", params);
        return ValidateSchema<TimelineEvent[]>(result.data, TimeLineEventListSchema);
    }
    async SetAvailability(resourceItemId: number, availability: boolean) {
        const result = await this.post("SetAvailability", {
            resourceItemId,
            availability,
        });
        return result.data;
    }
    async SetManualAllocation(params: ManualAllocation) {
        const result = await this.post<TimelineEvent[]>("ManualAllocation", params);
        return ValidateSchema<TimelineEvent[]>(result.data, TimeLineEventListSchema);
    }

    async checkResourceAvailability(params: MoveResource) {
        const result = await this.post<string>("CheckResourceAvailability", params);
        return result.data; //we do not need a validation for the response
    }
    async moveResource(params: MoveResource) {
        const result = await this.post("MoveResource", params);
        return result.data; //we do not need a validation for the response
    }
    async deleteManualAllocation(params: ManualAllocation) {
        await this.post("DeleteResource", params);
    }
    async whenUsed(resourceItemId: number) {
        const result = await this.get<ReservedOn[]>(`WhenUsed/${resourceItemId}`);
        return result.data;
    }
    async reallocate(eventDateId: number) {
        const result = await this.post(`Reallocate/${eventDateId}`, {});
        return result.data;
    }
}
const resourceService = new ResourceService();
export default resourceService;
