import React from "react";
import { CircularProgress, Typography, Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import { SignedWaiver, ApprovalStatus, Participants } from "shared/models/AWaiver";
import { DateTimeUtils } from "shared/utils/DateTimeUtils";
import { useIndexicTheme } from "theme/useIndexicTheme";

interface Props {
    loading: boolean;
    list: SignedWaiver[];
    hanldeSelectedWaiver: (signedWaiver_Id: number) => void;
    selectedWaiver: number;
}

const AWaiverList = (props: Props) => {
    const theme = useIndexicTheme();
    return props.loading ? (
        <Box style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
        </Box>
    ) : props.list.length > 0 ? (
        <Grid container spacing={1}>
            {props.list.map((item: SignedWaiver) => (
                <Grid item xs={12} key={item.signedWaiver_Id}>
                    <Paper
                        elevation={0}
                        sx={{
                            p: 2,
                            borderRadius: 2,
                            borderColor: theme.palette.success["darker"],
                            border: ".1rem solid",
                            bgcolor:
                                item.status === ApprovalStatus.Approved
                                    ? theme.palette.success["lighter"]
                                    : item.status === ApprovalStatus.Rejected
                                    ? theme.palette.error["lighter"]
                                    : "",
                            color:
                                item.status === ApprovalStatus.Approved
                                    ? theme.palette.success["darker"]
                                    : item.status === ApprovalStatus.Rejected
                                    ? theme.palette.error["darker"]
                                    : "",
                            gap: 1,
                        }}
                        onClick={() => props.hanldeSelectedWaiver(item.signedWaiver_Id)}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <div>
                                <Typography
                                    component="p"
                                    variant="body1"
                                >{`Signed by ${item.signedByName}`}</Typography>
                                <React.Fragment>
                                    {item.participants.map((element: Participants) => (
                                        <Typography
                                            key={element.id}
                                            variant="subtitle2"
                                            component="p"
                                        >
                                            {element.participantName}
                                        </Typography>
                                    ))}
                                </React.Fragment>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <Typography component="p" variant="body1">
                                    {DateTimeUtils.formatDate(
                                        DateTimeUtils.UTCStringToLocalDate(item.signed),
                                        {
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                            hour: "numeric",
                                            minute: "numeric",
                                        },
                                    )}
                                </Typography>
                            </div>
                        </div>
                    </Paper>
                </Grid>
            ))}
        </Grid>
    ) : null;
};

export default AWaiverList;
