import React from "react";
import Question, { QuestionState } from "./Question";
import { Grid, Divider } from "@mui/material";
import { isSameQuestion } from "shared/business/Questions";
import { QuestionBase, QuestionAnswer } from "shared/models/QuestionTypes";

interface QuestionsProps {
    questions?: QuestionBase[];
    oldAnswers?: QuestionAnswer[];
    answers?: QuestionAnswer[];
    onAnswerChange: (answers: QuestionsState) => void;
    showError?: boolean;
}
export interface QuestionsState {
    questionsWithAnswers: QuestionAnswer[];
    invalid: boolean;
}
export default function Questions(props: QuestionsProps) {
    const { questions, answers, onAnswerChange } = props;

    const addAnswer = (element: QuestionAnswer): QuestionAnswer[] => {
        const array: QuestionAnswer[] = answers || [];
        const existingAns = array.filter((ca) => ca.questionId !== element.questionId);
        return [...existingAns, element];
    };
    const checkAllValid = (array: QuestionAnswer[]): boolean =>
        array.every((el) => el.invalid === false);

    const handleAnswerChange = (
        { id, questionText, questionType }: QuestionBase,
        { invalid, touched, answer }: QuestionState,
    ) => {
        const newQuestion = {
            questionId: id,
            questionText,
            questionType,
            answer,
            invalid,
            touched,
        };
        const questionsWithAnswers = addAnswer(newQuestion);
        const newAns: QuestionsState = {
            questionsWithAnswers,
            invalid:
                !checkAllValid(questionsWithAnswers) ||
                questionsWithAnswers?.length !== questions?.length,
        };

        if (props.oldAnswers && props.oldAnswers?.length > 0) {
            newAns.questionsWithAnswers = [...questionsWithAnswers, ...props.oldAnswers];
        }

        if (onAnswerChange) {
            onAnswerChange(newAns);
        }
    };

    const currentAnswer = (question: QuestionBase): string[] => {
        const ans = answers?.find((answer) => isSameQuestion(answer)(question));
        return ans ? ans.answer : [];
    };

    if (!questions || questions.length === 0) {
        return null;
    }
    return (
        <Grid container spacing={3}>
            {questions.map((q, idx) => (
                <Grid item xs={12} key={idx}>
                    <Question
                        question={q}
                        answer={currentAnswer(q)}
                        onAnswerChange={handleAnswerChange}
                        showError={props.showError}
                    />
                    <Divider variant="middle" sx={{ marginTop: "1rem" }} />
                </Grid>
            ))}
        </Grid>
    );
}
