import React, { FunctionComponent, useState } from "react";
import SlidePanel from "../../../shared/components/SlidePanel";
import styles from "./Tipping.module.css";
import { useShoppingCartSlice } from "../../../store/ShoppingCart/useShoppingCartSlice";
import { CartReservation, TipMethod } from "../../../shared/models/Cart";
import { Fade, LinearProgress, MenuItem, TextField, Typography } from "@mui/material";
import { Nullable } from "../../../shared/models/Generals";
import LabelDivider from "../../../shared/components/LabelDivider";
import {
    TippingEditableAmountTable,
    TippingEditablePercentageTable,
    TippingTable,
} from "./TippingTables";
import { getShoppingCartNotifications } from "../ShoppingCartNotifications";
import useNotification from "../../../shared/hooks/useNotification";

interface TippingContentProps {
    closePanel: () => void;
}
const TippingContent: FunctionComponent<TippingContentProps> = (props) => {
    const [loading, setLoading] = useState(false);
    const [selectedReservationId, setSelectedReservationId] = useState<number>(-1);
    const { shoppingCartState, addTip } = useShoppingCartSlice();
    const reservations: CartReservation[] = shoppingCartState.cart?.reservations || [];
    const [selectedReservation, setSelectedReservation] = useState<Nullable<CartReservation>>(null);
    const [errorMsg, setErrorMsg] = useState("");
    const { showNotification } = useNotification();
    const onSelectedReservationChange = (reservationId: number) => {
        setSelectedReservationId(reservationId);
        const reservation = reservations.find((el) => el.reservationId === reservationId);
        if (reservation) {
            setSelectedReservation(reservation);
        }
        setErrorMsg("");
    };
    const handleAddTip = async (method: TipMethod, quantity: number) => {
        const notification = getShoppingCartNotifications("AddTip");
        if (selectedReservationId >= 0) {
            setLoading(true);
            try {
                await addTip(method, quantity, selectedReservationId);
                showNotification(notification.success);
            } catch (e) {
                showNotification(notification.error);
            } finally {
                setLoading(false);
            }
        } else {
            setErrorMsg("Please select a reservation");
        }
    };
    const price = selectedReservation?.price ?? 0;
    return (
        <div className={styles.wrapper}>
            <div className={styles.reservationSelect}>
                <Typography variant={"inputLabel"} gutterBottom>
                    Select a reservation*
                </Typography>
                <TextField
                    select
                    fullWidth
                    size={"small"}
                    value={selectedReservationId}
                    error={errorMsg !== ""}
                    helperText={errorMsg}
                    sx={{
                        background: "white",
                    }}
                    onChange={(e) => onSelectedReservationChange(Number(e.target.value))}
                >
                    <MenuItem value={-1} disabled>
                        Select a reservation
                    </MenuItem>
                    {reservations.map((el, idx) => (
                        <MenuItem key={idx} value={el.reservationId}>
                            <Typography>{cleanUpDescription(el.description)}</Typography>
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            <Fade in={loading}>
                <LinearProgress />
            </Fade>
            <TippingTable price={price} handleAddTip={handleAddTip} />
            <div className={styles.tipSpacer}>
                <LabelDivider
                    label="Custom tips"
                    styles={{
                        marginBottom: "1rem",
                    }}
                />
            </div>
            <Typography className={styles.tipSpacer} variant={"inputLabel"}>
                Define custom percentage tip
            </Typography>
            <TippingEditablePercentageTable price={price} handleAddTip={handleAddTip} />
            <Typography className={styles.tipSpacer} variant={"inputLabel"}>
                Define custom amount tip
            </Typography>
            <TippingEditableAmountTable price={price} handleAddTip={handleAddTip} />
        </div>
    );
};

interface TippingProps {
    open: boolean;
    onClose: () => void;
}
const Tipping: FunctionComponent<TippingProps> = (props) => {
    return (
        <SlidePanel
            title={"Add tip"}
            relativeWidth={90}
            padding={"0"}
            open={props.open}
            onClose={props.onClose}
            children={<TippingContent closePanel={props.onClose} />}
        />
    );
};

export default Tipping;

const cleanUpDescription = (description: string): string => {
    const lines = description.split("\n");
    return lines[0] + " (" + lines[1] + ")";
};
