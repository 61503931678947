import { ITransactionDetails } from "shared/models/Transaction";
import { HttpService, createUrl } from "../http/http";
import { IRefundResult } from "shared/models/Reservation";
import { ValidateSchema } from "shared/utils/ZodValidations";
import { ReceiptSchema } from "shared/models/Transaction.schema";

class TransactionService extends HttpService {
    constructor() {
        super("/portal");
    }

    async getReceipt(receiptId: number) {
        const uri = createUrl(["receipt", receiptId]);
        const result = await this.get<ITransactionDetails>(uri);
        return ValidateSchema<ITransactionDetails>(result.data, ReceiptSchema);
    }

    async sendReceipt(transactionId: number, email: string) {
        const uri = createUrl(["receipt", "send", `${transactionId}`, email]);
        const result = await this.get(uri);
        return result.data;
    }

    /**
     * @deprecated Use refund transaction instead
     * @param transactionItemId
     * @param quantity
     */
    async refundItem(transactionItemId: number, quantity: number) {
        const url: string = createUrl([
            "transaction",
            "ItemReturn",
            `${transactionItemId}`,
            `${quantity}`,
        ]);
        return this.put<IRefundResult>(url, {});
    }
}

const transactionService = new TransactionService();
export default transactionService;
