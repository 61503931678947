import React, { FunctionComponent, useEffect, useRef } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { TextField, TextFieldProps } from "@mui/material";
import { GiftCardRecipient } from "../../../shared/models/Cart";
import { GiftCardGeneralData } from "../../../shared/models/GiftCardRecipientsData";
import { FieldErrorsRecord, useFormValidation } from "../../../shared/hooks/useFormValidation";
import { Validation } from "../../../shared/utils/Validation";
interface GiftCardRecipientEntryProps {
    recipient: GiftCardRecipient;
    giftCardData: GiftCardGeneralData;
    index: number;
    isNew?: boolean;
    showError: boolean;
    onChange: (form: GiftCardRecipient, isValid: boolean) => void;
    onRemove: (recipient: GiftCardRecipient) => void;
}
const checkFormErrors = (recipient: GiftCardRecipient): FieldErrorsRecord<GiftCardRecipient> => {
    return {
        recipientName: Validation.Required(recipient.recipientName),
        recipientEmail: Validation.Email(recipient.recipientEmail),
        note: "",
        giftCardRecipientId: "",
        giftCardTemplateId: "",
    };
};

/**
 * Form components, it handles the validations for the email, name and note of the recipient,
 * is intercepted by the GiftCardRecipientEntry but can be used as a standalone component for new recipients
 * @param props
 * @constructor
 */
export const GiftCardRecipientEditable: FunctionComponent<GiftCardRecipientEntryProps> = (
    props,
) => {
    const showErrorPrev = useRef(props.showError);
    const isFirstRender = useRef(true);

    const {
        form,
        updateBlur,
        debouncedForm,
        isFieldRequired,
        showErrorMessage,
        getErrorText,
        updateForm,
        validateForm,
        displayErrors,
    } = useFormValidation<GiftCardRecipient>(
        completeGiftCardRecipientFields(props.recipient),
        true,
        ["recipientEmail", "recipientName"],
        checkFormErrors,
    );
    useEffect(() => {
        if (!isFirstRender.current) {
            props.onChange(form, validateForm());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedForm]);

    useEffect(() => {
        if (showErrorPrev.current !== props.showError) {
            showErrorPrev.current = props.showError;
            displayErrors();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.showError]);

    const handleBlur: TextFieldProps["onBlur"] = (e) => {
        const { name } = e.target;
        updateBlur(name as keyof GiftCardRecipient);
    };
    const handleChange: TextFieldProps["onChange"] = (e) => {
        const { name, value } = e.target;
        const fieldName = name as keyof GiftCardRecipient;
        updateForm({ [fieldName]: value });
        if (isFirstRender.current) {
            isFirstRender.current = false;
        }
    };
    return (
        <>
            <Grid xs={12} sm={6}>
                <TextField
                    label="Recipient Name"
                    name={"recipientName"}
                    fullWidth
                    size="small"
                    value={form.recipientName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={isFieldRequired("recipientName")}
                    error={showErrorMessage("recipientName")}
                    helperText={getErrorText("recipientName")}
                />
            </Grid>
            <Grid xs={12} sm={6}>
                <TextField
                    label="Recipient Email"
                    name={"recipientEmail"}
                    fullWidth
                    size="small"
                    value={form.recipientEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={isFieldRequired("recipientEmail")}
                    error={showErrorMessage("recipientEmail")}
                    helperText={getErrorText("recipientEmail")}
                />
            </Grid>
            <Grid xs={12}>
                <TextField
                    label="Note"
                    name={"note"}
                    multiline
                    fullWidth
                    size="small"
                    value={form.note}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={isFieldRequired("note")}
                    error={showErrorMessage("note")}
                    helperText={getErrorText("note")}
                />
            </Grid>
        </>
    );
};
// Needed since note is an optional field
const completeGiftCardRecipientFields = (recipient: GiftCardRecipient): GiftCardRecipient => {
    return {
        recipientName: recipient.recipientName ?? "",
        recipientEmail: recipient.recipientEmail ?? "",
        note: recipient.note ?? "",
        giftCardRecipientId: recipient.giftCardRecipientId,
        giftCardTemplateId: recipient.giftCardTemplateId,
    };
};
