import { OverrideComponent } from "../interfaces";

const TabOverrides: OverrideComponent<"MuiTab"> = (theme) => {
    return {
        MuiTab: {
            styleOverrides: {
                root: {
                    minHeight: 46,
                    color: theme.palette.text.primary,
                },
            },
        },
    };
};
export default TabOverrides;
