import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconActionButton } from "shared/components/IconActionButton";
import DialogComponent from "shared/components/DialogComponent";
import { Box, Typography } from "@mui/material";
import { useShoppingCartSlice } from "store/ShoppingCart/useShoppingCartSlice";
import reservationService from "shared/services/ReservationService";
import useNotification, { NotificationsType } from "shared/hooks/useNotification";
import { useQueryClient } from "@tanstack/react-query";
import useGlobal from "shared/hooks/GlobalHook";
import { APIError, ForbiddenError } from "shared/http/errors";
import { useAuthContext } from "modules/Auth/AuthProvider";
import { SystemAccess } from "shared/models/AccessRights";

interface Props {
    reservationId: number;
    hasTransactions: boolean;
}

const DeleteReservation = (props: Props) => {
    const { cart, removeReservation } = useShoppingCartSlice();
    const { getSystemAccess } = useAuthContext();
    const { globalState, clearReservationPanelPayload, setOpenPanel } = useGlobal();
    const { showNotification } = useNotification();
    const [open, setOpen] = useState(false);
    const queryClient = useQueryClient();
    const handleConfirm = async () => {
        if (cart?.reservations.some((x) => x.reservationId === props.reservationId)) {
            removeReservation(props.reservationId);
        }
        try {
            const { data } = await reservationService.deleteReservation(props.reservationId);
            if (data === -1) {
                showNotification({
                    message: "A reservation with financial transactions cannot be deleted.",
                    type: NotificationsType.warning,
                });
            } else {
                showNotification({
                    message: "Reservation deleted.",
                    type: NotificationsType.success,
                });
                queryClient.invalidateQueries({
                    queryKey: ["event-date-details"],
                });
                queryClient.invalidateQueries({
                    queryKey: ["calendar-by-event"],
                });
                if (
                    globalState.reservationPanelPayload &&
                    globalState.reservationPanelPayload.reservationId === props.reservationId &&
                    globalState.isReservationDetailsOpen
                ) {
                    clearReservationPanelPayload();
                    setOpenPanel(false);
                }
                setOpen(false);
            }
        } catch (e) {
            if (e instanceof APIError) {
                showNotification({
                    message: "Error deleting reservation.",
                    type: NotificationsType.error,
                });
            }

            if (e instanceof ForbiddenError) {
                showNotification({
                    message:
                        "You do not have access to delete report, please contact your company administrator.",
                    type: NotificationsType.warning,
                });
            }
        }
    };
    const havePermission = getSystemAccess(SystemAccess.ReservationDelete);
    return (
        <>
            <IconActionButton
                icon={<DeleteIcon />}
                onClick={() => setOpen(true)}
                label="Delete"
                size="small"
                disabled={props.hasTransactions && !havePermission} //validate if transaction exists
            />
            <DialogComponent
                DialogText="Delete reservation?"
                open={open}
                handleClose={() => setOpen(false)}
                ButtonActions={{
                    handleSuccessActionText: "Yes",
                    handleSuccessActionFunction: () => handleConfirm(),
                    handleCancelActionFunction: () => setOpen(false),
                    handleCancelActionText: "No",
                }}
            >
                <Grid container>
                    <Grid xs={12}>
                        <Box display="flex" flexDirection="column" justifyContent="center">
                            <Typography>
                                This will delete the reservation and any associated merchandise.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </DialogComponent>
        </>
    );
};

export default DeleteReservation;
