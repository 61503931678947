import { Table, TableHead, TableCell, TableBody, TableRow, Button } from "@mui/material";
import React, { FunctionComponent, useEffect, useRef } from "react";
import { CurrencyComponent } from "../components/CurrencyComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { SalesByDateRange } from "../../../shared/models/Report";
import { GridCheckIcon } from "@mui/x-data-grid-pro";
import "./SalesByDate.css";
import { NavigateBefore } from "@mui/icons-material";

const SalesByDatePrintable: FunctionComponent = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const hasRendered = useRef(false);
    useEffect(() => {
        if (hasRendered.current) {
            setTimeout(() => {
                window.print();
            }, 1);
        }
    }, []);
    if (!state || !state?.data) {
        return (
            <div
                style={{
                    background: "white",
                    padding: "1rem",
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div>
                    <Button
                        className={"navigate-button"}
                        color={"primary"}
                        variant={"contained"}
                        onClick={() => {
                            navigate("/reports/sales-by-date", { replace: true, state: null });
                        }}
                        startIcon={<NavigateBefore />}
                    >
                        Back to report
                    </Button>
                </div>
            </div>
        );
    }
    const data = state.data as SalesByDateRange;
    hasRendered.current = true;
    return (
        <div>
            <div
                id={"printable"}
                style={{
                    background: "white",
                    padding: "1rem",
                }}
            >
                <h1>Sales by date report</h1>
                <Button
                    className={"navigate-button"}
                    color={"primary"}
                    onClick={() => {
                        navigate("/reports/sales-by-date", { replace: true, state: null });
                    }}
                    startIcon={<NavigateBefore />}
                >
                    Back to report
                </Button>
                <h3>Summary</h3>
                <Table className={"summary"}>
                    <TableHead>
                        <TableRow style={{ border: "1px solid black" }}>
                            <TableCell>Event</TableCell>
                            <TableCell>Tickets #</TableCell>
                            <TableCell>Cash Subtotal</TableCell>
                            <TableCell>Cash Tax</TableCell>
                            <TableCell>Cash Total</TableCell>
                            <TableCell>Credit Subtotal</TableCell>
                            <TableCell>Credit Tax</TableCell>
                            <TableCell>Credit Total</TableCell>
                            <TableCell>Subtotal</TableCell>
                            <TableCell>Tax</TableCell>
                            <TableCell>Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(data?.reservations || []).map((el, key) => (
                            <TableRow key={key}>
                                <TableCell className={"eventName"}>{el.eventName}</TableCell>
                                <TableCell>{el.tickets}</TableCell>
                                <CurrencyCell value={el.cashSubtotal} />
                                <CurrencyCell value={el.cashTax} />
                                <CurrencyCell value={el.cashTotal} />
                                <CurrencyCell value={el.creditSubtotal} />
                                <CurrencyCell value={el.creditTax} />
                                <CurrencyCell value={el.creditTotal} />
                                <CurrencyCell value={el.subtotal} />
                                <CurrencyCell value={el.tax} />
                                <CurrencyCell value={el.total} />
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <h3>Details</h3>
                <Table className={"details"}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Event</TableCell>
                            <TableCell>Tickets #</TableCell>
                            <TableCell style={{ textAlign: "center" }}>Details</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(data?.reservations || []).map((el, key) => (
                            <TableRow key={key} className={"eventEntry"}>
                                <TableCell className={"eventName"}>{el.eventName}</TableCell>
                                <TableCell>{el.tickets}</TableCell>
                                <TableCell>
                                    {el.details.length > 0 ? (
                                        <Table className={"eventDetails"}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Processor</TableCell>
                                                    <TableCell>Subtotal</TableCell>
                                                    <TableCell>Tax</TableCell>
                                                    <TableCell>Total</TableCell>
                                                    <TableCell>Payment within range</TableCell>
                                                    <TableCell>Boost</TableCell>
                                                    <TableCell>Online</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {el.details.map((j, jIndex) => (
                                                    <TableRow key={`details-${key}-${jIndex}`}>
                                                        <TableCell className={"processor"}>
                                                            {j.processor}
                                                        </TableCell>
                                                        <CurrencyCell value={j.subtotal} />
                                                        <CurrencyCell value={j.tax} />
                                                        <CurrencyCell value={j.total} />
                                                        <BooleanCell value={j.inRange} />
                                                        <BooleanCell value={j.boost} />
                                                        <BooleanCell value={j.online} />
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    ) : null}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {data.catalogItems.length > 0 ? (
                    <>
                        <h3>Catalog items</h3>
                        <Table size={"small"} className={"catalogItems"}>
                            <TableHead>
                                <TableRow style={{ border: "1px solid black" }}>
                                    <TableCell>Item</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Cash</TableCell>
                                    <TableCell>Payment</TableCell>
                                    <TableCell>Addon</TableCell>
                                    <TableCell>Items</TableCell>
                                    <TableCell>Subtotal</TableCell>
                                    <TableCell>Service</TableCell>
                                    <TableCell>Tax</TableCell>
                                    <TableCell>Total</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(data?.catalogItems || []).map((el, key) => (
                                    <TableRow key={key}>
                                        <TableCell className={"itemCode"}>{el.itemCode}</TableCell>
                                        <TableCell>{el.description}</TableCell>
                                        <BooleanCell value={el.cash} />
                                        <BooleanCell value={el.today} />
                                        <BooleanCell value={el.addOn} />
                                        <TableCell style={{ textAlign: "right" }}>
                                            {el.quantity}
                                        </TableCell>
                                        <CurrencyCell value={el.subtotal} />
                                        <CurrencyCell value={el.serviceCharge} />
                                        <CurrencyCell value={el.tax} />
                                        <CurrencyCell value={el.total} />
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </>
                ) : null}
            </div>
        </div>
    );
};

export default SalesByDatePrintable;

interface ReportCellProps<T> {
    value: T;
}

interface CurrencyCellProps extends ReportCellProps<number> {}

const CurrencyCell = (props: CurrencyCellProps) => {
    return (
        <TableCell style={{ textAlign: "right" }}>
            <CurrencyComponent value={props.value} />
        </TableCell>
    );
};

interface BooleanCellProps extends ReportCellProps<boolean> {}

const BooleanCell = (props: BooleanCellProps) => {
    return (
        <TableCell style={{ textAlign: "center" }}>
            {props.value ? <GridCheckIcon /> : null}
        </TableCell>
    );
};
