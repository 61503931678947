import { DependencyList, useLayoutEffect, useRef, useState } from "react";
import { Nullable } from "../models/Generals";

export const useMeasureElement = <T extends HTMLElement>(
    dependencies: DependencyList,
): [React.RefObject<T>, DOMRect | null] => {
    const ref = useRef<T>(null);
    const [measures, setMeasures] = useState<Nullable<DOMRect>>(null);

    useLayoutEffect(() => {
        if (ref.current != null) {
            const rect = ref.current.getBoundingClientRect();
            setMeasures(rect);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, ...dependencies]);
    return [ref, measures];
};
