import { PortalEventSummary, EventTimeSummary, PortalEventSummaries } from "shared/models/Event";
import { createUrl, HttpService } from "../http/http";
import { ValidateSchema } from "../utils/ZodValidations";
import { z } from "zod";
import { EventTimeSummarySchema, PortalEventSummariesSchema } from "../models/Event.schema";
class EventsService extends HttpService {
    constructor() {
        super("/portal/Events/");
    }

    async getCalendarByEvent(
        events: Array<number> | number,
        startDate: Date,
        endDate: Date,
        includeZeroTickets: boolean,
    ) {
        let uri = createUrl([
            "CalendarByEvent",
            events.toString(),
            startDate,
            endDate,
            includeZeroTickets.toString(),
        ]);
        const result = await this.get<EventTimeSummary[]>(uri);
        return ValidateSchema<EventTimeSummary[]>(result.data, z.array(EventTimeSummarySchema));
    }
    async getCalendarByEventGroup(
        eventGroupId: number,
        startDate: Date,
        endDate: Date,
        includeZeroTickets: boolean,
    ) {
        let uri = createUrl([
            "CalendarByEventGroup",
            eventGroupId.toString(),
            startDate,
            endDate,
            includeZeroTickets.toString(),
        ]);
        const result = await this.get<EventTimeSummary[]>(uri);

        return ValidateSchema<EventTimeSummary[]>(result.data, z.array(EventTimeSummarySchema));
    }
    async getEventSummary(eventDateId: number) {
        const result = await this.get(eventDateId.toString());
        if (result.status === 200) {
            return result.data as PortalEventSummary;
        }
    }
    async getPortalSummaries() {
        const result = await this.get<PortalEventSummaries>("Summaries");
        return ValidateSchema<PortalEventSummaries>(result.data, PortalEventSummariesSchema);
    }
}

const service = new EventsService();
export default service;
