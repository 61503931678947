import React, { FunctionComponent, PropsWithChildren } from "react";
import { formatCurrency } from "shared/utils/Formats";
import { useIndexicTheme } from "../../../theme/useIndexicTheme";

interface CurrencyComponentProps {
    value: number;
}

export const CurrencyComponent: FunctionComponent<PropsWithChildren<CurrencyComponentProps>> = (
    props,
) => {
    const theme = useIndexicTheme();
    const value = formatCurrency(props.value);
    return (
        <span style={props.value < 0 ? { color: theme.palette.error.main } : undefined}>
            {value}
        </span>
    );
};
