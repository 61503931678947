import { OverrideComponent } from "../interfaces";

const AlertOverrides: OverrideComponent<"MuiAlert"> = () => {
    return {
        MuiAlert: {
            styleOverrides: {
                root: {
                    ".MuiAlert-icon": {
                        alignItems: "center",
                    },
                },
            },
        },
    };
};

export default AlertOverrides;
