import { FormControl, FormLabel } from "@mui/material";
import { isEmpty } from "shared/utils/Common";
import React from "react";
import {
    QuestionBase,
    QuestionType,
    QuestionNumber,
    QuestionSingleChoice,
    QuestionMultiChoice,
    QuestionDate,
} from "shared/models/QuestionTypes";
import { DateTimeUtils } from "shared/utils/DateTimeUtils";
import {
    INumberQuestionProps,
    NumberQuestion,
    IBooleanQuestionProps,
    BooleanQuestion,
    ISingleChoiceQuestionProps,
    SingleChoiceQuestion,
    IMultichoiceQuestionProps,
    MultiChoiceQuestion,
    ITextQuestionProps,
    TextQuestion,
    IDateQuestionProps,
    DateQuestion,
} from "./QuestionsInput";
import {
    IPhoneNumberQuestionProps,
    PhoneNumberQuestion,
} from "./QuestionsInput/PhoneNumberQuestion";
import { MuiTelInputInfo } from "mui-tel-input";
import { Validation } from "shared/utils/Validation";

export interface QuestionState {
    invalid: boolean;
    touched: boolean;
    answer: string[];
}

export interface QuestionProps {
    answer: string[];
    onAnswerChange: (arg: QuestionBase, arg2: QuestionState) => void;
    question: QuestionBase;
    showError?: boolean;
}

export interface IBaseQuestionInputProps {
    value: string;
    onChange: (e: any) => void;
    onBlur: () => void;
}

export default function Question(props: QuestionProps) {
    const { onAnswerChange, question } = props;

    const [state, setState] = React.useState<QuestionState>({
        answer: props.answer.length > 0 ? props.answer : [""],
        invalid: isEmpty(props.answer),
        touched: false,
    });

    React.useEffect(() => {
        if (props.showError === true) {
            setState((state) => ({
                ...state,
                invalid: isEmpty(props.answer),
                touched: true,
            }));
        }
    }, [props.showError, props.answer]);

    const handleBooleanChange = (event: any) => {
        const ans = event.target.value;
        const newState = { touched: true, answer: [ans], invalid: false };
        onAnswerChange(question, { ...newState, touched: true });
        setState(newState);
    };
    const handleAnswerChange = (event: any) => {
        const ans = event.target.value;
        const isInvalid = !event.target.checkValidity() || ans.toString().length === 0;
        const newState = { touched: true, answer: [ans], invalid: isInvalid };
        onAnswerChange(question, newState);
        setState(newState);
    };
    const handleSingleSelectionEditable = (event: any) => {
        const ans = event.target.value;
        const isInvalid = !event.target.checkValidity() || ans.toString().length === 0;
        const newState = { touched: true, answer: [ans], invalid: isInvalid };
        onAnswerChange({ ...question, questionType: QuestionType.SingleChoice }, newState);
        setState(newState);
    };
    const handleSelectionChange =
        (isEditable: boolean) =>
        ({ invalid, touched, selected }: any) => {
            const newState = { touched: touched, answer: selected, invalid: invalid };
            setState(newState);
            let newQuestion = question;
            if (isEditable) {
                newQuestion = { ...question, questionType: QuestionType.MultiChoice };
            }
            onAnswerChange(newQuestion, { ...newState, touched: true });
        };
    const handleDateChange = (date: Date, value: string) => {
        if (date) {
            // Required to send the correct payload to the server
            const parsedDate = DateTimeUtils.toYYYYMMDD(date);
            const newState = { touched: true, answer: [parsedDate], invalid: false };
            onAnswerChange(question, { ...newState, touched: true });
            setState(newState);
        }
    };
    const handlePhoneNumChange = (phone: string, info: MuiTelInputInfo) => {
        const phoneValid: boolean = Validation.PhoneNumber(phone).length === 0;
        const newState = {
            ...state,
            answer: [phone ? (phone as string) : ""],
            invalid: !phoneValid,
            touched: true,
        };
        setState(newState);
        onAnswerChange(question, newState);
    };
    const handleBlur = () => {
        // onAnswerChange(question, { ...state, touched: true });
    };

    // If we have a placeholder answer we return null value, only on multiSelect
    const multiSelectAnswer = (selected: string[]): string[] | null => {
        if (selected.length === 1) {
            return selected[0] === "" ? null : selected;
        }
        return selected;
    };
    const renderInput = () => {
        const error = state.invalid && state.touched;
        const baseProps: IBaseQuestionInputProps = {
            value: state.answer[0],
            onChange: handleAnswerChange,
            onBlur: handleBlur,
        };
        switch (question.questionType) {
            case QuestionType.Number:
                const numQuestion = question as QuestionNumber;
                const numberQuestionProps: INumberQuestionProps = {
                    ...baseProps,
                    error,
                    question: numQuestion,
                };
                return <NumberQuestion {...numberQuestionProps} />;

            case QuestionType.Boolean:
                const booleanQuestionProps: IBooleanQuestionProps = {
                    ...baseProps,
                    value: state.answer[0],
                    onChange: handleBooleanChange,
                };
                return <BooleanQuestion {...booleanQuestionProps} />;

            case QuestionType.SingleChoice:
                const singleChoiceQuestion = question as QuestionSingleChoice;
                const singleChoiceProps: ISingleChoiceQuestionProps = {
                    ...baseProps,
                    question: singleChoiceQuestion,
                    selected: state.answer,
                    onSelectionChange: handleSelectionChange,
                };
                return <SingleChoiceQuestion {...singleChoiceProps} />;

            case QuestionType.MultiChoice:
                const multiQuestion = question as QuestionMultiChoice;
                const multiChoiceProps: IMultichoiceQuestionProps = {
                    question: multiQuestion,
                    selected: multiSelectAnswer(state.answer),
                    onSelectionChange: handleSelectionChange(false),
                };
                return <MultiChoiceQuestion {...multiChoiceProps} />;

            case QuestionType.Text:
                let textQuestionProps: ITextQuestionProps = { ...baseProps, error };
                return <TextQuestion {...textQuestionProps} />;

            case QuestionType.Date:
                const dateQuestion = question as QuestionDate;
                const dateQuestionProps: IDateQuestionProps = {
                    ...baseProps,
                    error,
                    question: dateQuestion,
                    onChange: handleDateChange,
                };
                return <DateQuestion {...dateQuestionProps} />;

            case QuestionType.Phone:
                const phoneProps: IPhoneNumberQuestionProps = {
                    error,
                    onChange: handlePhoneNumChange,
                    onBlur: handleBlur,
                    value: state.answer[0],
                };
                return <PhoneNumberQuestion {...phoneProps} />;

            case QuestionType.MultiChoiceEditable:
                const multiChoiceEditableProps: IMultichoiceQuestionProps = {
                    question: question as QuestionMultiChoice,
                    selected: multiSelectAnswer(state.answer),
                    onSelectionChange: handleSelectionChange(true),
                    isEditable: true,
                };
                return <MultiChoiceQuestion {...multiChoiceEditableProps} />;
            case QuestionType.SingleChoiceEditable:
                let textQuestionProps2: ITextQuestionProps = {
                    ...baseProps,
                    error,
                    onChange: handleSingleSelectionEditable,
                };
                return <TextQuestion {...textQuestionProps2} />;
            default:
                return null;
        }
    };
    return (
        <FormControl
            component="fieldset"
            error={state.invalid && state.touched}
            variant="outlined"
            fullWidth={true}
        >
            <FormLabel component="legend">{question.questionText}</FormLabel>
            {renderInput()}
        </FormControl>
    );
}
