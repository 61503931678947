import {
    Box,
    InputAdornment,
    OutlinedInput,
    useMediaQuery,
    Theme,
    IconButton,
    TextFieldProps,
    CircularProgress,
} from "@mui/material";
import { SearchOutlined } from "@ant-design/icons";
import searchService from "shared/services/SearchService";
import { useState } from "react";
import { SearchReservationTransaction } from "shared/models/Search";
import { ModalSearch } from "shared/components/ModalSearch";
import useGlobal from "shared/hooks/GlobalHook";
import DialogComponent from "shared/components/DialogComponent";
import { useIndexicTheme } from "theme/useIndexicTheme";
import useNotification, { NotificationsType } from "shared/hooks/useNotification";
import { LoadingButton } from "@mui/lab";

const Search = () => {
    const theme = useIndexicTheme();
    const { showNotification } = useNotification();
    const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
    const [openModal, setOpenModal] = useState<boolean>(false);
    const { addReservationPanelPayload } = useGlobal();
    const [openModalSearch, setOpenModalSearch] = useState<boolean>(false);
    const [valueToSearch, setValueToSearch] = useState<string>("");
    const [searchValues, setSearchValues] = useState<SearchReservationTransaction>({
        reservations: [],
        transactions: [],
    });
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false);

    const handleSearch = (e: any) => {
        if (e.keyCode === 13) {
            getSearchReservationTransaction(e.target.value);
        }
    };

    const handleChangeValue: TextFieldProps["onChange"] = (e) => {
        setValueToSearch(e.target.value);
    };

    const getSearchReservationTransaction = async (search: string) => {
        setLoadingSearch(true);
        try {
            const data = await searchService.SearchReservationTransaction(search);

            if (data.reservations.length === 0 && data.transactions.length === 0) {
                showNotification({
                    message: `Can't find a reservation or transaction with this value: ${search}`,
                    type: NotificationsType.warning,
                });
                setOpenModal(false);
            } else {
                setOpenModal(true);
                setSearchValues(data);
                setOpenModalSearch(false);
            }
        } catch (e) {
            showNotification({
                message: "Error to search",
                type: NotificationsType.error,
            });
        }
        setLoadingSearch(false);
    };

    const openReservationDetails = (reservationId: number) => {
        addReservationPanelPayload(reservationId, 0);
        setOpenModal(false);
    };

    const handleClickIconSearch = () => {
        setOpenModalSearch(true);
    };

    return (
        <Box sx={{ width: "100%", ml: { xs: 0, sm: 1 } }}>
            {matchesXs ? (
                <IconButton
                    style={{ fontSize: "1.2rem", color: theme.palette.grey["800"] }}
                    children={<SearchOutlined />}
                    onClick={handleClickIconSearch}
                ></IconButton>
            ) : (
                <OutlinedInput
                    size="small"
                    id="header-search"
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchOutlined style={{ color: "var(--grey-700)" }} />
                        </InputAdornment>
                    }
                    endAdornment={
                        loadingSearch && (
                            <InputAdornment position="end">
                                <CircularProgress size={20} />
                            </InputAdornment>
                        )
                    }
                    aria-describedby="header-search-text"
                    inputProps={{
                        "aria-label": "weight",
                    }}
                    placeholder="Search..."
                    sx={{
                        borderRadius: "0.5rem",
                        backgroundColor: "var(--grey-100)",
                        borderColor: "var(--grey-400)",
                        color: "var(--grey-600)",
                    }}
                    onKeyDown={handleSearch}
                    onChange={handleChangeValue}
                />
            )}

            <DialogComponent
                open={openModalSearch}
                handleClose={() => setOpenModalSearch(false)}
                DialogText="Search reservation or transaction"
                aria-labelledby="modal-modal-search"
                aria-describedby="modal-modal-search-reservation-transaction"
            >
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <OutlinedInput
                        fullWidth
                        size="small"
                        id="header-search"
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchOutlined style={{ color: "var(--grey-700)" }} />
                            </InputAdornment>
                        }
                        aria-describedby="header-search-text"
                        inputProps={{
                            "aria-label": "weight",
                        }}
                        placeholder="Search..."
                        sx={{
                            borderRadius: "0.5rem",
                            backgroundColor: "var(--grey-100)",
                            borderColor: "var(--grey-400)",
                            color: "var(--grey-600)",
                        }}
                        value={valueToSearch}
                        onChange={handleChangeValue}
                    />
                    <LoadingButton
                        loading={loadingSearch}
                        variant="contained"
                        size="small"
                        onClick={() => {
                            getSearchReservationTransaction(valueToSearch);
                        }}
                        sx={{ marginLeft: ".5rem" }}
                    >
                        Search
                    </LoadingButton>
                </div>
            </DialogComponent>

            <ModalSearch
                open={openModal}
                searchValues={searchValues}
                openReservationDetails={openReservationDetails}
                handleCloseModal={() => setOpenModal(false)}
            />
        </Box>
    );
};

export default Search;
