import React, { ReactElement, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Fade,
    LinearProgress,
    Typography,
} from "@mui/material";

interface AcceptedProps {
    title: string;
    content: string | ReactElement;
    buttonActions?: IButtonActions[];
    onClose?: () => void;
}
interface IButtonActions {
    onAction: () => void;
    buttonText: string;
}
const useConfirmationDialog = () => {
    const [open, setOpen] = useState(false);
    const [closeText, setCloseText] = useState("No");
    const [dialogState, setDialogState] = useState<AcceptedProps>({
        title: "",
        content: "",
    });
    const [isLoading, setIsLoading] = useState(false);

    const handleOpen = (
        title: string,
        content: string | ReactElement,
        closeText: string,
        buttonActions?: IButtonActions[],
        onClose?: () => void,
    ) => {
        setOpen(true);
        setCloseText(closeText);
        setDialogState({ title, content, buttonActions, onClose });
    };

    const handleClose = (allowExternalClose: boolean = true) => {
        setOpen(false);
        if (dialogState.onClose && allowExternalClose) {
            dialogState.onClose();
        }
    };

    const handleConfirm = async (action?: () => void) => {
        setIsLoading(true);
        if (action) {
            await action();
            setIsLoading(false);
        }
        handleClose(false);
    };

    return {
        handleOpen,
        handleClose,
        dialogContext: (
            <>
                <Dialog
                    open={open}
                    onClose={() => handleClose()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <Typography variant="inputLabel">{dialogState.title}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Fade in={isLoading}>
                            <LinearProgress />
                        </Fade>
                        {typeof dialogState.content === "string" ? (
                            <DialogContentText id="alert-dialog-description">
                                <Typography variant="commentContent">
                                    {dialogState.content}
                                </Typography>
                            </DialogContentText>
                        ) : (
                            dialogState.content
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleClose()} color="primary">
                            {closeText}
                        </Button>
                        {dialogState.buttonActions &&
                            dialogState.buttonActions.map((item) => (
                                <Button
                                    key={item.buttonText}
                                    onClick={() => handleConfirm(item.onAction)}
                                    color="primary"
                                    autoFocus
                                >
                                    {item.buttonText}
                                </Button>
                            ))}
                    </DialogActions>
                </Dialog>
            </>
        ),
    };
};

export default useConfirmationDialog;
