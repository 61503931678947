import { Locale } from "./Locale";

export class MathEx {
    static intlFmt = new Intl.NumberFormat(Locale.locale || "en-US", {
        style: "currency",
        currency: Locale.currency || "USD",
        currencySign: "accounting",
    });

    static pr_o = Intl.PluralRules
        ? new Intl.PluralRules(Locale.locale || "en-US", {
              type: "ordinal",
          })
        : null;

    static formatCurrency(decimalNumber: number): string {
        if (this.intlFmt) return this.intlFmt.format(decimalNumber);
        else return decimalNumber.toString();
    }

    static round(value: number, step: number) {
        step || (step = 1.0);
        const inv = 1.0 / step;
        return Math.round(value * inv) / inv;
    }

    static ordinal(value: number) {
        if (!this.pr_o) {
            return value;
        }
        let suffix = Locale.ordinalSuffixOther;
        switch (this.pr_o.select(value)) {
            case "one":
                suffix = Locale.ordinalSuffixOne;
                break;
            case "two":
                suffix = Locale.ordinalSuffixTwo;
                break;
            case "few":
                suffix = Locale.ordinalSuffixFew;
                break;
            default:
                break;
        }
        return value + suffix;
    }
}
