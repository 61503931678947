import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { SubscriptionKeyCredential, MapsURL, SearchURL, Aborter, Models } from "azure-maps-rest";
import { IAddressValue } from "shared/models/Address";
import { debounceFactory } from "shared/utils/DebounceFactory";

let _searchURL: SearchURL;
export function getAzureMapsSearchURL(azureMapsKey: string): SearchURL {
    if (!_searchURL) {
        const subscriptionKeyCredential = new SubscriptionKeyCredential(azureMapsKey);

        // Use subscriptionKeyCredential to create a pipeline.
        const pipeline = MapsURL.newPipeline(subscriptionKeyCredential, {
            retryOptions: { maxTries: 4 }, // Retry options
        });
        _searchURL = new SearchURL(pipeline);
    }
    return _searchURL;
}

let searchResults: { [id: string]: IAddressValue[] } = {};

function addressEqual(optAddr: IAddressValue, valueAddr: IAddressValue): boolean {
    return optAddr.addressText === valueAddr.addressText;
}

export interface IAddressProps {
    azureMapsKey: string;
    label: string;
    value: IAddressValue | null;
    nearbyLat: number;
    nearbyLon: number;
    error?: boolean;
    helperText?: string;
    required?: boolean;
    fullWidth?: boolean;
    radius?: number;
    onChange: (result: IAddressValue | null) => void;
    onBlur?: () => void;
}

export default function Address(props: IAddressProps) {
    const value: IAddressValue = props.value || { addressText: "", lat: 0, lon: 0 };
    useEffect(() => {
        searchResults = {};
    }, []);

    const [addressList, setAddressList] = useState<IAddressValue[]>(
        props.value ? [props.value] : [],
    );

    const options = {
        lat: props.nearbyLat,
        lon: props.nearbyLon,
        radius: props.radius || (props.nearbyLat && props.nearbyLon ? 30000 : undefined), // radius in meters
    } as Models.SearchGetSearchFuzzyOptionalParams;

    const searchURL = getAzureMapsSearchURL(props.azureMapsKey);
    const doSearch = (searchInput: string) => {
        searchURL.searchFuzzy(Aborter.timeout(10000), searchInput, options).then((results) => {
            const newAddressList = results.results?.map((address) => {
                return {
                    addressText:
                        (address.type === "POI" ? address.poi?.name + ", " : "") +
                        address.address?.freeformAddress,
                    lat: address.position?.lat,
                    lon: address.position?.lon,
                } as IAddressValue;
            });

            searchResults[searchInput] = newAddressList || [];
            setAddressList(searchResults[searchInput]);
        });
    };

    const searchDebounce = debounceFactory(doSearch, 350);
    const handleInputChange = async (event: any, newInputValue: string) => {
        if (newInputValue.length > 2) {
            if (searchResults[newInputValue]) {
                setAddressList(searchResults[newInputValue]);
            } else {
                searchDebounce(newInputValue);
            }
            if (props.onChange && props.value?.addressText !== newInputValue) {
                props.onChange({ addressText: newInputValue } as IAddressValue);
            }
        }
    };

    const handleChange = (event: any, newValue: string | IAddressValue | null) => {
        if (props.onChange) {
            if (typeof newValue === "string") {
                newValue = { addressText: newValue } as IAddressValue;
            }
            props.onChange(newValue);
        }
    };
    const getOptionsLabel = (option: IAddressValue | string): string => {
        if (typeof option === "string") {
            return option;
        }
        return option.addressText ? option.addressText : "";
    };
    return (
        <Autocomplete
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.label}
                    error={props.error}
                    required={props.required}
                    helperText={props.helperText}
                    onBlur={props.onBlur}
                    fullWidth={props.fullWidth}
                    size="small"
                />
            )}
            getOptionLabel={getOptionsLabel}
            onInputChange={handleInputChange}
            onChange={handleChange}
            options={addressList}
            value={value}
            filterOptions={(x) => x}
            autoComplete
            includeInputInList
            filterSelectedOptions
            isOptionEqualToValue={addressEqual}
            freeSolo
            size="small"
        />
    );
}
