import { Configuration, RedirectRequest } from "@azure/msal-browser";
import { Environment } from "../shared/models/UtilsTypes";

export const environment: Environment = {
    PRODUCTION: true,
    BASE_URL: "https://areservationapi-portal.azurewebsites.net",
    BASE_SIGNALR: "https://signalr-indexic.azurewebsites.net/api",
    BASE_VIATOR: "https://viator.indexic.net",
};
export const PRODUCTION = true;
export const BASE_URL = "https://areservationapi-portal.azurewebsites.net";
export const BASE_SIGNALR = "https://signalr-indexic.azurewebsites.net/api";
export const BASE_VIATOR = "https://viator.indexic.net";
export const scopes = ["openid"];
export const REACT_APP_STRIPE_KEY = "pk_live_WMHXOX9YobatEMlNVpdOBRTZ";
export const REACT_APP_STRIPE_VERSION = "2022-11-15";
export const REACT_APP_APPINSIGHTS_KEY = "c95240c6-77b9-4107-b406-718e5cf94b6d";
export const MUI_KEY =
    "5a7e04a894151677e6a7839ddebf36daTz03OTg5OSxFPTE3MzMxNzA4NTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=";
// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "489376be-ccb8-491f-b269-f3234838572c",
        authority:
            "https://IndexicAuth.b2clogin.com/IndexicAuth.onmicrosoft.com/B2C_1_sign_in_sign_up",
        knownAuthorities: ["https://IndexicAuth.b2clogin.com/"],
        redirectUri: "https://portal.areservation.com",
        postLogoutRedirectUri: "https://www.indexic.net",
    },
    cache: {
        cacheLocation: "localStorage",
    },
};