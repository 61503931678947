import React, { useState } from "react";
import { Chip, CircularProgress } from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import reservationService from "shared/services/ReservationService";
import { NotificationsType } from "shared/hooks/useNotification";
import { useReservationModalContext } from "./context/ReservationModalProvider";
import { ReactComponent as HowToReg } from "../../assets/icons/how_to_reg.svg";
import { CheckInCheckOutResult } from "../../shared/models/Reservation";

//* Passing 0 as ticketPersonId means that all the tickets of the selected reservation will be update the checked in property
//* if ticketPersonId has a number > 0 means that only that ticket will be updated
interface Props {
    reservationId: number;
    ticketPersonId: number;
    checked: boolean;
    disabled?: boolean;
    callback: (response: CheckInCheckOutResult) => void;
}

const CheckInCheckOut = (props: Props) => {
    const { showNotification, handleActiveProgressBar } = useReservationModalContext();
    const [loading, setLoading] = useState<boolean>(false);

    const handleCheckinCheckout = async () => {
        // This function has to handle the process of calling the API to update if a Reservation is or not checked in
        // For example:
        // CheckInCheckOut(props.reservationId, props.ticketPersonId, !props.checked);
        handleActiveProgressBar(true);
        setLoading(true);
        try {
            const result = await reservationService.checkInOut(
                props.reservationId,
                props.ticketPersonId,
                !props.checked,
            );
            showNotification({
                message: `Checked ${!props.checked ? "in" : "out"}`,
                type: NotificationsType.success,
            });

            props.callback(result);
        } catch {
            showNotification({
                message: `Error ocurred to Check ${!props.checked ? "in" : "out"}`,
                type: NotificationsType.error,
            });
        }
        setLoading(false);
        handleActiveProgressBar(false);
    };
    return (
        <Chip
            variant={props.checked ? "makeCheckedIn" : "makeCheckIn"} //this is a custom variant that you will find in the Theme file
            onClick={handleCheckinCheckout}
            onDelete={handleCheckinCheckout}
            deleteIcon={
                !loading ? (
                    props.checked ? (
                        <HowToReg />
                    ) : (
                        <PersonOutlineOutlinedIcon />
                    )
                ) : (
                    <CircularProgress size="small" />
                )
            }
            size="small"
            label={props.checked ? "Checked-in" : "Check-in"}
            disabled={props.disabled}
        />
    );
};

export default CheckInCheckOut;
