import { styled } from "@mui/material/styles";
import { Button, ButtonProps } from "@mui/material";

export const FlatButton = styled(Button)<ButtonProps>(({ theme }) => ({
    borderRadius: "var(--global-radius)",
    background: theme.palette.grey["200"],
    padding: "0.375rem 0.75rem",
    "& .Mui-disabled": {
        background: theme.palette.grey["200"],
        color: theme.palette.grey["300"],
    },
    "&:hover": {
        background: theme.palette.grey["300"],
    },
}));
