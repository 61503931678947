import { addDays, format as DateFnsFormat, isValid, parseISO } from "date-fns";
import { DateTimeString } from "../models/UtilsTypes";

export enum FormatType {
    shortDate = "shortDate",
    mediumDate = "mediumDate",
    longDate = "longDate",
    fullDate = "fullDate",
    shortTime = "shortTime",
    mediumTime = "mediumTime",
    narrowWeekDay = "narrowWeekDay",
    shortWeekDay = "shortWeekDay",
    longWeekDay = "longWeekDay",
    short = "short",
    medium = "medium",
    long = "long",
    full = "full",
    shortWithDow = "shortWithDow"
}

const shortDateOption: Intl.DateTimeFormatOptions = { dateStyle: "short" };
const mediumDateOption: Intl.DateTimeFormatOptions = { dateStyle: "medium" };
const longDateOption: Intl.DateTimeFormatOptions = { dateStyle: "long" };
const fullDateOption: Intl.DateTimeFormatOptions = { dateStyle: "full" };

const narrowWeekDayOption: Intl.DateTimeFormatOptions = { weekday: "narrow" };
const shortWeekDayOption: Intl.DateTimeFormatOptions = { weekday: "short" };
const longWeekDayOption: Intl.DateTimeFormatOptions = { weekday: "long" };

const shortTimeOption: Intl.DateTimeFormatOptions = { timeStyle: "short" };
const mediumTimeOption: Intl.DateTimeFormatOptions = { timeStyle: "medium" };

const shortOption: Intl.DateTimeFormatOptions = {
    dateStyle: "short",
    timeStyle: "short",
};
const mediumOption: Intl.DateTimeFormatOptions = {
    dateStyle: "medium",
    timeStyle: "short",
};
const longOption: Intl.DateTimeFormatOptions = {
    dateStyle: "long",
    timeStyle: "short",
};
const fullOption: Intl.DateTimeFormatOptions = {
    dateStyle: "full",
    timeStyle: "medium",
};
const shortWithDow: Intl.DateTimeFormatOptions = {
    weekday: "short",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
};

const formats: Record<FormatType, Intl.DateTimeFormatOptions> = {
    [FormatType.shortDate]: shortDateOption,
    [FormatType.mediumDate]: mediumDateOption,
    [FormatType.longDate]: longDateOption,
    [FormatType.fullDate]: fullDateOption,
    [FormatType.shortTime]: shortTimeOption,
    [FormatType.mediumTime]: mediumTimeOption,
    [FormatType.narrowWeekDay]: narrowWeekDayOption,
    [FormatType.shortWeekDay]: shortWeekDayOption,
    [FormatType.longWeekDay]: longWeekDayOption,
    [FormatType.short]: shortOption,
    [FormatType.medium]: mediumOption,
    [FormatType.long]: longOption,
    [FormatType.full]: fullOption,
    [FormatType.shortWithDow]: shortWithDow
};

export class DateTimeUtils {
    static LOCALE = "en-US";
    static Weekdays: string[] = [];
    static WeekdaysCondensed: string[] = [];

    static eventLocalTime(dateTimeISOString: string): string {
        const localDate = this.eventLocalDate(dateTimeISOString);
        return this.newFormat(localDate, FormatType.shortTime);
    }

    static newFormat(date: Date | string, formatType: FormatType) {
        if (typeof date === "string") {
            date = this.parse(date)
        }
        const options = formats[formatType];
        if (!options) {
            console.error("Format type not found");
            return "";
        }
        return new Intl.DateTimeFormat(this.LOCALE, options).format(date);
    }

    /**
     * @deprecated use newFormat() instead
     * @see {@link newFormat}
     * @param date
     * @param options
     */
    static format(date: Date, options?: Intl.DateTimeFormatOptions) {
        const formatter = new Intl.DateTimeFormat(this.LOCALE, options);
        return formatter.format(date);
    }

    // Accommodates Safari Quirk with parsing Date Strings
    static eventLocalDate(dateTimeISOString: string) {
        const a = dateTimeISOString.split(/[^0-9]/).map((el) => Number(el));
        const result = new Date(a[0], a[1] - 1, a[2], a[3], a[4], a[5]);
        if (isValid(result)) {
            return result;
        } else {
            throw new Error(dateTimeISOString);
        }
    }

    static inRange(target: Date, start: Date, end: Date) {
        let targetMs = target.getTime();
        let startMs = start.getTime();
        let endMs = end.getTime();
        if (isNaN(targetMs) || isNaN(startMs) || isNaN(endMs)) {
            throw new Error("Invalid dates");
        }
        return startMs <= targetMs && targetMs <= endMs;
    }

    static range(start: Date, end: Date) {
        const dateArray: Date[] = [];
        let currentDate = start;
        while (currentDate < end) {
            dateArray.push(new Date(currentDate));
            currentDate = addDays(currentDate, 1);
        }
        return dateArray;
    }

    /**
     * @deprecated Use newFormat(date, FormatType.fullDate) instead
     * @param date
     * @see {@link newFormat}
     */
    static longDate(date: Date) {
        return this.newFormat(date, FormatType.fullDate);
    }

    /**
     * @deprecated Use a combination of eventLocalDate and newFormat
     * @see {@link eventLocalDate}
     * @see {@link newFormat}
     * @param ds
     */
    static longDateString(ds: string) {
        const d = DateTimeUtils.eventLocalDate(ds); // Safari Safe
        return DateTimeUtils.longDate(d);
    }

    // https://stackoverflow.com/questions/23593052/format-javascript-date-as-yyyy-mm-dd
    static toYYYYMMDD(date: Date) {
        return DateFnsFormat(date, "yyyy-MM-dd");
    }

    /**
     * Expects a date string in the form: yyyy-MM-DD and returns a date string in the form yyyy/MM/DD
     * The difference on this formats it's the way that Date object instantiates the timezone
     * i.e. yyyy/MM/DD it's set to midnight (00:00 GMT+x) in the local timezone while yyyy-MM-DD it's set to midnight in GMT+0
     * @see {@link toYYYYMMDD}
     * @param dateIso
     */
    static isoToLocaleString(dateIso: string): string {
        const reg = new RegExp(/[0-9]{4}-[0-9]{2}-[0-9]{2}/);
        if (!reg.test(dateIso)) {
            // If we have a string with a different format we return
            return dateIso;
        }
        return dateIso.replace(/-/g, "/");
    }

    // Expect time string of HH:MM:SS.ssss will convert
    // to HH:MM AM or HH:MM PM
    static toHHMM_AP(timePart: string, isShort?: boolean) {
        let timeParts: string[] = timePart.split(":"); // split HH:MM:SS.ssss
        let hour: number = parseInt(timeParts[0]);
        let extras = timeParts[1].split(" ");
        let min: string = extras[0];
        let meridian: string = extras[1];
        let suffix = !isShort ? meridian : meridian[0].toLowerCase();
        return hour.toString() + ":" + min + suffix;
    }

    static dayToString(weekday: number, condensed?: boolean) {
        if (!this.Weekdays.length) {
            for (let day = 0; day < 7; day++) {
                let dayDate = new Date(1996, 0, day);
                this.WeekdaysCondensed.push(this.newFormat(dayDate, FormatType.shortWeekDay));
                this.Weekdays.push(this.newFormat(dayDate, FormatType.longWeekDay));
            }
        }
        let temp = condensed ? this.WeekdaysCondensed : this.Weekdays;
        return temp[weekday % 7];
    }

    static getDayName(date: Date) {
        return this.newFormat(date, FormatType.shortWeekDay);
    }

    /**
     * @deprecated
     * @param date
     * @param options
     */
    static formatDate(date: Date, options?: Intl.DateTimeFormatOptions) {
        return this.format(date, {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            ...options,
        });
    }

    static formatLongDateTime(date: Date) {
        return this.newFormat(date, FormatType.long);
    }

    static formatShortDateTime(date: Date) {
        return this.newFormat(date, FormatType.short);
    }
    static getYearsFromDate = (year: number) => {
        let years: number[] = [];
        let four_years_before = year - 4;
        let four_years_after = year + 5;
        for (let index = four_years_before; index < four_years_after; index++) {
            years.push(index);
        }
        return years.reverse();
    };

    static formatDateWithoutTime(date: string | Date, options?: any) {
        let temp;
        if (typeof date === "string") {
            const a = date.split(/[^0-9]/).map((el) => Number(el));
            temp = new Date(a[0], a[1] - 1, a[2]);
        } else {
            temp = date;
            temp.setHours(0, 0, 0);
        }
        return this.format(temp, options);
    }

    static dateToDatePure(date: Date) {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
    }

    /**
     * Returns an ISO string for the given date with unspecified timezone.
     *
     * Useful when we want to send absolute dates and don't deal with calculations due to different timezones.
     * When instantiate the date with the returned string the system will create the date in the local timezone
     * @param date
     */
    static toISOStringWithoutTimezone(date: Date): string {
        // Date on GMT
        let utcDate = new Date(
            Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                date.getHours(),
                date.getMinutes(),
                date.getSeconds(),
                date.getMilliseconds(),
            ),
        );
        // We delete the "Z" on 2022-05-27T09:35:31.820Z that way the system won't calculate the GMT diff
        return utcDate.toISOString().slice(0, -1);
    }

    /**
     * Receives a local timezone date and returns the same date on UTC.
     * @param date
     * @constructor
     */
    static DateToUTC(date: Date): Date {
        return new Date(
            Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                date.getHours(),
                date.getMinutes(),
                date.getSeconds(),
                date.getMilliseconds(),
            ),
        );
    }

    /**
     * Working with UTC dates from the server will cause errors since datetime properties will return string like this
     * 2022-05-27T09:35:31.820 and ignore the timezone. If we're sure the date is on UTC we need to concatenate a Z to indicate
     * we're on UTC timezone
     * @param dateStr
     * @constructor
     */
    static UTCStringToLocalDate(dateStr: string): Date {
        const value = dateStr.endsWith("Z") ? dateStr : dateStr + "Z";
        let result = new Date(value);
        if (isValid(result)) {
            return result;
        }
        // Try parse ISO if previous parsing fails
        result = parseISO(dateStr);
        if (isValid(result)) {
            return result;
        }
        throw new Error("Error parsing date" + dateStr);
    }

    static isUTCDate(dateStr: string): boolean {
        const parts = dateStr.split("T");
        if (parts.length === 2) {
            const timePart = parts[1];
            const timeSections = timePart.split(/(-|\+)/);
            if (timeSections.length === 1) {
                return true;
            }
            const timezone = timeSections.pop();
            return timezone === "00:00";
        } else {
            throw new Error("Invalid date " + dateStr);
        }
    }

    static parse(dateStr: DateTimeString): Date {
        const isUTC = this.isUTCDate(dateStr);
        if (isUTC) {
            return this.UTCStringToLocalDate(dateStr);
        }
        return this.eventLocalDate(dateStr);
    }

    static FullDateWithTime(dateStr: Date): string {
        return `${this.newFormat(dateStr, FormatType.fullDate)} at ${this.newFormat(
            dateStr,
            FormatType.shortTime,
        )}`;
    }
}
