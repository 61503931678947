import { Grid, SxProps, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import { ReceiptTransaction } from "shared/models/Transaction";
import { DateTimeUtils, FormatType } from "shared/utils/DateTimeUtils";

interface PaymentInfoProps {
    data?: ReceiptTransaction;
}

type Props = PaymentInfoProps;

const PaymentInfo: FunctionComponent<Props> = (props) => {
    const styleTitle: SxProps = {
        // color: "var(--grey-700)",
        // fontSize: "0.875rem",
        // fontWeight: 500,
    };
    const styleContent: SxProps = {
        // color: "var(--grey-900)",
        // fontSize: "0.875rem",
        fontWeight: "bold",
    };
    if (!props.data) return null;
    const transactionDate = DateTimeUtils.parse(props.data.transactionDate);
    const dateStr = DateTimeUtils.newFormat(transactionDate, FormatType.shortDate);
    const timeStr = DateTimeUtils.newFormat(transactionDate, FormatType.mediumTime);
    return (
        <Grid container spacing={0}>
            <Grid item xs={6}>
                <Typography sx={styleTitle}>Transaction date:</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={styleContent}>{`${dateStr}, ${timeStr}`}</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={styleTitle}>Card ending with:</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={styleContent}>{props.data.card_Last_Digits ?? "--"}</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={styleTitle}>Card auth #:</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={styleContent}>{props.data.card_Authorization}</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={styleTitle}>Card transaction id:</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={styleContent}>{props.data.card_TransactionID}</Typography>
            </Grid>
        </Grid>
    );
};

export default PaymentInfo;
