import React, { useEffect, useMemo, useState } from "react";
import {
    Avatar,
    Button,
    Card,
    CardContent,
    CardHeader,
    Collapse,
    IconButton,
    IconButtonProps,
    Typography,
    styled,
    Fade,
    cardHeaderClasses,
} from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { RateQuestionGroup, RateTicketPerson } from "shared/models/Rates";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Unstable_Grid2";
import { hasRequiredFields, validateRequiredFields } from "shared/business/RequiredFields";
import DialogComponent, { DialogHooks } from "../DialogComponent";
import TicketsForm from "./TicketsForm";
import { RateQuestionParsed } from "./TicketsDisplay";
import { isEmpty } from "shared/utils/Common";
import { QuestionAnswer, QuestionBase } from "shared/models/QuestionTypes";
import useReservation from "shared/hooks/ReservationHook";
import { requiredFieldsToAnswer } from "shared/utils/ReservationUtils";
import AnswersDisplay from "../Questions/Answers/AnswersDisplay";
import { useReservationModalContext } from "modules/Reservation/context/ReservationModalProvider";
import { NotificationsType } from "shared/hooks/useNotification";
import reservationService from "shared/services/ReservationService";
import { useIndexicTheme } from "theme/useIndexicTheme";
import { useQueryClient } from "@tanstack/react-query";
import { Error } from "@mui/icons-material";

interface ITicketCardProps {
    information: RateTicketPerson;
    removeTicket: (rateId: number, ticketPersonId: number) => void;
}

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
}));
export const canCheckin = (ticket: RateTicketPerson): boolean => {
    if (ticket.ticket.ticketPersonId) {
        return ticket.ticket.ticketPersonId > 0;
    }
    return false;
};

const TicketCard = (props: ITicketCardProps) => {
    const theme = useIndexicTheme();
    const { showNotification, handleActiveProgressBar, refetchEventDateDetails } =
        useReservationModalContext();
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [isRequired, setIsRequired] = useState<boolean>(false);
    const [ticketRequiredFields, setTicketRequiredFields] = useState<number>(0);
    const [editedTicket, setEditedTicket] = useState<RateTicketPerson>(props.information);
    const { reservation, updateTicketPerson } = useReservation();
    const [openDialog, handleOpenDialog, handleCloseDialog] = DialogHooks();
    const queryClient = useQueryClient();

    useEffect(() => {
        if (reservation) {
            const isValid = validateRequiredFields(
                props.information.ticket,
                reservation.ticketRequiredFields,
            );
            setIsRequired(!isValid);
            setTicketRequiredFields(reservation.ticketRequiredFields);
        }
        //eslint-disable-next-line
    }, [reservation?.ticketRequiredFields]);

    useEffect(() => {
        setEditedTicket({ ...editedTicket, ...props.information });
        //eslint-disable-next-line
    }, [props.information]);

    const requiredFieldsAnswers = useMemo<QuestionAnswer[]>(
        () => requiredFieldsToAnswer(props.information.ticket),
        [props.information.ticket],
    );

    const rateQuestionGroups: RateQuestionParsed[] = useMemo(() => {
        if (
            reservation &&
            !isEmpty(reservation.rateQuestionGroups) &&
            reservation.rateQuestionGroups
        ) {
            return buildRateQuestions(reservation.rateQuestionGroups);
        }
        return [];
        //eslint-disable-next-line
    }, [reservation?.rateQuestionGroups]);

    if (!reservation) {
        return null;
    }
    const Actions = {
        handleSuccessActionText: "Confirm",
        handleSuccessActionFunction: () => handleConfirmTicket(),
        handleCancelActionText: "Cancel",
        handleCancelActionFunction: () => handleCloseDialog(),
    };

    function handleIndividualTicketEdit(ticket: RateTicketPerson): void {
        setEditedTicket(ticket);
    }

    const handleConfirmTicket = () => {
        if (editedTicket) {
            const isValid = validateRequiredFields(editedTicket.ticket, ticketRequiredFields);
            if (isValid) {
                setIsRequired(!isValid);
                updateTicketPerson(editedTicket);
                handleCloseDialog();
            }
        }
    };

    const handleCheckIn = async () => {
        // TODO need validation of Role for canEdit: Credentials.HasCompanyRole(SystemAccess.ReservationModify),
        // if (props.canEdit) {
        handleActiveProgressBar(true);
        if (editedTicket.ticket.ticketPersonId > 0) {
            const currentReservationId = reservation.reservationId ?? 0;
            const currentTicketPersonId = editedTicket.ticket.ticketPersonId ?? 0;

            if (currentReservationId > 0 && currentTicketPersonId > 0) {
                try {
                    await reservationService.checkInOut(
                        reservation.reservationId,
                        editedTicket.ticket.ticketPersonId,
                        !Boolean(editedTicket.ticket.checkedIn),
                    );
                    showNotification({
                        message: `Checked ${
                            !Boolean(editedTicket.ticket.checkedIn) ? "in" : "out"
                        }`,
                        type: NotificationsType.success,
                    });
                    queryClient.refetchQueries({ queryKey: ["reservation-details"] });
                    refetchEventDateDetails();
                } catch {
                    showNotification({
                        message: `Error ocurred to Check ${
                            !Boolean(editedTicket.ticket.checkedIn) ? "in" : "out"
                        }`,
                        type: NotificationsType.error,
                    });
                }
            }
        } else {
            showNotification({
                type: NotificationsType.error,
                message: "Please save the reservation before checking in.",
            });
        }
        handleActiveProgressBar(false);
    };

    const showEdit =
        hasRequiredFields(reservation.ticketRequiredFields) ||
        (props.information.questionGroupId && props.information.questionGroupId > 0);
    const name = [
        props.information.ticket.participantFirstName ?? "",
        props.information.ticket.participantLastName ?? "",
    ].join(" ");
    return (
        <>
            <div style={{ width: "100%" }}>
                <Fade in={isRequired}>
                    <Error
                        sx={{
                            transform: "translate(50%, -50%)",
                            borderRadius: 360,
                            color: theme.palette.error.dark,
                            padding: 0,
                            float: "left",
                            position: "absolute",
                        }}
                    />
                </Fade>
                <Card
                    sx={{
                        border: "1px solid",
                        borderColor: isRequired
                            ? theme.palette.error.main
                            : theme.palette.grey["300"],
                        background: "white",
                        borderRadius: "8px",
                        minWidth: 270,
                        maxWidth: 260,
                        transition: "all 0.2s ease-in",
                    }}
                >
                    <CardHeader
                        sx={{
                            [`& .${cardHeaderClasses.action}`]: {
                                alignSelf: "center",
                            },
                        }}
                        avatar={
                            <Avatar
                                sx={{
                                    color: canCheckin(editedTicket)
                                        ? Boolean(editedTicket.ticket.checkedIn)
                                            ? theme.palette.primary.main
                                            : theme.palette.error.dark
                                        : theme.palette.grey[100],
                                    cursor: canCheckin(editedTicket) ? "pointer" : "unset",
                                }}
                                onClick={() => (canCheckin(editedTicket) ? handleCheckIn() : null)}
                            />
                        }
                        action={
                            showEdit && isRequired ? (
                                <>
                                    <IconButton onClick={handleOpenDialog} color="primary">
                                        <ModeEditOutlineOutlinedIcon />
                                    </IconButton>
                                    <IconButton
                                        onClick={() =>
                                            props.removeTicket(
                                                props.information.rateId,
                                                props.information.ticket.ticketPersonId,
                                            )
                                        }
                                        color="primary"
                                    >
                                        <DeleteOutlineOutlinedIcon
                                            sx={{ color: theme.palette.error.dark }}
                                        />
                                    </IconButton>
                                </>
                            ) : (
                                <ExpandMore
                                    expand={isExpanded}
                                    onClick={() => setIsExpanded(!isExpanded)}
                                    aria-label="show more"
                                >
                                    <ExpandMoreIcon />
                                </ExpandMore>
                            )
                        }
                        title={
                            <Typography variant={"amountSummary"}>
                                {props.information.title}
                            </Typography>
                        }
                        subheader={<Typography variant={"inputLabel"}>{name}</Typography>}
                    />
                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid xs={12}>
                                    {isRequired ? (
                                        <Typography
                                            variant={"inputLabel"}
                                            sx={{
                                                color: theme.palette.error.dark,
                                            }}
                                        >
                                            Missing required fields
                                        </Typography>
                                    ) : (
                                        <div>
                                            <AnswersDisplay answers={requiredFieldsAnswers} />
                                            {props.information.parsedAnswers &&
                                            props.information.parsedAnswers.length > 0 ? (
                                                <AnswersDisplay
                                                    answers={props.information.parsedAnswers}
                                                />
                                            ) : null}
                                        </div>
                                    )}
                                </Grid>
                                <Grid xs={12}>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        {showEdit ? (
                                            <Button
                                                sx={{
                                                    fontSize: "12px",
                                                    color: "#212B36",
                                                    background: "#F4F6F8",
                                                    borderRadius: "8px",
                                                }}
                                                startIcon={
                                                    <ModeEditOutlineOutlinedIcon color="primary" />
                                                }
                                                onClick={() => handleOpenDialog()}
                                            >
                                                Edit details
                                            </Button>
                                        ) : null}
                                        <Button
                                            sx={{
                                                fontSize: "12px",
                                                color: "#212B36",
                                                background: "#F4F6F8",
                                                borderRadius: "8px",
                                            }}
                                            onClick={() =>
                                                props.removeTicket(
                                                    props.information.rateId,
                                                    props.information.ticket.ticketPersonId,
                                                )
                                            }
                                            startIcon={
                                                <DeleteOutlineOutlinedIcon
                                                    sx={{ color: "#B71D18" }}
                                                />
                                            }
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Collapse>
                </Card>
            </div>
            <DialogComponent
                isFullSize={false}
                maxWidth="sm"
                open={openDialog}
                handleClose={handleCloseDialog}
                ButtonActions={Actions}
                DialogText={
                    ticketRequiredFields && ticketRequiredFields > 0 ? props.information.title : ""
                }
            >
                <TicketsForm
                    ticketPerson={editedTicket}
                    requiredFields={ticketRequiredFields}
                    handleEdit={handleIndividualTicketEdit}
                    rateQuestionGroup={selectRateQuestionGroup(editedTicket, rateQuestionGroups)}
                    showError={false}
                />
            </DialogComponent>
        </>
    );
};
const selectRateQuestionGroup = (
    ticketPerson: RateTicketPerson,
    groups: RateQuestionParsed[] = [],
): RateQuestionParsed | null => {
    const questionGroupId = ticketPerson.questionGroupId;
    const questions = groups.filter((group) => group.questionGroupId === questionGroupId);
    if (questions?.length > 0) {
        return questions[0];
    }
    return null;
};

const buildRateQuestions = (groups: RateQuestionGroup[]): RateQuestionParsed[] => {
    const array: RateQuestionGroup[] = isEmpty(groups) ? [] : groups;
    return array.reduce((acc: RateQuestionParsed[], group) => {
        try {
            if (group.questionsJson) {
                const qa: QuestionBase[] = JSON.parse(group.questionsJson);
                const temp: RateQuestionParsed = {
                    ...group,
                    questions: qa,
                };
                acc.push(temp);
            }
        } catch (e) {}
        return acc;
    }, []);
};

export default TicketCard;
