import { Divider, Typography } from "@mui/material";
import { CSSProperties, ReactNode } from "react";

type JustifyContent = "start" | "end";
interface ILabelDividerProps {
    label?: string;
    children?: ReactNode;
    justify?: JustifyContent;
    styles?: CSSProperties;
}

const LabelDivider = (props: ILabelDividerProps) => {
    let styles: CSSProperties = {
        display: "flex",
        flexDirection: props.justify === "end" ? "row-reverse" : "row",
        alignItems: "center",
        width: "100%",
        gap: "0.5rem",
    };
    if (props.styles) {
        styles = { ...styles, ...props.styles };
    }
    return (
        <div style={styles}>
            {props.label && <Typography variant="headerExpandableTitle">{props.label}</Typography>}
            {props.children}
            <Divider light={true} sx={{ flexGrow: 1 }}></Divider>
        </div>
    );
};

export default LabelDivider;
