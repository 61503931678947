import { NotificationProps, NotificationsType } from "../../shared/hooks/useNotification";

type ShoppingCartNotifications =
    | "AddReservation"
    | "RemoveReservation"
    | "ApplyCoupon"
    | "AddMiscellaneousCatalogItem"
    | "AddCatalogItem"
    | "RemoveCatalogItem"
    | "AddTip"
    | "AddAdjustment"
    | "RemoveAdjustment";

interface CartNotifications {
    success: NotificationProps;
    error: NotificationProps;
}
export const Notifications: Record<ShoppingCartNotifications, CartNotifications> = {
    AddReservation: {
        success: {
            message: "Reservation added to cart successfully",
            type: NotificationsType.success,
        },
        error: {
            message: "Error to add reservation cart",
            type: NotificationsType.error,
        },
    },
    RemoveReservation: {
        success: {
            message: "Reservation removed from the cart",
            type: NotificationsType.success,
        },
        error: {
            message: "Error removing reservation from the cart",
            type: NotificationsType.error,
        },
    },
    ApplyCoupon: {
        success: {
            message: "Gift card / coupon applied",
            type: NotificationsType.success,
        },
        error: {
            message: "Error adding gift card / coupon",
            type: NotificationsType.warning,
        },
    },
    AddMiscellaneousCatalogItem: {
        success: {
            message: "Miscellaneous item added to the cart",
            type: NotificationsType.success,
        },
        error: {
            message: "Miscellaneous item not added, try again later",
            type: NotificationsType.warning,
        },
    },
    AddCatalogItem: {
        success: {
            message: "Merchandise item added to the cart",
            type: NotificationsType.success,
        },
        error: {
            message: "Merchandise item not added, try again later",
            type: NotificationsType.warning,
        },
    },
    RemoveCatalogItem: {
        success: {
            message: "Merchandise item removed from the cart",
            type: NotificationsType.success,
        },
        error: {
            message: "Error removing merchandise item from the cart",
            type: NotificationsType.error,
        },
    },
    AddTip: {
        success: {
            message: "Tip added to the cart",
            type: NotificationsType.success,
        },
        error: {
            message: "Error adding tip to the cart",
            type: NotificationsType.error,
        },
    },
    AddAdjustment: {
        success: {
            message: "Adjustment applied",
            type: NotificationsType.success,
        },
        error: {
            message: "Error applying adjustment, try again later",
            type: NotificationsType.warning,
        },
    },
    RemoveAdjustment: {
        success: {
            message: "Adjustment removed",
            type: NotificationsType.success,
        },
        error: {
            message: "Error removing adjustment, try again later",
            type: NotificationsType.warning,
        },
    },
};

export const getShoppingCartNotifications = (
    notificationType: ShoppingCartNotifications,
): CartNotifications => {
    return Notifications[notificationType];
};
