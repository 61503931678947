import React from "react";
import { Button } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import { useReservationModalContext } from "../context/ReservationModalProvider";
import useGlobal from "shared/hooks/GlobalHook";
type Props = {};

const CopyPasteActions = (props: Props) => {
    const { reservation, updateReservationData } = useReservationModalContext();
    const { togglePasteContactInfo } = useGlobal();
    const handleCopyContactInformation = () => {
        if (reservation !== null) {
            const { firstName, lastName, email, phone, userId, comment } = reservation;
            const contact = { firstName, lastName, email, phone, userId };
            localStorage.setItem("contactInformation", JSON.stringify({ contact, comment }));
        }
    };

    const pasteContactInformation = () => {
        const contact = localStorage.getItem("contactInformation");
        if (contact && reservation) {
            const _contact = JSON.parse(contact);
            updateReservationData({
                firstName: _contact.contact.firstName,
                lastName: _contact.contact.lastName,
                email: _contact.contact.email,
                phone: _contact.contact.phone,
                comment: _contact.comment,
                userId: _contact.contact.userId,
            });
            togglePasteContactInfo();
        }
    };
    return (
        <div style={{ display: "flex", flexDirection: "row", gap: 2 }}>
            <Button
                size="small"
                startIcon={<ContentCopyIcon />}
                onClick={handleCopyContactInformation}
            >
                Copy
            </Button>
            <Button
                size="small"
                startIcon={<AssignmentReturnedIcon />}
                onClick={pasteContactInformation}
            >
                Paste
            </Button>
        </div>
    );
};

export default CopyPasteActions;
