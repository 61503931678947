// Module augmentation for roles emitter
declare global {
    interface WindowEventMap {
        roles: StorageEvent;
    }
}
export class RolesEventHandler {
    static emit(roles: string) {
        if (window) {
            window.dispatchEvent(new StorageEvent("roles", { key: "roles", newValue: roles }));
        }
    }
}
