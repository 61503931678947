import { OverrideComponent } from "../interfaces";

const TableCellOverrides: OverrideComponent<"MuiTableCell"> = (theme) => {
    return {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: "0.875rem",
                    padding: 12,
                    borderColor: theme.palette.divider,
                },
                head: {
                    fontWeight: 600,
                    paddingTop: 20,
                    paddingBottom: 20,
                },
            },
        },
    };
};
export default TableCellOverrides;
