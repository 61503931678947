export class Financial {
    public static CalculateTax(
        unitPrice: number,
        quantity: number,
        taxRate: number,
    ): number {
        return (unitPrice * quantity * taxRate) / 100;
    }
    public static CalculateWithTax(
        unitPrice: number,
        quantity: number,
        taxRate: number,
    ) {
        return unitPrice * quantity + (unitPrice * quantity * taxRate) / 100;
    }
    public static CalculateAmountByPercentaje(value: number, percent: number) {
        return value + (percent / 100) * value;
    }
}
