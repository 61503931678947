import { useState } from "react";
import { Button } from "@mui/material";
import SmsIcon from "@mui/icons-material/Sms";
import { MuiTelInput, MuiTelInputInfo } from "mui-tel-input";
import { Validation } from "shared/utils/Validation";
import parsePhoneNumberFromString, { CountryCode } from "libphonenumber-js";
interface StartConversationProps {
    setPhone: (phoneNumber: string) => void;
}
const StartConversation = (props: StartConversationProps) => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [isBlurred, setIsBlurred] = useState(false);
    const [phoneErrorMsg, setPhoneErrorMsg] = useState("");
    const [countryNum, setCountryNum] = useState<CountryCode>("US");

    const submitPhoneNumber = (): void => {
        const parsePhoneNumber = parsePhoneNumberFromString(phoneNumber, countryNum);
        if (parsePhoneNumber) {
            props.setPhone(parsePhoneNumber.number);
        }
    };

    const onChangePhone = (phone: string, info: MuiTelInputInfo): void => {
        setPhoneNumber(phone);
        if (info.countryCode) {
            setCountryNum(info.countryCode);
        }
        setPhoneErrorMsg(phone === "" ? "" : Validation.PhoneNumber(phone));
    };

    return (
        <div style={{ display: "flex", flexDirection: "row", marginTop: "1rem" }}>
            <MuiTelInput
                required
                value={phoneNumber}
                onChange={onChangePhone}
                variant="outlined"
                disableFormatting
                forceCallingCode
                defaultCountry={"US"}
                fullWidth
                error={isBlurred && phoneErrorMsg !== ""}
                InputProps={{
                    autoComplete: "off",
                }}
                size="small"
                onBlur={() => {
                    setIsBlurred(true);
                }}
                sx={{ minWidth: "20rem", marginRight: "1rem" }}
            />
            <Button
                variant="contained"
                size="small"
                onClick={submitPhoneNumber}
                startIcon={<SmsIcon />}
                disabled={phoneErrorMsg !== ""}
                fullWidth
            >
                Continue
            </Button>
        </div>
    );
};

export default StartConversation;
