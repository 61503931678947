import { Nullable } from "./Generals";

export enum StartPage {
    EventList = 0,
    Calendar = 1,
    ReservationReport = 2,
    ResourceCalendar = 3,
}

export enum EventGroupFilter {
    JustCompany = 0,
    Partner = -2,
    AllEvents = -3,
}

export type WeekStart = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export enum CalendarView {
    Day = 0,
    Week = 1,
    Month = 2,
}

export interface CompanyItem {
    companyId: number;
    companyName: string;
}

export interface UserPreferences {
    defaultCalendarView: CalendarView;
    showZeroTicketTimes: boolean;
    showInactiveEvents: boolean;
    user24HourTime: boolean;
    firstDayOfWeek: WeekStart;
    calendarFirstHour: number;
    calendarLastHour: number;
}

export interface CompanyPreferences {
    defaultPage: StartPage;
    defaultEventGroupId: EventGroupFilter | number;
    resourceCalendarDefaultTypes: Nullable<Array<number>>;
}

export interface UserCredentials {
    companyID: number;
    firstName: string;
    lastName: string;
    oid: Nullable<string>;
    systemAdmin?: boolean;
    companies: Array<CompanyItem>;
    preferences: Nullable<UserPreferences>;
    companyPreferences: Nullable<CompanyPreferences>;
}
