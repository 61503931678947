import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import useReservation from "shared/hooks/ReservationHook";
import { AddShoppingCart } from "@mui/icons-material";
import { Tooltip, useMediaQuery } from "@mui/material";
import { Nullable } from "../../../shared/models/Generals";
import { useIndexicTheme } from "theme/useIndexicTheme";

const AddToCartButton = () => {
    const theme = useIndexicTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { canSave, loading, haveChanges, reservation, addToCart } = useReservation();
    const [tooltip, setTooltip] = useState<Nullable<string>>(null);
    const handleAddToCart = async () => {
        if (tooltip !== null) {
            return;
        }
        if (!haveChanges || Number(reservation?.reservationId) < 0) {
            await addToCart(reservation!);
            setTooltip(null);
            return;
        } else {
            setTooltip("Save reservation first");
            setTimeout(() => {
                setTooltip(null);
            }, 1_000);
        }
    };
    return (
        <Tooltip title={tooltip} open={tooltip !== "null"}>
            <span>
                <LoadingButton
                    loading={loading}
                    size="small"
                    variant="contained"
                    onClick={handleAddToCart}
                    disabled={!canSave}
                >
                    <AddShoppingCart
                        sx={{ width: "1.2rem", marginRight: !isMobile ? "5px" : "" }}
                    />
                    {!isMobile && "Add to cart"}
                </LoadingButton>
            </span>
        </Tooltip>
    );
};

export default AddToCartButton;
