import React from "react";
import { clearNotification, setNotification } from "store/Notifications";
import { useAppDispatch } from "store/Store";

export enum NotificationsType {
    success = "success",
    error = "error",
    warning = "warning",
    info = "info",
    default = "default",
}

export interface NotificationProps {
    type: NotificationsType;
    message: string;
    duration?: number;
}

const useNotification = () => {
    const dispatch = useAppDispatch();

    const showNotification = (args: NotificationProps) => {
        dispatch(setNotification(args));
    };

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }
        dispatch(clearNotification());
    };

    return {
        showNotification,
        handleClose,
    };
};

export default useNotification;
