import { ShoppingCartOutlined } from "@mui/icons-material";
import React, { FunctionComponent } from "react";
import { useShoppingCartSlice } from "store/ShoppingCart/useShoppingCartSlice";
import OutlineBadgeButton from "./components/OutlineBadgeButton";
import { MathEx } from "shared/utils/MathEx";
import { useShoppingCartProvider } from "modules/ShoppingCart/ShoppingCartProvider";
import { useMediaQuery, Theme } from "@mui/material";

const ShoppingCartHeader: FunctionComponent = () => {
    const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
    const { cart } = useShoppingCartSlice();
    const { toggleShoppingCartModal } = useShoppingCartProvider();

    return (
        <OutlineBadgeButton
            icon={<ShoppingCartOutlined />}
            badgeContent={cart?.reservations.length}
            onClick={() => toggleShoppingCartModal(true)}
        >
            {!matchesXs ? MathEx.formatCurrency(cart?.subTotal ?? 0) : null}
        </OutlineBadgeButton>
    );
};

export default ShoppingCartHeader;
