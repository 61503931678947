import React, { useEffect, useState } from "react";
import { NotificationsType } from "shared/hooks/useNotification";
import { SignedWaiver, SearchSettings, StatusFilter, ApprovalStatus } from "shared/models/AWaiver";
import aWaiverService from "shared/services/AWaiverService";
import DialogComponent, { DialogHooks } from "shared/components/DialogComponent";
import { useReservationModalContext } from "modules/Reservation/context/ReservationModalProvider";
import { IconActionButton } from "shared/components/IconActionButton";
import AWaiverIcon from "shared/icons/AWaiverIcon";
import {
    Grid,
    TextField,
    InputAdornment,
    IconButton,
    Chip,
    Avatar,
    FormGroup,
    FormControlLabel,
    Box,
    Stack,
		Divider
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DoneIcon from "@mui/icons-material/Done";
import AWaiverList from "./AWaiverList";
import AntSwitch from "shared/components/AntSwitch";
import AWaiverDetails from "./AWaiverDetails";
import { useIndexicTheme } from "theme/useIndexicTheme";
import LoadingButtons from "shared/components/LoadingButton";

const searchSettingsInit = {
    AsSigner: true,
    AsParticipant: true,
    SearchExpired: false,
    SearchNotExpired: true,
    IncludeRejected: false,
};
interface Props {
    reservationId: number;
    waiverId: number;
    callBack?: CallableFunction; //* this could be any function just to handle any process outside here
}
interface AWaiverDialogProps extends Props {
    disabled: boolean;
}
const AWaiverSearch = (props: Props) => {
    const theme = useIndexicTheme();
    const [list, setList] = useState<SignedWaiver[] | []>([]);
    const [searchSettings, setSettings] = useState<SearchSettings>(searchSettingsInit);
    const [searchString, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [selectedWaiver, setSelectedWaiver] = useState(0);
    const [filterStatus, setStatus] = useState<StatusFilter | null>(StatusFilter.PENDING);
    const [openDetails, OpenDetails, CloseDetails] = DialogHooks();
    const { showNotification } = useReservationModalContext();
    const [disabled, setDisabled] = useState(true);
    const [waitingForUpload, setWaiting] = useState(false);

    useEffect(() => {
        handleFilter(StatusFilter.PENDING);
        return () => {
            setDisabled(true);
        };
        // eslint-disable-next-line
    }, []);

    const handleSettingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettings({
            ...searchSettings,
            ...validateSettings(event.target.name, event.target.checked),
        });
    };

    const handleFilterValue = (event: any) => {
        const value = event.target.value;
        setSearch(value);
    };

    const search = async () => {
        setStatus(null);
        setLoading(true);
        delete searchSettings.Status;
        const data = await aWaiverService.searchWaivers(
            searchString,
            searchSettings,
            props.waiverId,
        );
        const signedWaivers = data.map((item: any) => {
            return item.signedWaivers;
        });
        setLoading(false);
        if (signedWaivers.length > 0) {
            setList(signedWaivers[0]);
        } else {
            setList([]);
        }
    };

    const validateSettings = (name: string, value: boolean) => {
        switch (name) {
            case "AsSigner":
                return {
                    AsSigner: value,
                    AsParticipant: !value ? true : value,
                };
            case "AsParticipant":
                return {
                    AsParticipant: value,
                    AsSigner: !value ? true : value,
                };
            case "IncludeExpired":
                return {
                    SearchExpired: value,
                    
                };
            default:
                return { [name]: value };
        }
    };

    const handleSelectedWaiver = (signedWaiverId: number) => {
        //dev id 3811, 5578
        setSelectedWaiver(signedWaiverId);
        OpenDetails();
    };

    const updateWaiverStatus = async (status: ApprovalStatus) => {
        try {
            setWaiting(true);
            await aWaiverService.updateWaiverStatus(
                selectedWaiver,
                props.reservationId,
                status.toString(),
            );
            setWaiting(false);
            showNotification({
                message: "Waiver Updated",
                type: NotificationsType.success,
            });
            CloseDetails();
            if (props.callBack) {
                props.callBack();
            }
        } catch (error) {
            setWaiting(false);
            showNotification({
                message: "There was an error trying to change the approval status",
                type: NotificationsType.error,
            });
        }
    };

    const handleFilter = async (status: StatusFilter | null) => {
        let signedWaivers = [];
        setStatus(status);
        setSearch("");
        setLoading(true);
        try {
					  var filter = status ? status : StatusFilter.PENDING;
            const data = await aWaiverService.getPendingWaivers(filter, props.waiverId);
            signedWaivers = data.map((item: any) => {
                return item.signedWaivers;
            });
            setLoading(false);
            if (signedWaivers.length > 0) {
                setList(signedWaivers[0]);
            } else {
                setList([]);
            }
        } catch (error) {
            setList([]);
            setLoading(false);
        }
    };
    const getWaiverDetails = (waiver: SignedWaiver) => {
        setDisabled(!Boolean(waiver));
    };
    return (
        <>
            <Grid container spacing={1} direction="column">
						<Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Chip
                                label="Pending Approval"
                                variant="outlined"
                                color="primary"
                                size="small"
                                clickable
                                deleteIcon={
                                    filterStatus === StatusFilter.PENDING ? (
                                        <DoneIcon />
                                    ) : (
                                        <div> </div>
                                    )
                                }
                                onClick={() => handleFilter(StatusFilter.PENDING)}
                                onDelete={() => handleFilter(StatusFilter.PENDING)}
                                avatar={<Avatar>P</Avatar>}
                            />
                        </Grid>
                        <Grid item>
                            <Chip
                                label="Recent"
                                variant="outlined"
                                color="primary"
                                size="small"
                                clickable
                                deleteIcon={
                                    filterStatus === StatusFilter.RECENT ? (
                                        <DoneIcon />
                                    ) : (
                                        <div> </div>
                                    )
                                }
                                onClick={() => handleFilter(StatusFilter.RECENT)}
                                onDelete={() => handleFilter(StatusFilter.RECENT)}
                                avatar={<Avatar>R</Avatar>}
                            />
                        </Grid>
                    </Grid>
                </Grid>
								<Grid item xs={12}>Manual Search<Divider /></Grid>
                <Grid item xs={12}>
                    <TextField
                        style={{ flexBasis: "100%" }}
                        fullWidth
                        label="Search"
                        type="text"
                        onChange={handleFilterValue}
                        onKeyPress={(ev) => ev.key === "Enter" && search()}
                        value={searchString}
                        size="small"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start" onClick={() => search()}>
                                    <IconButton aria-label="delete">
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormGroup
                        row
                        style={{
                            marginLeft: 15,
                        }}
                    >
                        <FormControlLabel
                            control={
                                <AntSwitch
                                    onChange={handleSettingChange}
                                    checked={searchSettings.AsSigner}
                                    name="AsSigner"
                                />
                            }
                            label="As signer"
                        />
                        <FormControlLabel
                            control={
                                <AntSwitch
                                    onChange={handleSettingChange}
                                    checked={searchSettings.AsParticipant}
                                    name="AsParticipant"
                                />
                            }
                            label="As participant"
                        />
                        <FormControlLabel
                            control={
                                <AntSwitch
                                    onChange={handleSettingChange}
                                    checked={searchSettings.SearchExpired}
                                    name="IncludeExpired"
                                />
                            }
                            label="Include Expired"
                        />
                        <FormControlLabel
                            control={
                                <AntSwitch
                                    onChange={handleSettingChange}
                                    checked={searchSettings.IncludeRejected}
                                    name="IncludeRejected"
                                />
                            }
                            label="Include Rejected"
                        />
                    </FormGroup>
                </Grid>

                <Grid item xs={12}>
                    <AWaiverList
                        loading={loading}
                        list={list}
                        hanldeSelectedWaiver={handleSelectedWaiver}
                        selectedWaiver={selectedWaiver}
                    />
                </Grid>
            </Grid>
            <DialogComponent
                DialogText="Signed Waiver"
                open={openDetails}
                handleClose={CloseDetails}
                isFullSize={false}
                maxWidth="sm"
                customButtons={
                    <Stack spacing={2} direction="row">
                        <Box>
                            <LoadingButtons
                                handleClick={() => updateWaiverStatus(ApprovalStatus.Approved)}
                                disabled={disabled}
                                loading={waitingForUpload}
                                label="Approve and Attach"
                            />
                        </Box>
                        <Box>
                            <LoadingButtons
                                sx={{
                                    backgroundColor: theme.palette.error.lighter,
                                    color: theme.palette.error.darker,
                                    "&:hover": {
                                        backgroundColor: theme.palette.error.light,
                                    },
                                }}
                                disabled={disabled}
                                handleClick={() => updateWaiverStatus(ApprovalStatus.Rejected)}
                                label="Reject"
                                loading={waitingForUpload}
                            />
                        </Box>
                    </Stack>
                }
            >
                <AWaiverDetails signedWaiver_Id={selectedWaiver} waiverDetails={getWaiverDetails} />
            </DialogComponent>
        </>
    );
};
const AWaiverDialog = (props: AWaiverDialogProps) => {
    const [open, handleOpen, handleClose] = DialogHooks();
    return (
        <React.Fragment>
            <IconActionButton
                disabled={props.disabled}
                icon={<AWaiverIcon />}
                onClick={() => handleOpen()}
                label="AWaiver"
                size="small"
            />
            <DialogComponent DialogText="Search for a waiver" open={open} handleClose={handleClose}>
                <AWaiverSearch
                    callBack={props.callBack}
                    reservationId={props.reservationId}
                    waiverId={props.waiverId}
                />
            </DialogComponent>
        </React.Fragment>
    );
};
export { AWaiverDialog, AWaiverSearch };
