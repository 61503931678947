import React, { Component } from "react";

import { AddCircle, RemoveCircle } from "@mui/icons-material";
import {
    Box,
    TextField,
    IconButton,
    Button,
    FormControlLabel,
    Checkbox,
    Radio,
} from "@mui/material";

interface SelectionListProps {
    answers: string[];
    multiselect: boolean;
    onSelectionChange: Function;
    selected: string[];
    isEditable?: boolean;
}

class SelectionList extends Component<SelectionListProps> {
    state = {
        invalid: true,
        touched: false,
        selected: this.props.selected || [],
        answers: this.props.answers,
    };
    onAnsChange = (event: any) => {
        const ans = event.target.value;
        const { selected } = this.state;
        let newSelected = [];
        if (event.target.checked) {
            if (this.props.multiselect) {
                newSelected = [...selected, ans];
            } else {
                newSelected = [ans];
            }
        } else {
            newSelected = selected.filter((a: any) => a !== ans);
        }
        this.setState(
            {
                invalid: newSelected.length === 0,
                touched: true,
                selected: newSelected,
            },
            () => {
                if (this.props.onSelectionChange) {
                    this.props.onSelectionChange(this.state);
                }
            },
        );
    };
    isChecked = (index: number) => {
        return (
            this.state.selected.find((a) => a === this.state.answers[index]) ===
            this.state.answers[index]
        );
    };
    addAnswer = () => {
        const answers = [...this.state.answers, ""];
        this.setState({ answers });
    };
    removeAnswer = (index: number) => {
        const prevAnswers = this.state.answers ?? [];
        const answers = prevAnswers.slice(0, index).concat(prevAnswers.slice(index + 1));
        this.updateAnswer(answers);
    };
    handleAnswerChanged = (index: number) => (event: any) => {
        const value = event.target.value;
        const prevAnswers = this.state.answers ?? [];
        const answers = prevAnswers.map((ans, idx) => (idx === index ? value : ans));
        this.updateAnswer(answers);
    };
    updateAnswer = (answers: string[]) => {
        this.setState(
            {
                invalid: answers.length === 0,
                touched: true,
                selected: answers,
                answers,
            },
            () => {
                if (this.props.onSelectionChange) {
                    this.props.onSelectionChange(this.state);
                }
            },
        );
    };
    render() {
        const { multiselect = false, isEditable = false } = this.props;
        const answers = this.state.answers ?? [];
        if (isEditable && multiselect) {
            return (
                <>
                    {answers.map((ans, index) => (
                        <Box key={index}>
                            <TextField
                                key={index}
                                value={ans}
                                onChange={this.handleAnswerChanged(index)}
                                size="small"
                            />
                            <IconButton
                                style={{ color: "red" }}
                                component="span"
                                onClick={() => this.removeAnswer(index)}
                            >
                                <RemoveCircle />
                            </IconButton>
                        </Box>
                    ))}
                    <div>
                        <Button color={"primary"} variant={"outlined"} onClick={this.addAnswer}>
                            <AddCircle style={{ marginRight: "10px" }} />
                            Add new answer
                        </Button>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    {answers.map((ans, index) => (
                        <FormControlLabel
                            key={index}
                            value={ans}
                            checked={multiselect ? this.isChecked(index) : undefined}
                            control={multiselect ? <Checkbox /> : <Radio />}
                            label={ans}
                            onChange={this.onAnsChange}
                        />
                    ))}
                </>
            );
        }
    }
}

export default SelectionList;
