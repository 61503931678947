import { Collapse, MenuItem, Select, Typography } from "@mui/material";
import { FunctionComponent, useState } from "react";
import { TransactionReservationDetail } from "shared/models/Reservation";
import { MathEx } from "shared/utils/MathEx";
import "../../modules/Reservation/components/TransactionTable/TransactionTable.css";
interface InnerTransactionRowProps {
    dispute: boolean;
    transactionTickets: TransactionReservationDetail[];
    open: boolean;
    handleTicketSelection: (quantity: number, ticket: TransactionReservationDetail) => void;
    hasRequirtedTickets: boolean;
    getStacked?: boolean;
}

type Props = InnerTransactionRowProps;

export const InnerTransactionRow: FunctionComponent<Props> = (props) => {
    const { transactionTickets, open } = props;

    return (
        <Collapse in={open} timeout="auto" unmountOnExit>
            <table className="transaction-table" style={{ minWidth: "90%" }}>
                <tbody>
                    <tr>
                        {!props?.getStacked ? (
                            <>
                                <td></td>
                                <td></td>
                            </>
                        ) : null}
                        <td colSpan={6}>
                            <Typography style={{ fontSize: 15, fontWeight: 500 }}>
                                TICKETS
                            </Typography>
                        </td>
                    </tr>
                    <tr style={{ lineHeight: "1.1rem" }}>
                        {!props?.getStacked ? (
                            <>
                                <th></th>
                                <th></th>
                            </>
                        ) : null}
                        <th style={{ textAlign: "left" }}>Description</th>
                        <th style={{ textAlign: "right" }}>Total</th>
                        <th style={{ textAlign: "right" }}>Rate</th>
                        <th>Tickets To Refund</th>
                    </tr>

                    {transactionTickets.map((ticket: TransactionReservationDetail) => (
                        <tr key={ticket.rateId}>
                            {!props?.getStacked ? (
                                <>
                                    <td></td>
                                    <td></td>
                                </>
                            ) : null}
                            <td style={{ textAlign: "left" }}>{ticket.description}</td>
                            <td style={{ textAlign: "right" }}>{ticket.tickets}</td>
                            <td style={{ textAlign: "right" }}>
                                {MathEx.formatCurrency(ticket.rate)}
                            </td>
                            <td style={{ textAlign: "center" }}>
                                <TicketsSelector
                                    dispute={props.dispute || props.hasRequirtedTickets}
                                    ticket={ticket}
                                    handleTicketSelection={props.handleTicketSelection}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Collapse>
    );
};

interface TicketsSelectorProps {
    dispute: boolean;
    ticket: TransactionReservationDetail;
    handleTicketSelection: (quantity: number, ticket: TransactionReservationDetail) => void;
}
const TicketsSelector = (props: TicketsSelectorProps) => {
    const [ticketsSelected, setTicketsSelected] = useState(0);
    const options = [...Array(props.ticket.tickets + 1).keys()];
    const handleSelection = (event: any) => {
        const ticketSelection = Number(event.target.value);
        setTicketsSelected(ticketSelection);
        props.handleTicketSelection(ticketSelection, props.ticket);
    };
    return (
        <Select
            sx={{
                ".MuiSvgIcon-root": {
                    color: !props.dispute ? "var(--dark-aqua-main)" : "",
                },
                height: "1.65rem",
                color: "var(--grey-600)",
                fontSize: "0.75rem",
                fontWeight: 400,
                lineHeight: "150%",
            }}
            disabled={props.dispute}
            value={ticketsSelected}
            onChange={handleSelection}
            style={{ marginRight: "10px", minWidth: "50px", marginLeft: "1rem" }}
        >
            {options.map((el) => (
                <MenuItem key={el} value={el}>
                    {el}
                </MenuItem>
            ))}
        </Select>
    );
};
