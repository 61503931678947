import React, { FunctionComponent } from "react";
import { Chip } from "@mui/material";
import { MathEx } from "../utils/MathEx";

interface Props {
    subtotal: number;
    tax?: number;
    labelSize?: "condensed" | "small" | "medium" | "large";
}

const DueAmountChip: FunctionComponent<Props> = (props) => {
    const subtotal = props.subtotal;
    const tax = props.tax ?? 0;
    const variant = props.subtotal === 0 ? "filled" : props.subtotal > 0 ? "due" : "overpayment";
    const subTotalStr = MathEx.formatCurrency(subtotal);
    const taxStr = MathEx.formatCurrency(Math.abs(tax));
    const labelSize = props.labelSize ?? "medium";
    const separator = tax < 0 ? '-' : '+';
    let label: string;
    switch (labelSize) {
        case "small":
            label = `${subTotalStr}${separator}${taxStr}`;
            break;
        case "medium":
            label = `${subTotalStr} ${separator} ${taxStr}T`;
            break;
        case "large":
            label = `${subTotalStr} ${separator} ${taxStr} tax`;
            break;
        case "condensed":
            label = subTotalStr;
            break;
        default:
            label = `${subTotalStr} ${separator} ${taxStr}T`;
    }
    // let variant;

    return <Chip size="small" variant={variant} label={label} />;
};

export default DueAmountChip;
