import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { BASE_SIGNALR } from "environment/environment";
import { HttpService } from "shared/http/http";
import {
    IJoinConversationRequest,
    ISetCompanyGroupRequest,
    SignalRConnectionInfo,
} from "shared/models/SignalR";

class SignalRService extends HttpService {
    connection!: HubConnection;
    constructor() {
        super("/");
    }

    async negotiate<SignalRConnectionInfo>(userId: string): Promise<SignalRConnectionInfo | null> {
        const result = await this.post("", null, {
            baseURL: `${BASE_SIGNALR}/ares_negotiate?negotiateVersion=1`,
            headers: {
                "x-indexic-u": userId,
            },
        });

        if (result.status === 200) {
            return result.data as SignalRConnectionInfo;
        }
        return null;
    }
    async joinCompanyGroup(userId: string, companyId: number) {
        const request: ISetCompanyGroupRequest = {
            userOid: userId,
            companyId: companyId,
        };
        return await this.post("", request, {
            baseURL: `${BASE_SIGNALR}/JoinCompanyGroup`,
            headers: {
                "x-indexic-u": userId,
            },
        });
    }

    async joinConversation(userId: string, conversationId: string) {
        const request: IJoinConversationRequest = {
            userOid: userId,
            conversationId: conversationId,
        };
        return await this.post("", request, {
            baseURL: `${BASE_SIGNALR}/JoinConversation`,
            headers: {
                "x-indexic-u": userId,
            },
        });
    }

    async initializeConnection(userOid: string): Promise<HubConnection | null> {
        try {
            const connectionInfo: SignalRConnectionInfo | null = await this.negotiate(userOid);
            if (connectionInfo) {
                this.connection = new HubConnectionBuilder()
                    .withUrl(connectionInfo.url, {
                        accessTokenFactory: () => connectionInfo.accessToken,
                    })
                    .withAutomaticReconnect()
                    .build();
            }
            return this.connection;
        } catch (error) {
            console.log("SignalR connection ", error);
            return null;
        }
    }
}

const signalRService = new SignalRService();
export default signalRService;
