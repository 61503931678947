import { z } from "zod";
import { Coupon } from "./Coupon";
import { InterfaceToZod } from "./UtilsTypes";

export const CouponSchema = z.object<InterfaceToZod<Coupon>>({
    couponCode: z.string(),
    companyId: z.number(),
    couponId: z.number(),
    startDate: z.string(),
    endDate: z.string(),
    comment: z.string().nullable(),
    name: z.string(),
    percentage: z.boolean(),
});

export const CouponsSchema = z.array(CouponSchema);
