import { useEffect, useRef, useState } from "react";

interface SearchableItem<T> {
    data: T;
    searchKey: string;
}

type CreateSearchFunc<T> = (element: T) => string;
/**
 * useSearchableItem reuses logic to make a list searchable, keeps in memory the original data and exposes the filtered data
 * @param createSearch
 */
export const useSearchableItem = <T>(createSearch: (element: T) => string) => {
    const savedCreateSearch = useRef<CreateSearchFunc<T> | null>(null);

    const [items, setItems] = useState<SearchableItem<T>[]>([]);
    const [filteredItems, setFilteredItems] = useState<T[]>([]);

    // Remember the latest callback.
    useEffect(() => {
        savedCreateSearch.current = createSearch;
    }, [createSearch]);
    const setData = (arr: T[]) => {
        if (savedCreateSearch.current !== null) {
            const searchable: SearchableItem<T>[] = arr.map((el) => ({
                searchKey: (savedCreateSearch.current as CreateSearchFunc<T>)(el),
                data: el,
            }));
            setFilteredItems(arr);
            setItems(searchable);
        }
    };

    const searchItems = (value: string) => {
        const val = value.trim().toLowerCase();
        if (val && val !== "") {
            const filtered = items.filter((el) => el.searchKey.includes(val)).map((el) => el.data);
            setFilteredItems(filtered);
        } else {
            const filtered = items.map((el) => el.data);
            setFilteredItems(filtered);
        }
    };
    return {
        setData,
        searchItems,
        filteredItems,
    };
};
