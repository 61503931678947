import React, { FunctionComponent, useState } from "react";
import styles from "./Merchandise.module.css";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
    Checkbox,
    CircularProgress,
    FormControlLabel,
    IconButton,
    InputAdornment,
    TextField,
    TextFieldProps,
    Typography,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { MiscellaneousItem } from "shared/models/Catalog";
import { useShoppingCartSlice } from "../../../store/ShoppingCart/useShoppingCartSlice";
import { FlatButton } from "../../../shared/components/FlatButton";
import { getShoppingCartNotifications } from "../ShoppingCartNotifications";
import useNotification from "../../../shared/hooks/useNotification";

interface MiscellaneousCatalogItemProps {
    selectedReservationId: number;
}

export const MiscellaneousCatalogItemEntry: FunctionComponent<MiscellaneousCatalogItemProps> = (
    props,
) => {
    const { showNotification } = useNotification();
    const { addMiscellaneousItem } = useShoppingCartSlice();
    const [loading, setLoading] = useState(false);
    const [description, setDescription] = useState("");
    const [unitPrice, setUnitPrice] = useState("");
    const [taxable, setTaxable] = useState(false);
    const [quantity, setQuantity] = useState(0);
    const [errors, setErrors] = useState<{ description: string; unitPrice: string }>({
        description: "",
        unitPrice: "",
    });
    const onDescriptionChange: TextFieldProps["onChange"] = (e) => {
        setDescription(e.target.value);
        setErrors((s) => ({ ...s, description: "" }));
    };
    const onUnitPriceChange: TextFieldProps["onChange"] = (e) => {
        setUnitPrice(e.target.value);
        setErrors((s) => ({ ...s, unitPrice: "" }));
    };
    const checkErrors = (): boolean => {
        let hasDescriptionError = false;
        let hasAmountError = false;
        if (description === "") {
            hasDescriptionError = true;
        }
        const price = Number(unitPrice);
        if (isNaN(price) || price === 0) {
            hasAmountError = true;
        }
        setErrors({
            unitPrice: hasAmountError ? "Incorrect price" : "",
            description: hasDescriptionError ? "Description required" : "",
        });
        return hasDescriptionError || hasAmountError;
    };
    const handleAddToCart = async () => {
        const notifications = getShoppingCartNotifications("AddMiscellaneousCatalogItem");
        const hasErrors = checkErrors();
        if (hasErrors || loading) {
            return;
        }
        setLoading(true);
        const item: MiscellaneousItem = {
            description,
            taxable,
            unitPrice: Number(unitPrice),
            quantity,
        };
        if (props.selectedReservationId !== 0) {
            item.reservationIdAddOn = props.selectedReservationId;
        }
        try {
            await addMiscellaneousItem(item);
            showNotification(notifications.success);
            setQuantity(0);
            setDescription("");
            setUnitPrice("");
        } catch (e) {
            showNotification(notifications.error);
        } finally {
            setLoading(false);
        }
    };
    return (
        <Grid container spacing={1} alignItems={"flex-end"}>
            <Grid xs={12}>
                <Typography variant={"inputLabel"}>Add custom Merchandise item</Typography>
            </Grid>
            <Grid xs={12}>
                <Typography variant={"amountSummary"}>Description:</Typography>
                <TextField
                    fullWidth
                    size={"small"}
                    sx={{
                        backgroundColor: "white",
                    }}
                    placeholder={"Write a custom description"}
                    value={description}
                    onChange={onDescriptionChange}
                    error={errors.description !== ""}
                    helperText={errors.description}
                />
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={6}>
                <Typography variant={"amountSummary"}>Price:</Typography>
                <TextField
                    type={"number"}
                    fullWidth
                    size={"small"}
                    sx={{
                        backgroundColor: "white",
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    value={unitPrice}
                    onChange={onUnitPriceChange}
                    error={errors.unitPrice !== ""}
                    helperText={errors.unitPrice}
                />
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={6}>
                <FormControlLabel
                    control={<Checkbox checked={taxable} color="primary" />}
                    label="Taxable item"
                    onChange={() => setTaxable((t) => !t)}
                />
            </Grid>
            <Grid xs={12}>
                <div className={styles.flexWrapper}>
                    <div className={styles.catalogEntryInputWrapper}>
                        <IconButton
                            color={"primary"}
                            disabled={quantity <= 0}
                            onClick={() => setQuantity((t) => (t - 1 < 0 ? 0 : t - 1))}
                        >
                            <RemoveIcon />
                        </IconButton>
                        <div className={styles.catalogEntryInputCount} onClick={handleAddToCart}>
                            {quantity}
                        </div>
                        <IconButton color={"primary"} onClick={() => setQuantity((t) => t + 1)}>
                            <AddIcon />
                        </IconButton>
                    </div>
                    <FlatButton
                        onClick={handleAddToCart}
                        disabled={props.selectedReservationId === 0 || quantity === 0 || loading}
                    >
                        {loading ? <CircularProgress size={"1rem"} /> : null}
                        Add to cart
                    </FlatButton>
                </div>
            </Grid>
        </Grid>
    );
};
