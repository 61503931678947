import React from "react";
import { SvgIcon } from "@mui/material";
import { ReactComponent as AwaiverLogo } from "../../assets/icons/awaiver-icon-square.svg";

const AWaiverIcon = () => {
    return (
        <SvgIcon>
            <AwaiverLogo />
        </SvgIcon>
    );
};

export default AWaiverIcon;
