import { z } from "zod";
import { CheckedInBy, IReservationHistory } from "shared/components/WhoWhenComponent";
import { InterfaceToZod } from "./UtilsTypes";

const HistoryChangesSchema = z.object<InterfaceToZod<IReservationHistory>>({
    ud: z.string(),
    delta: z.string().nullable(),
    action: z.string(),
    changedBy: z.string(),
});

export const HistorySchema = z.object<InterfaceToZod<CheckedInBy>>({
    checkedIn: z.string().nullable(),
    checkedInBy: z.string(),
    isCheckedIn: z.boolean(),
    changes: z.array(HistoryChangesSchema),
});
