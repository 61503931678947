import * as React from "react";
import { SxProps, Theme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { MathEx } from "shared/utils/MathEx";

interface CurrencyProps {
    amount: number;
    sx?: SxProps<Theme>;
}

const Currency = (props: CurrencyProps) => {
    return (
        <Typography
            component="span"
            sx={props.sx}
            color={props.amount < 0 ? "error" : "textPrimary"}
        >
            {MathEx.formatCurrency(props.amount)}
        </Typography>
    );
};

export default Currency;
