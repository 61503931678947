import React, { FunctionComponent, useEffect } from "react";
import { FormHelperText, MenuItem, TextField, Typography } from "@mui/material";
import { useShoppingCartSlice } from "../../../store/ShoppingCart/useShoppingCartSlice";
import { CartReservation } from "../../../shared/models/Cart";
import { useIndexicTheme } from "theme/useIndexicTheme";

interface SelectReservationControlProps {
    selectedReservation: number;
    onSelectReservation: (reservationId: number) => void;
}

export const SelectReservationControl: FunctionComponent<SelectReservationControlProps> = (
    props,
) => {
    const theme = useIndexicTheme();
    const { shoppingCartState } = useShoppingCartSlice();
    const reservations: CartReservation[] = shoppingCartState.cart?.reservations || [];

    useEffect(() => {
        if (reservations.length === 0) {
            props.onSelectReservation(-1);
        }
        //eslint-disable-next-line
    }, [reservations]);
    return reservations.length > 0 ? (
        <>
            <Typography variant={"inputLabel"} gutterBottom>
                Is this with a reservation? (Select one)
            </Typography>
            <TextField
                select
                fullWidth
                size={"small"}
                value={props.selectedReservation}
                sx={{
                    background: "white",
                }}
                onChange={(e) => props.onSelectReservation(Number(e.target.value))}
                defaultValue={0}
                error={props.selectedReservation === 0}
            >
                <MenuItem value={-1}>Not with a reservation</MenuItem>
                {reservations.map((el, idx) => (
                    <MenuItem key={idx} value={el.reservationId}>
                        <Typography>{cleanUpDescription(el.description)}</Typography>
                    </MenuItem>
                ))}
            </TextField>
            {props.selectedReservation === 0 ? (
                <FormHelperText sx={{ color: theme.palette.error["main"] }}>
                    Please select an option
                </FormHelperText>
            ) : null}
        </>
    ) : null;
};

const cleanUpDescription = (description: string): string => {
    const lines = description.split("\n");
    return lines[0] + " (" + lines[1] + ")";
};
