import { z } from "zod";
import { InterfaceToZod } from "./UtilsTypes";
import { CatalogDetail } from "./Catalog";
import { GiftCardRecipientSchema } from "./Cart.schema";

export const CatalogDetailSchema = z.object<InterfaceToZod<CatalogDetail>>({
    catalogId: z.number(),
    companyId: z.number(),
    itemCode: z.string().nullable(),
    description: z.string(),
    longDescription: z.string().nullable(),
    requiresShipping: z.boolean(),
    unitPrice: z.number(),
    available: z.number(),
    giftCard: z.boolean(),
    imgUrls: z.array(z.string()).optional(),
    internal: z.boolean(),
    inSchedule: z.boolean().nullable(),
    requiredQuantity: z.number(),
    giftCards: z.array(GiftCardRecipientSchema).optional(),
});
export const CatalogDetailListSchema = z.array(CatalogDetailSchema);
