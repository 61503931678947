export interface SearchWaivers {
    waiver_id: number;
    currentWaiverRevision: number;
    name: string;
    signedWaivers: Array<SignedWaiver>;
}
export interface Participants {
    id: number;
    signedWaiverId: number;
    participantName: string;
    birthday: string | null;
    referenceKey: string | null;
    signedWaiver: string | null;
    pdfFileName: string;
    activityDate: string[];
    groupParticipant: string[];
}
export interface SignedWaiver {
    signedWaiver_Id: number;
    signedByName: string;
    participants: Participants[];
    signed: string;
    status: number;
    attachmentFiles: number;
    currentWaiverRevision: number;
    name: string;
    notes: string[];
    currentNotes: string[];
    otherFiles?: null;
    waiver_Id: number;
    contactEmail: string | null;
    contactPhone: string | null;
    emergencyPhone: string | null;
    optIn: boolean;
    expiration: string;
    referenceKey: string | null;
    waiverRevision: number;
}

export interface AWaiverFiles {
    signatureUrl: string;
    pictureUrl: string;
    videoUrl: string;
}

export interface SearchSettings {
    AsSigner: boolean;
    AsParticipant: boolean;
    SearchExpired: boolean;
    SearchNotExpired: boolean;
    IncludeRejected: boolean;
    Status?: ApprovalStatus | null;
    RecentDuration?: number; //this value must be in hours
}

export interface ResponseWaiver {
    attachmentUrls: AWaiverFiles;
    signedWaiver: SignedWaiver;
}

export interface IApprovalStatus {
    Rejected: number;
    Pending: number;
    Approved: number;
}

export enum ApprovalStatus {
    Rejected = -1,
    Pending = 0,
    Approved = 1,
}

export enum StatusFilter {
    PENDING = "PENDING",
    RECENT = "RECENT",
}
