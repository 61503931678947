import React from "react";
import { Alert, AlertProps } from "@mui/material";

type CustomAlertProps = Omit<AlertProps, "variant" | "severity">;
export const WarningAlert = (props: CustomAlertProps) => {
    const customStyle = props.sx;
    const styles: AlertProps["sx"] = {
        backgroundColor: (theme) => theme.palette.warning.lighter,
        color: (theme) => theme.palette.grey["900"],
        fontWeight: "500",
    };
    return (
        <Alert severity={"warning"} variant={"filled"} sx={{ ...styles, ...customStyle }}>
            {props.children}
        </Alert>
    );
};

export const ErrorAlert = (props: CustomAlertProps) => {
    const customStyle = props.sx;
    const styles: AlertProps["sx"] = {
        backgroundColor: (theme) => theme.palette.error.lighter,
        color: (theme) => theme.palette.grey["900"],
        fontWeight: "500",
    };
    return (
        <Alert severity={"error"} variant={"filled"} sx={{ ...styles, ...customStyle }}>
            {props.children}
        </Alert>
    );
};
