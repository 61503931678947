import { IconButton, Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import React, { Component, ReactNode } from "react";

import "./SimpleCalendar.css";
// Calendar is driven by props. The parent is responsible for setting the
// current month, selected date, start date, and date array in the props.
// Callbacks basically request calendar navigation and parent gives approval
// by changing the props accordingly.
interface ISimpleCalendarProps {
    firstDayOfWeek: number;
    locales: string;
    min: Date;
    max: Date;
    startDate: Date;
    selectedDate?: Date;
    btnBack?: ReactNode;
    btnForward?: ReactNode;
    selectableDates?: Date[];
    onNavigate?: (newDate: Date) => void;
    onSelectDate?: (selectedDate: Date) => void;
}

class SimpleCalendar extends Component<ISimpleCalendarProps> {
    static defaultProps: Partial<ISimpleCalendarProps> = {
        firstDayOfWeek: 0,
        locales: "en-US",
        btnBack: "<",
        btnForward: ">",
        min: new Date(0, 0, 1),
        max: new Date(9999, 11, 31),
        startDate: new Date(),
    };

    startOfWeek = (startDate: Date): number => {
        const someDate = new Date(startDate.setHours(0, 0, 0, 0));
        const offset = (7 - (this.props.firstDayOfWeek - someDate.getDay())) % 7;
        return someDate.setDate(someDate.getDate() - offset);
    };
    endOfWeek = (someDate: Date): number => {
        const offset = (6 - (someDate.getDay() - this.props.firstDayOfWeek)) % 7;
        return someDate.setDate(someDate.getDate() + offset);
    };
    onBackClick = () => {
        if (this.canGoBack()) {
            this.navigate(true);
        }
    };
    onForwardClick = () => {
        if (this.canGoForward()) {
            this.navigate();
        }
    };

    navigate = (back = false) => {
        const monthToView = this.props.startDate; // View is driven by props
        const newMonth = new Date(
            monthToView.getFullYear(),
            monthToView.getMonth() + (back ? -1 : 1),
            1,
        );
        if (this.props.onNavigate) {
            this.props.onNavigate(newMonth);
        }
    };
    canGoBack = () => {
        return this.props.startDate > this.props.min;
    };
    canGoForward = () => {
        return this.props.startDate < this.props.max;
    };
    renderNav = () => {
        const monthToView = this.props.startDate;
        const monthName = monthToView.toLocaleString(this.props.locales, {
            month: "long",
        });
        return (
            <Box className="calNav">
                <Typography className="calendarMonth">
                    {monthName} {monthToView.getFullYear()}
                </Typography>
                <IconButton onClick={this.onBackClick} disabled={!this.canGoBack()}>
                    {this.props.btnBack}
                </IconButton>
                <IconButton onClick={this.onForwardClick} disabled={!this.canGoForward()}>
                    {this.props.btnForward}
                </IconButton>
            </Box>
        );
    };

    renderDayNames = () => {
        const days = [];
        for (let day = 0; day < 7; day++) {
            days.push(
                new Date(1970, 0, day + 4 + this.props.firstDayOfWeek).toLocaleString(
                    this.props.locales,
                    {
                        weekday: "short" /* or 'long' */,
                    },
                ),
            );
        }
        return days.map((dayName, i) => {
            return (
                <Box className="calDayCell" key={i}>
                    <span>{dayName}</span>
                </Box>
            );
        });
    };

    onDayClick = (clickedDay: Date) => {
        if (
            !(this.props.selectedDate && clickedDay.getTime() === this.props.selectedDate.getTime())
        ) {
            if (this.props.onSelectDate) {
                this.props.onSelectDate(clickedDay);
            }
        }
    };

    renderDays = (selectableDates?: Date[]) => {
        const monthToView = this.props.startDate;
        const now = new Date().setHours(0, 0, 0, 0);
        const month = monthToView.getMonth();
        const year = monthToView.getFullYear();
        const nextMonth = new Date(this.endOfWeek(new Date(year, month + 1, 0)));
        const start = this.startOfWeek(
            new Date(monthToView.getFullYear(), monthToView.getMonth(), 1),
        );
        const calDays = [];
        for (
            let curDay = new Date(start);
            curDay <= nextMonth;
            curDay.setDate(curDay.getDate() + 1)
        ) {
            calDays.push(new Date(curDay));
        }
        return calDays.map((day, index) => {
            let content = <div></div>;
            const dayTime = day.getTime();
            const isCurrentSelectedDate =
                this.props.selectedDate &&
                this.props.selectedDate.getUTCDate() === day.getUTCDate() &&
                this.props.selectedDate.getUTCMonth() === day.getUTCMonth() &&
                this.props.selectedDate.getUTCFullYear() === day.getUTCFullYear();
            let dowClass = `calDayCell ${dayTime < now ? "past" : ""}`;
            if (day.getMonth() === this.props.startDate.getMonth()) {
                dowClass += ` ${dayTime === now ? "today" : ""}`;
                content = <span>{day.getDate().toString()}</span>;
                if (selectableDates) {
                    let sd = selectableDates.find(
                        (d) =>
                            d.getUTCDate() === day.getUTCDate() &&
                            d.getUTCMonth() === day.getUTCMonth() &&
                            d.getUTCFullYear() === day.getUTCFullYear(),
                    );

                    if (sd) {
                        content = (
                            <div key={index} data-cy-day={day.getDate().toString()}>
                                <Button
                                    variant={isCurrentSelectedDate ? "outlined" : "contained"}
                                    className={`calDayButton ${
                                        isCurrentSelectedDate ? "selectedDay" : ""
                                    }`}
                                    color="primary"
                                    onClick={() => this.onDayClick(day)}
                                >
                                    {day.getDate()}
                                    <span
                                        className={`${dayTime === now ? "dotCurrentDay" : ""} `}
                                    ></span>
                                </Button>
                            </div>
                        );
                    }
                }
            }
            return (
                <Box className={dowClass} key={day.getTime()}>
                    {" "}
                    {content}{" "}
                </Box>
            );
        });
    };
    render() {
        return (
            <div data-cy="SimpleCalendar">
                <Box className="simple-calendar">
                    {this.renderNav()}
                    {this.renderDayNames()}
                    {this.renderDays(this.props.selectableDates)}
                </Box>
            </div>
        );
    }
}

export default SimpleCalendar;
