import React, { FunctionComponent } from "react";
import parsePhoneNumberFromString from "libphonenumber-js";
import { SxProps, Typography } from "@mui/material";
import { Nullable } from "shared/models/Generals";
import { BillingInfo } from "shared/models/Cart";

interface BillingInfoViewerProps {
    billingInfo?: Nullable<BillingInfo>;
}

type Props = BillingInfoViewerProps;

const BillingInfoViewer: FunctionComponent<Props> = (props) => {
    const styleContent: SxProps = {
        color: "var(--grey-900)",
        fontSize: "0.875rem",
        fontWeight: 500,
    };

    if (!props.billingInfo) return null;
    return (
        <div>
            <Typography sx={{ color: "var(--grey-600)", fontSize: "1rem", fontWeight: 500 }}>
                Bill to:
            </Typography>
            <Typography sx={styleContent}>
                {props.billingInfo.firstName ?? ""} {props.billingInfo.lastName ?? ""}
            </Typography>
            <Typography sx={styleContent}>
                {props.billingInfo.address} {props.billingInfo.address2}
            </Typography>
            <Typography sx={styleContent}>
                {props.billingInfo.city} {props.billingInfo.state ?? ""} {props.billingInfo.zip}
            </Typography>
            <Typography sx={styleContent}>{props.billingInfo.country}</Typography>
            <Typography sx={styleContent}>
                {parsePhoneNumberFromString(props.billingInfo.phone ?? "")?.formatInternational()}
            </Typography>
        </div>
    );
};

export default BillingInfoViewer;
