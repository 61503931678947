import { endOfDay } from "date-fns";
import { RateSelectedForLinkStyle } from "modules/EventList/LinkStyleReservation";
import { Rate, RateTicketPerson } from "shared/models/Rates";
import {
    TicketDetail,
    TicketPerson,
    TransactionReservationDetail,
} from "shared/models/Reservation";
import { DateTimeUtils } from "shared/utils/DateTimeUtils";
import { MathEx } from "shared/utils/MathEx";

export const hasRequiredFieldsAndQuestions = (
    requiredFields: number | null,
    questions: number | null,
) => {
    let disabled = false;
    if (requiredFields !== null && requiredFields > 0) {
        disabled = true;
    }
    if (questions !== null && questions > 0) {
        disabled = true;
    }
    return disabled;
};

export const createTicketPersonsArray = (tickets: TicketDetail[]): RateTicketPerson[] => {
    const newTickets: RateTicketPerson[] = [];
    let orderId = 1;
    let prevRateId = -1;
    let onlyTicketPersons: RateTicketPerson[] = [];
    const onlyValidTickets = tickets.filter((x) => x.tickets > 0);

    onlyValidTickets.forEach((ticket) => {
        onlyTicketPersons = onlyTicketPersons.concat(
            ticket.ticketPersons.map((item) => {
                return {
                    rateId: ticket.rateId,
                    parsedAnswers: item.answersJson !== null ? JSON.parse(item.answersJson) : [],
                    questionGroupId: ticket.questionGroupId ?? null,
                    ticket: item,
                };
            }),
        );
    });
    let sortedTickets = onlyTicketPersons.sort((a, b) => {
        if (a.rateId < b.rateId) {
            return -1;
        }
        if (a.rateId > b.rateId) {
            return 1;
        }
        return 0;
    });

    sortedTickets.forEach((rateTicketPerson) => {
        if (prevRateId !== rateTicketPerson.rateId) {
            // We start again the count when we change rateId
            prevRateId = Number(rateTicketPerson.rateId);
            orderId = 1;
        } else {
            orderId++;
        }
        const rate = tickets.find((x) => x.rateId === Number(rateTicketPerson.rateId));
        const description = rate?.description || "";
        const title = `${MathEx.ordinal(orderId)} ${description}`;
        newTickets.push({
            title,
            rateId: rate?.rateId!,
            questionGroupId: rateTicketPerson.questionGroupId,
            parsedAnswers: rateTicketPerson.parsedAnswers,
            ticket: rateTicketPerson.ticket,
        });
    });
    return newTickets;
};

export const createTicketPersonArray = (count: number, startId: number = 0): TicketPerson[] => {
    const tickets: TicketPerson[] = [];
    let id = startId;
    for (let i = 0; i < count; i++) {
        const ticketPerson: Partial<TicketPerson> = {
            ticketPersonId: id - 1,
        };
        id = id - 1;
        tickets.push(ticketPerson as TicketPerson);
    }
    return tickets;
};
export const buildRateTicketPerson =
    (rateId: number | undefined, questionId?: number, description?: string) =>
    (ticket: TicketPerson, idx: number): TicketPerson => {
        if (ticket.height) {
            const normalizeHeight = ticket.height > 3 ? ticket.height / 100 : ticket.height;
            ticket.height = normalizeHeight;
        }

        return {
            ticketPersonId: ticket.ticketPersonId,
            signedWaiverId: null,
            participantFirstName: null,
            participantLastName: null,
            participantPhone: null,
            height: null,
            weight: null,
            checkedIn: null,
            checkedInBy: null,
            answersJson: null,
        };
    };

export const findMinId = (arr: TicketPerson[]): number => {
    let minId = 0;
    arr.forEach((el) => {
        if (el.ticketPersonId && minId > el.ticketPersonId) {
            minId = Number(el.ticketPersonId);
        }
    });
    return minId;
};

/**this function returns an Array of TicketEdits
 * properly formated and also builds the TicketPersons array which contains all the needed data to
 * display the Tickets persons on the UI`
 **/
export const buildTicketPersons = (
    rateId: number,
    quantity: number,
    ticketEdits: TicketDetail[],
    amount?: number,
) => {
    let newRateArray: any = [];
    let rate = ticketEdits.find((x) => x.rateId === rateId)!;
    if (rate) {
        const difference = quantity - Number(rate.tickets);
        newRateArray = ticketEdits.map((item) => {
            if (item.rateId === rate.rateId) {
                if (difference >= 0) {
                    const ticketRates = createTicketPersonArray(
                        difference,
                        findMinId(item.ticketPersons || []),
                    ).map(
                        buildRateTicketPerson(rate.rateId, rate.questionGroupId, rate.description),
                    );
                    return {
                        ...rate,
                        rate: amount ?? item.rate,
                        tickets: quantity,
                        ticketPersons: item.ticketPersons!.concat(ticketRates),
                    };
                } else {
                    item.ticketPersons!.splice(-1, 1);
                    return {
                        ...rate,
                        rate: amount ?? item.rate,
                        tickets: quantity,
                        ticketPersons: item.ticketPersons!,
                    };
                }
            }
            return { ...item };
        });
    }
    return { ticketEdits: newRateArray };
};

/**
 * This is one of the most important rules for Rates.
 * After selecting a rate only display on screen rates that has the same duration.
 * @param currentRatesSelected
 * @param rateId
 * @returns a boolean that tells if the currect selection of rates has the same duration
 */
export const isRateRelatedWithLessDuration = (
    currentRatesSelected: RateSelectedForLinkStyle[],
    rateId: number,
): Boolean => {
    if (currentRatesSelected && currentRatesSelected.length > 0) {
        const rate = currentRatesSelected.find((x) => x.rateId === rateId);
        if (rate) {
            return currentRatesSelected.some(
                (x) =>
                    x.rateId !== rate.rateId &&
                    x.durationInSeconds === rate.durationInSeconds &&
                    x.displayOrder > rate.displayOrder,
            );
        }
    }
    return true;
};

export const isRadioFlatRate = (maxTickets: number, isFlate: boolean | undefined) => {
    return isRadioInput(maxTickets) && isFlate;
};

/**
 * The function `GetRatesByRules` filters an array of rates based on certain conditions and returns the
 * filtered rates.
 * @param {Rate[]} rates - An array of Rate objects. Each Rate object has properties such as
 * durationInSeconds, maxTickets, flatRate, and internal.
 * @param {Rate} selected_rate - The `selected_rate` parameter is an object of type `Rate` that
 * represents the rate that has been selected by the user.
 * @param {boolean} [includeInternalRate] - A boolean value indicating whether to include internal
 * rates in the result or not. If set to true, internal rates will be included. If set to false or not
 * provided, internal rates will be excluded from the result.
 * @returns The function `GetRatesByRules` returns an array of `Rate` objects.
 */
export const GetRatesByRules = (
    rates: Rate[],
    selected_rate: Rate,
    includeInternalRate?: boolean,
) => {
    //if the selected rate is a radio and flatrate true
    if (isRadioFlatRate(selected_rate.maxTickets, selected_rate.flatRate)) {
        //return just the selected rate because the rest of the rates do not has relationship with this rate
        return [selected_rate];
    }

    if (!includeInternalRate) {
        return rates.filter(
            (r) =>
                r.durationInSeconds === selected_rate.durationInSeconds &&
                !isRadioFlatRate(r.maxTickets, r.flatRate) &&
                !r.internal,
        );
    }
    return rates.filter(
        (r) =>
            r.durationInSeconds === selected_rate.durationInSeconds &&
            !isRadioFlatRate(r.maxTickets, r.flatRate),
    );
};

export const isRadioInput = (maxTickets: number) => {
    return maxTickets === 1;
};

export const shouldShowTickets = (
    tickets: TransactionReservationDetail[],
    requiredFields: number,
): boolean => {
    if (requiredFields && requiredFields > 0) {
        return true;
    }
    const ticketsWithQuestions = tickets.filter(
        (tk) => tk.questionGroupId && tk.questionGroupId > 0,
    );
    return ticketsWithQuestions.length > 0;
};

/**
 * The function `filterRatesByAvailability` filters an array of rates based on their availability for a
 * given target date.
 * @param {Rate[]} rates - An array of Rate objects. Each Rate object represents a rate with its
 * effective dates.
 * @param {string} targetDate - The `targetDate` parameter is a string representing the date for which
 * you want to filter the rates.
 * @returns a filtered array of rates that have a valid effective date for the target date.
 */
export const filterRatesByAvailability = (rates: Rate[], targetDate: Date) => {
    return rates
        .filter((rate) =>
            rate.rateEffectives.some((re) =>
                rateIsValid(targetDate, re.effectiveFrom, re.effectiveTo),
            ),
        )
        .slice();
};
const rateIsValid = (targetDate: Date, start_date: string, end_date: string): boolean => {
    return DateTimeUtils.inRange(targetDate, new Date(start_date), endOfDay(new Date(end_date)));
};
