import { createTheme, Theme } from "@mui/material/styles";
import { Color, PaletteMode } from "@mui/material";
import { grey } from "@mui/material/colors";
import { PaletteOptions } from "@mui/material/styles/createPalette";

declare module "@mui/material/styles" {
    interface PaletteColor {
        bright: string;
        lighter: string;
        darker: string;
    }
    interface SimplePaletteColorOptions {
        bright?: string;
        lighter?: string;
        darker?: string;
    }
}

const CreateThemeColors = (): Required<
    Pick<
        PaletteOptions,
        "grey" | "primary" | "secondary" | "warning" | "info" | "success" | "error"
    >
> => {
    const greyColors: Color = {
        50: grey["50"],
        100: "#f9fafb",
        200: "#f4f6f8",
        300: "#dfe3e8",
        400: "#c4cdd5",
        500: "#919eab",
        600: "#637381",
        700: "#454f5b",
        800: "#212b36",
        900: "#161c24",
        A100: "#f9fafb",
        A200: "#f4f6f8",
        A400: "#c4cdd5",
        A700: "#454f5b",
    };
    const contrastText = "#fff";

    return {
        primary: {
            bright: "#C2F5FF",
            lighter: "#4FDCF8",
            light: "#08A5C4",
            main: "#05697E",
            dark: "#045060",
            darker: "#033742",
            contrastText,
        },
        secondary: {
            bright: "#c2d0ff",
            lighter: "#4d75f9",
            light: "#0a3feb",
            main: "#081e66",
            dark: "#04103a",
            darker: "#010a28",
        },
        error: {
            lighter: "#ffe9d5",
            light: "#ffac82",
            main: "#ff5630",
            dark: "#b71d18",
            darker: "#7a0916",

            contrastText,
        },
        warning: {
            lighter: "#fff5cc",
            light: "#ffd666",
            main: "#ffab00",
            dark: "#b76e00",
            darker: "#7a4100",
            contrastText: greyColors[100],
        },
        info: {
            lighter: "#cafdf5",
            light: "#61f3f3",
            main: "#00b8d9",
            dark: "#006c9c",
            darker: "#003768",
            contrastText,
        },
        success: {
            lighter: "#d3fcd2",
            light: "#77ed8d",
            main: "#22c55e",
            dark: "#118d57",
            darker: "#065e49",
            contrastText,
        },
        grey: greyColors,
    };
};
const Palette = (mode: PaletteMode): Theme => {
    const paletteColor = CreateThemeColors();

    const paletteTheme: PaletteOptions = {
        mode,
        common: {
            black: "#000",
            white: "#fff",
        },
        ...paletteColor,
        text: {
            primary: paletteColor.grey[700],
            secondary: paletteColor.grey[500],
            disabled: paletteColor.grey[700],
        },
        action: {
            disabled: paletteColor.grey[500],
        },
        divider: paletteColor.grey[200],
        background: {
            paper: paletteColor.grey[100],
            default: paletteColor.grey[300],
        },
    };

    return createTheme({
        palette: paletteTheme,
    });
};

export default Palette;
