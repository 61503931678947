import { Action, configureStore, ThunkAction, combineReducers } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import reservationSliceReducer from "./Reservation/Reservation";
import globalsReducer from "./Globals";
import credentialsReducer from "./Credentials/CredentialsSlice";
import shoppingCartReducer from "./ShoppingCart/ShoppingCartSlice";
import companySliceReducer from "./Company/CompanySlice";
import notificationSliceReducer from "./Notifications";
const rootReducer = combineReducers({
    reservation: reservationSliceReducer,
    globals: globalsReducer,
    credentials: credentialsReducer,
    shoppingCart: shoppingCartReducer,
    company: companySliceReducer,
    notifications: notificationSliceReducer,
    //  if we need to use more reducers
});
export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({ reducer: rootReducer });

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
