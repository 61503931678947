import { useState } from "react";
import ReportAccessForm from "./ReportAccessForm";
import SystemAccessForm from "./SystemAccessForm";
import { IUser } from "shared/models/User";
import { ReportAccess, SystemAccess } from "shared/models/AccessRights";
import userService from "shared/services/UserService";
import useNotification, { NotificationsType } from "shared/hooks/useNotification";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { Typography } from "@mui/material";

interface SystemAccessProps {
    reportAccess: number;
    systemAccess: number;
    userName: string;
    userId: string;
    handleUpdateUserPermissions: (userUpdated: IUser) => void;
}
interface IUserAccess {
    SystemRoles: SystemAccess;
    ReportAccess: ReportAccess;
}
const SystemAccessComponent = ({
    reportAccess,
    systemAccess,
    userId,
    userName,
    handleUpdateUserPermissions,
}: SystemAccessProps) => {
    const [userAccess, setUserAccess] = useState<IUserAccess>({
        SystemRoles: systemAccess,
        ReportAccess: reportAccess,
    });
    const { showNotification } = useNotification();
    const [loadingRequest, setLoadingRequest] = useState<boolean>(false);

    const systemAccessData = (newSystemAccess: SystemAccess) => {
        setUserAccess({ ...userAccess, SystemRoles: newSystemAccess });
    };

    const reportAccessData = (newReportAccess: ReportAccess) => {
        setUserAccess({ ...userAccess, ReportAccess: newReportAccess });
    };

    const handleSave = async () => {
        setLoadingRequest(true);
        try {
            const result = await userService.updateUserSystemReportAccess(
                userAccess.SystemRoles,
                userAccess.ReportAccess,
                userId,
            );

            showNotification({
                type: NotificationsType.success,
                message: "Permissions updated",
            });
            if (result) {
                handleUpdateUserPermissions(result);
            }
        } catch (err) {
            showNotification({
                type: NotificationsType.error,
                message: "Failed to update permissions",
            });
        }
        setLoadingRequest(false);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h3">Permissions of {userName}</Typography>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <SystemAccessForm
                    systemAccess={systemAccess}
                    onHandleReturnForm={systemAccessData}
                />
                <ReportAccessForm
                    reportAccess={reportAccess}
                    onHandleReturnForm={reportAccessData}
                />
            </div>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <LoadingButton
                    size="small"
                    loading={loadingRequest}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                >
                    Save
                </LoadingButton>
            </div>
        </div>
    );
};

export default SystemAccessComponent;
