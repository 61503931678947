import { Nullable } from "./Generals";
import { CartItem, GiftCardRecipient } from "./Cart";

export interface CatalogDetail {
    available: number;
    catalogId: number;
    companyId: number;
    description: string;
    giftCard: boolean;
    imgUrls: string[];
    inSchedule: Nullable<boolean>;
    internal: boolean;
    itemCode: Nullable<string>;
    longDescription: Nullable<string>;
    requiredQuantity: number;
    requiresShipping: boolean;
    unitPrice: number;
    giftCards?: GiftCardRecipient[];
}

// CatalogID codes > 0 have corresponding entries in the TBLCatalog table.
// CatalogID codes < -100 in the Cart are MiscellaneousItems that need to be
// converted to -2 on saving the TblTransactionItem.
export enum CatalogIDCode {
    TicketServiceCharge = 0,
    NotSet = -1,
    MiscellaneousItem = -2,
    Tip = -3,
    Reservation = -4,
    Refund = -5,
    Deposit = -6,
    Coupon = -7,
    CatalogAdjustment = -8,
    ReservationAdjustment = -9,
    GiftCard = -10,
    DepositTSC = -11,
    ReservedItemPayment = -12,
    Surcharge = -13,
    CartSurcharge = -14,
}

export const getCatalogNameFromId = (code: CatalogIDCode): string | null => {
    const names: Record<CatalogIDCode, string> = {
        [CatalogIDCode.TicketServiceCharge]: "Ticket service charge",
        [CatalogIDCode.NotSet]: "NotSet",
        [CatalogIDCode.MiscellaneousItem]: "Miscellaneous Item",
        [CatalogIDCode.Tip]: "Tip",
        [CatalogIDCode.Reservation]: "Reservation",
        [CatalogIDCode.Refund]: "Refund",
        [CatalogIDCode.Deposit]: "Deposit",
        [CatalogIDCode.Coupon]: "Coupon",
        [CatalogIDCode.CatalogAdjustment]: "Catalog Adjustment",
        [CatalogIDCode.ReservationAdjustment]: "Reservation Adjustment",
        [CatalogIDCode.GiftCard]: "Gift Card",
        [CatalogIDCode.DepositTSC]: "Deposit Tsc",
        [CatalogIDCode.ReservedItemPayment]: "Reserved Item Payment",
        [CatalogIDCode.Surcharge]: "Surcharge",
        [CatalogIDCode.CartSurcharge]: "Cart surcharge",
    };
    const value = names[code];
    return value ?? null;
};
export interface CatalogItem {
    catalogId: number;
    quantity: number;
    reservationIdAddOn?: number;
}
export interface MiscellaneousItem extends Omit<CatalogItem, "catalogId"> {
    description: string;
    taxable: boolean;
    unitPrice: number;
}

export const getCatalogItemDescription = (item: CartItem) => {
    const itemType = getCatalogNameFromId(item.catalogId);
    const headerArr: string[] = [];
    if (itemType) {
        headerArr.push(itemType);
    }
    if (item.itemCode) {
        headerArr.push(item.itemCode);
    }
    let itemHeader = headerArr.join(" - ");
    if (item.tax > 0) {
        itemHeader += " · Taxable";
    }
    return itemHeader;
};
