import { HttpService } from "../http/http";
import { ValidateSchema } from "../utils/ZodValidations";
import { AssignedSeat, SeatsToAssignPayload } from "shared/models/Seats";
import { AssignedSeatListSchema } from "shared/models/Seats.schema";

class SeatsService extends HttpService {
    constructor() {
        super("/portal/Seats/");
    }

    async getReservedSeats(reservationId: number, categoryKey: number) {
        const result = await this.get<AssignedSeat[]>(
            `getReservedSeats/${reservationId}/${categoryKey}`,
        );
        return ValidateSchema<AssignedSeat[]>(result.data, AssignedSeatListSchema);
    }
    async reAssignSeats(
        reservationId: number,
        eventDateId: number,
        category: number,
        payloadBody: SeatsToAssignPayload,
    ) {
        const result = await this.put(
            `reassignSeat/${eventDateId}/${reservationId}/${category}`,
            payloadBody,
        );
        //we do not return any data from the api
        return result.data;
    }
}

const seatsService = new SeatsService();

export default seatsService;
