import { z } from "zod";
import { InterfaceToZod } from "./UtilsTypes";
import { CheckoutStripeTransaction, StripeLocation, StripeReader } from "./Stripe";

const StripeReaderStatus = z.enum(["online", "offline"]);
export const StripeReaderSchema = z.object<InterfaceToZod<StripeReader>>({
    readerID: z.string().nullable(),
    readerName: z.string().nullable(),
    status: StripeReaderStatus.nullable(),
    errorMsg: z.string().nullable(),
});
const StripeLocationSchema = z.object<InterfaceToZod<StripeLocation>>({
    locationName: z.string(),
    readers: z.array(StripeReaderSchema),
});

export const StripeLocationsSchema = z.array(StripeLocationSchema);

export const CheckoutStripeTransactionSchema = z.object<InterfaceToZod<CheckoutStripeTransaction>>({
    checkoutTransaction: z.string().nullable(),
});
