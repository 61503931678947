import { OverrideComponent } from "../interfaces";

const ListItemIconOverrides: OverrideComponent<"MuiListItemIcon"> = () => {
    return {
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: 24,
                },
            },
        },
    };
};

export default ListItemIconOverrides;
