import React, { useState } from "react";
import { InputAdornment, TextField, Typography } from "@mui/material";
import { MathEx } from "shared/utils/MathEx";
import { TipMethod } from "shared/models/Cart";
import { FlatButton } from "shared/components/FlatButton";
import { useMeasureElement } from "shared/hooks/useMeasureElement";
import styles from "./Tipping.module.css";

export interface TippingTableProps {
    price: number;
    handleAddTip: (method: TipMethod, quantity: number) => void;
}

export const TippingTable = (props: TippingTableProps) => (
    <div className={`${styles.tipTable} ${styles.tipSpacer}`}>
        <div className={styles.tipTableHeader}>
            <Typography variant={"headerExpandableTitle"}>Res. amount</Typography>
        </div>
        <div className={styles.tipTableHeader}>
            <Typography variant={"headerExpandableTitle"}>Percentage</Typography>
        </div>
        <div className={styles.tipTableHeader}></div>
        <div className={styles.tipTableHeader}>
            <Typography variant={"headerExpandableTitle"}>Tip amount</Typography>
        </div>
        <div className={styles.tipTableHeader}></div>
        <TippingRow price={props.price} percentage={10} onAddTip={props.handleAddTip} />
        <TippingRow price={props.price} percentage={15} onAddTip={props.handleAddTip} />
        <TippingRow price={props.price} percentage={18} onAddTip={props.handleAddTip} />
        <TippingRow price={props.price} percentage={20} onAddTip={props.handleAddTip} />
    </div>
);
export const TippingEditablePercentageTable = (props: TippingTableProps) => {
    const [percentage, setPercentage] = useState(0);
    const [ref, measures] = useMeasureElement<HTMLDivElement>([]);
    return (
        <div className={`${styles.tipTable} ${styles.tipSpacer}`}>
            <div className={`${styles.tipTableHeader} ${styles.hidden}`}>
                <Typography variant={"headerExpandableTitle"}>Res. amount</Typography>
            </div>
            <div className={`${styles.tipTableHeader} ${styles.hidden}`} ref={ref}>
                <Typography variant={"headerExpandableTitle"}>Percentage</Typography>
            </div>
            <div className={`${styles.tipTableHeader} ${styles.hidden}`}></div>
            <div className={`${styles.tipTableHeader} ${styles.hidden}`}>
                <Typography variant={"headerExpandableTitle"}>Tip amount</Typography>
            </div>
            <div className={`${styles.tipTableHeader} ${styles.hidden}`}></div>
            <div className={styles.tipTableCell} style={{ justifyContent: "flex-end" }}>
                <Typography variant={"inputLabel"}>{MathEx.formatCurrency(props.price)}</Typography>
            </div>
            <div
                className={styles.tipTableCell}
                style={{
                    width: measures ? measures.width : "0px",
                }}
            >
                <TextField
                    size={"small"}
                    type={"number"}
                    value={percentage}
                    onFocus={(e) => e.target.select()}
                    onChange={(e) => setPercentage(Number(e.target.value))}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position={"end"}>
                                <Typography variant={"inputLabel"}>%</Typography>
                            </InputAdornment>
                        ),
                        inputProps: {
                            min: 0,
                        },
                    }}
                />
            </div>
            <div className={styles.tipTableCell}>
                <Typography variant="inputLabel">=</Typography>
            </div>
            <div className={styles.tipTableCell} style={{ justifyContent: "flex-end" }}>
                <Typography variant="inputLabel" color={"primary"}>
                    {MathEx.formatCurrency((percentage * props.price) / 100)}
                </Typography>
            </div>
            <div className={styles.tipTableCell} style={{ justifyContent: "flex-end" }}>
                <FlatButton
                    color={"primary"}
                    onClick={() => props.handleAddTip(TipMethod.Percentage, percentage)}
                >
                    Add tip
                </FlatButton>
            </div>
        </div>
    );
};
export const TippingEditableAmountTable = (props: TippingTableProps) => {
    const [amount, setAmount] = useState(0);
    const [ref, measures] = useMeasureElement<HTMLDivElement>([]);
    return (
        <div className={`${styles.tipTable} ${styles.tipSpacer}`}>
            <div className={`${styles.tipTableHeader} ${styles.hidden}`}>
                <Typography variant={"headerExpandableTitle"}>Res. amount</Typography>
            </div>
            <div className={`${styles.tipTableHeader} ${styles.hidden}`}>
                <Typography variant={"headerExpandableTitle"}>Percentage</Typography>
            </div>
            <div className={`${styles.tipTableHeader} ${styles.hidden}`}>
                {" "}
                <Typography variant="inputLabel">=</Typography>
            </div>
            <div className={`${styles.tipTableHeader} ${styles.hidden}`} ref={ref}>
                <Typography variant={"headerExpandableTitle"}>Tip amount</Typography>
            </div>
            <div className={`${styles.tipTableHeader} ${styles.hidden}`}></div>

            <div className={styles.tipTableCell}></div>
            <div className={styles.tipTableCell}></div>
            <div className={styles.tipTableCell}></div>
            <div
                className={styles.tipTableCell}
                style={{
                    width: measures ? measures.width : "0px",
                }}
            >
                <TextField
                    fullWidth
                    size={"small"}
                    type={"number"}
                    value={amount}
                    onFocus={(e) => e.target.select()}
                    onChange={(e) => setAmount(Number(e.target.value))}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position={"start"}>
                                <Typography variant={"inputLabel"}>$</Typography>
                            </InputAdornment>
                        ),
                        inputProps: {
                            min: 0,
                        },
                    }}
                />
            </div>
            <div className={styles.tipTableCell} style={{ justifyContent: "flex-end" }}>
                <FlatButton
                    color={"primary"}
                    onClick={() => props.handleAddTip(TipMethod.FixedAmount, amount)}
                >
                    Add tip
                </FlatButton>
            </div>
        </div>
    );
};

interface TippingRowProps {
    price: number;
    percentage: number;
    onAddTip: (method: TipMethod, quantity: number) => void;
}

const TippingRow = (props: TippingRowProps) => {
    return (
        <>
            <div className={styles.tipTableCell} style={{ justifyContent: "flex-end" }}>
                <Typography variant={"inputLabel"}>{MathEx.formatCurrency(props.price)}</Typography>
            </div>
            <div className={styles.tipTableCell}>
                <Typography variant="inputLabel">{props.percentage}% tip</Typography>
            </div>
            <div className={styles.tipTableCell}>
                <Typography variant="inputLabel">=</Typography>
            </div>
            <div className={styles.tipTableCell} style={{ justifyContent: "flex-end" }}>
                <Typography variant="inputLabel" color={"primary"}>
                    {MathEx.formatCurrency((props.price * props.percentage) / 100)}
                </Typography>
            </div>
            <div className={styles.tipTableCell} style={{ justifyContent: "flex-end" }}>
                <FlatButton
                    color={"primary"}
                    onClick={() => props.onAddTip(TipMethod.Percentage, props.percentage)}
                >
                    Add tip
                </FlatButton>
            </div>
        </>
    );
};
