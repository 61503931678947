import { Typography } from "@mui/material";
import AntSwitch from "./AntSwitch";
import LabelDivider from "./LabelDivider";
import { IEventSettings } from "shared/models/Event";
import React from "react";

interface EmailsRemindersProps {
    noFollowUp: boolean;
    noReminder: boolean;
    handleUpdateEmailsReminders: (noFollowUp: boolean, noReminder: boolean) => void;
}

const EmailsReminders = (props: EmailsRemindersProps) => {
    const handleOnChangeSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const key = e.target.id as keyof IEventSettings;
        console.log(key);
        if (key === "noFollowUp") {
            console.log(e.target.checked);
            props.handleUpdateEmailsReminders(e.target.checked, props.noReminder);
        }
        if (key === "noReminder") {
            props.handleUpdateEmailsReminders(props.noFollowUp, e.target.checked);
        }
    };

    return (
        <div>
            <LabelDivider label="Emails and reminders" />
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingTop: "0.5rem",
                }}
            >
                <AntSwitch
                    id="noFollowUp"
                    checked={props.noFollowUp}
                    inputProps={{ "aria-label": "ant design" }}
                    onChange={handleOnChangeSwitch}
                />

                <Typography variant="inputLabel" sx={{ paddingLeft: "12px" }}>
                    Do not send follow-up emails
                </Typography>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingTop: "0.5rem",
                }}
            >
                <AntSwitch
                    id="noReminder"
                    checked={props.noReminder}
                    inputProps={{ "aria-label": "ant design" }}
                    onChange={handleOnChangeSwitch}
                />

                <Typography variant="inputLabel" sx={{ paddingLeft: "12px" }}>
                    Do not send reminders
                </Typography>
            </div>
        </div>
    );
};

export default EmailsReminders;
