import { ExpandMore } from "@mui/icons-material";
import {
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Collapse,
    List,
    ListItem,
} from "@mui/material";
import React, { useState } from "react";
import { MenuItems } from "./menuItems";
import { Each } from "shared/components/Each";
import { useMatch } from "react-router-dom";
import NavigationGroupChild from "./NavigationGroupChild";
import { alpha } from "@mui/material/styles";
import { useIndexicTheme } from "../../../theme/useIndexicTheme";

const NavigationGroup = (props: MenuItems) => {
    const [open, setOpen] = useState(false);
    // const navigation = useNavigate();
    const theme = useIndexicTheme();
    const match = useMatch(props.path);
    const active = match !== null;
    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <>
            <ListItem disablePadding>
                <ListItemButton
                    className={"nav-item"}
                    onClick={handleClick}
                    disabled={!props.active}
                    sx={{
                        backgroundColor: active ? alpha(theme.palette.primary.bright, 0.3) : "",
                    }}
                >
                    <ListItemIcon
                        sx={{
                            color: active ? theme.palette.primary.main : "",
                        }}
                    >
                        {props.icon}
                    </ListItemIcon>
                    <ListItemText
                        primary={props.name}
                        color="inherit"
                        primaryTypographyProps={{
                            variant: "idReservation",
                            sx: {
                                color: active ? theme.palette.primary.main : "",
                            },
                        }}
                    />
                    <ListItemIcon>
                        <ExpandMore
                            sx={{
                                color: active ? theme.palette.primary.main : "",
                                transform: `rotate(${open ? "180deg" : "365deg"})`,
                                transition: "all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                            }}
                        />
                    </ListItemIcon>
                </ListItemButton>
            </ListItem>
            <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
                sx={{
                    display: "block",
                }}
            >
                <List
                    component="div"
                    disablePadding
                    sx={{
                        backgroundColor: theme.palette.grey[200],
                        borderTop: `1px solid ${theme.palette.grey[300]}`,
                        borderBottom: `1px solid ${theme.palette.grey[300]}`,
                    }}
                >
                    <Each
                        of={(props.menu || []).filter((el) => el.display)}
                        render={(item, index) => <NavigationGroupChild key={index} {...item} />}
                    />
                </List>
            </Collapse>
        </>
    );
};

export default NavigationGroup;
