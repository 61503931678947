import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, Typography } from "@mui/material";
import DialogComponent from "shared/components/DialogComponent";
import SaveIcon from "@mui/icons-material/Save";

interface DialogActiveInactiveUserProps {
    openActiveInactiveUser: boolean;
    setOpenActiveInactiveUser: (state: boolean) => void;
    handleActiveInactiveUser: () => void;
    userActiveInactiveSelected: {
        userId: string;
        active: boolean;
    };
    loadingUserRequest: boolean;
}
const DialogActiveInactiveUser = (props: DialogActiveInactiveUserProps) => {
    return (
        <DialogComponent
            open={props.openActiveInactiveUser}
            handleClose={() => props.setOpenActiveInactiveUser(false)}
        >
            <Box display="flex" flexDirection="column" justifyContent="center">
                <Typography marginBottom={".5rem"}>
                    {"Are you sure you want to "}
                    {!props.userActiveInactiveSelected.active ? "activate" : "deactivate"} the user?
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={() => props.setOpenActiveInactiveUser(false)}
                        >
                            CANCEL
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <LoadingButton
                            fullWidth
                            variant="contained"
                            color="primary"
                            startIcon={<SaveIcon />}
                            onClick={props.handleActiveInactiveUser}
                            loading={props.loadingUserRequest}
                        >
                            YES
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Box>
        </DialogComponent>
    );
};

export default DialogActiveInactiveUser;
