import { Drawer, IconButton, styled, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useIndexicTheme } from "theme/useIndexicTheme";
import CloseIcon from "@mui/icons-material/Close";
interface ISlidePanelProps {
    open: boolean;
    onClose: () => void;
    children: React.ReactNode;
    relativeWidth?: number; // Receives a number 1-100 that represents the percentage of the regular width to be used
    // Useful to show dialogs smaller than a parent dialog
    title?: string | React.ReactNode;
    padding?: string; // Overrides current padding with the string property, i.e. padding={'1rem'} will have a padding of 1rem instead of 10px (default)
}
const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    borderBottom: "1px solid var(--grey-300)",
}));
const SlidePanel = (props: ISlidePanelProps) => {
    const theme = useIndexicTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const width = isMobile ? 100 : 600;

    const relativeWidth = props.relativeWidth
        ? Number(Math.floor((width * Math.min(props.relativeWidth, 100)) / 100))
        : width;
    const padding = props.padding ?? "10px";
    return (
        <Drawer
            anchor="right"
            open={props.open}
            onClose={props.onClose}
            sx={{
                "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    boxShadow: "inherit",
                },
                flexShrink: 0,
            }}
            PaperProps={{
                sx: {
                    width: isMobile ? `${relativeWidth}%` : `${relativeWidth}px`,
                    background: "white",
                    padding,
                },
            }}
        >
            <DrawerHeader>
                <IconButton onClick={() => props.onClose()}>
                    <CloseIcon />
                </IconButton>
                {props.title ? (
                    <Typography variant={"reservationFullName"}>{props.title}</Typography>
                ) : null}
            </DrawerHeader>
            {props.open ? props.children : null}
        </Drawer>
    );
};

export default SlidePanel;
