import React, { FunctionComponent, useState } from "react";
import { Button, TextField } from "@mui/material";
import { useShoppingCartSlice } from "../../../store/ShoppingCart/useShoppingCartSlice";
import { getShoppingCartNotifications } from "../ShoppingCartNotifications";
import useNotification from "../../../shared/hooks/useNotification";

const ApplyCouponField: FunctionComponent = () => {
    const { shoppingCartState, applyCoupon } = useShoppingCartSlice();
    const { showNotification } = useNotification();
    const [couponCode, setCouponCode] = useState("");
    const applyCouponNotifications = getShoppingCartNotifications("ApplyCoupon");
    const handleSubmitApplyCoupon: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        if (shoppingCartState.status === "pending" || couponCode !== "") {
            // Reject all actions if the status is pending;
            return;
        }
        try {
            await applyCoupon(couponCode);
            showNotification(applyCouponNotifications.success);
        } catch (error) {
            showNotification(applyCouponNotifications.error);
        }
    };
    return (
        <form style={{ display: "flex", gap: "0.75rem" }} onSubmit={handleSubmitApplyCoupon}>
            <TextField
                label={"Coupon code"}
                variant={"outlined"}
                size={"small"}
                value={couponCode}
                sx={{
                    background: "white",
                    flex: "1",
                }}
                onChange={(e) => setCouponCode(e.target.value)}
            />
            <Button color="primary" variant="outlined" type="submit">
                Apply
            </Button>
        </form>
    );
};

export default ApplyCouponField;
