import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect } from "react";
import useCompany from "shared/hooks/CompanyHook";
import { ICompanyUser } from "shared/models/Company";

interface IUsersSelector {
    handleUserSelected: (userId: string | null) => void;
}

const UserSelector = (props: IUsersSelector) => {
    const { companyUsers, getCompanyUsers } = useCompany();

    useEffect(() => {
        getCompanyUsers();
        //eslint-disable-next-line
    }, []);

    const handleInputChange = (newInputValue: string) => {
        const user = companyUsers.find(
            (option) => `${option.firstName} ${option.lastName}` === newInputValue,
        );
        if (user) {
            props.handleUserSelected(user.userId);
        }
        props.handleUserSelected(null);
    };
    return (
        <>
            {companyUsers.length > 0 && (
                <Autocomplete
                    id="userSelector"
                    options={companyUsers}
                    getOptionLabel={(option: ICompanyUser) =>
                        `${option.firstName} ${option.lastName}`
                    }
                    size="small"
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    fullWidth
                    onInputChange={(ev, value) => handleInputChange(value)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Reservation created by"
                            variant="outlined"
                            size="small"
                        />
                    )}
                />
            )}
        </>
    );
};

export default UserSelector;
