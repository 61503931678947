import { useQueryClient } from "@tanstack/react-query";
import Reservation from "modules/Reservation/Reservation";
import React, { ReactElement, createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SlidePanel from "shared/components/SlidePanel";
import useGlobal from "shared/hooks/GlobalHook";
import useNotification, { NotificationProps } from "shared/hooks/useNotification";
import { ReservationDetails } from "shared/models/Reservation";
import { updatedDiff } from "deep-object-diff";
import DialogComponent from "shared/components/DialogComponent";
import { Box, Button, Grid, Typography } from "@mui/material";
import { DateTimeUtils, FormatType } from "shared/utils/DateTimeUtils";
import { IContactInfoTemplate } from "shared/models/ContactInfo";

interface ICalendarContextProps {
    children?: ReactElement;
}
export const defaultContactInfoValidity: IContactInfoTemplate<boolean> = {
    email: true,
    lastName: true,
    phone: true,
    firstName: true,
};
interface ReservationModalProps {
    reservation: ReservationDetails | null;
    canSave: boolean;
    updateReservationData: (reservationData: Partial<ReservationDetails>) => void;
    undoChanges: () => void;
    setCanSave: (canSave: boolean) => void;
    showNotification: (args: NotificationProps) => void;
    activeProgressBar: boolean;
    handleActiveProgressBar: (active: boolean) => void;
    refetchEventDateDetails: () => void;
    haveChanges: boolean;
    setHaveChanges: (haveChanges: boolean) => void;
    setReservationDetails: (reservationData: ReservationDetails | null) => void;
    setReservationBackup: (reservationData: ReservationDetails | null) => void;
    isValid: boolean;
    undoPressed: boolean;
    setIsValid: (contact: boolean) => void;
}
const ReservationModalContext = createContext<ReservationModalProps>({
    reservation: null,
    canSave: false,
    updateReservationData: (reservationData: Partial<ReservationDetails>) => {},
    setReservationDetails: (reservationData: ReservationDetails | null) => {},
    setReservationBackup: (reservationData: ReservationDetails | null) => {},
    undoChanges: () => {},
    setCanSave: (canSave: boolean) => {},
    showNotification: (args: NotificationProps) => {},
    activeProgressBar: false,
    handleActiveProgressBar: (active: boolean) => {},
    refetchEventDateDetails: () => {},
    haveChanges: false,
    setHaveChanges: (haveChanges: boolean) => {},
    isValid: true,
    undoPressed: false,
    setIsValid: (value: boolean) => {},
});

const ReservationModalProvider = ({ children }: ICalendarContextProps) => {
    const location = useLocation();
    const { globalState, setOpenPanel } = useGlobal();
    const queryClient = useQueryClient();
    const [reservation, setReservationDetails] = useState<ReservationDetails | null>(null);
    const [reservationBackup, setReservationBackup] = useState<ReservationDetails | null>(null);
    const [activeProgressBar, setActiveProgressBar] = useState<boolean>(false);
    const [haveChanges, setHaveChanges] = useState<boolean>(false);
    const [canSave, setCanSave] = useState(false);
    const [undoPressed, setUndoPressed] = useState(false);
    const [openConfirmCloseModal, setOpenConfirmCloseModal] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(true);
    const { showNotification } = useNotification();

    useEffect(() => {
        if (globalState.reservationPanelPayload.reservationId !== 0) {
            setOpenPanel(true);
        }
        //eslint-disable-next-line
    }, [globalState.reservationPanelPayload]);

    const updateReservationData = (reservationData: Partial<ReservationDetails>) => {
        if (reservation) {
            const result = updatedDiff(reservationData, reservation);
            setHaveChanges(Object.keys(result).length > 0);
            setReservationDetails({
                ...reservation,
                ...reservationData,
            });
        } else {
            setReservationDetails(reservationData as ReservationDetails);
        }
    };

    const undoChanges = () => {
        setReservationDetails(reservationBackup);
        setCanSave(false);
        setHaveChanges(false);
        setUndoPressed(!undoPressed);
    };

    const handleActiveProgressBar = (active: boolean) => {
        setActiveProgressBar(active);
    };

    const refetchEventDateDetails = () => {
        if (reservation !== null) {
            if (
                location.pathname ===
                `/event/calendar/${reservation.eventId}/${reservation.eventDateId}`
            ) {
                queryClient.invalidateQueries({
                    queryKey: ["event-date-details"],
                });
            }
        }
    };

    const handleCloseReservationDetailsModal = () => {
        if (!haveChanges) {
            setOpenConfirmCloseModal(false);
            setOpenPanel(false);
            setCanSave(false);
        } else {
            setOpenConfirmCloseModal(true);
        }
    };

    return (
        <ReservationModalContext.Provider
            value={{
                reservation,
                canSave,
                undoPressed,
                updateReservationData,
                undoChanges,
                setCanSave,
                showNotification,
                activeProgressBar,
                handleActiveProgressBar,
                refetchEventDateDetails,
                haveChanges,
                setHaveChanges,
                setReservationDetails,
                setReservationBackup,
                isValid,
                setIsValid,
            }}
        >
            {children}
            <SlidePanel
                open={globalState.isReservationDetailsOpen}
                onClose={handleCloseReservationDetailsModal}
                title={
                    <React.Fragment>
                        <Typography variant="idReservation">{reservation?.eventName}</Typography>
                        <Typography variant="idReservation">
                            {reservation?.eventDateTime && reservation?.eventDateTime !== ""
                                ? `${DateTimeUtils.newFormat(
                                      DateTimeUtils.parse(reservation?.eventDateTime),
                                      FormatType.full,
                                  )}   `
                                : ""}
                        </Typography>
                        <Typography variant="subtitle1">
                            {globalState.companyId !== reservation?.companyId &&
                                reservation?.companyName}
                        </Typography>
                    </React.Fragment>
                }
                children={<Reservation />}
            />
            <DialogComponent
                open={openConfirmCloseModal}
                handleClose={() => setOpenConfirmCloseModal(false)}
            >
                <Box display="flex" flexDirection="column" justifyContent="center">
                    <Typography marginBottom={".5rem"}>
                        {"Are you sure you want to close without saving?"}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                variant="outlined"
                                onClick={() => setOpenConfirmCloseModal(false)}
                            >
                                NO
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => {
                                    setOpenPanel(false);
                                    setOpenConfirmCloseModal(false);
                                }}
                            >
                                YES
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </DialogComponent>
        </ReservationModalContext.Provider>
    );
};

export default ReservationModalProvider;
export const useReservationModalContext = () => {
    const context = React.useContext(ReservationModalContext);
    if (context === undefined) {
        throw new Error("useReservationCalendarContext must be used within a ContextProvider");
    }
    return context;
};
