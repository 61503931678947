/** @description Used to prevent too many calls to a function "func"
 *
 * @param func The function to call after debouncing
 * @param wait Time in milliseconds to wait for no activity before calling func.
 * @returns Returns a function to call in place of func
 *
 */
export const debounceFactory = (func: any, wait: number) => {
    let timeout: ReturnType<typeof setTimeout> | null = null;
    return function (...args: any[]) {
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            timeout = null;
            func(...args);
        }, wait);
    };
};
