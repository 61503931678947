import { Button } from "@mui/material";
import { diff } from "deep-object-diff";
import React, { ReactElement, createContext, useEffect, useState } from "react";
import LoadingButtons from "shared/components/LoadingButton";
import useNotification, { NotificationsType } from "shared/hooks/useNotification";
import { ICompanyUser } from "shared/models/Company";
import { CompanyPreferences, UserPreferences } from "shared/models/Creds";
import userService from "shared/services/UserService";
import { useCredentials } from "store/Credentials/CredencialHook";

interface Props {
    children?: ReactElement;
}
interface UserSettingsContextProps {
    loadingCompanyUser: boolean;
    isUpdating: boolean;
    companyUser: ICompanyUser;
    updateUserPreferences: (data: Partial<UserPreferences>) => void;
    updateCompanyPreferences: (data: Partial<CompanyPreferences>) => void;
    updateUser: (data: Partial<ICompanyUser>) => void;
}
export const defaultCompanyUser: ICompanyUser = {
    companyId: 0,
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    userId: "",
    userPreferences: {
        defaultCalendarView: 0,
        showZeroTicketTimes: false,
        showInactiveEvents: false,
        user24HourTime: false,
        firstDayOfWeek: 0,
        calendarFirstHour: 0,
        calendarLastHour: 24,
    },
    companyPreferences: {
        defaultPage: 0,
        defaultEventGroupId: 0,
        resourceCalendarDefaultTypes: [],
    },
    systemAccess: 0,
    reportAccess: 0,
    active: false,
};

const UserSettingsContext = createContext<UserSettingsContextProps>({
    loadingCompanyUser: false,
    isUpdating: false,
    companyUser: defaultCompanyUser,
    updateUserPreferences: (data: Partial<UserPreferences>) => {},
    updateCompanyPreferences: (data: Partial<CompanyPreferences>) => {},
    updateUser: (data: Partial<ICompanyUser>) => {},
});

const UserSettingsProvider = (props: Props) => {
    const [companyUser, setCompanyUser] = useState<ICompanyUser>(defaultCompanyUser);
    const [previousCompanyUser, setPreviousCompanyUser] =
        useState<ICompanyUser>(defaultCompanyUser);
    const [edited, setEdited] = useState(false);
    const [blockedByRules, setBlocked] = useState(false);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [loadingCompanyUser, setLoadingCompanyUser] = useState<boolean>(false);
    const { showNotification } = useNotification();
    const { fetchCredentials } = useCredentials();

    useEffect(() => {
        getCompanyUser();
        setEdited(false);
    }, []);

    useEffect(() => {
        const result = diff(companyUser, previousCompanyUser);
        const hasEdited = Object.keys(result).length > 0;
        if (
            companyUser.userPreferences?.calendarFirstHour! >=
                companyUser.userPreferences?.calendarLastHour! ||
            companyUser.userPreferences?.calendarLastHour! <=
                companyUser.userPreferences?.calendarFirstHour!
        ) {
            setBlocked(true);
        } else {
            setBlocked(false);
            setEdited(hasEdited);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyUser]);

    const getCompanyUser = async () => {
        setLoadingCompanyUser(true);
        try {
            const result = await userService.getCompanyUser();
            setCompanyUser({
                ...companyUser,
                ...result,
            });
            setPreviousCompanyUser(result);
            setEdited(false);
        } catch {}

        setLoadingCompanyUser(false);
    };

    const updateUser = (data: Partial<ICompanyUser>) => {
        setCompanyUser((user) => ({ ...user, ...data }));
    };

    const updateCompanyPreferences = (data: Partial<CompanyPreferences>) => {
        const companyPreferences = {
            ...companyUser.companyPreferences,
            ...data,
        } as CompanyPreferences;
        updateUser({ companyPreferences });
    };

    const updateUserPreferences = (data: Partial<UserPreferences>) => {
        const userPreferences = { ...companyUser.userPreferences, ...data } as UserPreferences;
        updateUser({ userPreferences });
    };

    const handleSave = async () => {
        setIsUpdating(true);
        try {
            const result = await userService.updateCompanyUser(companyUser);
            setCompanyUser(result);
            setPreviousCompanyUser(result);
            setEdited(false);
            showNotification({
                type: NotificationsType.success,
                message: "Information saved.",
            });
            fetchCredentials();
        } catch {
            showNotification({
                type: NotificationsType.error,
                message: "Cannot update information.",
            });
        }
        setIsUpdating(false);
    };

    const handleCancel = () => {
        setCompanyUser(previousCompanyUser);
    };

    return (
        <UserSettingsContext.Provider
            value={{
                loadingCompanyUser,
                isUpdating,
                companyUser,
                updateUser,
                updateUserPreferences,
                updateCompanyPreferences,
            }}
        >
            <div
                style={{
                    padding: 10,
                }}
            >
                {props.children}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: 2,
                        margin: 2,
                    }}
                >
                    <Button onClick={handleCancel}>Cancel</Button>
                    <LoadingButtons
                        loading={isUpdating}
                        label="Save"
                        disabled={!edited || blockedByRules}
                        handleClick={handleSave}
                    />
                </div>
            </div>
        </UserSettingsContext.Provider>
    );
};
export default UserSettingsProvider;
export const useUserSettingsContext = () => {
    const context = React.useContext(UserSettingsContext);
    if (context === undefined) {
        throw new Error("useUserSettingsContext must be used within a ContextProvider");
    }
    return context;
};
