import React from "react";
import Address from "shared/components/Address";
import { IAddressValue } from "shared/models/Address";
import { useReservationModalContext } from "../context/ReservationModalProvider";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import { Button } from "@mui/material";

const azureMapKeys =
    process.env.REACT_APP_MAPS_KEY || "Ygq_BxrmbqGl8T_bbldVTZgwgijkoXPIeGhvtS6AfmQ";

const Delivery = () => {
    const { reservation, updateReservationData } = useReservationModalContext();
    const [selectedAddress, SetSelectedAddress] = React.useState<IAddressValue>({
        addressText: reservation?.address || "",
        lat: reservation?.lat || 32.65,
        lon: reservation?.lon || -79.94,
    });

    const handleAddressChange = (newAddress: IAddressValue | null) => {
        if (newAddress != null) {
            SetSelectedAddress(newAddress);
        }
    };

    const handleSetAddress = () => {
        if (selectedAddress != null) {
            updateReservationData({
                address: selectedAddress.addressText,
                lat: selectedAddress.lat,
                lon: selectedAddress.lon,
            });
        }
    };

    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                alignItems: "center",
                gap: 5,
            }}
        >
            <div>
                <Address
                    azureMapsKey={azureMapKeys}
                    label="Address"
                    value={selectedAddress}
                    nearbyLat={selectedAddress?.lat}
                    nearbyLon={selectedAddress?.lon}
                    onChange={handleAddressChange}
                    fullWidth
                />
            </div>
            <div>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<AddLocationIcon />}
                    onClick={handleSetAddress}
                >
                    Set Address
                </Button>
            </div>
        </div>
    );
};
export default Delivery;
