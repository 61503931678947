import React, { FunctionComponent } from "react";
import { GiftCardDetail } from "../../../shared/models/Cart";
import { MathEx } from "../../../shared/utils/MathEx";
import { Typography } from "@mui/material";
import { FlatButton } from "../../../shared/components/FlatButton";

interface AppliedGiftCardsProps {
    giftCards?: GiftCardDetail[];
}

export const AppliedGiftCards: FunctionComponent<AppliedGiftCardsProps> = (props) => {
    const { giftCards } = props;
    if (!giftCards || giftCards?.length === 0) {
        return null;
    }
    return (
        <>
            {giftCards.map((el, idx) => (
                <GiftCardEntry giftCard={el} key={idx} />
            ))}
        </>
    );
};
interface GiftCardEntryProps {
    giftCard: GiftCardDetail;
}
const GiftCardEntry = (props: GiftCardEntryProps) => {
    const giftCard = props.giftCard;

    return (
        <div
            style={{
                display: "flex",
                gap: "0.5rem",
                borderBottom: "1px solid var(--grey-300)",
                marginBottom: "0.5rem",
            }}
        >
            {/*needed to have same spacing as other items*/}
            <FlatButton style={{ opacity: 0 }} disabled={true}></FlatButton>
            <div style={{ paddingBottom: "1rem" }}>
                <Typography variant={"headerExpandableTitle"}>Gift card added</Typography>
                <div style={{ display: "flex", gap: "0.5rem" }}>
                    <Typography>Code:</Typography>
                    <Typography variant={"inputLabel"} sx={{ fontSize: "0.875rem" }}>
                        {giftCard.code}
                    </Typography>
                </div>
                <table style={{ borderSpacing: "0 4px" }}>
                    <tbody>
                        <tr>
                            <td>
                                <Typography>Beginning balance:&nbsp;</Typography>
                            </td>
                            <td>
                                <Typography variant={"inputLabel"} sx={{ fontSize: "0.875rem" }}>
                                    {MathEx.formatCurrency(giftCard.originalPrice)}
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography>Amount applied:&nbsp;</Typography>
                            </td>
                            <td>
                                <Typography variant={"inputLabel"} sx={{ fontSize: "0.875rem" }}>
                                    {MathEx.formatCurrency(giftCard.balance)}
                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};
