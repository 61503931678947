import React, { CSSProperties, FunctionComponent } from "react";
import OutlineBadgeButton from "./components/OutlineBadgeButton";
import { SmsOutlined } from "@mui/icons-material";
import { Theme, useMediaQuery } from "@mui/material";
import useGlobal from "shared/hooks/GlobalHook";
import { useNavigate } from "react-router-dom";

interface MessageCountProps {
    style?: CSSProperties;
}
const MessageCount: FunctionComponent<MessageCountProps> = (props) => {
    const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
    const { globalState } = useGlobal();
    const navigate = useNavigate();

    if (matchesXs) {
        return null;
    }
    return (
        <OutlineBadgeButton
            badgeContent={globalState.unReadMessages}
            icon={<SmsOutlined />}
            style={props.style}
            onClick={() => navigate("/textMessages")}
        />
    );
};

export default MessageCount;
