import React, { FunctionComponent, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { FlatButton } from "../../../shared/components/FlatButton";
import { Button, Typography } from "@mui/material";
import {
    CatalogIDCode,
    CatalogItem,
    getCatalogItemDescription,
} from "../../../shared/models/Catalog";
import { MathEx } from "../../../shared/utils/MathEx";
import { CartItem } from "../../../shared/models/Cart";
import { useShoppingCartSlice } from "../../../store/ShoppingCart/useShoppingCartSlice";
import useNotification from "../../../shared/hooks/useNotification";
import { getShoppingCartNotifications } from "../ShoppingCartNotifications";
import { ShoppingCartAdjustments } from "./ShoppingCartAdjustments/ShoppingCartAdjustments";

interface CatalogItemCartProps {
    item: CartItem;
    isAddon?: boolean;
    reservationId?: number;
}

export const CatalogItemCart: FunctionComponent<CatalogItemCartProps> = (props) => {
    const { removeCatalogItem, showGiftCardRecipientList } = useShoppingCartSlice();
    const [loading, setLoading] = useState(false);
    const { showNotification } = useNotification();
    const item = props.item;
    let itemHeader = getCatalogItemDescription(item);
    const isCoupon = item.catalogId === CatalogIDCode.Coupon;
    const handleRemoveCatalogItem = async () => {
        const data: CatalogItem = {
            catalogId: item.catalogId,
            quantity: item.quantity,
            reservationIdAddOn: props.reservationId ?? -1,
        };
        const notification = getShoppingCartNotifications("RemoveCatalogItem");
        setLoading(true);
        try {
            await removeCatalogItem(data);
            showNotification(notification.success);
        } catch (e) {
            showNotification(notification.error);
        } finally {
            setLoading(false);
        }
    };
    const showRecipients = () => {
        showGiftCardRecipientList(item);
    };
    return (
        <Grid
            xs={12}
            container
            style={{
                borderTop: props.isAddon ? "2px dashed var(--grey-300)" : "",
                borderBottom: !props.isAddon ? "1px solid var(--grey-300)" : "",
                marginBottom: !props.isAddon ? "0.5rem" : "",
                padding: "0.5rem 0",
            }}
        >
            <Grid xs={9}>
                <div style={{ display: "flex", gap: "0.5rem" }}>
                    <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                        <FlatButton
                            color={"error"}
                            onClick={handleRemoveCatalogItem}
                            disabled={loading}
                        >
                            Remove
                        </FlatButton>
                    </div>
                    <div>
                        <Typography variant={"headerExpandableTitle"}>{itemHeader}</Typography>
                        <Typography variant={"inputLabel"}>{item.description}</Typography>
                        {item.quantity > 1 ? (
                            <ul style={{ margin: 0, marginBottom: "0.25rem" }}>
                                <li>
                                    {item.quantity} items ({MathEx.formatCurrency(item.price)} each)
                                </li>
                            </ul>
                        ) : null}
                        {/*Show adjustments for gift cards, merchandise or misc items*/}
                        {item.catalogId > 0 || item.catalogId < -100 ? (
                            <ShoppingCartAdjustments
                                catalogItem={item}
                                reservationIdAddon={props.reservationId}
                            />
                        ) : null}

                        {item.giftCards && item.giftCards?.length > 0 ? (
                            <Button
                                variant={"text"}
                                color={"primary"}
                                sx={{
                                    color: (theme) => theme.palette.success.main,
                                }}
                                onClick={showRecipients}
                            >
                                View recipients
                            </Button>
                        ) : null}
                    </div>
                </div>
            </Grid>
            <Grid xs={3}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                    }}
                >
                    {!isCoupon ? (
                        <>
                            <Typography
                                variant={"headerExpandableTitle"}
                                component={"p"}
                                style={{ marginBottom: "0.5rem" }}
                            >
                                Total
                            </Typography>
                            <Typography variant={"inputLabel"}>
                                {MathEx.formatCurrency(item.price * item.quantity ?? 0)}
                            </Typography>
                        </>
                    ) : null}
                </div>
            </Grid>
        </Grid>
    );
};
