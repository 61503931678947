import React, { FunctionComponent } from "react";
import { Button, Chip, Typography } from "@mui/material";
import { FlatButton } from "./FlatButton";

interface ComponentsListProps {}
// List all possible components
const ComponentsList: FunctionComponent<ComponentsListProps> = (props) => {
    return (
        <div style={{ background: "white", width: "100%", minHeight: "100%" }}>
            <Typography variant={"h1"}> Typography variant: h1</Typography>
            <Typography variant={"h2"}> Typography variant: h2</Typography>
            <Typography variant={"h3"}> Typography variant: h3</Typography>
            <Typography variant={"h4"}> Typography variant: h4</Typography>
            <Typography variant={"h5"}> Typography variant: h5</Typography>
            <Typography variant={"h6"}> Typography variant: h6</Typography>
            <Typography variant={"subtitle1"}> Typography variant: subtitle1</Typography>
            <Typography variant={"body1"}> Typography variant: body1</Typography>
            <Typography variant={"body2"}> Typography variant: body2</Typography>
            <div>
                <Typography variant={"caption"}> Typography variant: caption</Typography>
            </div>
            <div>
                <Typography variant={"button"}> Typography variant: button</Typography>
            </div>
            <div>
                <Typography variant={"overline"}> Typography variant: overline</Typography>
            </div>
            <div style={{ margin: "2rem" }}></div>
            {/*<div>*/}
            {/*    <Typography variant={"transactionItemHeader"}>*/}
            {/*        Typography variant: transactionItemHeader*/}
            {/*    </Typography>*/}
            {/*</div>*/}
            <div>
                <Typography variant={"amountSummary"}>Typography variant: amountSummary</Typography>
            </div>
            <div>
                <Typography variant={"idReservation"}>
                    Typography variant: ID_RESERVATION
                </Typography>
            </div>
            <div>
                <Typography variant={"headerExpandableTitle"}>
                    Typography variant: HEADER_EXPANDABLE_TITLE
                </Typography>
            </div>
            {/*<div>*/}
            {/*    <Typography variant={"eventListHeader"}>*/}
            {/*        Typography variant: EVENT_LIST_HEADER*/}
            {/*    </Typography>*/}
            {/*</div>*/}
            <div>
                <Typography variant={"transactionItem"}>
                    Typography variant: transactionItem
                </Typography>
            </div>
            {/*<div>*/}
            {/*    <Typography variant={"tableContentCard"}>*/}
            {/*        Typography variant: tableContentCard*/}
            {/*    </Typography>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*    <Typography variant={"tableContentTotalCard"}>*/}
            {/*        Typography variant: tableContentTotalCard*/}
            {/*    </Typography>*/}
            {/*</div>*/}
            <div>
                <Typography variant={"commentContent"}>
                    Typography variant: commentContent
                </Typography>
            </div>
            {/*<div>*/}
            {/*    <Typography variant={"contactInfoLabel"}>*/}
            {/*        Typography variant: contactInfoLabel*/}
            {/*    </Typography>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*    <Typography variant={"eventLabelTicket"}>*/}
            {/*        Typography variant: eventLabelTicket*/}
            {/*    </Typography>*/}
            {/*</div>*/}
            <div>
                <Typography variant={"inputLabel"}> Typography variant: inputLabel</Typography>
            </div>
            {/*<div>*/}
            {/*    <Typography variant={"headerLabel"}> Typography variant: headerLabel</Typography>*/}
            {/*</div>*/}
            <div>
                <Typography variant={"reservationFullName"}>
                    Typography variant: reservationFullName
                </Typography>
            </div>
            {/*<div>*/}
            {/*    <Typography variant={"title"}> Typography variant: title</Typography>*/}
            {/*</div>*/}
            <div style={{ margin: "2rem" }}></div>
            <div>
                <Chip variant="billingName" label={"ChipOverrides variant: billingName"} />
            </div>
            <div>
                <Chip variant="makeCheckIn" label={"ChipOverrides variant: makeCheckIn"} />
            </div>
            <div>
                <Chip variant="makeCheckedIn" label={"ChipOverrides variant: makeCheckedIn"} />
            </div>
            <div>
                <Chip variant="due" label={"ChipOverrides variant: due"} />
            </div>
            <div>
                <Chip
                    variant="smallNumberLabel"
                    label={"ChipOverrides variant: smallNumberLabel"}
                />
            </div>
            <div>
                <Chip
                    variant={"filled"}
                    label={"ChipOverrides variant: filled primary"}
                    color={"primary"}
                />
                <Chip
                    variant={"filled"}
                    label={"ChipOverrides variant: filled secondary"}
                    color={"secondary"}
                />
            </div>
            <div>
                <Chip
                    variant={"outlined"}
                    label={"ChipOverrides variant: outlined primary"}
                    color={"primary"}
                />
                <Chip
                    variant={"outlined"}
                    label={"ChipOverrides variant: outlined secondary"}
                    color={"secondary"}
                />
            </div>
            <div style={{ margin: "2rem" }}></div>
            <div>
                <Button variant={"refundBtn"}>Button variant refundBtn</Button>
            </div>
            <div>
                <Button variant={"outlined"} color={"primary"}>
                    Button variant outlined primary
                </Button>
            </div>
            <div>
                <Button variant={"outlined"} color={"secondary"}>
                    Button variant outlined secondary
                </Button>
            </div>
            <div>
                <Button variant={"contained"} color={"primary"}>
                    Button variant contained primary
                </Button>
            </div>
            <div>
                <Button variant={"contained"} color={"secondary"}>
                    Button variant contained secondary
                </Button>
            </div>
            <div style={{ margin: "2rem" }}></div>
            <div>
                <Button variant={"contained"} color={"secondary"} disabled={true}>
                    Button variant contained secondary
                </Button>
            </div>{" "}
            <div>
                <Button variant={"contained"} color={"primary"} disabled={true}>
                    Button variant contained primary
                </Button>
            </div>
            <div>
                <Button variant={"outlined"} color={"primary"} disabled={true}>
                    Button variant outlined primary
                </Button>
            </div>
            <div>
                <Button color={"primary"} disabled={true}>
                    Button variant text primary
                </Button>
            </div>
            <div>
                <FlatButton disabled={true}>Flat button disabled</FlatButton>
            </div>
            <div>
                <FlatButton color={"primary"}>Add to cart</FlatButton>
            </div>
            <div>
                <FlatButton disabled={true}>Flat button disabled</FlatButton>
            </div>
            <div>
                <FlatButton color={"error"}>Delete</FlatButton>
            </div>
            <div style={{ margin: "5rem" }}></div>
        </div>
    );
};

export default ComponentsList;
