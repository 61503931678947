import React, { ChangeEvent } from "react";

import { Grid, TextField, InputAdornment } from "@mui/material";
import { Locale } from "shared/utils/Locale";
import { mToFeetInches, feetInchesToM, roundTo } from "shared/utils/Conversions";

interface IHeightProps {
    height: number;
    onChange: (height: number) => void;
    metric?: boolean;
    onBlur?: () => void;
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
}

export default function HeightInput(props: IHeightProps) {
    const { feet, inches } = mToFeetInches(props.height);
    const hasHeight = props.height > 0;
    const disabled = props.disabled === true;
    const handleImperialUnitChange = (e: ChangeEvent<HTMLInputElement>) => {
        let heightInM = 0;
        const { value, name } = e.target;

        if (name.includes("ft")) {
            heightInM = feetInchesToM(Number(value), inches);
        }

        if (name.includes("in")) {
            heightInM = feetInchesToM(feet, Number(value));
        }
        props.onChange(heightInM);
    };

    const handleMetricUnitChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const height = Number(value);
        props.onChange(height);
    };

    return (
        <>
            {!props.metric && (
                <>
                    <Grid item xs={6} md={4}>
                        <TextField
                            fullWidth
                            value={hasHeight ? feet.toString() : ""}
                            label={Locale.height}
                            name="height-ft"
                            type="number"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">{Locale.feet}</InputAdornment>
                                ),
                            }}
                            InputLabelProps={{ shrink: hasHeight }}
                            onChange={handleImperialUnitChange}
                            variant="outlined"
                            error={props.error}
                            onBlur={props.onBlur}
                            onFocus={(e) => e.target.select()}
                            helperText={props.helperText}
                            disabled={disabled}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <TextField
                            fullWidth
                            value={hasHeight ? roundTo(inches, 1).toString() : ""}
                            label={Locale.height}
                            name="height-in"
                            type="number"
                            inputProps={{ step: 0.5 }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">{Locale.inches}</InputAdornment>
                                ),
                            }}
                            InputLabelProps={{ shrink: hasHeight }}
                            onChange={handleImperialUnitChange}
                            variant="outlined"
                            error={props.error}
                            onBlur={props.onBlur}
                            onFocus={(e) => e.target.select()}
                            helperText={props.helperText}
                            disabled={disabled}
                            size="small"
                        />
                    </Grid>
                </>
            )}
            {(props.metric === undefined || props.metric) && (
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        value={hasHeight ? roundTo(props.height, 2).toString() : ""}
                        label={Locale.height}
                        name="height-m"
                        type="number"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">{Locale.meters}</InputAdornment>
                            ),
                        }}
                        InputLabelProps={{ shrink: hasHeight }}
                        onChange={handleMetricUnitChange}
                        variant="outlined"
                        error={props.error}
                        onBlur={props.onBlur}
                        onFocus={(e) => e.target.select()}
                        helperText={props.helperText}
                        disabled={disabled}
                        size="small"
                    />
                </Grid>
            )}
        </>
    );
}
