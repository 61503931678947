import { CartItem, GiftCardRecipient } from "./Cart";
import { CatalogDetail } from "./Catalog";
export interface GiftCardGeneralData {
    catalogId: number;
    description: string | null;
    itemCode: null | string;
    price: number;
}
export interface GiftCardRecipientsSerialized {
    giftCardData: GiftCardGeneralData;
    giftCards: GiftCardRecipient[];
    isNew: boolean;
}
export class GiftCardRecipientsData {
    private giftCardData: GiftCardGeneralData;
    protected constructor(
        catalogId: number,
        description: string | null,
        itemCode: null | string,
        price: number,
        public isNew: boolean,
        private giftCards: GiftCardRecipient[],
    ) {
        this.giftCardData = {
            catalogId,
            description,
            itemCode,
            price,
        };
    }

    static fromCatalogDetails(item: CatalogDetail): GiftCardRecipientsData {
        if (!item.giftCard) {
            throw new Error("Gift recipients must be used on a gift card item");
        }
        return new this(
            item.catalogId,
            `Gift card ${item.description}`,
            item.itemCode,
            item.unitPrice,
            false,
            item.giftCards ?? [],
        );
    }

    static fromCartItem(item: CartItem): GiftCardRecipientsData {
        return new this(
            item.catalogId,
            item.description,
            item.itemCode,
            item.price,
            false,
            item.giftCards ?? [],
        );
    }

    static newRecipientsFromCatalogDetails(item: CatalogDetail, quantity: number) {
        return new this(
            item.catalogId,
            item.description,
            item.itemCode,
            item.unitPrice,
            true,
            this.createRecipients(quantity),
        );
    }
    static newRecipientsFromCartItem(item: CartItem, quantity: 0): GiftCardRecipientsData {
        return new this(
            item.catalogId,
            item.description,
            item.itemCode,
            item.price,
            false,
            item.giftCards ?? [],
        );
    }

    private static createRecipients(quantity: number) {
        const array: GiftCardRecipient[] = [];
        for (let i = 0; i < quantity; i++) {
            const data: GiftCardRecipient = {
                giftCardRecipientId: 0 - i - 1,
                recipientEmail: "",
                recipientName: "",
                note: "",
            };
            array.push(data);
        }
        return array;
    }
    public serialize(): GiftCardRecipientsSerialized {
        return {
            giftCardData: this.giftCardData,
            giftCards: this.giftCards,
            isNew: this.isNew,
        };
    }
}
