import { OverrideComponent } from "../interfaces";
import { SimplePaletteColorOptions } from "@mui/material/styles";

declare module "@mui/material/Chip" {
    interface ChipPropsVariantOverrides {
        billingName: true;
        makeCheckIn: true;
        makeCheckedIn: true;
        due: true;
        overpayment: true;
        smallNumberLabel: true;
    }
}
const ChipOverrides: OverrideComponent<"MuiChip"> = (theme) => {
    const primary = theme.palette.primary as SimplePaletteColorOptions;
    const error = theme.palette.error as SimplePaletteColorOptions;
    const warning = theme.palette.warning as SimplePaletteColorOptions;
    const success = theme.palette.success as SimplePaletteColorOptions;
    return {
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    "&:active": {
                        boxShadow: "none",
                    },
                },
                sizeLarge: {
                    fontSize: "1rem",
                    height: 40,
                },
                light: {
                    color: primary.main,
                    backgroundColor: primary.lighter,
                    borderColor: primary.light,
                    "&.MuiChip-lightError": {
                        color: error.main,
                        backgroundColor: error.lighter,
                        borderColor: error.light,
                    },
                    "&.MuiChip-lightSuccess": {
                        color: success.main,
                        backgroundColor: success.lighter,
                        borderColor: success.light,
                    },
                    "&.MuiChip-lightWarning": {
                        color: warning.main,
                        backgroundColor: warning.lighter,
                        borderColor: warning.light,
                    },
                },
                billingName: {
                    borderRadius: "4px",
                    background: "#4D75F9",
                    color: "#F9FAFB",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "150%" /* 18px */,
                },
                makeCheckIn: {
                    borderRadius: "8px",
                    background: "#C2F5FF",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "150%",
                    color: "#033742",
                    ".MuiChip-deleteIcon": {
                        color: "#033742",
                        height: "20px",
                        width: "20px",
                    },
                    ".MuiChip-deleteIcon:hover": {
                        cursor: "pointer",
                        color: "#033742",
                    },
                },
                makeCheckedIn: {
                    borderRadius: "8px",
                    borderColor: "#DFE3E8",
                    background: "#F4F6F8",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "150%",
                    color: "#05697E",
                    ".MuiChip-deleteIcon": {
                        color: "#05697E",
                        height: "20px",
                        width: "20px",
                    },
                    ".MuiChip-deleteIcon:hover": {
                        cursor: "pointer",
                        color: "#05697E",
                    },
                },
                due: {
                    borderRadius: "4px",
                    height: "20px",
                    color: "#F9FAFB",
                    background: "#B71D18",
                    fontSize: "13px",
                    fontWeight: 700,
                    lineHeight: "150%" /* 18px */,
                },
                overpayment: {
                    borderRadius: "4px",
                    height: "20px",
                    color: theme.palette.grey[900],
                    background: warning.dark,
                    fontSize: "13px",
                    fontWeight: 700,
                    lineHeight: "150%",
                },
                smallNumberLabel: {
                    ".MuiChip-label": { marginRight: "5.5px" },
                    marginLeft: "4px",
                    justifyContent: "center",
                    height: "18px",
                    width: "18px",
                    background: "#212B36",
                    color: "#F9FAFB",
                    borderRadius: "14.4px",
                    fontSize: "10.8px!important",
                },
            },
        },
    };
};
export default ChipOverrides;
