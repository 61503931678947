import React, { FunctionComponent, useState } from "react";
import DialogComponent from "../../../shared/components/DialogComponent";
import {
    Divider,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
    Typography,
} from "@mui/material";
import { CurrencyComponent } from "../../Reports/components/CurrencyComponent";
import {
    IRefundCatalogItem,
    RefundCatalogItem,
    RefundCatalogItemTransaction,
} from "../../../shared/models/Transaction";
import { MathEx } from "shared/utils/MathEx";
import NumberInput from "../../../shared/components/NumberInput";
import { updateOrAppendToArray } from "../../../shared/utils/Common";
import { RefundAmountField } from "./RefundAmountField";

interface RefundCatalogAmounts {
    quantity: number;
    unitPrice: number;
    serviceCharge: number;
    tax: number;
    amount: number;
    partialRefund: boolean;
}

export interface RefundCatalogItemDialogProps {
    maxRefund: RefundCatalogItem;
    taxRate: number;
    onSet: (arg: IRefundCatalogItem) => void;
    onCancel: () => void;
    open: boolean;
    isCatalogItem?: boolean;
}

type RefundType = "complete" | "partial";
// Fields that can be edited
type EditableRefundAmount = Pick<RefundCatalogAmounts, "quantity" | "serviceCharge" | "unitPrice">;

interface RefundMaxCatalogItemTransaction extends RefundCatalogItemTransaction {
    maxQuantity: number;
    maxUnitPrice: number;
}

const RefundCatalogItemDialog: FunctionComponent<RefundCatalogItemDialogProps> = (props) => {
    const { maxRefund, taxRate, open } = props;
    // Generate the select entries according to the number of catalog items
    // const options = maxRefund.quantity > -1 ? [...Array(maxRefund.quantity + 1).keys()] : [];

    const [refundType, setRefundType] = useState<RefundType>("complete");
    const [refundTransactions, setRefundTransactions] = useState<RefundMaxCatalogItemTransaction[]>(
        () => {
            const tran: RefundMaxCatalogItemTransaction[] = props.maxRefund.transactions.map(
                (t) => ({
                    quantity: 0,
                    maxQuantity: t.quantity,
                    unitPrice: t.unitPrice,
                    maxUnitPrice: t.unitPrice,
                }),
            );
            return tran;
        },
    );
    const [refundServiceCharge, setRefundServiceCharge] = useState(0);

    const handleSetRefundAmounts = () => {
        const transactions: RefundCatalogItemTransaction[] = refundTransactions.map((t) => ({
            quantity: t.quantity,
            unitPrice: t.unitPrice,
        }));
        const refundData: IRefundCatalogItem = {
            catalogId: maxRefund.catalogId,
            reservationIdAddOn: maxRefund.reservationIdAddOn,
            refundServiceCharge: refundServiceCharge,
            transactions,
            partialRefund: refundType === "partial",
        };
        props.onSet(refundData);
    };
    const handleChangeTransactionsCatalogItem = (
        data: RefundMaxCatalogItemTransaction,
        idx: number,
    ) => {
        setRefundTransactions((s) => updateOrAppendToArray(s, data, idx));
    };
    // if max refundable qty is 0 there's noting to refund
    const maxRefundableQuantity = refundTransactions.reduce(
        (sum, curr) => sum + curr.maxQuantity,
        0,
    );
    return (
        <DialogComponent
            open={open}
            handleClose={props.onCancel}
            DialogText="Set the amount you want to refund"
            ButtonActions={{
                handleSuccessActionText: "Set",
                // handleSuccessActionFunction: console.log,
                handleSuccessActionFunction: handleSetRefundAmounts,
                handleCancelActionText: "Cancel",
                handleCancelActionFunction: props.onCancel,
            }}
        >
            <Typography variant="inputLabel" gutterBottom>
                Maximum refundable amounts
            </Typography>
            <CatalogItemTransactionsDisplay transactions={props.maxRefund.transactions} />
            {props.maxRefund.refundServiceCharge > 0 ? (
                <Typography>
                    Service charge: {MathEx.formatCurrency(props.maxRefund.refundServiceCharge)}
                </Typography>
            ) : null}

            {maxRefundableQuantity > 0 ? (
                <>
                    <Tabs
                        value={refundType}
                        sx={{
                            margin: "0.5rem 0",
                        }}
                        onChange={(el, val) => setRefundType(val)}
                    >
                        <Tab label="Refund item" value={"complete"} />
                        <Tab label="Partial refund item" value={"partial"} />
                    </Tabs>
                    <Typography
                        variant="commentContent"
                        component={"p"}
                        sx={{ marginBottom: "0.5rem" }}
                    >
                        Enter the amounts you want to refund
                    </Typography>
                    <Typography variant="idReservation">Refund quantity</Typography>
                    {refundTransactions.map((t, index) => (
                        <RefundTransactionInput
                            key={index}
                            maxRefund={t}
                            partialRefund={refundType === "partial"}
                            onChange={(data) => handleChangeTransactionsCatalogItem(data, index)}
                        />
                    ))}
                </>
            ) : null}

            {maxRefund.refundServiceCharge > 0 ? (
                <>
                    <Typography variant="idReservation">Refund service charge</Typography>
                    <RefundAmountField
                        value={refundServiceCharge}
                        max={maxRefund.refundServiceCharge}
                        onChange={setRefundServiceCharge}
                    />
                </>
            ) : null}

            <Divider sx={{ margin: "1rem 0 " }} />
            <Typography variant="inputLabel" gutterBottom>
                You'll refund
            </Typography>
            <Typography>Service charge : {MathEx.formatCurrency(refundServiceCharge)}</Typography>
            <Typography>Transactions: </Typography>
            <CatalogItemTransactionsDisplay
                transactions={refundTransactions.filter((t) => t.quantity > 0)}
            />
        </DialogComponent>
    );
};

export default RefundCatalogItemDialog;

interface AmountsTableProps {
    data: RefundCatalogAmounts;
}

export const AmountsTable = (props: AmountsTableProps) => {
    return (
        <Table className="transaction-table">
            <TableHead>
                <TableRow>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Unit price</TableCell>
                    <TableCell>Tax</TableCell>
                    <TableCell>Fees</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>{props.data.quantity}</TableCell>
                    <TableCell>
                        <CurrencyComponent value={props.data.unitPrice} />
                    </TableCell>
                    <TableCell>
                        <CurrencyComponent value={props.data.tax} />
                    </TableCell>
                    <TableCell>
                        <CurrencyComponent value={props.data.serviceCharge} />
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

interface RefundTransactionInputProps {
    maxRefund: RefundMaxCatalogItemTransaction;
    partialRefund: boolean;
    onChange: (arg: RefundMaxCatalogItemTransaction) => void;
}

export const RefundTransactionInput = (props: RefundTransactionInputProps) => {
    const { quantity, maxQuantity, unitPrice, maxUnitPrice } = props.maxRefund;
    const handleChangeQuantity = (value: number) => {
        const quantity = Math.min(value, maxQuantity);
        props.onChange({ ...props.maxRefund, quantity });
    };
    const handleChangePrice = (value: number) => {
        const unitPrice = Math.min(value, maxUnitPrice);
        props.onChange({ ...props.maxRefund, unitPrice });
    };
    return (
        <div style={{ display: "flex", gap: "0.5rem" }}>
            <div style={{ width: "8rem" }}>
                <NumberInput
                    value={quantity}
                    maxValue={maxQuantity}
                    onChange={handleChangeQuantity}
                />
            </div>
            <div style={{ width: "8rem" }}>
                {props.partialRefund ? (
                    <RefundAmountField
                        value={unitPrice}
                        max={maxUnitPrice}
                        onChange={handleChangePrice}
                    />
                ) : (
                    <Typography style={{ height: "2.3rem", padding: "8.5px 14px" }}>
                        {MathEx.formatCurrency(maxUnitPrice)}
                    </Typography>
                )}
            </div>
        </div>
    );
};

interface CatalogItemTransactionsDisplayProps {
    transactions: RefundCatalogItemTransaction[];
}

const CatalogItemTransactionsDisplay = (props: CatalogItemTransactionsDisplayProps) => {
    if (props.transactions.length === 0) {
        return null;
    }
    return (
        <>
            <Typography>Items:</Typography>
            <ul>
                {props.transactions.map((t) => (
                    <li>
                        {t.quantity} x {MathEx.formatCurrency(t.unitPrice)}
                    </li>
                ))}
            </ul>
        </>
    );
};
