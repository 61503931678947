import { useState } from "react";
import { useDebounce } from "./useDebounce";

/**
 * Custom hook to validation forms like address info or contact info
 *
 * This hooks handle automatically errors, form info, debounced update, blurred states and complete form validation
 * @param defaultInfo Default form information
 * @param portalEntry Determines if it's working on portal or link
 * @param requiredFields Fields required on portal, it assumes that on link all fields are required
 * @param checkFormErrors Validation function, receives the form object and returns the same keys with the associated array or empty if valid
 */
export const useFormValidation = <T>(
    defaultInfo: T,
    portalEntry: boolean,
    requiredFields: Array<keyof T>,
    checkFormErrors: (data: T) => FieldErrorsRecord<T>,
) => {
    if (
        typeof defaultInfo === "string" ||
        typeof defaultInfo === "number" ||
        typeof defaultInfo === "function"
    ) {
        throw new Error("Default info should be an object instance");
    }
    const [fieldErrors, setFieldErrors] = useState<FieldErrorsRecord<T>>(
        createEmptyStringObject(defaultInfo),
    );
    const [form, setForm] = useState<T>(() => ({
        ...(createEmptyStringObject(defaultInfo) as T),
        ...defaultInfo,
    }));
    const debouncedForm = useDebounce(form);
    const [hasBlurred, setHasBlurred] = useState<FieldBlurredRecord<T>>(
        createEmptyBlurredObject(defaultInfo),
    );
    const showErrorMessage = (field: keyof T): boolean => {
        const isBlurred = hasBlurred[field];
        const isFieldValid = fieldErrors[field].length === 0;
        const isRequired = isFieldRequired(field);
        return isBlurred && isRequired && !isFieldValid;
    };
    const getErrorText = (field: keyof T): string => {
        return showErrorMessage(field) ? fieldErrors[field] : "";
    };
    const isFieldRequired = (field: keyof T) => {
        // For non portal products all fields are required
        if (!portalEntry) {
            return true;
        }
        // For portal products only the fields on the list are required
        return requiredFields.includes(field);
    };
    const updateBlur = (field: keyof T) => {
        setHasBlurred((s) => ({ ...s, [field]: true }));
    };
    const updateForm = (partialForm: Partial<T>) => {
        const newForm = { ...form, ...partialForm } as T;
        setForm(newForm);
        setFieldErrors(checkFormErrors(newForm));
    };
    const validateForm = (): boolean => {
        let isValid = true;
        Object.keys(fieldErrors).forEach((key) => {
            const field = key as keyof T;
            const isRequired = isFieldRequired(field);
            if (isRequired && fieldErrors[field].length !== 0) {
                isValid = false;
            }
        });
        return isValid;
    };
    const displayErrors = () => {
        // Set all object as blurred
        setHasBlurred(createEmptyBlurredObject(defaultInfo, true));
        // Validate form
        setFieldErrors(checkFormErrors(form));
    };
    return {
        debouncedForm,
        form,
        setForm,
        hasBlurred,
        updateBlur,
        showErrorMessage,
        updateForm,
        getErrorText,
        isFieldRequired,
        validateForm,
        displayErrors,
    };
};

export type FieldErrorsRecord<T> = Record<keyof T, string>;
const createEmptyStringObject = <T>(data: T): FieldErrorsRecord<T> => {
    const errorsObj = {} as FieldErrorsRecord<T>;
    Object.keys(data as object).forEach((e) => {
        const field = e as keyof T;
        errorsObj[field] = "";
    });
    return errorsObj;
};
type FieldBlurredRecord<T> = Record<keyof T, boolean>;
const createEmptyBlurredObject = <T>(data: T, flag: boolean = false): FieldBlurredRecord<T> => {
    const errorsObj = {} as FieldBlurredRecord<T>;
    Object.keys(data as object).forEach((e) => {
        const field = e as keyof T;
        errorsObj[field] = flag;
    });
    return errorsObj;
};
