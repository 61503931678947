import React, { useState, useEffect, ChangeEvent } from "react";

import { parsePhoneNumberFromString } from "libphonenumber-js";
import { Grid, TextField } from "@mui/material";
import { isEmpty } from "shared/utils/Common";
import { TicketPerson } from "shared/models/Reservation";
import { Locale } from "shared/utils/Locale";
import HeightInput from "./HeightInput";
import WeightInput from "./WeightInput";
import { MuiTelInput } from "mui-tel-input";

export interface RequiredFieldsProps {
    index?: number;
    requiredFields: number;
    ticketPerson?: TicketPerson;
    showError?: boolean;
    disabled?: boolean;
    handleAnswers: (arg: TicketPerson) => void;
}

export enum RequiredFieldsEnum {
    HolderFirstName = 1 << 0, // 1
    HolderLastName = 1 << 1, // 2
    HolderPhone = 1 << 2, // 4
    HolderEmail = 1 << 3, // 8
    Name = 1 << 4, // 16
    Phone = 1 << 6, // 64
    Height = 1 << 9, // 512
    Weight = 1 << 10, //1024
}

type FieldState = { [field: string]: boolean | undefined };
const ticketPersonDefault: Partial<TicketPerson> = {
    participantFirstName: "",
    participantLastName: "",
    participantPhone: "",
    height: 0,
    weight: 0,
};
//TODO: add phone component
const RequiredFields = (props: RequiredFieldsProps) => {
    const [fieldBlur, setFieldBlur] = useState<FieldState>({});
    const [fieldErrors, setFieldErrors] = useState<FieldState>({});
    const ticketPerson = props.ticketPerson ? props.ticketPerson : ticketPersonDefault;
    const disabled = props.disabled === true;

    useEffect(() => {
        if (props.showError === true) {
            setFieldBlur({
                participantFirstName: true,
                participantLastName: true,
                height: true,
                weight: true,
                participantPhone: true,
            });
        }
    }, [props.showError]);

    useEffect(() => {
        handleFieldErrors();
    }, [fieldBlur, props.ticketPerson]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleFieldBlur = (field: string) => () => {
        setFieldBlur((prevState) => {
            return {
                ...prevState,
                [field]: true,
            };
        });
    };
    const updatePerson = (data: Partial<TicketPerson>): Partial<TicketPerson> => ({
        ...ticketPerson,
        ...data,
    });
    const handleInputChange = (field: string) => (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.currentTarget;
        props.handleAnswers(updatePerson({ [field]: value }) as TicketPerson);
    };

    const handleDimensionsChange = (field: string) => (value: number) => {
        props.handleAnswers(updatePerson({ [field]: value }) as TicketPerson);
    };

    const handlePhoneChange = (value: string, country: any) => {
        props.handleAnswers(updatePerson({ participantPhone: value }) as TicketPerson);
    };
    const handleFieldErrors = () => {
        const fields = [
            "participantFirstName",
            "participantLastName",
            "height",
            "weight",
            "participantPhone",
        ];
        const newErrorState = { ...fieldErrors };

        for (const field of fields) {
            let hasError: boolean | undefined = false;
            if (field === "participantFirstName" || field === "participantLastName") {
                hasError = fieldBlur[field] && isEmpty(ticketPerson[field]);
                newErrorState[field] = hasError;
            }

            if (field === "height" && ticketPerson) {
                hasError =
                    fieldBlur[field] &&
                    (Number(ticketPerson[field]) <= 0.1 || Number(ticketPerson[field]) >= 3);
                newErrorState[field] = hasError;
            }

            if (field === "weight" && ticketPerson) {
                hasError =
                    fieldBlur[field] &&
                    (Number(ticketPerson[field]) <= 1 || Number(ticketPerson[field]) >= 250);
                newErrorState[field] = hasError;
            }

            if (field === "participantPhone" && ticketPerson) {
                const phoneNum = parsePhoneNumberFromString(ticketPerson.participantPhone ?? "");
                hasError = fieldBlur[field] && !phoneNum?.isValid();
                newErrorState[field] = hasError;
            }
        }

        setFieldErrors((prevState) => {
            return {
                ...prevState,
                ...newErrorState,
            };
        });
    };

    return (
        <Grid container spacing={1}>
            {(props.requiredFields & RequiredFieldsEnum.Name) > 0 && (
                <>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            variant="outlined"
                            label={Locale.firstName}
                            value={ticketPerson?.participantFirstName ?? ""}
                            onChange={handleInputChange("participantFirstName")}
                            onBlur={handleFieldBlur("participantFirstName")}
                            error={fieldErrors.participantFirstName}
                            // helperText={fieldErrors.participantFirstName ? Locale.required : undefined}
                            disabled={disabled}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            variant="outlined"
                            label={Locale.lastName}
                            value={ticketPerson?.participantLastName ?? ""}
                            onChange={handleInputChange("participantLastName")}
                            onBlur={handleFieldBlur("participantLastName")}
                            error={fieldErrors.participantLastName}
                            // helperText={fieldErrors.participantLastName ? Locale.required : undefined}
                            disabled={disabled}
                            size="small"
                        />
                    </Grid>
                </>
            )}
            {(props.requiredFields & RequiredFieldsEnum.Height) > 0 && (
                <Grid container item sm={12} spacing={1}>
                    <HeightInput
                        height={ticketPerson?.height || 0}
                        onChange={handleDimensionsChange("height")}
                        onBlur={handleFieldBlur("height")}
                        error={fieldErrors.height}
                        // helperText={fieldErrors.height ? Locale.required : undefined}
                        disabled={disabled}
                    />
                </Grid>
            )}
            {(props.requiredFields & RequiredFieldsEnum.Weight) > 0 && (
                <Grid container item sm={12} spacing={1}>
                    <WeightInput
                        weightInKg={ticketPerson?.weight || 0}
                        onChange={handleDimensionsChange("weight")}
                        onBlur={handleFieldBlur("weight")}
                        error={fieldErrors.weight ?? false}
                        // helperText={fieldErrors.weight ? Locale.required : undefined}
                        disabled={disabled}
                    />
                </Grid>
            )}
            {(props.requiredFields & RequiredFieldsEnum.Phone) > 0 && (
                <Grid item sm={12}>
                    <MuiTelInput
                        value={ticketPerson?.participantPhone ?? ""}
                        defaultCountry="US"
                        error={fieldErrors.participantPhone && fieldBlur.participantPhone}
                        onChange={handlePhoneChange}
                        onBlur={handleFieldBlur("participantPhone")}
                        disabled={disabled}
                        variant="outlined"
                        disableFormatting
                        size="small"
                        fullWidth
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default RequiredFields;
