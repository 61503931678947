import axios, {
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
    CreateAxiosDefaults,
} from "axios";
import { BASE_URL } from "../../environment/environment";
import {
    AuthRequestInterceptor,
    ErrorResponseInterceptor,
    HeadersResponseInterceptor,
} from "./interceptors";
export interface QueryParam {
    key: string;
    value: UrlType;
}
type UrlType = number | string | Date | object | Record<any, any> | boolean;
export class HttpService {
    private request: AxiosInstance;
    headers: CreateAxiosDefaults["headers"] = {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
    };
    constructor(url: string, useInterceptor: boolean = true) {
        if (!url.startsWith("/")) {
            throw new Error("Valid url must start with /, current: " + url);
        }
        this.request = axios.create({
            baseURL: `${BASE_URL}${url}`,
            headers: this.headers,
        });

        if (useInterceptor) {
            this.request.interceptors.request.use((req) => AuthRequestInterceptor(req));
            this.request.interceptors.response.use(
                (response) => HeadersResponseInterceptor(response),
                (err) => ErrorResponseInterceptor(err),
            );
        }
    }
    setHeaders(headers: any) {
        this.headers = headers;
    }
    protected post<T = unknown, K = unknown>(url: string, params: K, config?: AxiosRequestConfig) {
        return this.request.post<T, K>(url, params, config) as Promise<AxiosResponse<T>>;
    }
    protected get<T = unknown>(url: string, config?: AxiosRequestConfig) {
        return this.request.get<T>(url, config);
    }
    protected put<T = unknown, K = unknown>(url: string, params?: K, config?: AxiosRequestConfig) {
        return this.request.put<T, K>(url, params, config) as Promise<AxiosResponse<T>>;
    }
    protected delete<T = unknown>(url: string, config?: AxiosRequestConfig) {
        return this.request.delete<T>(url, config);
    }
    protected patch<T = unknown, K = unknown>(
        url: string,
        params?: T,
        config?: AxiosRequestConfig,
    ) {
        return this.request.patch<T, K>(url, params, config) as Promise<AxiosResponse<T>>;
    }
}

/**
 * Using for complex queries, uses native api to create a query string
 * @param params
 * @protected
 */
export const createQueryParams = (params: QueryParam[]): string => {
    if (params.length === 0) {
        return "";
    }
    const queryRecord: Record<string, string> = {};
    (params || []).forEach((param) => {
        queryRecord[param.key] = urlTypeToString(param.value);
    });
    let searchParam = new URLSearchParams(queryRecord);
    return `?${searchParam.toString()}`;
};
const urlTypeToString = (url: UrlType): string => {
    if (typeof url == "object") {
        if (url instanceof Date) {
            return url.toISOString();
        } else {
            return JSON.stringify(url);
        }
    } else {
        return url.toString();
    }
};

/**
 * For complex url slugs you can pass an array of different values and will create a string representation for the route
 * @param route
 * @protected
 */
export const createUrl = (route: UrlType[]) => {
    const parsedRoutes = route.map(urlTypeToString);
    return "/" + parsedRoutes.join("/");
};
