import { presetPrimaryColors } from "@ant-design/colors";
import { Grid, SxProps, Typography } from "@mui/material";
import Currency from "shared/components/Currency";
import { MathEx } from "shared/utils/MathEx";

interface ReceiptTotalProps {
    subTotal: number;
    serviceCharge: number;
    tax: number;
}
const ReceiptTotal = (props: ReceiptTotalProps) => {
    const styleTitle: SxProps = {};
    const styleContent: SxProps = {};
    return (
        <Grid container spacing={0}>
            <Grid item xs={6}>
                <Typography sx={styleTitle}>Subtotal:</Typography>
            </Grid>
            <Grid item xs={6}>
                <Currency sx={styleContent} amount={props.subTotal} />
            </Grid>
            <Grid item xs={6}>
                <Typography sx={styleTitle}>Service Fees:</Typography>
            </Grid>
            <Grid item xs={6}>
                <Currency sx={styleContent} amount={props.serviceCharge} />
            </Grid>
            <Grid item xs={6}>
                <Typography sx={styleTitle}>Tax: </Typography>
            </Grid>
            <Grid item xs={6}>
                <Currency sx={styleContent} amount={props.tax} />
            </Grid>
            <Grid item xs={6}>
                <Typography sx={styleTitle}>Total:</Typography>
            </Grid>
            <Grid item xs={6}>
                <Currency
                    sx={styleContent}
                    amount={props.subTotal + props.serviceCharge + props.tax}
                />
            </Grid>
        </Grid>
    );
};

export default ReceiptTotal;
