import {
    AppBar,
    Box,
    Chip,
    CircularProgress,
    Grid,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    Paper,
    Theme,
    Toolbar,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ApprovalStatus, ResponseWaiver, SignedWaiver } from "shared/models/AWaiver";
import aWaiverService from "shared/services/AWaiverService";
import { DateTimeUtils } from "shared/utils/DateTimeUtils";
import { useIndexicTheme } from "theme/useIndexicTheme";

interface AWaiverDetailsProps {
    signedWaiver_Id: number | null;
    waiverDetails?: (signedWaiver: SignedWaiver) => void;
}

const AWaiverDetails = (props: AWaiverDetailsProps) => {
    const theme = useIndexicTheme();
    const [details, setDetails] = useState<ResponseWaiver | null>(null);
    const smallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));

    useEffect(() => {
        if (props.signedWaiver_Id) {
            getWaiverDetails().then();
        }
        // eslint-disable-next-line
    }, [props.signedWaiver_Id]);

    useEffect(() => {
        if (details?.signedWaiver && props.waiverDetails) {
            props.waiverDetails(details.signedWaiver);
        }
        // eslint-disable-next-line
    }, [details]);

    const getWaiverDetails = async () => {
        try {
            const data = await aWaiverService.waiver(props.signedWaiver_Id!.toString());
            if (data !== null) {
                const { attachmentUrls, signedWaiver }: ResponseWaiver = data;
                setDetails({
                    signedWaiver,
                    attachmentUrls,
                });
            }
        } catch (error) {}
    };

    return details !== null ? (
        <Grid container>
            <Grid item xs={12}>
                <AppBar
                    position="sticky"
                    color="transparent"
                    style={{
                        backgroundColor: "white",
                    }}
                    elevation={smallScreen ? 0 : 4}
                >
                    <Toolbar disableGutters={true}>
                        <Box
                            display="flex"
                            flexDirection={smallScreen ? "column" : "row"}
                            justifyContent="space-between"
                            alignItems={smallScreen ? "flexStart" : "center"}
                            style={{
                                width: " 100%",
                            }}
                            p={1}
                        >
                            <Box
                                display={smallScreen ? "flex" : ""}
                                style={smallScreen ? { width: "100%" } : {}}
                            >
                                <Typography
                                    variant="body1"
                                    component="h2"
                                    gutterBottom={smallScreen}
                                    style={smallScreen ? { marginRight: "8px" } : {}}
                                >
                                    Signed by: {details.signedWaiver.signedByName}
                                </Typography>
                                {details.signedWaiver.status === ApprovalStatus.Approved ? (
                                    <Chip
                                        sx={{
                                            backgroundColor: theme.palette.success["lighter"],
                                            color: theme.palette.success["darker"],
                                        }}
                                        label="Approved"
                                        size="small"
                                    />
                                ) : details.signedWaiver.status === ApprovalStatus.Rejected ? (
                                    <Chip
                                        sx={{
                                            backgroundColor: theme.palette.error["lighter"],
                                            color: theme.palette.error["darker"],
                                        }}
                                        label="Rejected"
                                        size="small"
                                    />
                                ) : null}
                            </Box>
                            <Box display="flex" flexDirection="column">
                                <Box>
                                    <Typography
                                        variant="body1"
                                        component="h2"
                                        gutterBottom={smallScreen}
                                    >
                                        Signed On:{" "}
                                        {DateTimeUtils.formatLongDateTime(
                                            DateTimeUtils.UTCStringToLocalDate(
                                                details.signedWaiver.signed,
                                            ),
                                        )}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography
                                        variant="body1"
                                        component="h2"
                                        gutterBottom={smallScreen}
                                    >
                                        Expires:{" "}
                                        {DateTimeUtils.formatLongDateTime(
                                            DateTimeUtils.UTCStringToLocalDate(
                                                details.signedWaiver.expiration,
                                            ),
                                        )}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Toolbar>
                </AppBar>
            </Grid>
            <Grid
                item
                xs={12}
                sm={4}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 5,
                }}
            >
                <Typography variant={smallScreen ? "h6" : "h5"} component="p" gutterBottom>
                    Participants
                </Typography>
                <Box
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}
                >
                    <List
                        style={{
                            position: "relative",
                            overflow: "auto",
                            maxHeight: 300,
                            marginBottom: 5,
                        }}
                        subheader={<ListSubheader>Participant Names</ListSubheader>}
                    >
                        {details.signedWaiver.participants.map((item, index) => (
                            <ListItem dense={true} key={index}>
                                <ListItemText
                                    primary={
                                        <Box
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                            }}
                                        >
                                            <Typography>{item.participantName}</Typography>
                                        </Box>
                                    }
                                    secondary={
                                        item.birthday && (
                                            <Typography gutterBottom color="textSecondary">
                                                DOB : {item.birthday}
                                            </Typography>
                                        )
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Grid>

            <Grid item sm={8} style={{ marginTop: 5 }}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Box width={"100%"}>
                        <Typography variant={smallScreen ? "h6" : "h5"} component="p" gutterBottom>
                            Video/Image and Signature
                        </Typography>
                    </Box>
                    <Box justifySelf="center" alignItems="center">
                        <Paper elevation={2} style={{ padding: "8px" }}>
                            {details.attachmentUrls.videoUrl === "" ? (
                                details.attachmentUrls.pictureUrl !== "" ? (
                                    <img
                                        src={details.attachmentUrls.pictureUrl}
                                        style={{
                                            display: "flex",
                                            maxWidth: "20rem",
                                            maxHeight: "20rem",
                                            marginTop: 2,
                                        }}
                                        alt="Waiver"
                                    />
                                ) : (
                                    <span> No video/image provided </span>
                                )
                            ) : (
                                <video
                                    src={details.attachmentUrls.videoUrl}
                                    controls
                                    style={{
                                        width: "auto",
                                        height: "auto",
                                        maxHeight: "20rem",
                                        maxWidth: "100%",
                                    }}
                                ></video>
                            )}
                        </Paper>
                    </Box>
                    <Box justifySelf="center" alignItems="center">
                        {details.attachmentUrls.signatureUrl !== "" ? (
                            <img
                                src={details.attachmentUrls.signatureUrl}
                                style={{
                                    display: "flex",
                                    maxWidth: "10rem",
                                    maxHeight: "4rem",
                                    marginTop: 2,
                                }}
                                alt={"Waiver signature"}
                            />
                        ) : (
                            <span>No signature</span>
                        )}
                    </Box>
                </Box>
            </Grid>

            {/*<Grid item xs={12}>*/}
            {/*    <Divider />*/}
            {/*    /!* here goes the AWaiverNotes component *!/*/}
            {/*</Grid>*/}
        </Grid>
    ) : (
        <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            style={{ height: "100%", minHeight: "200px" }}
        >
            <CircularProgress />
        </Box>
    );
};

export default AWaiverDetails;
