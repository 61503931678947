import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Company } from "shared/models/Company";
import { Nullable } from "shared/models/Generals";
interface CompanySlice {
    company: Nullable<Company>;
}
const initialState: CompanySlice = {
    company: null,
};
const slice = createSlice({
    name: "company",
    initialState: initialState,
    reducers: {
        setCompanyStore: (state, param: PayloadAction<Company>) => {
            state.company = param.payload;
        },
    },
});

const companySliceReducer = slice.reducer;
export const { setCompanyStore } = slice.actions;
export default companySliceReducer;
