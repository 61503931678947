// ==============================|| DEFAULT THEME - TYPOGRAPHY  ||============================== //

import { TypographyOptions } from "@mui/material/styles/createTypography";

const Typography = (fontFamily: string): TypographyOptions => ({
    htmlFontSize: 16,
    fontFamily,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
        fontWeight: 600,
        fontSize: "2.375rem",
        lineHeight: 1.21,
    },
    h2: {
        fontWeight: 600,
        fontSize: "1.875rem",
        lineHeight: 1.27,
    },
    h3: {
        fontWeight: 600,
        fontSize: "1.5rem",
        lineHeight: 1.33,
    },
    h4: {
        fontWeight: 600,
        fontSize: "1.25rem",
        lineHeight: 1.4,
    },
    h5: {
        fontWeight: 600,
        fontSize: "1rem",
        lineHeight: 1.5,
    },
    h6: {
        fontWeight: 400,
        fontSize: "0.875rem",
        lineHeight: 1.57,
    },
    caption: {
        fontWeight: 400,
        fontSize: "0.75rem",
        lineHeight: 1.66,
    },
    body1: {
        fontSize: "0.875rem",
        lineHeight: 1.57,
    },
    body2: {
        fontSize: "0.75rem",
        lineHeight: 1.66,
    },
    subtitle1: {
        fontSize: "0.875rem",
        fontWeight: 600,
        lineHeight: 1.57,
    },
    subtitle2: {
        fontSize: "0.75rem",
        fontWeight: 500,
        lineHeight: 1.66,
    },
    overline: {
        lineHeight: 1.66,
    },
    button: {
        textTransform: "capitalize",
    },
    amountSummary: {
        color: "#919EAB",
        fontSize: ".875rem",
        fontWeight: 700,
        lineHeight: "150%",
    },
    // eventLabelTicket: {
    //     color: "#161C24",
    //     fontSize: "20px",
    //     fontWeight: 500,
    //     lineHeight: "150%",
    // },
    // contactInfoLabel: {
    //     color: "var(--grey-900)",
    //     fontSize: "14px",
    //     fontWeight: 500,
    //     lineHeight: "150%" /* 18px */,
    // },
    headerExpandableTitle: {
        // paddingRight: "16px",
        color: "#637381",
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: "150%" /* 21px */,
        letterSpacing: "1.2px",
        textTransform: "uppercase",
    },
    // transactionItemHeader: {
    //     color: "#919EAB",
    //     fontSize: "10px",
    //     fontWeight: 700,
    //     lineHeight: "150%",
    //     letterSpacing: "1px",
    //     textTransform: "uppercase",
    // },
    transactionItem: {
        color: "#212B36",
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "150%",
    },
    inputLabel: {
        color: "var(--grey-900)",
        fontSize: "1rem",
        fontWeight: 500,
        lineHeight: "150%",
    },
    // title: {
    //     fontSize: "1.5rem",
    //     fontWeight: 500,
    //     lineHeight: "150%" /* 36px */,
    // },
});

export default Typography;
