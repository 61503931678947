import { ErrorInfo } from "react";
import { appInsights } from "./TelemetryService";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

class ErrorHandlingService {
    logError(error: Error, info: ErrorInfo) {
        console.error(error);
        appInsights.trackException({ exception: error, severityLevel: SeverityLevel.Critical });
    }
}
const errorHandlingService = new ErrorHandlingService();

export default errorHandlingService;
