import { MuiTelInput, MuiTelInputInfo } from "mui-tel-input";
import React from "react";

// This interface doesn't extends from IBaseQuestionInputProps since onChange and value props are different
export interface IPhoneNumberQuestionProps {
    error: any;
    value: any;
    onChange: (phone: string, info: MuiTelInputInfo) => void;
    onBlur: () => void;
}

export function PhoneNumberQuestion(props: IPhoneNumberQuestionProps) {
    return (
        <MuiTelInput
            value={props.value}
            defaultCountry="US"
            error={props.error}
            onChange={props.onChange}
            onBlur={props.onBlur}
            variant="outlined"
            disableFormatting
            size="small"
        />
    );
}
