import React, { useEffect, useState } from "react";

import { TransactionReservationDetail } from "shared/models/Reservation";
import {
    FormControl,
    InputAdornment,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Typography,
    styled,
    useMediaQuery,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { hasRequiredFieldsAndQuestions } from "shared/business/Rates";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Validation } from "shared/utils/Validation";
import LabelDivider from "./LabelDivider";
import useReservation from "shared/hooks/ReservationHook";
import { useIndexicTheme } from "theme/useIndexicTheme";
import "./Rates.css";
import useNotification, { NotificationsType } from "shared/hooks/useNotification";
import { useReservationModalContext } from "modules/Reservation/context/ReservationModalProvider";

export enum RateOperation {
    UPDATE = "UPDATE",
    ADD = "ADD",
    REMOVE = "REMOVE",
}

export interface IRateSelectionProps {
    readonly rates: TransactionReservationDetail[];
    ticketEdits: Partial<TransactionReservationDetail>[];
    disableRateAmountInput?: boolean;
    useTicketEdits: boolean;
    requiredFields?: number;
    transactions?: boolean;
}
export interface ITicketRates {
    rateId: number;
    tickets: number;
    description: string | null;
    rate: number;
    questionGroupId: number | null;
    readOnly: boolean;
    disabledRules: boolean;
    maxTickets: number;
    onChangeRatePrice: (rateId: number, value: number) => void;
    onChangeRate: (rateId: number, value: number, amount: number, expired?: boolean) => void;
    hideTicketPrice?: boolean;
    expired?: boolean;
}
const Rates = (props: IRateSelectionProps) => {
    const theme = useIndexicTheme();
    const isDownMd = useMediaQuery(theme.breakpoints.down("lg"));
    const [localRates, setLocalRates] = useState<TransactionReservationDetail[]>([]);
    const [selectedRates, setSelectedRates] = useState<TransactionReservationDetail[]>([]);
    const { updateTickets, updateTicketAmount } = useReservation();
    const { updateReservationData } = useReservationModalContext();

    useEffect(() => {
        let filterRates: TransactionReservationDetail[] = [];
        let selectedTickets: TransactionReservationDetail[] = [];
        if (props.useTicketEdits) {
            if (props.ticketEdits.length > 0) {
                props.ticketEdits.forEach((rate) => {
                    let ticketObject = {
                        rateId: rate.rateId!,
                        description: rate.description!,
                        tickets: rate.tickets!,
                        rate: rate.rate!,
                        questionGroupId: rate.questionGroupId!,
                        expired: rate.expired,
                    };
                    if (rate.tickets === 0) {
                        filterRates.push(ticketObject);
                    } else {
                        selectedTickets.push(ticketObject);
                    }
                });
            } else {
                props.rates.forEach((rate) => {
                    let ticketObject = {
                        rateId: rate.rateId!,
                        description: rate.description!,
                        tickets: 0,
                        rate: rate.rate!,
                        questionGroupId: rate.questionGroupId!,
                        expired: rate.expired,
                    };
                    filterRates.push(ticketObject);
                });
            }
            setLocalRates(
                props.rates.filter((r) => !selectedTickets.some((s) => s.rateId === r.rateId)),
            );
            setSelectedRates(selectedTickets);
        } else {
            props.rates.forEach((rate) => {
                //EventList can change a event date time and that rates can change and this condition helps to know if there are selected rates previous to the change of DT
                const exists = selectedRates.find((x) => x.rateId === rate.rateId);
                if (exists) {
                    setSelectedRates(
                        selectedRates.map((item) => {
                            if (item.rateId === exists.rateId) {
                                item.rate = rate.rate;
                            }
                            return item;
                        }),
                    );
                } else {
                    filterRates.push(rate);
                }
            });
            setLocalRates(filterRates);
            if (props.ticketEdits.length === 0) {
                setSelectedRates([]);
            } else {
                setSelectedRates(
                    selectedRates.filter((selected) =>
                        props.ticketEdits.some((ticket) => ticket.rateId === selected.rateId),
                    ),
                );
            }
        }
        // eslint-disable-next-line
    }, [props.rates, props.ticketEdits]);

    const handleSelectedRate = (ev: SelectChangeEvent) => {
        const value = `${ev.target.value}`;
        setLocalRates(localRates.filter((x) => `${x.rateId}` !== value));

        if (!selectedRates.some((x) => `${x.rateId}` === value)) {
            const ticket = localRates.find((x) => `${x.rateId}` === value);
            if (ticket) {
                setSelectedRates(
                    selectedRates.concat({
                        rateId: ticket.rateId,
                        description: ticket.description,
                        tickets: ticket.tickets === 0 ? 1 : 1,
                        rate: ticket.rate,
                        questionGroupId: ticket.questionGroupId,
                    }),
                );
            }
        }
    };

    const onChangeRate = (rateId: number, count: number, amount: number, expired?: boolean) => {
        if (expired) {
            setSelectedRates(selectedRates.filter((x) => x.rateId !== rateId));
            updateTickets(rateId, amount, count);
        } else {
            const rate = props.rates.find((x) => x.rateId === rateId);
            if (rate && count === 0) {
                setLocalRates(localRates.concat(rate));
                setSelectedRates(selectedRates.filter((x) => x.rateId !== rateId));
            }
            if (rate) {
                updateTickets(rate.rateId, amount, count);
            }
        }
    };

    const onChangeRatePrice = (rateId: number, amount: number) => {
        setSelectedRates(
            selectedRates.map((item) => {
                if (item.rateId === rateId) {
                    item.rate = amount;
                }
                return item;
            }),
        );
        updateTicketAmount(rateId, amount);
    };

    return (
        <Grid container>
            <Grid xs={12}>
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: isDownMd ? "repeat(auto-fill, 100%)" : "1fr 1fr",
                        gridTemplateRows: "1fr",
                        gap: 5,
                        gridTemplateAreas: ". .",
                        marginBottom: 10,
                    }}
                >
                    {selectedRates.map((item: TransactionReservationDetail, index: number) => (
                        <RateSelected
                            key={item.rateId}
                            rateId={item.rateId}
                            tickets={item.tickets}
                            description={item.description}
                            maxTickets={-1}
                            rate={item.rate}
                            questionGroupId={item.questionGroupId ?? null}
                            readOnly={props.disableRateAmountInput ?? Boolean(props.transactions)}
                            disabledRules={hasRequiredFieldsAndQuestions(
                                props.requiredFields ?? null,
                                item.questionGroupId ?? null,
                            )}
                            onChangeRatePrice={onChangeRatePrice}
                            onChangeRate={onChangeRate}
                            expired={item.expired}
                        />
                    ))}
                </div>
                {selectedRates.some((x) => x.expired) && (
                    <span style={{ color: "red", marginTop: 5 }}>
                        *Rate currently not available
                    </span>
                )}
            </Grid>
            {localRates.length > 0 && (
                <LabelDivider justify="end">
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="headerExpandableTitle">Add more tickets:</Typography>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small" hiddenLabel>
                            <Select
                                value="0"
                                onChange={handleSelectedRate}
                                name="range"
                                inputProps={{ "aria-label": "age" }}
                                fullWidth
                            >
                                <MenuItem value="0">Select rate</MenuItem>
                                {localRates
                                    .filter((x) => !x.expired)
                                    .map((options: any, index) => (
                                        <MenuItem key={index} value={options.rateId}>
                                            {options.description}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </div>
                </LabelDivider>
            )}
        </Grid>
    );
};
export const RateSelected = (props: ITicketRates) => {
    const [amount, setAmount] = useState<number>(props.rate);
    const [displayAmount, setdisplayAmount] = useState(props.rate.toFixed(2));
    const [quantity, setQuantity] = useState(props.tickets);
    const { showNotification } = useNotification();

    useEffect(() => {
        if (quantity > 0) {
            props.onChangeRate(props.rateId, quantity, amount);
        }
        // eslint-disable-next-line
    }, [quantity]);

    useEffect(() => {
        setAmount(props.rate);
    }, [props.rate]);

    useEffect(() => {
        setQuantity(props.tickets);
    }, [props.tickets]);

    const onChangeAmount = (value: string) => {
        const safeValue = Number(value) ? Number(value) : 0;
        props.onChangeRatePrice(props.rateId, safeValue);
        setAmount(safeValue);
        setdisplayAmount(value);
    };

    const onChangeQuantity = (value: number) => {
        if (Validation.isNumericValue(String(value))) {
            setQuantity(value);
            if (value === 0) {
                props.onChangeRate(props.rateId, value, amount, props.expired);
            }
        }
    };

    return (
        <React.Fragment>
            <div
                className="rate"
                data-cy-rate={props.description}
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 5,
                }}
            >
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div className="quantity">
                        <button
                            onClick={() =>
                                props.tickets > 0 && !props.disabledRules
                                    ? onChangeQuantity(props.tickets - 1)
                                    : showNotification({
                                          type: NotificationsType.warning,
                                          message:
                                              "This ticket must be removed from ticket person card",
                                      })
                            }
                        >
                            <svg
                                fill="none"
                                viewBox="0 0 24 24"
                                height="14"
                                width="14"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinejoin="round"
                                    strokeLinecap="round"
                                    strokeWidth="2.5"
                                    stroke="#47484b"
                                    d="M20 12L4 12"
                                ></path>
                            </svg>
                        </button>
                        <label>{props.tickets}</label>
                        <button
                            onClick={() =>
                                !props.expired
                                    ? props.maxTickets === -1
                                        ? onChangeQuantity(props.tickets + 1)
                                        : props.tickets < props.maxTickets
                                          ? onChangeQuantity(props.tickets + 1)
                                          : {}
                                    : {}
                            }
                        >
                            <svg
                                fill="none"
                                viewBox="0 0 24 24"
                                height="14"
                                width="14"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinejoin="round"
                                    strokeLinecap="round"
                                    strokeWidth="2.5"
                                    stroke="#47484b"
                                    d="M12 4V20M20 12H4"
                                ></path>
                            </svg>
                        </button>
                    </div>
                    <Typography
                        sx={{
                            fontSize: "clamp(0.6rem, 0.9774rem + 2.7936vw,0.9rem)",
                            marginLeft: "0.5rem",
                            color: props.expired ? "red" : "none",
                        }}
                    >
                        {props.description}
                    </Typography>
                </div>
                {!props.hideTicketPrice ? (
                    <div>
                        <RateTextField
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            notched={false}
                            type="number"
                            value={displayAmount}
                            onChange={(ev) => onChangeAmount(ev.target.value)}
                            onFocus={(e) => e.target.select()}
                            onBlur={() => setdisplayAmount(amount.toFixed(2))}
                            disabled={props.readOnly || props.expired}
                        />
                    </div>
                ) : null}
            </div>
        </React.Fragment>
    );
};

const RateTextField = styled(OutlinedInput)({
    "& .MuiInputBase-input": {
        width: 70,
        height: 36,
        padding: "0 0 0 5px",
        borderRadius: 5,
        outline: "none",
        filter: "drop-shadow(0px 1px 0px #efefef) drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5))",
        transition: "all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1)",
        fontSize: 15,
        fontWeight: 700,
        color: "#47484b",
    },
});
export default Rates;
