import { RequiredFieldsEnum } from "shared/models/RequiredFields";
import { TicketPerson } from "shared/models/Reservation";
import { isValidPhoneNumber } from "libphonenumber-js";
import { isEmpty } from "shared/utils/Common";
export const validateRequiredFields = (ticket: TicketPerson, requiredFields: number): boolean => {
    const hasRequired = hasRequiredField(requiredFields);
    let isValid = true;
    if (hasRequired(RequiredFieldsEnum.Name)) {
        isValid = isValid && isValidName(ticket);
    }
    if (hasRequired(RequiredFieldsEnum.Phone)) {
        isValid = isValid && isValidPhone(ticket);
    }
    if (hasRequired(RequiredFieldsEnum.Weight)) {
        isValid = isValid && isValidWeight(ticket);
    }
    if (hasRequired(RequiredFieldsEnum.Height)) {
        isValid = isValid && isValidHeight(ticket);
    }
    return isValid;
};

export const hasRequiredField =
    (requiredFields: number) =>
    (requiredEnum: RequiredFieldsEnum): boolean => {
        return (requiredFields & requiredEnum) > 0;
    };

export const isValidName = (ticket: TicketPerson): boolean => {
    return !(isEmpty(ticket.participantFirstName) || isEmpty(ticket.participantLastName));
};

export const isValidPhone = (ticket: TicketPerson): boolean => {
    if (ticket.participantPhone) {
        return isValidPhoneNumber(ticket.participantPhone);
    }
    return false;
};
export const isValidWeight = (ticket: TicketPerson): boolean => {
    const weight = Number(ticket.weight);
    if (isNaN(weight)) {
        return false;
    }
    return weight >= 1 && weight <= 250;
};
export const isValidHeight = (ticket: TicketPerson): boolean => {
    const height = Number(ticket.height);
    if (isNaN(height)) {
        return false;
    }
    return height >= 0.1 && height <= 3;
};
export const hasRequiredFields = (requiredFields: number): boolean => {
    return (
        (requiredFields & RequiredFieldsEnum.Name) > 0 ||
        (requiredFields & RequiredFieldsEnum.Height) > 0 ||
        (requiredFields & RequiredFieldsEnum.Weight) > 0 ||
        (requiredFields & RequiredFieldsEnum.Phone) > 0
    );
};
