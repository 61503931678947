import PropTypes from "prop-types";
import React, { useMemo, PropsWithChildren } from "react";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { createTheme, ThemeProvider, ThemeOptions } from "@mui/material/styles";
import Palette from "./palette";
import Typography from "./typography";
import CustomShadows from "./shadows";
import componentsOverride from "./overrides/ComponentsOverrides";

declare module "@mui/material/styles" {
    interface TypographyVariants {
        amountSummary: React.CSSProperties;
        // tableContentCard: React.CSSProperties;
        // eventLabelTicket: React.CSSProperties;
        // tableContentTotalCard: React.CSSProperties;
        // contactInfoLabel: React.CSSProperties;
        headerExpandableTitle: React.CSSProperties;
        idReservation: React.CSSProperties;
        reservationFullName: React.CSSProperties;
        commentContent: React.CSSProperties;
        // transactionItemHeader: React.CSSProperties;
        transactionItem: React.CSSProperties;
        inputLabel: React.CSSProperties;
        // title: React.CSSProperties;
        // headerLabel: React.CSSProperties;
        eventListHeader: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        amountSummary?: React.CSSProperties;
        // tableContentCard?: React.CSSProperties;
        // eventLabelTicket?: React.CSSProperties;
        // tableContentTotalCard?: React.CSSProperties;
        // contactInfoLabel?: React.CSSProperties;
        headerExpandableTitle?: React.CSSProperties;
        idReservation?: React.CSSProperties;
        reservationFullName?: React.CSSProperties;
        commentContent?: React.CSSProperties;
        // transactionItemHeader?: React.CSSProperties;
        transactionItem?: React.CSSProperties;
        inputLabel?: React.CSSProperties;
        // title?: React.CSSProperties;
        // headerLabel?: React.CSSProperties;
        // eventListHeader?: React.CSSProperties;
    }
    interface Theme {
        customShadows: {
            button: string;
            text: string;
            z1: string;
        };
        eventColorButton: React.CSSProperties;
        calendarEvent: {
            expand: React.CSSProperties;
            expandOpen: React.CSSProperties;
            card: React.CSSProperties;
        };
    }
}

export default function IndexicTheme({ children }: PropsWithChildren) {
    const palette = Palette("light");

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const themeTypography = Typography(`'Inter', sans-serif`);
    const themeCustomShadows = useMemo(() => CustomShadows(palette), [palette]);

    const themeOptions: ThemeOptions = useMemo(
        () => ({
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 768,
                    md: 1024,
                    lg: 1266,
                    xl: 1536,
                },
            },
            direction: "ltr",
            mixins: {
                toolbar: {
                    minHeight: 60,
                    paddingTop: 8,
                    paddingBottom: 8,
                },
            },
            backdrop: {
                zIndex: palette.zIndex.drawer + 1,
                color: "#fff",
            },
            eventColorButton: {
                margin: "2px",
                padding: "5px 15px",
                cursor: "pointer",
                borderRadius: "4px",
                borderColor: "transparent",
                width: "7em",
                "&:hover": {
                    opacity: "0.5",
                },
            },
            calendarEvent: {
                expand: {
                    transform: "rotate(0deg)",
                    marginLeft: "auto",
                    transition: palette.transitions.create("transform", {
                        duration: palette.transitions.duration.shortest,
                    }),
                },
                expandOpen: {
                    transform: "rotate(180deg)",
                },
                card: {
                    backgroundColor: "white",
                    maxHeight: "55px !important",
                    padding: "8px",
                },
            },
            palette: palette.palette,
            customShadows: themeCustomShadows,
            typography: themeTypography,
            // transitions: Transitions, Todo: Check template since Transitions its a component and shouldn't be on the theme
        }),
        [palette, themeTypography, themeCustomShadows],
    );
    const theme = createTheme(themeOptions);
    theme.components = componentsOverride(theme);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

IndexicTheme.propTypes = {
    children: PropTypes.node,
};
