import {
    FormControl,
    FormLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
} from "@mui/material";
import { CompanyPreferences, EventGroupFilter, StartPage } from "shared/models/Creds";
import { useUserSettingsContext } from "./UserSettingsContext";
import UserCalendarSection from "./UserCalendarSection";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useQuery } from "@tanstack/react-query";
import EventsService from "../../shared/services/EventsService";

const defaultCompanyPreferences = {
    defaultPage: 0,
    defaultEventGroupId: 0,
    resourceCalendarDefaultTypes: [],
    resourceCalendarDefaultView: null,
};

const UserGeneralSection = () => {
    const { companyUser, updateCompanyPreferences } = useUserSettingsContext();
    const summariesQuery = useQuery(["portal-summaries"], () => EventsService.getPortalSummaries());

    let preferences: CompanyPreferences;
    if (companyUser.companyPreferences) {
        preferences = companyUser.companyPreferences;
    } else {
        preferences = defaultCompanyPreferences;
    }
    const handleChange = (event: SelectChangeEvent) => {
        let { name, value } = event.target;
        const data = { [name]: Number(value) };
        updateCompanyPreferences(data as Partial<CompanyPreferences>);
    };
    return (
        <div>
            <Typography variant="h5" gutterBottom>
                General
            </Typography>
            <Grid container spacing={2}>
                <Grid xs={12}>
                    <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend">Starting page</FormLabel>
                        <Select
                            value={`${preferences.defaultPage}`}
                            name="defaultPage"
                            onChange={handleChange}
                            size="small"
                        >
                            <MenuItem value={StartPage.Calendar}>Calendar</MenuItem>
                            <MenuItem value={StartPage.EventList}>Event List</MenuItem>

                            <MenuItem disabled value={StartPage.ReservationReport}>
                                Reservation report (coming soon)
                            </MenuItem>
                            <MenuItem disabled value={StartPage.ResourceCalendar}>
                                Resource calendar (coming soon)
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={12}>
                    <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend">Show events in group</FormLabel>
                        <Select
                            value={`${preferences.defaultEventGroupId}`}
                            name="defaultEventGroupId"
                            onChange={handleChange}
                            size="small"
                        >
                            {summariesQuery.data ? (
                                summariesQuery.data.eventGroups.map((el, idx) => (
                                    <MenuItem key={idx} value={el.eventGroupId}>
                                        {el.eventGroupName}
                                    </MenuItem>
                                ))
                            ) : (
                                <>
                                    <MenuItem value={EventGroupFilter.AllEvents}>
                                        All events
                                    </MenuItem>
                                    <MenuItem value={EventGroupFilter.Partner}>
                                        Partner events
                                    </MenuItem>
                                    <MenuItem value={EventGroupFilter.JustCompany}>
                                        Company events
                                    </MenuItem>
                                </>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <UserCalendarSection />
        </div>
    );
};

export default UserGeneralSection;
