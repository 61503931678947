import React, { FunctionComponent, useState } from "react";
import { IosShareOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useShoppingCartSlice } from "store/ShoppingCart/useShoppingCartSlice";
import { SendCartButtonDialog } from "./SendCartButtonDialog";

interface SendCartButtonProps {}

export const SendCartButton: FunctionComponent<SendCartButtonProps> = () => {
    const { cart } = useShoppingCartSlice();
    const [open, setOpen] = useState(false);
    const onCartSend = () => {
        setOpen(false);
    };
    const onCartCancel = () => {
        setOpen(false);
    };

    return (
        <>
            <Button
                variant={"text"}
                startIcon={<IosShareOutlined />}
                size={"small"}
                sx={{
                    border: "1px solid var(--grey-400)",
                    background: "white",
                }}
                disabled={cart?.isEmpty}
                onClick={() => setOpen(true)}
            >
                Send cart
            </Button>
            <SendCartButtonDialog
                open={open}
                onCartSendSuccess={onCartSend}
                onCartCancel={onCartCancel}
            />
        </>
    );
};
