import * as React from "react";
import { Menu, MenuItem, Divider, useMediaQuery, Theme } from "@mui/material";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import OutlineBadgeButton from "layouts/Header/HeaderContent/components/OutlineBadgeButton";
import { useCredentials } from "store/Credentials/CredencialHook";
import { useNavigate } from "react-router-dom";
import { logout } from "shared/auth/MSALAuth";
import Package from "../../../../package.json";

export default function UserProfile() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { credentialsState } = useCredentials();
    const navigate = useNavigate();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    if (!credentialsState.credentials) {
        return (
            <OutlineBadgeButton icon={<PersonOutlinedIcon />} badgeContent={0}></OutlineBadgeButton>
        );
    }
    return (
        <div>
            <OutlineBadgeButton
                icon={<PersonOutlinedIcon />}
                badgeContent={0}
                onClick={handleClick}
            >
                {!isMobile
                    ? credentialsState.credentials.lastName +
                      "," +
                      credentialsState.credentials.firstName
                    : null}
            </OutlineBadgeButton>
            <Menu
                id="user-profile-menu"
                MenuListProps={{
                    "aria-labelledby": "user-profile",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {!credentialsState.credentials.systemAdmin ? (
                    <div>
                        <MenuItem
                            onClick={() => {
                                handleClose();
                                navigate("/preferences");
                            }}
                        >
                            Preferences
                        </MenuItem>
                        <Divider />
                    </div>
                ) : null}
                <MenuItem
                    onClick={() => {
                        handleClose();
                        logout();
                    }}
                >
                    Logout ({Package.version})
                </MenuItem>
            </Menu>
        </div>
    );
}
