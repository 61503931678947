import { Fade, LinearProgress, Typography } from "@mui/material";
import React, { FunctionComponent, PropsWithChildren } from "react";
import { useShoppingCartSlice } from "../../store/ShoppingCart/useShoppingCartSlice";
import ReservationItemCart from "./components/ReservationItemCart";
import { CartDTO } from "shared/models/Cart";
import "./ShoppingCart.css";
import { MathEx } from "../../shared/utils/MathEx";
import CartUtils from "../Checkout/CartUtils";
import { ContinueCheckoutButton } from "./components/ContinueCheckoutButton";
import { EmptyCartButton } from "./components/EmptyCartButton";
import { SendCartButton } from "./components/SendCartButton/SendCartButton";
import { ShoppingCartButtonGroup } from "./components/ShoppingCartButtonGroup/ShoppingCartButtonGroup";
import { CatalogItemCart } from "./components/CatalogItemCart";
import { useMeasureElement } from "../../shared/hooks/useMeasureElement";
import { EmptyShoppingCartIndicator } from "../Checkout/components/EmptyShoppingCartIndicator";
import GiftCardRecipientList from "./GiftCardRecipient/GiftCardRecipientList";
import { AppliedGiftCards } from "./components/AppliedGiftCards";
import { Each } from "../../shared/components/Each";
import { ErrorAlert, WarningAlert } from "../../shared/components/Alerts";

export const ShoppingCart: FunctionComponent = () => {
    const { shoppingCartState } = useShoppingCartSlice();
    const [ref, measures] = useMeasureElement<HTMLDivElement>([shoppingCartState.cart]);
    const cart = shoppingCartState.cart;
    if (cart === null) {
        //TODO: change for skeleton
        return null;
    }
    return (
        <div className={"cart-wrapper"}>
            <div
                style={{
                    marginBottom: "1.5rem",
                    height: `calc(100vh - ${measures?.height ?? 0}px - 2rem - 37px)`,
                    overflow: "auto",
                    padding: "0 1rem",
                }}
            >
                <Fade in={shoppingCartState.status === "pending"}>
                    <LinearProgress />
                </Fade>
                {cart.isEmpty ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "1.5rem 0 ",
                        }}
                    >
                        <EmptyShoppingCartIndicator />
                    </div>
                ) : null}
                {cart.reservations.map((el) => (
                    <ReservationItemCart
                        key={el.reservationId}
                        reservation={el}
                        totalCartDiscount={shoppingCartState.cart?.totalCartDiscount || 0}
                    />
                ))}
                {cart.catalogItems.map((el, idx) => (
                    <CatalogItemCart item={el} key={idx} />
                ))}
                <AppliedGiftCards giftCards={cart.appliedGiftCards} />
            </div>
            <div ref={ref}>
                <Fade in={cart.warnings.length > 0}>
                    <div style={{ padding: "0.5rem" }}>
                        <Each
                            of={cart.warnings}
                            render={(t, idx) => <WarningAlert key={idx}>{t}</WarningAlert>}
                        />
                    </div>
                </Fade>
                <Fade in={cart.errors.length > 0}>
                    <div style={{ padding: "0.5rem" }}>
                        <Each
                            of={cart.errors}
                            render={(t, idx) => <ErrorAlert key={idx}>{t}</ErrorAlert>}
                        />
                    </div>
                </Fade>
                <div style={{ marginBottom: "0.5rem" }}>
                    <ShoppingCartButtonGroup />
                </div>
                <div style={{ marginBottom: "0.5rem" }}>
                    <TotalsTable cart={cart} />
                </div>
                <div className={"cart-footer"}>
                    <ContinueCheckoutButton />
                    <div style={{ flex: 1 }}></div>
                    <SendCartButton />
                    <EmptyCartButton />
                </div>
            </div>
            <GiftCardRecipientList />
        </div>
    );
};

interface TotalsTableProps {
    cart: CartDTO;
}

const TotalsTable = (props: TotalsTableProps) => {
    const cart = props.cart;
    const taxAndFees = CartUtils.calculateCartTaxesAndFees(cart);
    const appliedGiftCards = CartUtils.calculateGiftCardTotal(cart);
    const total = CartUtils.calculateCartTotal(cart);
    const discount = CartUtils.calculateDiscount(cart);

    const remainingBalance =
        cart?.depositBalance && cart?.depositBalance >= 0 ? cart?.depositBalance : -1;

    return (
        <table style={{ borderSpacing: "0.5rem 0" }}>
            <tbody>
            <PaymentRow amount={discount}>DISCOUNT</PaymentRow>
            <PaymentRow amount={cart.subTotal} showZeroAmount={true}>
                SUBTOTAL
            </PaymentRow>
            <PaymentRow amount={taxAndFees} showZeroAmount={true}>
                TAXES & FEES
            </PaymentRow>
            <PaymentRow amount={appliedGiftCards} showAsNegative={true}>
                GIFT CARDS
            </PaymentRow>
            <PaymentRow amount={remainingBalance}>REMAINING BALANCE</PaymentRow>
            <PaymentRow amount={total} mainRow={true} showZeroAmount={true}>
                TOTAL DUE TODAY
            </PaymentRow>
            </tbody>
        </table>
    );
};

interface PaymentRowProps {
    amount: number;
    mainRow?: boolean;
    showZeroAmount?: boolean;
    showAsNegative?: boolean;
}

const PaymentRow: FunctionComponent<PropsWithChildren<PaymentRowProps>> = (props) => {
    if (!props.showZeroAmount && props.amount <= 0) {
        return null;
    }
    return (
        <tr style={{ gap: "1rem" }}>
            <td>
                <Typography
                    variant={"headerExpandableTitle"}
                    sx={{
                        color: (theme) =>
                            props.mainRow ? theme.palette.primary.main : theme.palette.grey["600"],
                    }}
                >
                    {props.children}
                </Typography>
            </td>
            <td style={{ textAlign: "right" }}>
                <Typography
                    sx={{
                        fontWeight: props.mainRow ? "600" : "500",
                        fontSize: "0.875rem",
                        color: (theme) =>
                            props.mainRow ? theme.palette.primary.main : theme.palette.grey["600"],
                    }}
                >
                    {MathEx.formatCurrency(props.showAsNegative ? -1 * props.amount : props.amount)}
                </Typography>
            </td>
        </tr>
    );
};
