import { OverrideComponent } from "../interfaces";
// import React from "react";
// TODO: verify if work without this
// declare module "@mui/material/styles" {
//     interface ButtonVariants {
//         refundBtn: React.CSSProperties;
//     }
//     interface ButtonVariantsOptions {
//         refundBtn?: React.CSSProperties;
//     }
// }
declare module "@mui/material/Button" {
    interface DividerPropsVariantOverrides {}
}
const DividerOverrides: OverrideComponent<"MuiDivider"> = (theme) => {
    return {
        MuiDivider: {
            styleOverrides: {
                root: {
                    color: theme.palette.grey[400],
                    borderColor: theme.palette.grey[400],
                },
            },
            variants: [
                {
                    props: { light: true },
                    style: {
                        color: theme.palette.grey[300],
                        borderColor: theme.palette.grey[300],
                    },
                },
            ],
        },
    };
};

export default DividerOverrides;
