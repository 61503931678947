import {
    DataGridPro,
    gridClasses,
    DataGridProProps,
    GridValidRowModel,
} from "@mui/x-data-grid-pro";
import React from "react";
import { SxProps, Theme } from "@mui/material";
import { CustomNoRowsOverlay } from "../NoDataComponent";

export const ReportDataTable = <T extends GridValidRowModel>(props: DataGridProProps<T>) => {
    const { sx, slots, ...forwardedProps } = props;
    let customSx: SxProps<Theme> = {
        background: "white",
        fontSize: "0.875rem",
        minHeight: 650,
        [`& .${gridClasses.pinnedColumns}`]: {
            background: "white",
        },
        [`&  .${gridClasses.pinnedRows} .${gridClasses.pinnedColumns}`]: {
            background: "inherit",
        },
        [`& .${gridClasses.columnHeaderTitle}`]: {
            fontWeight: 600,
        },
        [`& .${gridClasses.columnHeaderTitleContainerContent}`]: {
            fontWeight: 600,
        },
        [`& .${gridClasses.columnHeader}`]: {
            backgroundColor: (theme) => theme.palette.background.default,
        },
        "--DataGrid-overlayHeight": "300px",
    };
    if (sx) {
        customSx = { ...customSx, ...sx };
    }
    let customSlots: DataGridProProps["slots"] = {
        noRowsOverlay: CustomNoRowsOverlay,
    };
    if (slots) {
        customSlots = { ...customSlots, ...slots };
    }
    return <DataGridPro slots={customSlots} sx={customSx} {...forwardedProps} />;
};
