import React, { FunctionComponent, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import CompanyService from "../../../shared/services/portal/CompanyService";
import { useSearchableItem } from "../../../shared/hooks/useSearchableItem";
import { Coupon } from "../../../shared/models/Coupon";
import {
    Fade,
    InputAdornment,
    LinearProgress,
    TextField,
    TextFieldProps,
    Typography,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { useMeasureElement } from "../../../shared/hooks/useMeasureElement";
import styles from "./Coupons.module.css";
import { FlatButton } from "../../../shared/components/FlatButton";
import { useShoppingCartSlice } from "../../../store/ShoppingCart/useShoppingCartSlice";
import { getShoppingCartNotifications } from "../ShoppingCartNotifications";
import useNotification from "../../../shared/hooks/useNotification";

const createSearchableKey = (el: Coupon) => {
    return `${(el.couponCode ?? "").toLowerCase()}-${el.comment?.toLowerCase()}`;
};

export const CouponSearch: FunctionComponent = () => {
    const query = useQuery(["coupons"], () => CompanyService.getCoupons(), {
        placeholderData: [],
    });
    const { applyCoupon } = useShoppingCartSlice();
    const { showNotification } = useNotification();
    const { filteredItems, searchItems, setData } = useSearchableItem<Coupon>(createSearchableKey);
    const [ref, measures] = useMeasureElement<HTMLDivElement>([]);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (query.isSuccess && query.data && query.data?.length > 0) {
            setData(query.data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query.isSuccess, query.data]);
    const onSearchChange: TextFieldProps["onChange"] = (e) => {
        const value = e.target.value;
        setSearch(value);
        searchItems(value);
    };
    const occupiedHeight = measures?.bottom ?? 0;
    // const occupiedHeight = (measures?.bottom ?? 0) + props.wrapperHeight;
    const applyCouponNotifications = getShoppingCartNotifications("ApplyCoupon");
    const handleAddCoupon = async (couponCode: string) => {
        if (couponCode !== "") {
            setLoading(true);
            try {
                await applyCoupon(couponCode);
                showNotification(applyCouponNotifications.success);
            } catch (e) {
                showNotification(applyCouponNotifications.error);
            } finally {
                setLoading(false);
            }
        }
    };
    return (
        <div>
            <div
                style={{
                    padding: "0.5rem 1rem 0 1rem",
                }}
                ref={ref}
            >
                <Typography
                    variant={"inputLabel"}
                    fontSize={"1.125rem"}
                    style={{ marginBottom: "0.5rem" }}
                >
                    All available coupons
                </Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    value={search}
                    size="small"
                    id="header-search"
                    onChange={onSearchChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search sx={{ color: (theme) => theme.palette.grey["700"] }} />
                            </InputAdornment>
                        ),
                    }}
                    onFocus={(e) => e.target.select()}
                    placeholder="Search merchandise items"
                    sx={{
                        borderRadius: "0.5rem",
                        borderColor: (theme) => theme.palette.grey["400"],
                        color: (theme) => theme.palette.grey["600"],
                        marginBottom: "0.5rem",
                    }}
                />
                <Fade in={query.isFetching || loading}>
                    <LinearProgress />
                </Fade>
            </div>
            <div
                className={styles.couponSearchContainer}
                style={{
                    height: `calc(100vh - ${occupiedHeight}px)`,
                }}
            >
                <div className={styles.couponSearchGrid}>
                    <Typography variant={"headerExpandableTitle"}>code</Typography>
                    <Typography variant={"headerExpandableTitle"}>name</Typography>
                    <div></div>
                    {filteredItems.map((el, idx) => (
                        <CouponItemEntry key={idx} data={el} onApplyCoupon={handleAddCoupon} />
                    ))}
                </div>
            </div>
        </div>
    );
};

interface CouponItemEntryProps {
    data: Coupon;
    onApplyCoupon: (code: string) => void;
}
const CouponItemEntry = (props: CouponItemEntryProps) => {
    return (
        <>
            <Typography variant={"inputLabel"}>{props.data.couponCode}</Typography>
            <Typography variant={"inputLabel"}>{props.data.name}</Typography>
            <FlatButton
                color={"primary"}
                onClick={() => props.onApplyCoupon(props.data.couponCode)}
            >
                Apply
            </FlatButton>
        </>
    );
};
