import React from "react";
import { styled, Tab, TabProps, Tabs, TabsProps, tabsClasses, tabClasses } from "@mui/material";
import { alpha } from "@mui/material/styles";

export const SectionTabContainer = styled(Tabs, {
    shouldForwardProp: (propKey) => propKey !== "widthPercentage",
})<TabsProps & { widthPercentage?: number }>(({ theme, widthPercentage }) => ({
    background: theme.palette.grey[300],
    borderRadius: 8,
    display: "inline-flex",
    minHeight: "36px",
    padding: "0 0.5rem",
    minWidth: widthPercentage ? `${widthPercentage}%` : 0,
    [`& .${tabsClasses.indicator}`]: {
        background: "white",
        height: "calc(100% - 8px)",
        top: 0,
        transform: "translateY(4px)",
        borderRadius: 8,
        boxShadow: 2,
    },
    [`& .${tabClasses.textColorPrimary}`]: {
        color: theme.palette.grey[900],
    },
    "& .Mui-focusVisible": {
        background: `${alpha(theme.palette.grey[900], 0.15)}`,
        borderRadius: 8,
    },
    [`& .${tabsClasses.flexContainer}`]: {
        justifyContent: "center",
    },
}));

export const SectionTab = (props: TabProps) => {
    const { disableRipple, sx: customSx, ...forwardedProps } = props;
    return (
        <Tab
            disableRipple={true}
            sx={{
                zIndex: 3,
                ...customSx,
            }}
            {...forwardedProps}
        ></Tab>
    );
};
