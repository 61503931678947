import { HttpService, createUrl } from "../http/http";
import { UserCredentials } from "../models/Creds";
import { ValidateSchema } from "../utils/ZodValidations";
import { UserCredentialsSchema } from "../models/Creds.schema";
import { ICompanyUser } from "shared/models/Company";
import { CompanyUserSchema } from "shared/models/Company.schema";

class CredentialsService extends HttpService {
    constructor() {
        super("/portal");
    }

    async loadUserCredentials() {
        const result = await this.get<UserCredentials>("Creds");
        // Authenticated Users that are Inactive or have no Company
        if (result.status === 204) {
            const noCompanyResult: UserCredentials = {
                companyID: -1,
                firstName: "",
                lastName: "",
                oid: "Possible Invite",
                systemAdmin: false,
                companies: [],
                preferences: null,
                companyPreferences: null,
            };

            return noCompanyResult;
        }

        return ValidateSchema<UserCredentials>(result.data, UserCredentialsSchema);
    }

    async acceptCompanyInvite(inviteId: string) {
        const uri = createUrl(["User/Invite/Accept"]);
        try {
            const result = await this.post<ICompanyUser>(
                uri,
                "FFBE19D5-49D7-4919-86B4-0413BDBDD54F",
            );

            return ValidateSchema<ICompanyUser>(result.data, CompanyUserSchema);
        } catch (error) {}
    }
}

const credentialsService = new CredentialsService();

export default credentialsService;
