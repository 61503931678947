import React from "react";
import { Alert, AlertColor, Snackbar, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "store/Store";
import { clearNotification } from "store/Notifications";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const NotificationSnackbar = () => {
    const dispatch = useAppDispatch();
    const { message, type, open, duration } = useAppSelector((store) => store.notifications);

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }
        dispatch(clearNotification());
    };
    return (
        <Snackbar
            open={open}
            autoHideDuration={duration ?? 6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
            <Alert
                variant="filled"
                onClose={handleClose}
                severity={type as AlertColor}
                sx={{ width: "100%", pb: 0, pt: 0 }}
            >
                <Typography component="span" variant="inputLabel" sx={{ color: "white" }}>
                    <ReactMarkdown children={message} rehypePlugins={[rehypeRaw]} />
                </Typography>
            </Alert>
        </Snackbar>
    );
};

export default NotificationSnackbar;
