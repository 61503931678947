import React, { FunctionComponent, useState } from "react";
import DialogComponent, { DialogHooks } from "../../../shared/components/DialogComponent";
import { IconActionButton } from "../../../shared/components/IconActionButton";
import { Unsubscribe } from "@mui/icons-material";
import EmailsReminders from "../../../shared/components/EmailsReminders";
import { ReservationNotified } from "../../../shared/models/Reservation";
import reservationService from "../../../shared/services/ReservationService";
import useNotification, { NotificationsType } from "../../../shared/hooks/useNotification";
import { Fade, LinearProgress } from "@mui/material";

interface FollowUpProps {
    reservationNotified: number;
    reservationId: number;
    afterSaveCallback?: CallableFunction;
}

export const FollowUp: FunctionComponent<FollowUpProps> = (props) => {
    const [openDialog, handleClickOpen, handleCloseDialog] = DialogHooks();
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(false);
    const [noFollowUp, setNoFollowUp] = useState(
        (props.reservationNotified & ReservationNotified.StopFollowUpEmail) > 0,
    );
    const [noReminders, setNoReminders] = useState(
        (props.reservationNotified & ReservationNotified.StopReminders) > 0,
    );
    const handleSuccess = async () => {
        setLoading(true);
        try {
            await reservationService.setRemindersFollowUp(
                props.reservationId,
                noFollowUp,
                noReminders,
            );
            if (props.afterSaveCallback) {
                props.afterSaveCallback();
            }
            showNotification({ type: NotificationsType.success, message: "Settings updated" });
        } catch {
            showNotification({
                type: NotificationsType.error,
                message: "Error updating the settings",
            });
        } finally {
            handleCloseDialog();
            setLoading(false);
        }
    };
    const handleCancel = () => {
        handleCloseDialog();
    };
    const handleEmailRemindersChange = (noFollowUp: boolean, noReminders: boolean) => {
        setNoFollowUp(noFollowUp);
        setNoReminders(noReminders);
    };
    return (
        <>
            <IconActionButton
                icon={<Unsubscribe />}
                color="primary"
                label="Follow up"
                size="small"
                onClick={handleClickOpen}
            />
            <DialogComponent
                DialogText={"Follow up configuration"}
                open={openDialog}
                handleClose={handleCloseDialog}
                ButtonActions={{
                    handleSuccessActionText: "Save",
                    handleSuccessActionFunction: handleSuccess,
                    handleCancelActionFunction: handleCancel,
                    handleCancelActionText: "Cancel",
                }}
            >
                <div style={{ padding: "1rem" }}>
                    <EmailsReminders
                        noFollowUp={noFollowUp}
                        noReminder={noReminders}
                        handleUpdateEmailsReminders={handleEmailRemindersChange}
                    />
                    <Fade in={loading}>
                        <LinearProgress />
                    </Fade>
                </div>
            </DialogComponent>
        </>
    );
};
