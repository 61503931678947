import { Fragment, ReactElement, Children } from "react";

type RenderFunction<T> = (item: T, index: number) => ReactElement;
type OfProp<T> = T[];

interface EachProps<T> {
    render: RenderFunction<T>;
    of: OfProp<T>;
}

export const Each = <T,>({ render, of }: EachProps<T>) => {
    return <Fragment>{Children.toArray(of.map((item, index) => render(item, index)))}</Fragment>;
};
