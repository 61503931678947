import React, { useState } from "react";
import { SxProps, TextField, TextFieldProps } from "@mui/material";
import { MathEx } from "shared/utils/MathEx";

interface RefundAmountFieldProps {
    value: number;
    max: number;
    disabled?: boolean;
    onChange: (data: number) => void;
    sx?: SxProps;
}

export const RefundAmountField = (props: RefundAmountFieldProps) => {
    const [error, setError] = useState("");
    const [displayValue, setDisplayValue] = useState(props.value.toFixed(2))
    const handleOnChange: TextFieldProps["onChange"] = (e) => {
        const { value } = e.target;
        setDisplayValue(value);
        const parsedValue = Number(value) ? Number(value) : 0;
        if (parsedValue > props.max) {
            setError(`Max refundable amount is ${MathEx.formatCurrency(props.max)}`);
        } else {
            setError("");
        }
        props.onChange(parsedValue);
    };
    
    return (
        <TextField
            size="small"
            fullWidth
            value={displayValue}
            type="number"
            onChange={handleOnChange}
            onFocus={(e) => e.target.select()}
            inputProps={{ max: props.max, min: 0, step: "0.01" }}
            helperText={error}
            error={error !== ""}
            sx={{ ...props.sx, marginBottom: "1rem" }}
            disabled={props.max <= 0 || props.disabled}
            onBlur={()=>setDisplayValue(props.value.toFixed(2))}
        />
    );
};
