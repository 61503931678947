import React, { CSSProperties, FunctionComponent, PropsWithChildren } from "react";
import { useIndexicTheme } from "../../../theme/useIndexicTheme";

type Severity = "warning" | "error" | "success";

interface OwnProps {
    severity: Severity;
    customStyles?: CSSProperties;
    label?: string;
}

const SeverityChip: FunctionComponent<PropsWithChildren<OwnProps>> = (props) => {
    const theme = useIndexicTheme();

    if (!props.children && !props.label) {
        return null;
    }
    const getSeverityStyles = (severity: Severity): CSSProperties => {
        const warningStyles: CSSProperties = {
            color: theme.palette.warning.darker,
            background: theme.palette.warning.lighter,
        };
        const successStyles: CSSProperties = {
            color: theme.palette.success.darker,
            background: theme.palette.success.lighter,
        };
        const errorStyles: CSSProperties = {
            color: theme.palette.error.darker,
            background: theme.palette.error.lighter,
        };
        let styles: CSSProperties;
        switch (severity) {
            case "warning":
                styles = warningStyles;
                break;
            case "error":
                styles = errorStyles;
                break;
            case "success":
                styles = successStyles;
                break;
            default:
                styles = successStyles;
        }
        return styles;
    };

    const severityStyles = getSeverityStyles(props.severity);
    let generalStyles: CSSProperties = {
        padding: "0.25rem 0.5rem",
        display: "inline-flex",
        alignItems: "flex-start",
        gap: "0.5rem",
        fontSize: "0.75rem",
        fontWeight: "600",
        borderRadius: "10rem",
        ...severityStyles,
        margin: "0 0.25rem",
    };

    if (props.customStyles) {
        generalStyles = { ...generalStyles, ...props.customStyles };
    }

    return <span style={generalStyles}>{props.label ? props.label : props.children}</span>;
};

export default SeverityChip;
