import React, { FunctionComponent, ReactNode } from "react";
import { Badge } from "@mui/material";
import { useIndexicTheme } from "../../../../theme/useIndexicTheme";
import "./OutlineBadgeButton.css";
interface OutlineBadgeButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    badgeContent?: number;
    icon?: ReactNode;
}

const OutlineBadgeButton: FunctionComponent<OutlineBadgeButtonProps> = ({
    badgeContent,
    children,
    icon,
    ...props
}) => {
    const theme = useIndexicTheme();
    return (
        <button className={"outline-badge-button"} {...props}>
            {icon ? (
                <Badge
                    badgeContent={badgeContent ?? 0}
                    sx={{
                        marginRight: "1rem",
                        "& .MuiBadge-badge": {
                            backgroundColor: theme.palette.primary.light,
                            color: theme.palette.grey["100"],
                        },
                    }}
                >
                    {icon}
                </Badge>
            ) : null}
            <span style={{ fontWeight: "inherit" }}>{children}</span>
        </button>
    );
};

export default OutlineBadgeButton;
