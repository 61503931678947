import { createUrl, HttpService } from "../http/http";
import {
    CheckInCheckOutResult,
    ReservationDetails,
    SetFollowUpReminders,
} from "../models/Reservation";
import { ValidateSchema } from "../utils/ZodValidations";
import {
    CheckInCheckoutResultSchema,
    RentalInsuranceSchema,
    ReservationDetailsSchema,
    SetFollowUpRemindersSchema,
} from "../models/Reservation.schema";
import { IRentalInsurance } from "shared/models/RentalInsurance";
import { z } from "zod";
import { CheckedInBy } from "shared/components/WhoWhenComponent";
import { HistorySchema } from "shared/models/History.schema";
import { TicketsRequired } from "shared/models/Cart";

class ReservationService extends HttpService {
    constructor() {
        super("/portal/Reservations");
    }

    async GetReservationDetails(
        reservationId: number,
        eventDateId?: number,
        cartId?: string | null,
    ) {
        let uri = createUrl([reservationId]);
        if (reservationId < 0) {
            uri = `${uri}/${eventDateId}`;
            if (cartId) {
                uri += `/${cartId}`;
            }
        }
        const result = await this.get<ReservationDetails>(uri);
        return ValidateSchema<ReservationDetails>(result.data, ReservationDetailsSchema);
    }

    async SaveReservation(reservation: ReservationDetails) {
        const result = await this.put<ReservationDetails, ReservationDetails>("", reservation);
        return ValidateSchema<ReservationDetails>(result.data, ReservationDetailsSchema);
    }

    async moveReservations(eventDateId: number, reservations: string, sendEmail: boolean) {
        const result = await this.put(`${eventDateId}/move/${reservations}/${sendEmail}`);
        return result.data;
    }

    async getRentalInsurance(reservationId: string) {
        const uri = createUrl(["RentalInsuranceStatus", reservationId]);
        const result = await this.get<IRentalInsurance[]>(uri);
        return ValidateSchema<IRentalInsurance[]>(result.data, z.array(RentalInsuranceSchema));
    }

    async checkInOut(reservationId: number, ticketPersonId: number, checked: boolean) {
        const uri = createUrl(["CheckIn", `${reservationId}`, `${checked}`, `${ticketPersonId}`]);
        const result = await this.put<CheckInCheckOutResult>(uri, {});
        return ValidateSchema<CheckInCheckOutResult>(result.data, CheckInCheckoutResultSchema);
    }

    async getHistory(reservationId: number) {
        const result = await this.get<CheckedInBy>(`History/${reservationId}`);
        return ValidateSchema<CheckedInBy>(result.data, HistorySchema);
    }

    async moveReservationEvent(
        eventDateId: number,
        reservationId: number,
        tickets: TicketsRequired[],
    ) {
        const result = await this.post(`MoveEvent/${eventDateId}/${reservationId}`, tickets);
        return result;
    }

    async deleteReservation(reservationId: number) {
        //TODO: verify api response and change for a better improvement
        return await this.delete<number>(`${reservationId}`);
    }

    async setRemindersFollowUp(
        reservationId: number,
        enableFollowUp: boolean,
        enableReminders: boolean,
    ) {
        const result = await this.put<SetFollowUpReminders>(`FollowUp/${reservationId}`, {
            followUp: enableFollowUp,
            reminders: enableReminders,
        });
        return ValidateSchema<SetFollowUpReminders>(result.data, SetFollowUpRemindersSchema);
    }
}

const reservationService = new ReservationService();

export default reservationService;
