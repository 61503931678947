import { Divider, Grid, IconButton, Typography } from "@mui/material";
import { ReactNode, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

interface ICollapsableSingleProps {
    content: ReactNode;
    title: string;
    count?: string;
}

interface IExpandableIconProps {
    icon: ReactNode;
}

const ExpandableIcon = (props: IExpandableIconProps) => {
    return (
        <IconButton
            sx={{
                height: "32px",
                width: "32px",
                borderColor: "transparent !important",
                background: "#F4F6F8",
                border: 1,
                borderRadius: 360,
            }}
            aria-label="icon"
        >
            {props.icon}
        </IconButton>
    );
};

export const CollapsableSingle = (props: ICollapsableSingleProps) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const handleExpandCollapsable = () => {
        setIsExpanded(!isExpanded);
    };
    return (
        <Grid container spacing={2}>
            <Grid
                item
                xs={12}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                }}
                onClick={handleExpandCollapsable}
            >
                <Typography variant="headerExpandableTitle">
                    {props.title} {props.count ?? props.count}
                </Typography>
                <Divider light={true} sx={{ flexGrow: 1, marginRight: "16px" }} />
                {isExpanded ? (
                    <ExpandableIcon icon={<KeyboardArrowUpIcon />} />
                ) : (
                    <ExpandableIcon icon={<KeyboardArrowDownIcon />} />
                )}
            </Grid>

            {isExpanded && (
                <Grid item xs={12}>
                    {props.content}
                </Grid>
            )}
        </Grid>
    );
};
