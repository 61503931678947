import { format } from "date-fns";
import React, { useState } from "react";
import BasicDatePicker from "shared/components/DatePickerComponent";
import { QuestionDate } from "shared/models/QuestionTypes";
import { DateTimeUtils } from "shared/utils/DateTimeUtils";
export interface IDateQuestionProps {
    error: any;
    question: QuestionDate;
    value: string;
    label?: string;
    onChange: (arg: Date, arg2: string) => void;
}
/**
 *  Renders date question control
 *
 * _Important: If the date picker doesn't shows, double check that
 * MuiPickersUtilsProvider is present at the highest level of your components tree.
 * For more information see: [docs](https://material-ui-pickers.dev/getting-started/installation)_
 * @param props
 */
export function DateQuestion(props: IDateQuestionProps) {
    const [state, setState] = useState({
        error: props.error,
        value: parseDate(props.value),
    });
    const { min, max } = props.question;
    // const value = props.value === '' ? null : DateTimeUtils.isoToLocaleString(props.value);
    const handleOnDateChange = (value: Date | null) => {
        if (value === null) {
            setState({ ...state, error: true });
        } else {
            const dateString = format(value, "yyyy-MM-dd");
            props.onChange(value, dateString);
            setState({ value: dateString, error: false });
        }
    };
    return (
        <BasicDatePicker
            value={state.value}
            min={new Date(min)}
            max={new Date(max)}
            label={props.label ?? ""}
            onDateChanged={handleOnDateChange}
        />
    );
}

const parseDate = (value: string): string | null => {
    return value === "" ? null : DateTimeUtils.isoToLocaleString(value);
};
