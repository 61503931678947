import { OverrideComponent } from "../interfaces";

const LinkOverrides: OverrideComponent<"MuiLink"> = () => {
    return {
        MuiLink: {
            defaultProps: {
                underline: "hover",
            },
        },
    };
};
export default LinkOverrides;
