import React, { useState } from "react";
import {
    InputBaseComponentProps,
    TextField,
    TextFieldProps,
    TextFieldVariants,
} from "@mui/material";
import { MathEx } from "../utils/MathEx";

type NumberInputProps<T extends TextFieldVariants> = Omit<TextFieldProps<T>, "onChange" | "value">;
type Props<T extends TextFieldVariants> = NumberInputProps<T> & {
    onChange: (numericValue: number) => void;
    value: number;
    includeDecimalPlaces?: boolean;
    maxValue?: number;
};

const NumberInput = <T extends TextFieldVariants>(props: Props<T>) => {
    const { value, onChange, includeDecimalPlaces, ...forwardedProps } = props;
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState("");
    const step = includeDecimalPlaces ? 0.01 : 1;
    let maxValue = isNaN(Number(props.maxValue)) ? -1 : Number(props.maxValue);

    const handleOnChange: TextFieldProps["onChange"] = (e) => {
        const val = e.target.value;
        let parsed = Number(val);
        if (!isNaN(parsed)) {
            setError(false);
            setHelperText("");
            parsed = MathEx.round(parsed, step);
        } else {
            setHelperText("Invalid number");
            setError(false);
        }
        if (maxValue > 0 && maxValue < parsed) {
            // set error when max number is actual
            setError(true);
            setHelperText(`Max value is ${maxValue}`);
        }
        onChange(parsed);
    };

    let inputProps: InputBaseComponentProps = {
        step: step,
    };
    if (maxValue > 0) {
        inputProps = { ...inputProps, max: maxValue };
    }
    return (
        <TextField
            type={"number"}
            {...forwardedProps}
            inputProps={inputProps}
            value={value}
            onFocus={(e) => e.target.select()}
            onChange={handleOnChange}
            helperText={helperText}
            error={error}
            size={"small"}
        />
    );
};

export default NumberInput;
