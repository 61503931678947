import { z } from "zod";
import { InterfaceToZod } from "./UtilsTypes";
import {
    BillingInfo,
    CartDTO,
    CartDtoReservationRate,
    GiftCardRecipient,
    CartItem,
    CartReservation,
    GiftCardDetail,
    HowHeardAnswerItem,
    PaymentOnFile,
    PreCheckoutData,
    SendCartEmailResponse,
    SendEmailCartInfo,
} from "./Cart";
import { DateTimeISOValidation } from "../utils/ZodValidations";

import { IContactInfo } from "./ContactInfo";

export const ContactInfoSchema = z.object<InterfaceToZod<IContactInfo>>({
    firstName: z.string().nullable(),
    lastName: z.string(),
    phone: z.string().nullable(),
    email: z.string().nullable(),
});

export const GiftCardDetailSchema = z.object<InterfaceToZod<GiftCardDetail>>({
    companyId: z.number(),
    companyName: z.string(),
    description: z.string(),
    code: z.string(),
    transactionId: z.number(),
    transactionDate: DateTimeISOValidation,
    originalPrice: z.number(),
    balance: z.number(),
});

export const GiftCardRecipientSchema = z.object<InterfaceToZod<GiftCardRecipient>>({
    giftCardRecipientId: z.number().optional(),
    giftCardTemplateId: z.number().optional(),
    recipientName: z.string().nullable(),
    recipientEmail: z.string().nullable(),
    note: z.string().nullable().optional(),
});
export const CartDtoReservationRateSchema = z.object<InterfaceToZod<CartDtoReservationRate>>({
    rateId: z.number(),
    description: z.string(),
    rate: z.number(),
    tickets: z.number(),
});

export const CartItemSchema = z.object<InterfaceToZod<CartItem>>({
    catalogId: z.number(),
    description: z.string().nullable(),
    price: z.number(),
    discount: z.number(),
    tax: z.number(),
    quantity: z.number(),
    itemCode: z.string().nullable(),
    requiredQuantity: z.number(),
    giftCards: z.array(GiftCardRecipientSchema).nullable(),
});
export const CartReservationSchema = z.object<InterfaceToZod<CartReservation>>({
    description: z.string(),
    discount: z.number(),
    reservationId: z.number(),
    tax: z.number(),
    price: z.number(),
    latestSellDate: DateTimeISOValidation,
    hasTipping: z.boolean(),
    contact: ContactInfoSchema,
    rates: z.array(CartDtoReservationRateSchema).nullable(),
    addOns: z.array(CartItemSchema),
    eventDateId: z.number(),
    eventId: z.number(),
});
export const CartDTOSchema = z.object<InterfaceToZod<CartDTO>>({
    companyId: z.number(),
    companySlug: z.string().nullable(),
    cartId: z.string(),
    isEmpty: z.boolean(),
    reservations: z.array(CartReservationSchema),
    catalogItems: z.array(CartItemSchema),
    clickAgreements: z.array(z.string()),
    warnings: z.array(z.string()),
    errors: z.array(z.string()),
    appliedGiftCards: z.array(GiftCardDetailSchema),
    tax: z.number(),
    subTotal: z.number(),
    depositBalance: z.number(),
    buyerServiceCharge: z.number(),
    totalCartDiscount: z.number(),
    processorSpecificJson: z.string().nullable(),
});

export const HowHeardAnswerItemSchema = z.object<InterfaceToZod<HowHeardAnswerItem>>({
    howHeardAnswerID: z.number(),
    answer: z.string(),
});

const PaymentOnFileSchema = z.object<InterfaceToZod<PaymentOnFile>>({
    description: z.string(),
    transactionId: z.number(),
});
const BillingInfoSchema = z.object<InterfaceToZod<BillingInfo>>({
    firstName: z.string().nullable(),
    lastName: z.string().nullable(),
    phone: z.string().nullable(),
    email: z.string().nullable(),
    address: z.string().nullable(),
    address2: z.string().nullable(),
    city: z.string().nullable(),
    state: z.string().nullable(),
    zip: z.string().nullable(),
    country: z.string().nullable(),
});
export const PreCheckoutDataSchema = z.object<InterfaceToZod<PreCheckoutData>>({
    billingInfo: BillingInfoSchema,
    gatewayId: z.number(),
    cartDTO: CartDTOSchema,
    howHeardAnswers: z.array(HowHeardAnswerItemSchema).nullable(),
    paymentsOnFile: z.array(PaymentOnFileSchema).nullable(),
    boost: z.boolean(),
});

export const SendEmailCartInfoSchema = z.object<InterfaceToZod<SendEmailCartInfo>>({
    email: z.string().nullable(),
    phone: z.string().nullable(),
    companyFriendlyNames: z.array(z.string()).nullable(),
});

export const SendCartEmailResponseSchema = z.object<InterfaceToZod<SendCartEmailResponse>>({
    success: z.boolean(),
    errors: z.array(z.string()).nullable(),
});
