import { ZodArray, ZodObject, z } from "zod";
import { PRODUCTION } from "../../environment/environment";

export const ValidateSchema = <T = unknown>(data: T, schema: ZodObject<any> | ZodArray<any>): T => {
    const result = schema.safeParse(data);
    if (result.success) {
        return result.data as T;
    }
    // Needed for type narrow
    if (result.success === false) {
        // Display alert only on testing mode
        if (!PRODUCTION) {
            alert(result.error);
        }
        console.error(result.error);
    }
    return data as T;
};

export const DateTimeISOValidation = z.string();
