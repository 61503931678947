import React, { useEffect, useMemo, useState } from "react";
import { endOfMonth, startOfMonth } from "date-fns";
import SimpleCalendar from "shared/Calendar/SimpleCalendar";
import { DateTimeUtils, FormatType } from "shared/utils/DateTimeUtils";
import eventService from "shared/services/api/EventService";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Button, Divider, Step, StepLabel, Stepper, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { CalendarDate, Event } from "shared/models/Event";
import { RateSelected } from "shared/components/Rates";
import {
    GetRatesByRules,
    filterRatesByAvailability,
    hasRequiredFieldsAndQuestions,
    isRateRelatedWithLessDuration,
} from "shared/business/Rates";
import AvailableTimes, { MoveReservationRequiredParams } from "./AvailableTimes";
import { Rate } from "shared/models/Rates";
import { Each } from "shared/components/Each";

interface Props {
    eventId: number;
    moveReservationRequiredParams?: MoveReservationRequiredParams;
}

export interface RateSelectedForLinkStyle extends Rate {
    rateId: number;
    tickets: number;
    amount: number;
}
/* It allows users
to select a date and tickets for an event, and then proceed to select a time for the reservation.
 */
const LinkStyleReservation = (props: Props) => {
    const [month, setMonth] = useState(DateTimeUtils.dateToDatePure(new Date()));
    const [simpleCalendarItems, setSimpleCalendarItems] = useState<Date[]>([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [eventDetails, setEventDetails] = useState<null | Event>(null);
    const [ratesSelected, setRateSelected] = useState<RateSelectedForLinkStyle[]>([]);
    const [displayableRates, setDisplayableRates] = useState<Rate[]>([]);
    const [activeStep, setActiveStep] = useState(1);

    useEffect(() => {
        if (props.eventId > 0) {
            const eId = Number(props.eventId);
            if (eId) {
                const start = startOfMonth(month);
                const end = endOfMonth(month);
                loadCalendar(start, end).then();
                getEventDetails();
            }
        }
        //eslint-disable-next-line
    }, [props.eventId, month]);

    useEffect(() => {
        if (eventDetails) {
            const new_rate_list = filterRatesByAvailability(eventDetails.rates, selectedDate);
            const rates_to_remove = ratesSelected.filter(
                (x) => !new_rate_list.some((r) => r.rateId === x.rateId),
            );
            if (rates_to_remove.length > 0) {
                setRateSelected(
                    ratesSelected.filter(
                        (x) => !rates_to_remove.some((r) => r.rateId === x.rateId),
                    ),
                );
            }
            setDisplayableRates(new_rate_list);
        }
        //eslint-disable-next-line
    }, [eventDetails, selectedDate]);

    useEffect(() => {
        if (ratesSelected.length === 0 && eventDetails) {
            const new_rate_list = filterRatesByAvailability(eventDetails.rates, selectedDate);
            const rates_to_remove = ratesSelected.filter(
                (x) => !new_rate_list.some((r) => r.rateId === x.rateId),
            );
            if (rates_to_remove.length > 0) {
                setRateSelected(
                    ratesSelected.filter(
                        (x) => !rates_to_remove.some((r) => r.rateId === x.rateId),
                    ),
                );
            }
            setDisplayableRates(new_rate_list);
        }
        //eslint-disable-next-line
    }, [ratesSelected, selectedDate]);

    const warnignMessage = useMemo(() => {
        if (ratesSelected.length === 0) {
            return "Please choose at least one rate";
        }
        return "";
    }, [ratesSelected]);

    const getEventDetails = async () => {
        const data = await eventService.getEvent(props.eventId);
        setEventDetails(data);
    };

    const getDateSelected = (event: Date) => {
        setSelectedDate(event);
    };

    const loadCalendar = async (startDate: Date, endDate: Date) => {
        const data: CalendarDate[] = (await eventService.getCalendarEventDates(
            props.eventId,
            startDate,
            endDate,
        )) as CalendarDate[];
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const items = (data || [])
            .map((item: CalendarDate) => new Date(item.day))
            .filter((item: Date) => item.getTime() >= today.getTime());
        setSimpleCalendarItems(items);
    };

    const onChangeRate = (rateId: number, count: number, amount: number) => {
        const rate = eventDetails?.rates.find((x) => x.rateId === rateId);
        if (rate) {
            if (!ratesSelected.some((x) => x.rateId === rateId) && count > 0) {
                setRateSelected(ratesSelected.concat({ ...rate, amount, tickets: count }));
            } else if (count > 0) {
                setRateSelected(
                    ratesSelected.map((item) => {
                        if (item.rateId === rateId) {
                            return { ...item, amount, tickets: count };
                        }
                        return item;
                    }),
                );
            } else if (count === 0) {
                if (!isRateRelatedWithLessDuration(ratesSelected, rateId)) {
                    setRateSelected(ratesSelected.filter((x) => x.rateId !== rateId));
                }
            }
            setDisplayableRates(
                GetRatesByRules(
                    filterRatesByAvailability(eventDetails?.rates as Rate[], selectedDate),
                    rate,
                    true,
                ),
            );
        }
    };

    return (
        <div data-cy="LinkStyleReservation">
            <Grid container spacing={0.5}>
                <Grid xs={12}>
                    <Typography variant={"h6"}>New reservation</Typography>
                    <Typography variant={"h4"}>{eventDetails ? eventDetails.name : ""}</Typography>
                </Grid>
                <Grid xs={12}>
                    <div style={{ width: "100%" }}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            <Step key={1}>
                                <StepLabel>Select a date and tickets</StepLabel>
                            </Step>
                            <Step key={2}>
                                <StepLabel>Select a time</StepLabel>
                            </Step>
                        </Stepper>
                    </div>
                </Grid>
            </Grid>
            {activeStep === 1 ? (
                <Grid container spacing={2}>
                    <Grid xs={12}>
                        <SimpleCalendar
                            startDate={month}
                            selectedDate={DateTimeUtils.dateToDatePure(selectedDate)}
                            onSelectDate={getDateSelected}
                            onNavigate={(month) => setMonth(startOfMonth(month))}
                            selectableDates={simpleCalendarItems}
                            btnForward={<ArrowForwardIosIcon />}
                            btnBack={<ArrowBackIosIcon />}
                        />
                    </Grid>
                    <Grid xs={12}>
                        <Divider />
                        <Typography variant={"headerExpandableTitle"}>Selected day:</Typography>
                        <Typography variant={"h5"}>
                            <strong>
                                {DateTimeUtils.newFormat(selectedDate, FormatType.fullDate)}
                            </strong>
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <div
                            style={{
                                display: "grid",
                                gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                                gridTemplateRows: "1fr",
                                gap: 5,
                                gridTemplateAreas: ". .",
                            }}
                        >
                            <Each
                                of={displayableRates}
                                render={(item) => (
                                    <RateSelected
                                        key={item.rateId}
                                        rateId={item.rateId}
                                        tickets={
                                            ratesSelected.length > 0
                                                ? ratesSelected.find(
                                                      (x) => x.rateId === item.rateId,
                                                  )?.tickets! || 0
                                                : 0
                                        }
                                        maxTickets={item.maxTickets}
                                        description={item.description}
                                        rate={item.rateEffectives[0].rate}
                                        questionGroupId={item.questionGroupId ?? null}
                                        readOnly={true}
                                        disabledRules={hasRequiredFieldsAndQuestions(null, null)} //at linkstyle reservation we do not need to look for rules to disable/enable the ability of blocking the reduce tickets
                                        onChangeRatePrice={() => {}} //price will not be able to change here, only on the reseservation
                                        onChangeRate={onChangeRate}
                                        hideTicketPrice={true}
                                    />
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid xs={12}>
                        <div data-cy="GetAvailableTimes">
                            <Button
                                fullWidth
                                variant="contained"
                                disabled={ratesSelected.length === 0}
                                onClick={() => setActiveStep(2)}
                                sx={{ width: "100%" }}
                            >
                                See Available Times
                            </Button>
                        </div>
                        <span>{warnignMessage}</span>
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={1.5}>
                    <Grid xs={12}>
                        <Button variant="text" onClick={() => setActiveStep(1)}>
                            Go Back
                        </Button>
                    </Grid>
                    <Grid xs={12}>
                        {eventDetails !== null ? (
                            <AvailableTimes
                                event={eventDetails}
                                date={selectedDate}
                                tickets={ratesSelected}
                                moveReservationRequiredParams={
                                    props.moveReservationRequiredParams
                                        ? {
                                              reservationId:
                                                  props.moveReservationRequiredParams.reservationId,
                                              isMovingReservation:
                                                  props.moveReservationRequiredParams
                                                      .isMovingReservation,
                                          }
                                        : undefined
                                }
                            />
                        ) : null}
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default LinkStyleReservation;
