import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import { SearchItem, SearchReservationTransaction } from "shared/models/Search";
import { DateTimeUtils, FormatType } from "shared/utils/DateTimeUtils";
import useGlobal from "shared/hooks/GlobalHook";
import DialogComponent from "./DialogComponent";
import { Each } from "./Each";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const a11yProps = (index: number) => {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
};

interface ModalSearchProps {
    open: boolean;
    searchValues: SearchReservationTransaction;
    handleCloseModal: () => void;
    openReservationDetails: (reservationId: number) => void;
}

export const ModalSearch = (props: ModalSearchProps) => {
    const [value, setValue] = useState<number>(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <DialogComponent
            DialogText="Search reservation or transaction"
            open={props.open}
            handleClose={props.handleCloseModal}
            aria-labelledby="modal-modal-search"
            aria-describedby="modal-modal-search-reservation-transaction"
        >
            <Tabs value={value} onChange={handleChange} aria-label="reservation transaction">
                <Tab
                    label={`Reservations ${props.searchValues.reservations.length}`}
                    {...a11yProps(0)}
                />
                <Tab
                    label={`Transactions ${props.searchValues.transactions.length}`}
                    {...a11yProps(1)}
                />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
                <Each
                    of={props.searchValues.reservations}
                    render={(res, idx) => (
                        <ReservationEntry
                            key={idx}
                            value={res}
                            handleSelectReservation={props.openReservationDetails}
                        />
                    )}
                ></Each>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <Each
                    of={props.searchValues.transactions}
                    render={(trans, idx) => <TransactionEntry key={idx} value={trans} />}
                ></Each>
            </CustomTabPanel>
        </DialogComponent>
    );
};

interface EntryProps {
    value: SearchItem;
    handleSelectReservation?: (reservationId: number) => void; //optional ultil we get the transaction logic
}
const ReservationEntry = (props: EntryProps) => {
    const reservation = props.value;
    const date = DateTimeUtils.parse(reservation.date);
    let dateLabel =
        date.getFullYear() === 9999 ? (
            <span style={{ fontStyle: "italic" }}>On hold</span>
        ) : (
            DateTimeUtils.newFormat(date, FormatType.medium)
        );

    return (
        <Grid container spacing={2}>
            <Grid xs={4} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <Button
                    variant="contained"
                    onClick={() =>
                        props.handleSelectReservation
                            ? props.handleSelectReservation(reservation.id)
                            : null
                    }
                >
                    {reservation.id}
                </Button>
            </Grid>
            <Grid xs={8}>
                <Grid xs={12}>{reservation.name}</Grid>
                <Grid xs={12}>{reservation.phone}</Grid>
                <Grid xs={12}>{reservation.email}</Grid>
                <Grid xs={12}>{dateLabel}</Grid>
            </Grid>
        </Grid>
    );
};

const TransactionEntry = (props: EntryProps) => {
    const { setTransactionID, setOpenTransactionDetails } = useGlobal();
    const transaction = props.value;
    const date = DateTimeUtils.parse(transaction.date);
    let dateLabel =
        date.getFullYear() === 9999 ? (
            <span style={{ fontStyle: "italic" }}>On hold</span>
        ) : (
            DateTimeUtils.newFormat(date, FormatType.medium)
        );

    const handleSelectTransaction = () => {
        setTransactionID(transaction.id);
        setOpenTransactionDetails(true);
    };
    return (
        <Grid container spacing={2}>
            <Grid xs={4} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <Button onClick={() => handleSelectTransaction()} variant="contained">
                    {transaction.id}
                </Button>
            </Grid>
            <Grid xs={8}>
                <Grid xs={12}>{transaction.name}</Grid>
                <Grid xs={12}>{transaction.phone}</Grid>
                <Grid xs={12}>{transaction.email}</Grid>
                <Grid xs={12}>{dateLabel}</Grid>
            </Grid>
        </Grid>
    );
};
