import { Typography } from "@mui/material";
import React, { ErrorInfo, PropsWithChildren } from "react";
import ErrorHandlingService from "./ErrorHandlingService";
import { Link } from "react-router-dom";
interface ErrorBoundaryProps {}
interface ErrorBoundaryState {
    hasError: boolean;
}
class ErrorBoundary extends React.Component<
    PropsWithChildren<ErrorBoundaryProps>,
    ErrorBoundaryState
> {
    constructor(props: PropsWithChildren<ErrorBoundaryProps>) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true };
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        ErrorHandlingService.logError(error, info);
    }
    resetError = () => {
        this.setState({ hasError: false });
    };
    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            // @ts-ignore
            // @ts-ignore
            return (
                <div
                    style={{
                        background: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                    }}
                >
                    <div>
                        <Typography variant={"h2"}>Something went wrong</Typography>
                        <Link reloadDocument={true} to={"/"} onClick={this.resetError}>
                            Return to home
                        </Link>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}
export default ErrorBoundary;
