import { IconButton, Typography, IconButtonProps, Tooltip } from "@mui/material";
import { FunctionComponent, ReactNode } from "react";
import { useIndexicTheme } from "../../theme/useIndexicTheme";
import { Box } from "@mui/material";

interface IconActionButtonProps extends IconButtonProps {
    icon: ReactNode;
    borderColor?: string;
    label?: string;
    tooltip?: string;
    showLabel?: boolean;
}
export const IconActionButton: FunctionComponent<IconActionButtonProps> = ({
    icon,
    label,
    showLabel = true,
    borderColor,
    ...props
}) => {
    const theme = useIndexicTheme();
    const borderC = borderColor ? borderColor : theme.palette.grey["400"];

    return (
        <Box
            sx={{
                display: "inline-flex",
                flexDirection: "column",
                alignItems: "center",
                paddingRight: "5px",
                paddingLeft: "5px",
                [theme.breakpoints.down("sm")]: {
                    paddingRight: "2px",
                    paddingLeft: "2px",
                },
            }}
        >
            <Tooltip title={props.tooltip ?? ""}>
                <span>
                    <IconButton
                        sx={{
                            border: 1,
                            borderRadius: 360,
                            borderColor: borderC,
                            width: "35px",
                            height: "35px",
                        }}
                        aria-label="icon"
                        {...props}
                    >
                        {icon}
                    </IconButton>
                </span>
            </Tooltip>
            {label && showLabel ? (
                <Typography variant="button" sx={{ fontSize: "0.75rem" }}>
                    {label}
                </Typography>
            ) : null}
        </Box>
    );
};
