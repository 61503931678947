import { Button } from "@mui/material";
import LogoPage from "./LogoPage";
import { logout } from "shared/auth/MSALAuth";

const InvalidUser = () => {
	const signout = () => {
		logout(); // Force Logout of Unknown User
	}
    return (
			<>
        <div>
            <LogoPage
                message={
                    "If you are not signing up a new Tour Company then your aReservation account may be inactive or not assigned to a valid Tour Company. Contact your Tour Company and ask for an invite to their company."
                }
            />
            <center>
                <h1>
									<a href="https://www.indexic.net/request-demo-2">Company Signup</a>
                </h1>
            </center>
						<center><Button onClick={signout}>Logout</Button> </center>
        </div>
				</>
    );
};

export default InvalidUser;
