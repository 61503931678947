export class BiteWiseUtils {
    static getMostSignificantBite<T extends object>(status: number, enumInterface: T) {
        let mostSignificantBite = 0;

        Object.keys(enumInterface).forEach((option) => {
            const key = option as keyof T;
            const accessKey = enumInterface[key] as number;
            const value = status & accessKey;

            if (value > mostSignificantBite) {
                mostSignificantBite = value;
            }
        });
        return mostSignificantBite;
    }
}
