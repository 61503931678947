import { EventDateTimeItem } from "shared/models/Event";
import { DateTimeUtils } from "shared/utils/DateTimeUtils";
import { Locale } from "shared/utils/Locale";
import { MathEx } from "shared/utils/MathEx";

export interface EventTimeValidationResponse {
    valid: boolean;
    message: string;
}

export interface IDateTimeAvailableValidation {
    eventEarliestSell: number;
    eventLatestSell: number;
    isPartnerCompany: boolean;
    eventLatestChange?: number;
    eventDateTimeItem: EventDateTimeItem;
}

/**
 * @description This function returns if an eventDateTime is valid to selecction
 * @param payload
 */
export const isDateTimeAvailable = (payload: IDateTimeAvailableValidation) => {
    const { eventDateTimeItem, eventEarliestSell } = payload;
    let eventLatestSell =
        payload.isPartnerCompany && !isNaN(Number(payload.eventLatestChange))
            ? payload.eventLatestChange!
            : payload.eventLatestSell;

    let timeString = DateTimeUtils.eventLocalTime(eventDateTimeItem.startTime);
    let text: string = "";
    let rateTotalAmount = MathEx.formatCurrency(eventDateTimeItem.totalTicketAmount);
    let disabled: boolean = true;
    const now: Date = new Date();

    let eventDateTime: Date = new Date(eventDateTimeItem.eventDateTime);
    //TODO: We need to get the this two values from the server.
    let earliestSellTime: Date = new Date(eventDateTime.getTime() - eventEarliestSell * 1000);
    let latestSellTime: Date = new Date(eventDateTime.getTime() - eventLatestSell * 1000);

    if (eventDateTimeItem.sell_Status === "O" || eventDateTimeItem.sell_Status === "Open") {
        disabled = false;
        if (eventDateTimeItem.startTime !== eventDateTimeItem.eventDateTime) {
            timeString += " - " + DateTimeUtils.eventLocalTime(eventDateTimeItem.eventDateTime);
        }
    } else if (
        eventDateTimeItem.sell_Status === "S" ||
        eventDateTimeItem.sell_Status === "SoldOut"
    ) {
        text = `${Locale.soldOut}`;
    }

    if (eventDateTimeItem.sell_Status !== "U" && eventDateTimeItem.sell_Status !== "V") {
        timeString = `${timeString}`;
    }
    //TODO: booking window is removed for now, will be back in the future. We need to add more rules
    // const validateSellingWindow = ValidateSellingWindow(
    //     eventDateTimeItem.eventDateTime,
    //     eventEarliestSell,
    //     eventLatestSell,
    // );
    // if (!validateSellingWindow.valid) {
    //     disabled = true;
    //     text = validateSellingWindow.message;
    // }
    // Booking window only for partners
    if (payload.isPartnerCompany) {
        // Check the preset Selling Boundary
        if (now.getTime() < earliestSellTime.getTime()) {
            text = Locale.tooEarly + ` (${DateTimeUtils.longDate(earliestSellTime)})`;
            disabled = true;
        } else if (now.getTime() >= latestSellTime.getTime()) {
            text = Locale.tooLate;
            disabled = true;
        }
    }

    return {
        timeString: timeString,
        text: text,
        rateTotalAmount,
        disabled: disabled,
        rateAdjustmentPercent: eventDateTimeItem.rateAdjustmentPercent,
        rateAdjustmentRoundingUnit: eventDateTimeItem.rateAdjustmentRoundingUnit,
    };
};

export const ValidateSellingWindow = (
    eventDate: string,
    earliestSell: number,
    latestSell: number,
): EventTimeValidationResponse => {
    let response: EventTimeValidationResponse = {
        valid: true,
        message: "",
    };

    const now = new Date();
    const eventDateTime = new Date(eventDate);
    const latestSellTime = new Date(eventDateTime.getTime() - latestSell * 1000);
    const earliestSellTime = new Date(eventDateTime.getTime() - earliestSell * 1000);

    if (now.getTime() < earliestSellTime.getTime()) {
        response.message = Locale.tooEarly + ` (${DateTimeUtils.longDate(earliestSellTime)})`;
        response.valid = false;
    } else if (now.getTime() >= latestSellTime.getTime()) {
        response.message = Locale.tooLate;
        response.valid = false;
    }
    return response;
};
