import UserProfile from "./UserProfile";
import Search from "./Search";
import CompanySelect from "./CompanySelect";
import ShoppingCartHeader from "./ShoppingCartHeader";
import MessageCount from "./MessageCount";
import { useAppSelector } from "store/Store";

const HeaderContent = () => {
    const company = useAppSelector((store) => store.company.company);

    return (
        <>
            <CompanySelect />
            <Search />
            {company && company.textingModuleEnabled ? (
                <MessageCount
                    style={{
                        marginRight: "1rem",
                    }}
                />
            ) : null}
            <ShoppingCartHeader />
            <UserProfile />
        </>
    );
};

export default HeaderContent;
