import React, { FunctionComponent, useEffect, useState } from "react";
import {
    Alert,
    Box,
    Button,
    Fade,
    LinearProgress,
    Tab,
    Tabs,
    TextField,
    TextFieldProps,
    Typography,
} from "@mui/material";
import DialogComponent, { DialogHooks } from "shared/components/DialogComponent";
import {
    CartCatalogItemAdjustment,
    CartItem,
    CartReservation,
    TicketsRequired,
} from "shared/models/Cart";
import { SplitPaymentCalculator, SplitPaymentRate } from "./SplitPaymentCalculator";
import { useIndexicTheme } from "../../../../theme/useIndexicTheme";
import { useShoppingCartSlice } from "../../../../store/ShoppingCart/useShoppingCartSlice";
import { FlatButton } from "shared/components/FlatButton";
import { DeleteForeverOutlined } from "@mui/icons-material";
import { getShoppingCartNotifications } from "../../ShoppingCartNotifications";
import useNotification, { NotificationsType } from "shared/hooks/useNotification";
import { useAuthContext } from "../../../Auth/AuthProvider";
import { SystemAccess } from "shared/models/AccessRights";
import { ForbiddenError } from "shared/http/errors";
import { NewLineToBr } from "../../../../shared/components/NewLineToBr";
import { getCatalogItemDescription } from "shared/models/Catalog";
import { MathEx } from "../../../../shared/utils/MathEx";

interface ShoppingCartReservationActionsProps {
    reservation: CartReservation;
    catalogItem?: undefined;
}

interface ShoppingCartCatalogItemActionsProps {
    reservation?: undefined;
    catalogItem: CartItem;
    reservationIdAddon?: number;
}

type ShoppingCartActionsProps =
    | ShoppingCartReservationActionsProps
    | ShoppingCartCatalogItemActionsProps;

enum AdjustmentPanel {
    none = "none",
    split = "split",
    deposit = "deposit",
    remove = "remove",
    unitPrice = "unitPrice",
}

type TabsLabel = { label: string; value: AdjustmentPanel };
export const ShoppingCartAdjustments: FunctionComponent<ShoppingCartActionsProps> = (props) => {
    const theme = useIndexicTheme();
    const {
        adjustReservationAmount,
        adjustReservationTickets,
        removeAdjustments,
        adjustCatalogItemUnitPrice,
        adjustReservationUnitPrice,
    } = useShoppingCartSlice();

    const { showNotification } = useNotification();
    const { getSystemAccess } = useAuthContext();
    const [openDialog, handleClickOpen, handleCloseDialog] = DialogHooks();
    const [activeTab, setActiveTab] = useState<AdjustmentPanel>(AdjustmentPanel.deposit);
    const [amount, setAmount] = useState(0);
    const [splitTicketsSelected, setSplitTicketsSelected] = useState<SplitPaymentRate[]>([]);
    const [loading, setLoading] = useState(false);
    const [tabs, setTabs] = useState<TabsLabel[]>([]);
    const [error, setError] = useState("");
    const canModifyUnitPrice = getSystemAccess(SystemAccess.AdhocPriceAdjustments);
    useEffect(() => {
        const isReservationAdjustment = !props.catalogItem;
        const innerTabs: TabsLabel[] = [];
        if (isReservationAdjustment) {
            innerTabs.push({ label: "Deposit", value: AdjustmentPanel.deposit });
            innerTabs.push({ label: "Split payment", value: AdjustmentPanel.split });
        }
        // Hidden from reservation adjustments temporarily
        if (canModifyUnitPrice) {
            innerTabs.push({ label: "Change price", value: AdjustmentPanel.unitPrice });
        }

        if (innerTabs.length > 0) {
            innerTabs.push({ label: "Remove adjustments", value: AdjustmentPanel.remove });
        }
        setTabs(innerTabs);
        setActiveTab(innerTabs.length > 0 ? innerTabs[0].value : AdjustmentPanel.none);
    }, [props.catalogItem, canModifyUnitPrice]);

    const onCloseDialog = async () => {
        handleCloseDialog();
        // clean up
        setAmount(0);
        setError("");
        setSplitTicketsSelected([]);
        setLoading(false);
    };
    const handleChange = (event: React.SyntheticEvent, newValue: AdjustmentPanel) => {
        setActiveTab(newValue);
        setError("");
    };
    const handleSplitTickets = (tickets: SplitPaymentRate[]) => {
        setSplitTicketsSelected(tickets);
        setError("");
    };

    const applyReservationAmountAdjustment = async (amount: number) => {
        if (!props.reservation) return;
        const notification = getShoppingCartNotifications("AddAdjustment");
        try {
            setLoading(true);
            await adjustReservationAmount(props.reservation.reservationId, amount);
            showNotification(notification.success);
            handleCloseDialog();
        } catch (e) {
            if (e instanceof ForbiddenError) {
                showNotification({
                    message:
                        "You do not have access to this adjustment, please contact your company administrator.",
                    type: NotificationsType.warning,
                });
            }
            showNotification(notification.error);
        } finally {
            setLoading(false);
        }
    };

    const applyUnitPriceAdjustment = async (amount: number) => {
        const notification = getShoppingCartNotifications("AddAdjustment");
        try {
            setLoading(true);
            if (!props.reservation) {
                // Catalog item adjustment
                const data: CartCatalogItemAdjustment = {
                    amount,
                    reservationIdAddon: !props.reservationIdAddon ? -1 : props.reservationIdAddon,
                };
                await adjustCatalogItemUnitPrice(props.catalogItem.catalogId, data);
            } else if (!props.catalogItem) {
                await adjustReservationUnitPrice(props.reservation.reservationId, amount);
            }
            showNotification(notification.success);
            handleCloseDialog();
        } catch (e) {
            if (e instanceof ForbiddenError) {
                showNotification({
                    message:
                        "You do not have access to this adjustment, please contact your company administrator.",
                    type: NotificationsType.warning,
                });
            } else {
                showNotification(notification.error);
            }
        } finally {
            setLoading(false);
        }
        return;
    };
    const applyReservationTicketsAdjustment = async (data: SplitPaymentRate[]) => {
        if (!props.reservation) return;
        const notification = getShoppingCartNotifications("AddAdjustment");
        const tickets: TicketsRequired[] = data
            .filter((el) => el.quantity > 0)
            .map((el) => ({ rateId: el.rate.rateId, tickets: el.quantity }));
        try {
            setLoading(true);
            const reservationId = props.reservation.reservationId;
            await adjustReservationTickets(reservationId, tickets);
            showNotification(notification.success);
            handleCloseDialog();
        } catch (e) {
            showNotification(notification.error);
        } finally {
            setLoading(false);
        }
    };
    const onApplyAdjustment = () => {
        if (activeTab === AdjustmentPanel.split) {
            const hasTicketsSelected =
                splitTicketsSelected.filter((el) => el.quantity > 0).length > 0;
            if (!hasTicketsSelected) {
                // setSplitError("Select at least one ticket");
                setError("Select at least one ticket");
                return;
            }
            return applyReservationTicketsAdjustment(splitTicketsSelected);
        }
        if (activeTab === AdjustmentPanel.deposit) {
            if (amount < 0) {
                // setDepositError("Deposit amount must be 0 or greater");
                setError("Deposit amount must be 0 or greater");
                return;
            }
            return applyReservationAmountAdjustment(amount);
        }
        if (activeTab === AdjustmentPanel.unitPrice) {
            if (amount < 0) {
                // setDepositError("Deposit amount must be 0 or greater");
                setError("Unit price amount must be 0 or greater");
                return;
            }
            return applyUnitPriceAdjustment(amount);
        }
    };
    const removeReservationAdjustments = async () => {
        if (!props.reservation) return;
        const notification = getShoppingCartNotifications("RemoveAdjustment");
        try {
            setLoading(true);
            const { reservationId } = props.reservation;
            await removeAdjustments(reservationId);
            showNotification(notification.success);
            handleCloseDialog();
        } catch (e) {
            showNotification(notification.error);
        } finally {
            setLoading(false);
        }
    };

    if (tabs.length === 0) {
        return null;
    }
    const isReservationAdjustment = !props.catalogItem;
    return (
        <>
            <Button
                variant={"text"}
                color={"primary"}
                sx={{
                    color: (theme) => theme.palette.success.main,
                }}
                onClick={handleClickOpen}
            >
                Adjustments
            </Button>
            <DialogComponent
                isFullSize={false}
                maxWidth="lg"
                open={openDialog}
                handleClose={onCloseDialog}
                DialogText={"Select an adjustment"}
                ButtonActions={{
                    handleSuccessActionText: "Apply adjustment",
                    handleSuccessActionFunction: onApplyAdjustment,
                    handleCancelActionFunction: onCloseDialog,
                    handleCancelActionText: "Cancel",
                }}
            >
                <div
                    style={{
                        minHeight: "200px",
                        minWidth: "350px",
                    }}
                >
                    <Typography variant="headerExpandableTitle" gutterBottom>
                        Currently adjusting:
                    </Typography>
                    {props.reservation ? (
                        <Box
                            sx={{
                                borderLeftWidth: "2px",
                                borderLeftStyle: "solid",
                                borderLeftColor: theme.palette.grey[900],
                                paddingLeft: "4px",
                            }}
                        >
                            <Typography variant="commentContent">
                                <NewLineToBr>{props.reservation.description}</NewLineToBr>
                            </Typography>
                        </Box>
                    ) : null}
                    {props.catalogItem ? (
                        <Box
                            sx={{
                                borderLeftWidth: "2px",
                                borderLeftStyle: "solid",
                                borderLeftColor: theme.palette.grey[900],
                                paddingLeft: "4px",
                            }}
                        >
                            <Typography variant="commentContent" component={"p"}>
                                {getCatalogItemDescription(props.catalogItem)}
                            </Typography>
                            <Typography variant="commentContent">
                                <NewLineToBr>{props.catalogItem.description ?? ""}</NewLineToBr>
                            </Typography>
                            {props.catalogItem.quantity > 1 ? (
                                <ul style={{ margin: 0, marginBottom: "0.25rem" }}>
                                    <li>
                                        {props.catalogItem.quantity} items (
                                        {MathEx.formatCurrency(props.catalogItem.price)} each)
                                    </li>
                                </ul>
                            ) : null}
                        </Box>
                    ) : null}

                    <Fade in={loading}>
                        <LinearProgress />
                    </Fade>
                    <Tabs
                        value={activeTab}
                        onChange={handleChange}
                        aria-label="adjustment tabs"
                        sx={{
                            marginBottom: "0.5rem",
                        }}
                    >
                        {tabs.map((t, idx) => (
                            <Tab key={idx} label={t.label} value={t.value} />
                        ))}
                    </Tabs>
                    <div
                        style={{
                            position: "relative",
                        }}
                    >
                        <Fade in={activeTab === AdjustmentPanel.deposit} unmountOnExit>
                            <div>
                                <AmountAdjustments
                                    amount={amount}
                                    label={"Deposit amount"}
                                    onChange={(val) => setAmount(val)}
                                />
                            </div>
                        </Fade>
                        <Fade in={activeTab === AdjustmentPanel.split} unmountOnExit>
                            <div>
                                <Typography
                                    variant={"headerExpandableTitle"}
                                    component={"p"}
                                    style={{ marginBottom: "0.5rem", opacity: 1 }}
                                >
                                    Split payment
                                </Typography>
                                <SplitPaymentCalculator
                                    rates={props.reservation?.rates || []}
                                    handleChange={handleSplitTickets}
                                />
                            </div>
                        </Fade>
                        <Fade in={activeTab === AdjustmentPanel.remove} unmountOnExit>
                            <FlatButton
                                startIcon={<DeleteForeverOutlined />}
                                onClick={removeReservationAdjustments}
                            >
                                Remove adjustments
                            </FlatButton>
                        </Fade>
                        <Fade in={activeTab === AdjustmentPanel.unitPrice} unmountOnExit>
                            <div>
                                <AmountAdjustments
                                    amount={amount}
                                    label={"Enter in new price you want to charge"}
                                    onChange={(val) => setAmount(val)}
                                />
                                {isReservationAdjustment ? (
                                    <Alert severity={"info"}>
                                        Enter in the price you want to charge for the total
                                        reservation pre tax.
                                    </Alert>
                                ) : (
                                    <Alert severity={"info"}>
                                        Enter in the price you want to charge for each item.
                                    </Alert>
                                )}
                            </div>
                        </Fade>
                        <Fade in={error !== ""} unmountOnExit>
                            <Alert
                                severity={"warning"}
                                variant={"filled"}
                                sx={{
                                    backgroundColor: theme.palette.warning.light,
                                    color: theme.palette.grey["900"],
                                    fontWeight: "500",
                                }}
                            >
                                {error}
                            </Alert>
                        </Fade>
                    </div>
                </div>
            </DialogComponent>
        </>
    );
};

interface AmountAdjustmentsProps {
    amount: number;
    label: string;
    onChange: (amount: number) => void;
}

const AmountAdjustments = (props: AmountAdjustmentsProps) => {
    const handleChange: TextFieldProps["onChange"] = (e) => {
        const value = Number(e.target.value);
        props.onChange(value);
    };
    return (
        <>
            <Typography
                variant={"headerExpandableTitle"}
                component={"p"}
                style={{ marginBottom: "0.5rem", opacity: 1 }}
            >
                {props.label}
            </Typography>
            <TextField
                placeholder={"Amount"}
                size={"small"}
                value={props.amount}
                onChange={handleChange}
                onFocus={(e) => e.target.select()}
                type={"number"}
                InputProps={{
                    inputProps: {
                        min: 0,
                    },
                }}
                fullWidth
                sx={{ marginBottom: "1rem" }}
            />
        </>
    );
};
