import React from "react";
//@ts-ignore
import { SeatsioSeatingChart } from "@seatsio/seatsio-react";
//@ts-ignore
import {
    ChartRendererMode,
    ExtraConfig,
    BookableObjectProps,
    ChartRendererConfigOptions,
} from "@seatsio/seatsio-types";

interface Props {
    workspaceKey: string | null;
    event: string;
    mode?: ChartRendererMode;
    extraConfig?: ExtraConfig;
    extraProps?: Partial<ChartRendererConfigOptions>;
}
interface ExtraConfigProps {
    reservationId: number | null;
    readOnly: boolean;
    seatAssignmentsCategories: { seatAssignmentCategoryId: number }[];
}

function SeatsManager(props: Props) {
    return props.workspaceKey !== null ? (
        <SeatsioSeatingChart
            workspaceKey={props.workspaceKey}
            event={props.event}
            mode={props.mode ?? "normal"}
            region="na"
            loading="<div class='loader'>Loading...</div>"
            objectColor={(
                object: BookableObjectProps,
                defaultColor: string,
                extraConfig: ExtraConfigProps,
            ) => {
                if (extraConfig) {
                    if (extraConfig.readOnly) {
                        if (object.status === "booked") {
                            return "black";
                        }
                        return defaultColor;
                    } else {
                        let localColor = "#C4CDD5"; //default color for all the seats
                        if (object.extraData !== undefined) {
                            if (extraConfig.reservationId !== null) {
                                const { reservationId } = object.extraData as ExtraConfigProps;
                                if (Number(extraConfig.reservationId) === Number(reservationId)) {
                                    localColor = "red"; //color for the seats that belongs to the current selected reservation
                                }
                            }
                        } else {
                            if (extraConfig.seatAssignmentsCategories !== null)
                                if (
                                    extraConfig.seatAssignmentsCategories.findIndex(
                                        (item) =>
                                            item.seatAssignmentCategoryId === object.category!.key,
                                    ) !== -1
                                ) {
                                    localColor = "green";
                                }
                        }
                        return localColor;
                    }
                } else {
                    return defaultColor;
                }
            }}
            extraConfig={props.extraConfig}
            {...(props.extraProps as any)}
        />
    ) : null;
}

export default SeatsManager;
