import { Grid, TextField, InputAdornment } from "@mui/material";
import React, { ChangeEvent } from "react";
import { kgToPounds, poundsToKg, roundTo } from "shared/utils/Conversions";
import { Locale } from "shared/utils/Locale";

interface IWeightProps {
    weightInKg: number;
    onChange: (weight: number) => void;
    metric?: boolean;
    onBlur?: () => void;
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
}

export default function WeightInput(props: IWeightProps) {
    const pounds = kgToPounds(props.weightInKg);
    const hasWeight = props.weightInKg > 0;
    const disabled = props.disabled || false;
    const handlePoundsChange = (e: ChangeEvent<HTMLInputElement>) => {
        const kg = poundsToKg(Number(e.target.value));
        const weightInKg = roundTo(kg, 1);
        props.onChange(weightInKg);
    };
    const handleKgChange = (e: ChangeEvent<HTMLInputElement>) => {
        const weightInKg = Number(e.target.value);
        props.onChange(weightInKg);
    };
    return (
        <>
            {!props.metric && (
                <Grid item xs={6}>
                    <TextField
                        value={hasWeight ? roundTo(pounds, 0).toString() : ""}
                        label={Locale.weight}
                        name="weight-lbs"
                        type="number"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">{Locale.lbs}</InputAdornment>
                            ),
                        }}
                        InputLabelProps={{ shrink: hasWeight }}
                        onChange={handlePoundsChange}
                        variant="outlined"
                        error={props.error}
                        onFocus={(e) => e.target.select()}
                        onBlur={props.onBlur}
                        helperText={props.helperText}
                        fullWidth
                        disabled={disabled}
                        size="small"
                    />
                </Grid>
            )}
            {(props.metric === undefined || props.metric) && (
                <Grid item xs={6}>
                    <TextField
                        value={hasWeight ? props.weightInKg.toString() : ""}
                        label={Locale.weight}
                        name="weight-kgs"
                        type="number"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">{Locale.kg}</InputAdornment>
                            ),
                        }}
                        InputLabelProps={{ shrink: hasWeight }}
                        onChange={handleKgChange}
                        variant="outlined"
                        error={props.error}
                        onBlur={props.onBlur}
                        onFocus={(e) => e.target.select()}
                        helperText={props.helperText}
                        fullWidth
                        disabled={disabled}
                        size="small"
                    />
                </Grid>
            )}
        </>
    );
}
