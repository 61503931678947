import { z } from "zod";
import { InterfaceToZod } from "./UtilsTypes";
import { SearchItem, SearchReservationTransaction } from "./Search";

export const SearchItemSchema = z.object<InterfaceToZod<SearchItem>>({
    date: z.string(),
    email: z.string().nullable(),
    hasSavedPayment: z.boolean(),
    id: z.number(),
    name: z.string(),
    phone: z.string().nullable(),
});
export const SearchReservationTransactionSchema = z.object<
    InterfaceToZod<SearchReservationTransaction>
>({
    reservations: z.array(SearchItemSchema),
    transactions: z.array(SearchItemSchema),
});
