import { HttpService } from "shared/http/http";

class CameraService extends HttpService {
    constructor() {
        super("/portal/camera");
    }

    async GetListBlobs(reservationId: string) {
        const result = await this.get(`GetListBlobs/${reservationId}`);
        if (result.status === 200) {
            return result.data as string[];
        }
    }
}

const cameraService = new CameraService();
export default cameraService;
