export enum APIErrorCode {
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    Conflict = 409,
    Unprocessable = 422,
    InternalServerError = 500,
}
export class APIError extends Error {
    code: number;
    data: unknown;
    constructor(message: string, _code: APIErrorCode, _data?: unknown) {
        super(message);
        this.code = _code;
        this.data = _data;
    }
}

export class ForbiddenError extends APIError {
    constructor(message: string, _data?: unknown) {
        super(message, APIErrorCode.Forbidden, _data);
    }
}
export class BadRequestError extends APIError {
    constructor(message: string, _data?: unknown) {
        super(message, APIErrorCode.BadRequest, _data);
    }
}
export class NotFoundError extends APIError {
    constructor(message: string, _data?: unknown) {
        super(message, APIErrorCode.NotFound, _data);
    }
}
