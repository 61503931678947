import {
    FormControl,
    FormControlLabel,
    FormLabel,
    MenuItem,
    Select,
    Switch,
    Typography,
} from "@mui/material";
import { UserPreferences } from "shared/models/Creds";
import { defaultCompanyUser, useUserSettingsContext } from "./UserSettingsContext";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

const UserCalendarSection = () => {
    const { companyUser, updateUserPreferences } = useUserSettingsContext();

    let preferences: UserPreferences;

    if (companyUser.userPreferences) {
        preferences = companyUser.userPreferences;
    } else {
        preferences = defaultCompanyUser.userPreferences!;
    }

    const handleChange = (event: any) => {
        let { name, value } = event.target;
        if (name === "showInactiveEvents" || name === "showZeroTicketTimes") {
            value = !(preferences as any)[name];
        }
        if (name === "defaultCalendarView" || name === "firstDayOfWeek") {
            value = Number(value);
        }
        const data = { [name]: value };
        updateUserPreferences(data);
    };
    return (
        <div style={{ paddingTop: "1.5rem" }}>
            <Typography variant="h5" gutterBottom>
                Calendar
            </Typography>

            <Grid container spacing={2}>
                <Grid xs={12}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={!preferences.showInactiveEvents}
                                onChange={handleChange}
                                name="showInactiveEvents"
                                color="primary"
                            />
                        }
                        label="Only display active events"
                    />
                </Grid>
                <Grid xs={12}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={!preferences.showZeroTicketTimes}
                                onChange={handleChange}
                                name="showZeroTicketTimes"
                                color="primary"
                            />
                        }
                        label="Only show events with reservations on All Events Calendar"
                    />
                </Grid>
                <Grid xs={12}>
                    <FormControl fullWidth component="fieldset" onChange={handleChange}>
                        <FormLabel component="legend">Default calendar view</FormLabel>

                        <Select
                            fullWidth
                            value={preferences.defaultCalendarView}
                            name="defaultCalendarView"
                            onChange={handleChange}
                            size="small"
                        >
                            <MenuItem value={0}>Month</MenuItem>
                            <MenuItem value={1}>Week</MenuItem>
                            <MenuItem value={2}>Day</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={12}>
                    <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend">
                            First day of week to show on calendars
                        </FormLabel>
                        <Select
                            value={preferences.firstDayOfWeek}
                            name="firstDayOfWeek"
                            onChange={handleChange}
                            fullWidth
                            size="small"
                        >
                            <MenuItem value={0}>Sunday</MenuItem>
                            <MenuItem value={1}>Monday</MenuItem>
                            <MenuItem value={2}>Tuesday</MenuItem>
                            <MenuItem value={3}>Wednesday</MenuItem>
                            <MenuItem value={4}>Thursday</MenuItem>
                            <MenuItem value={5}>Friday</MenuItem>
                            <MenuItem value={6}>Saturday</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
};

export default UserCalendarSection;
