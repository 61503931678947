import { OverrideComponent } from "../interfaces";

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        amountSummary: true;
        // tableContentCard: true;
        // eventLabelTicket: true;
        // tableContentTotalCard: true;
        // contactInfoLabel: true;
        headerExpandableTitle: true;
        idReservation: true;
        reservationFullName: true;
        commentContent: true;
        // transactionItemHeader: true;
        transactionItem: true;
        inputLabel: true;
        // title: true;
        // headerLabel: true;
        eventListHeader: true;
    }
}
const Typography: OverrideComponent<"MuiTypography"> = () => {
    return {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    inputLabel: "p",
                    idReservation: "p",
                },
            },
            styleOverrides: {
                gutterBottom: {
                    marginBottom: 12,
                },
                subtitle1: {
                    color: "var(--grey-500)",
                    fontSize: "10px",
                    fontWeight: 700,
                    lineHeight: "150%" /* 18px */,
                    textTransform: "uppercase",
                },
                // amountSummary: {
                //     marginTop: "4px",
                //     marginRight: "5px",
                //     letterSpacing: "1px",
                //     textTransform: "uppercase",
                // },
                // tableContentCard: {
                //     color: "var(--grey-800)",
                //     fontSize: "0.75rem",
                //     fontWeight: 500,
                //     lineHeight: "150%" /* 18px */,
                // },
                // tableContentTotalCard: {
                //     color: "var(--grey-800)",
                //     fontSize: "0.75rem",
                //     fontWeight: 600,
                //     lineHeight: "150%" /* 18px */,
                // },
                idReservation: {
                    color: "var(--grey-600)",
                    fontSize: "0.75rem",
                    fontWeight: 700,
                    lineHeight: "150%" /* 18px */,
                },
                reservationFullName: {
                    color: "var(--grey-900)",
                    fontSize: "1.25rem",
                    fontWeight: 500,
                    lineHeight: "150%" /* 18px */,
                    display: "inline-block",
                },
                commentContent: {
                    color: "var(--grey-800)",
                    fontSize: "0.875rem",
                    fontWeight: 400,
                    lineHeight: "150%" /* 21px */,
                    letterSpacing: "0.14px",
                },
                // title: {
                //     fontSize: "1.5rem",
                // },
                // headerLabel: {
                //     color: "var(--grey-800)",
                //     fontSize: "1rem",
                //     fontWeight: 500,
                //     lineHeight: "150%",
                // },
                eventListHeader: {
                    color: "var(--grey-600)",
                    fontSize: "0.75rem",
                    fontWeight: 600,
                    lineHeight: "150%",
                    letterSpacing: "0.075rem",
                    textTransform: "Uppercase",
                },
            },
        },
    };
};

export default Typography;
