import React, { FunctionComponent, useEffect, useState } from "react";
import { ReservationDetails } from "shared/models/Reservation";
import { Button, Typography } from "@mui/material";
import AnswersDisplay from "shared/components/Questions/Answers/AnswersDisplay";
import { useReservationModalContext } from "../context/ReservationModalProvider";
import { parseJson } from "shared/utils/Common";
import { QuestionAnswer, QuestionBase, QuestionsState } from "shared/models/QuestionTypes";
import { divideQuestionAnswers } from "shared/business/Questions";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Questions from "shared/components/Questions/Questions";
interface Props extends Pick<ReservationDetails, "answersJson" | "questionsJson"> {
    isNewReservation: boolean;
}

const EventQuestions: FunctionComponent<Props> = (props) => {
    const [answers, setAnswers] = useState<QuestionAnswer[]>([]);
    const [questions, setQuestions] = useState<QuestionBase[]>([]);
    const [isEditable, setIsEditable] = useState(props.isNewReservation);
    const { updateReservationData } = useReservationModalContext();

    useEffect(() => {
        let answersPayload: QuestionAnswer[] = [];
        if (props.answersJson) {
            answersPayload = parseJson<QuestionAnswer[]>(props.answersJson) ?? [];
        }

        let questionsPayload: QuestionBase[] = [];
        if (props.questionsJson) {
            questionsPayload = parseJson<QuestionBase[]>(props.questionsJson) ?? [];
        }
        const [existing, parsed] = divideQuestionAnswers(questionsPayload, answersPayload);
        setQuestions(parsed.length > 0 ? [...questionsPayload, ...parsed] : questionsPayload);
        setAnswers(existing);
        //eslint-disable-next-line
    }, []);

    const handleAnswerChange = (data: QuestionsState) => {
        setAnswers(data.questionsWithAnswers);
        updateReservationData({ answersJson: JSON.stringify(data.questionsWithAnswers) });
    };

    return (
        <div>
            <Button startIcon={<EditOutlinedIcon />} onClick={() => setIsEditable((s) => !s)}>
                Edit questions
            </Button>
            {isEditable ? (
                <EditableQuestions
                    answers={answers}
                    questions={questions}
                    handleAnswerChange={handleAnswerChange}
                />
            ) : (
                <ReadOnlyAnswers answers={answers} questions={questions} />
            )}
        </div>
    );
};

export default EventQuestions;

interface InnerComponentProps {
    answers: QuestionAnswer[];
    questions: QuestionBase[];
}
const ReadOnlyAnswers = (props: InnerComponentProps) => {
    const { answers, questions } = props;
    if (answers?.length === 0) {
        const questionText = `${questions.length} question${questions.length > 1 ? "s" : ""}`;
        return (
            <Typography>
                No Answers for this Event {questions.length > 0 ? `,${questionText}` : ""}
            </Typography>
        );
    }
    return <AnswersDisplay answers={answers} />;
};

const EditableQuestions = (
    props: InnerComponentProps & { handleAnswerChange: (data: QuestionsState) => void },
) => {
    const { answers, questions } = props;
    if (questions?.length === 0) {
        return <Typography>No Questions for this Event</Typography>;
    }
    return (
        <Questions
            questions={questions}
            answers={answers}
            onAnswerChange={props.handleAnswerChange}
        />
    );
};
