import { OverrideComponent } from "../interfaces";
import { Spacing } from "@mui/system/createTheme/createSpacing";
const IconButtonOverrides: OverrideComponent<"MuiIconButton"> = (theme) => {
    const spacing = theme.spacing as Spacing;
    return {
        MuiIconButton: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                },
                sizeLarge: {
                    width: spacing(5.5),
                    height: spacing(5.5),
                    fontSize: "1.25rem",
                },
                sizeMedium: {
                    width: spacing(4.5),
                    height: spacing(4.5),
                    fontSize: "1rem",
                },
                sizeSmall: {
                    width: spacing(3.75),
                    height: spacing(3.75),
                    fontSize: "0.75rem",
                },
            },
        },
    };
};

export default IconButtonOverrides;
