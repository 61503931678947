import { AppBar, IconButton, Toolbar, useMediaQuery } from "@mui/material";
import HeaderContent from "./HeaderContent/HeaderContent";
import { useIndexicTheme } from "../../theme/useIndexicTheme";
import logo from "./indexic-logo.svg";
import { ArrowBackIosNew, Menu } from "@mui/icons-material";
import { drawerWidth } from "theme/config";
interface Props {
    open: boolean;
    handleDrawerToggle: () => void;
}
const Header = ({ open, handleDrawerToggle }: Props) => {
    const theme = useIndexicTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    // common header
    const mainHeader = (
        <Toolbar
            sx={{
                background: theme.palette.grey["200"],
                borderBottomColor: theme.palette.grey["300"],
                borderBottomWidth: "1px",
                borderBottomStyle: "solid",
                paddingLeft: open ? "1rem!important" : "",
            }}
        >
            <IconButton
                data-cy="MainMenuButton"
                disableRipple
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
                color="primary"
                sx={{
                    ml: { xs: 0, lg: -2 },
                }}
            >
                {!open ? <Menu /> : <ArrowBackIosNew />}
            </IconButton>
            <img
                src={logo}
                style={{
                    marginRight: "1.2rem",
                }}
                alt={"indexic logo"}
            />
            <HeaderContent />
        </Toolbar>
    );

    return (
        <AppBar
            position="absolute"
            color="inherit"
            elevation={0}
            sx={{
                borderBottom: `1px solid ${theme.palette.divider}`,
                width: open && !isMobile ? `calc(100% - ${drawerWidth}px)` : "100%",
            }}
        >
            {mainHeader}
        </AppBar>
    );
};

export default Header;
