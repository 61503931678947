import React, { FunctionComponent, useState } from "react";
import { CartDtoReservationRate } from "shared/models/Cart";
import {
    Divider,
    MenuItem,
    NativeSelect,
    NativeSelectProps,
    Select,
    SelectProps,
    Typography,
} from "@mui/material";

export interface SplitPaymentRate {
    rate: CartDtoReservationRate;
    quantity: number;
}

interface SplitPaymentCalculatorProps {
    rates: CartDtoReservationRate[];
    handleChange: (rates: SplitPaymentRate[]) => void;
}

export const SplitPaymentCalculator: FunctionComponent<SplitPaymentCalculatorProps> = (props) => {
    const [rates, setRates] = useState<SplitPaymentRate[]>(
        props.rates.map((el) => {
            const data: SplitPaymentRate = { rate: el, quantity: 0 };
            return data;
        }),
    );

    const handleOnChange = (data: SplitPaymentRate) => {
        const newRates = rates.map((el) => {
            if (el.rate.rateId === data.rate.rateId) {
                return data;
            }
            return el;
        });
        setRates(newRates);
        props.handleChange(newRates);
    };
    return (
        <>
            {rates.map((el, idx, arr) => (
                <React.Fragment key={el.rate.rateId}>
                    <SplitPaymentRow data={el} handleChange={handleOnChange} />
                    {idx < arr.length ?? <Divider key={`divider-${el.rate.rateId}`} />}
                </React.Fragment>
            ))}
        </>
    );
};

interface SplitPaymentRowProps {
    data: SplitPaymentRate;
    handleChange: (rate: SplitPaymentRate) => void;
}

const SplitPaymentRow = (props: SplitPaymentRowProps) => {
    const options = [...Array(props.data.rate.tickets + 1).keys()];
    const rate = props.data.rate;
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const innerChange = (value: number) => {
        if (!isNaN(value)) {
            const data: SplitPaymentRate = { ...props.data, quantity: value };
            props.handleChange(data);
        }
    };
    const handleChange: SelectProps["onChange"] = (e) => {
        const value = Number(e.target.value);
        innerChange(value);
    };
    const handleNativeSelect: NativeSelectProps["onChange"] = (e) => {
        const value = Number(e.target.value);
        innerChange(value);
    };
    return (
        <div style={{ display: "flex", gap: "1rem", alignItems: "center", marginBottom: "1rem" }}>
            {!isMobile ? (
                <Select
                    style={{ minWidth: "100px" }}
                    size={"small"}
                    value={props.data.quantity}
                    onChange={handleChange}
                >
                    {options.map((el) => (
                        <MenuItem key={el} value={el}>
                            {el}
                        </MenuItem>
                    ))}
                </Select>
            ) : (
                <NativeSelect
                    style={{ minWidth: "100px" }}
                    size={"small"}
                    value={props.data.quantity}
                    onChange={handleNativeSelect}
                >
                    {options.map((el) => (
                        <option value={el} key={el}>
                            {el}
                        </option>
                    ))}
                </NativeSelect>
            )}

            <Typography variant={"inputLabel"}>{rate.description}</Typography>
        </div>
    );
};
