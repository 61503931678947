import { OverrideComponent } from "../interfaces";

const CardContentOverrides: OverrideComponent<"MuiCardContent"> = (theme) => {
    return {
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: 20,
                    "&:last-child": {
                        paddingBottom: 20,
                    },
                },
            },
        },
    };
};
export default CardContentOverrides;
