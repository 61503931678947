import { useAppDispatch, useAppSelector } from "store/Store";
import { CredentialsState, fetchCredentialsThunk, setCredentials } from "./CredentialsSlice";
import { UserCredentials } from "shared/models/Creds";

type Props = {
	credentialsState: CredentialsState;
	fetchCredentials: () => void;
	setUserCredentials: (credentials: UserCredentials) => void;
};

export const useCredentials = (): Props => {
    const credentialsState = useAppSelector((state) => state.credentials);
    const dispatch = useAppDispatch();

    const fetchCredentials = () => {
        dispatch(fetchCredentialsThunk());
    };

    const setUserCredentials = (credentials:UserCredentials ) => {
        dispatch(setCredentials(credentials));
    };

    return {
        credentialsState,
        fetchCredentials,
				setUserCredentials
    };
};