import React, { FunctionComponent } from "react";
import { SxProps, Typography } from "@mui/material";
import { TransactionReservation } from "shared/models/Transaction";

interface Props {
    data?: TransactionReservation;
}

const CompanyInfoViewer: FunctionComponent<Props> = (props) => {
    const data = props.data;
    const styleCompanyInfo: SxProps = {
        color: "var(--grey-900)",
        fontSize: "0.875rem",
        fontWeight: 500,
    };
    return (
        <>
            <Typography
                sx={{
                    color: "var(--grey-600)",
                    fontSize: "1rem",
                    fontWeight: 500,
                }}
            >
                From:
            </Typography>
            <Typography sx={styleCompanyInfo}>{data?.companyName ?? ""}</Typography>
            <Typography sx={styleCompanyInfo}>{data?.address ?? ""}</Typography>
            <Typography sx={styleCompanyInfo}>{data?.companyPhone ?? ""}</Typography>
            <Typography sx={styleCompanyInfo}>{data?.companyEmail ?? ""}</Typography>
        </>
    );
};

export default CompanyInfoViewer;
