import { Nullable } from "../models/Generals";
import {
    CompanyStorage,
    CompanyStorageItem,
    GeneralCalendarStorage,
    ShoppingCartItem,
    TerminalReaderItem,
} from "../models/LocalStorageData";

enum LocalStorageKeys {
    "GeneralCalendar" = "GeneralCalendar",
    "ShoppingCart" = "ShoppingCart",
    "StripeReader" = "StripeReader",
}

/**
 * Base class for Local storage data manipulation, includes utilities for storing company based properties
 * i.e. shopping cart id (one id for each company), stripe reader, etc.
 */
abstract class LocalStorageBase {
    protected static COMPANY_HEADER = "x-indexic-c";
    protected static prefix: string = "Indexic";
    static getCompanyId(): Nullable<number> {
        const companyHeader = localStorage.getItem(this.COMPANY_HEADER);
        const companyId = Number(companyHeader);
        if (!companyHeader || isNaN(companyId)) {
            return null;
        }
        return companyId;
    }
    /**
     * Returns the corresponding item on a collection depending on the current companyId
     * @param key
     * @private
     */
    protected static getCompanyItemFromCollection<T>(key: LocalStorageKeys): Nullable<T> {
        const companyId = this.getCompanyId();
        if (!companyId) return null;

        const collection = this.getByKey<CompanyStorage<T>>(key);
        if (!collection) return null;
        const item = collection.find((el) => el.companyId === companyId);
        return item?.data ?? null;
    }
    protected static setCompanyItemFromCollection<T>(key: LocalStorageKeys, data: T) {
        const companyId = this.getCompanyId();
        if (!companyId) return null;

        const collection = this.getByKey<CompanyStorage<T>>(key);
        let storage = collection ? collection : [];
        const existingIdx = storage?.findIndex((el) => el.companyId === companyId);
        const item: CompanyStorageItem<T> = { data, companyId };
        if (existingIdx >= 0) {
            storage[existingIdx] = item;
        } else {
            storage.push(item);
        }
        this.setByKey<CompanyStorage<T>>(key, storage);
    }
    protected static clearCompanyItemFromCollection<T>(key: LocalStorageKeys) {
        const companyId = this.getCompanyId();
        if (!companyId) return null;

        const collection = this.getByKey<CompanyStorage<T>>(key);
        if (collection) {
            const data = collection.filter((el) => el.companyId !== companyId);
            if (data.length === 0) {
                this.removeByKey(key);
                return;
            }
            this.setByKey<CompanyStorage<T>>(key, data);
        }
    }
    protected static createKey(key: LocalStorageKeys): string {
        return this.prefix + "-" + key;
    }
    protected static getByKey<T>(key: LocalStorageKeys): Nullable<T> {
        try {
            const result = localStorage.getItem(this.createKey(key));
            if (result) {
                return JSON.parse(result) as T;
            }
            return null;
        } catch (e) {
            console.error(e);
            return null;
        }
    }
    protected static setByKey<T>(key: LocalStorageKeys, value: T) {
        if (value) {
            const string = JSON.stringify(value);
            localStorage.setItem(this.createKey(key), string);
        }
    }
    protected static removeByKey(key: LocalStorageKeys) {
        localStorage.removeItem(this.createKey(key));
    }
}
export abstract class LocalStorageService extends LocalStorageBase {
    static getGeneralCalendarPreferences(): Nullable<GeneralCalendarStorage> {
        return this.getByKey<GeneralCalendarStorage>(LocalStorageKeys.GeneralCalendar);
    }

    static setGeneralCalendarPreferences(data: GeneralCalendarStorage) {
        this.setByKey<GeneralCalendarStorage>(LocalStorageKeys.GeneralCalendar, data);
    }
    static getShoppingCartId(): Nullable<ShoppingCartItem> {
        return this.getCompanyItemFromCollection<ShoppingCartItem>(LocalStorageKeys.ShoppingCart);
    }
    static setShoppingCartId(cartId: string) {
        const data = {
            shoppingCartId: cartId,
        };
        this.setCompanyItemFromCollection<ShoppingCartItem>(LocalStorageKeys.ShoppingCart, data);
    }
    static clearShoppingCartId() {
        this.clearCompanyItemFromCollection(LocalStorageKeys.ShoppingCart);
    }
    static getStripeReaderId() {
        return this.getCompanyItemFromCollection<TerminalReaderItem>(LocalStorageKeys.StripeReader);
    }
    static setStripeReader(readerId: string) {
        const data = {
            readerId,
        };
        this.setCompanyItemFromCollection<TerminalReaderItem>(LocalStorageKeys.StripeReader, data);
    }
    static clearStripeReader() {
        this.clearCompanyItemFromCollection<TerminalReaderItem>(LocalStorageKeys.StripeReader);
    }
}
