import React, { CSSProperties, Fragment, FunctionComponent, ReactNode } from "react";
type LineStyleSelector = "All" | number;
interface NewLineToBrProps {
    lineStyles?: CSSProperties;
    lineToStyle?: LineStyleSelector;
    endLines?: number; // Indicates the max number of lines to show for the component
    startLines?: number;
    children: string;
}
export const NewLineToBr: FunctionComponent<NewLineToBrProps> = ({
    children,
    endLines = 0,
    startLines = 0,
    ...props
}) => {
    if (!children) return null;

    let lines = children.split("\n");
    const endLimit = endLines > 0 ? endLines : lines.length;
    lines = lines.slice(startLines, endLimit);
    return (
        <Fragment>
            {lines.reduce(
                (arr, line, index) =>
                    arr.concat(
                        <NewLineFragment
                            key={index}
                            index={index}
                            lineToStyle={props.lineToStyle}
                            lineStyles={props.lineStyles}
                        >
                            {line}
                        </NewLineFragment>,
                    ),
                [] as ReactNode[],
            )}
        </Fragment>
    );
};

const NewLineFragment = (props: { index: number } & NewLineToBrProps) => {
    let lineStyles: CSSProperties | undefined;
    if (props.lineToStyle === "All" || props.lineToStyle === props.index) {
        lineStyles = props.lineStyles;
    }
    return (
        <Fragment>
            <span style={lineStyles}>{props.children}</span>
            <br />
        </Fragment>
    );
};
