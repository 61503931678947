import React, { FunctionComponent, useState } from "react";
import { DeleteForeverOutlined } from "@mui/icons-material";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fade,
    LinearProgress,
    Typography,
} from "@mui/material";
import { useShoppingCartSlice } from "../../../store/ShoppingCart/useShoppingCartSlice";

interface EmptyCartButtonProps {}

export const EmptyCartButton: FunctionComponent<EmptyCartButtonProps> = (props) => {
    const { emptyCart, cart } = useShoppingCartSlice();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleDeleteCart = async () => {
        setLoading(true);
        await emptyCart();
        setOpen(false);
    };
    return (
        <>
            <Button
                variant={"text"}
                color={"error"}
                startIcon={<DeleteForeverOutlined />}
                size={"small"}
                sx={{
                    border: "1px solid var(--grey-400)",
                    background: "white",
                }}
                disabled={cart?.isEmpty}
                onClick={() => setOpen(true)}
            >
                Empty cart
            </Button>
            <Dialog onClose={handleDeleteCart} open={open}>
                <DialogTitle
                    sx={{
                        background: (t) => t.palette.grey["200"],
                    }}
                >
                    <Typography variant={"inputLabel"}> Empty shopping cart</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant={"inputLabel"}>Are you sure?</Typography>
                    <Typography>
                        This action will clear all reservations from the shopping cart.
                    </Typography>
                    <Fade in={loading}>
                        <LinearProgress />
                    </Fade>
                </DialogContent>
                <DialogActions
                    sx={{
                        background: (t) => t.palette.grey["100"],
                        borderTopWidth: "1px",
                        borderTopStyle: "solid",
                        borderTopColor: (t) => t.palette.grey["300"],
                    }}
                >
                    <Button
                        variant={"text"}
                        sx={{
                            color: (t) => t.palette.grey["600"],
                        }}
                        onClick={() => setOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{
                            background: (t) => t.palette.error.dark,
                            "&:hover": {
                                background: (t) => t.palette.error.darker,
                            },
                        }}
                        variant={"contained"}
                        autoFocus
                        onClick={handleDeleteCart}
                    >
                        Empty Cart
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
