import { IRefundDTO, IRefundResult } from "shared/models/Reservation";
import { HttpService, createUrl } from "shared/http/http";
import { RefundTransaction, RefundTransactionDTO } from "shared/models/Transaction";
import { ValidateSchema } from "shared/utils/ZodValidations";
import { RefundResultSchema } from "shared/models/Transaction.schema";

export class RefundService extends HttpService {
    constructor() {
        super("/portal/transaction");
    }

    async refundReservationByTransactionItem(
        transactionItemId: number,
        amount: number,
        refundDTO: IRefundDTO,
        ticketPersonId?: number,
    ) {
        let uri = createUrl([
            "RefundReservation",
            `${transactionItemId}`,
            `${amount}`,
            ticketPersonId ? `${ticketPersonId}` : "",
        ]);
        const result = await this.put<IRefundResult, IRefundDTO>(uri, refundDTO);
        // TODO: Validate schema with correct reservation details schema from API
        return result.data;
    }

    async refundTransaction(refundDTO: RefundTransactionDTO): Promise<IRefundResult> {
        let uri = createUrl(["RefundTransaction", `${refundDTO.transactionId}`]);
        const result = await this.put<IRefundResult, RefundTransactionDTO>(uri, refundDTO);
        return ValidateSchema<IRefundResult>(result.data, RefundResultSchema);
    }

    async refundFullReservation(reservationId: number): Promise<IRefundResult> {
        let uri = createUrl(["RefundFullReservation", reservationId]);
        const result = await this.put<IRefundResult, {}>(uri);
        return result.data;
    }
}

const service = new RefundService();
export default service;
