import React, { FunctionComponent } from "react";
import { DateTimeUtils, FormatType } from "../../../shared/utils/DateTimeUtils";
import { TimeType } from "../../../shared/models/Report";
import { Fade } from "@mui/material";
import { parseISOStrByTimeType } from "../../../shared/utils/Formats";
import SeverityChip from "../../../shared/components/SeverityChip/SeverityChip";

interface DateCellComponentProps {
    dateStr: string;
    timeType: TimeType;
}

export const DateTimeCellComponent: FunctionComponent<DateCellComponentProps> = (props) => {
    const date = parseISOStrByTimeType(props.dateStr, props.timeType);
    if (!date) {
        return <div>--</div>;
    }
    const formatted = `${DateTimeUtils.newFormat(
        date,
        FormatType.shortDate,
    )}, ${DateTimeUtils.newFormat(date, FormatType.mediumTime)}`;
    return (
        <div>
            <Fade in={props.timeType === TimeType.UTC}>
                <span>
                    <SeverityChip severity={"warning"}>UTC</SeverityChip>
                </span>
            </Fade>
            {formatted}
        </div>
    );
};
