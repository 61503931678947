import React, { FunctionComponent } from "react";
import SaveIcon from "@mui/icons-material/Save";
import useReservation from "../../../shared/hooks/ReservationHook";
import { LoadingButton } from "@mui/lab";
import { useMediaQuery } from "@mui/material";
import { useIndexicTheme } from "theme/useIndexicTheme";

export const SaveReservationButton: FunctionComponent = () => {
    const theme = useIndexicTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { canSave, loading, saveReservation, dialogContext, isValid } = useReservation();
    const handleSave = async () => {
        await saveReservation();
    };
    return (
        <>
            <div data-cy="SaveReservation">
                <LoadingButton
                    loading={loading}
                    size="small"
                    variant="contained"
                    onClick={handleSave}
                    disabled={!canSave || !isValid}
                >
                    <SaveIcon sx={{ width: "1.2rem", marginRight: !isMobile ? "5px" : "" }} />
                    {!isMobile && "Save reservation"}
                </LoadingButton>
            </div>
            {dialogContext}
        </>
    );
};
