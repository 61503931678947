import { useEffect } from "react";
import SlidePanel from "shared/components/SlidePanel";
import useGlobal from "shared/hooks/GlobalHook";
import TransactionDetails from "./TransactionDetails";
import { useQueryClient } from "@tanstack/react-query";

const TransactionDetailsPanel = () => {
    const { globalState, setOpenTransactionDetails, setTransactionID } = useGlobal();
    const queryClient = useQueryClient();

    useEffect(() => {
        if (globalState.transactionId !== 0) {
            setOpenTransactionDetails(true);
        }
        //eslint-disable-next-line
    }, [globalState.transactionId]);

    return (
        <SlidePanel
            open={globalState.isTransactionDetailsOpen}
            title={"Transaction details"}
            onClose={() => {
                setOpenTransactionDetails(false);
                setTransactionID(0);
                if (globalState.isReservationDetailsOpen) {
                    queryClient.refetchQueries({ queryKey: ["reservation-details"] });
                }
            }}
            children={<TransactionDetails transactionId={globalState.transactionId} />}
        />
    );
};

export default TransactionDetailsPanel;
