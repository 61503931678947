import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { REACT_APP_APPINSIGHTS_KEY } from "environment/environment";

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: REACT_APP_APPINSIGHTS_KEY,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
    },
});
//appInsights.loadAppInsights();
export { reactPlugin, appInsights };
