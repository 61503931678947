import React, { FunctionComponent } from "react";
import { Button } from "@mui/material";
import { AssignmentTurnedInOutlined } from "@mui/icons-material";
import { useShoppingCartProvider } from "../ShoppingCartProvider";
import { useNavigate } from "react-router-dom";
import { useShoppingCartSlice } from "../../../store/ShoppingCart/useShoppingCartSlice";

interface ContinueCheckoutButtonProps {}

export const ContinueCheckoutButton: FunctionComponent<ContinueCheckoutButtonProps> = (props) => {
    const { toggleShoppingCartModal } = useShoppingCartProvider();
    const { cart } = useShoppingCartSlice();
    const navigate = useNavigate();
    const navigateCheckout = () => {
        toggleShoppingCartModal(false);
        navigate("/checkout");
    };
    return (
        <Button
            color={"primary"}
            variant={"contained"}
            startIcon={<AssignmentTurnedInOutlined />}
            size={"small"}
            onClick={navigateCheckout}
            disabled={cart?.isEmpty}
        >
            Checkout
        </Button>
    );
};
