import { OverrideComponent } from "../interfaces";
import { Spacing } from "@mui/system/createTheme/createSpacing";

const BadgeOverrides: OverrideComponent<"MuiBadge"> = (theme) => {
    const spacing = theme.spacing as Spacing;
    return {
        MuiBadge: {
            styleOverrides: {
                standard: {
                    minWidth: spacing(2),
                    height: spacing(2),
                    padding: spacing(0.5),
                },
            },
        },
    };
};

export default BadgeOverrides;
