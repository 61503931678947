import { PayloadAction, createSlice } from "@reduxjs/toolkit";
export interface ReservationState {
    selectedReservationIds: number[];
}

const initialState: ReservationState = {
    selectedReservationIds: [],
};
const reservationSlice = createSlice({
    name: "reservation",
    initialState: initialState,
    reducers: {
        // this function is used to add all the reservationsId that were selected on the ReservationList -> Card
        addSelectedReservations: (state, param: PayloadAction<number>) => {
            state.selectedReservationIds = state.selectedReservationIds.concat(param.payload);
        },
        //remove reservationId from selected reservations
        removeSelectedReservationId: (state, param: PayloadAction<number>) => {
            state.selectedReservationIds = state.selectedReservationIds.filter(
                (x) => x !== param.payload,
            );
        },
        clearSelections: (state) => {
            state.selectedReservationIds = [];
        },
    },
});
const reservationSliceReducer = reservationSlice.reducer;
export const { addSelectedReservations, removeSelectedReservationId, clearSelections } =
    reservationSlice.actions;
export default reservationSliceReducer;
