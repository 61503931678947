import React, { FunctionComponent } from "react";
import { DateTimeUtils, FormatType } from "../../../shared/utils/DateTimeUtils";
import { Nullable } from "../../../shared/models/Generals";

interface OwnProps {
    value: string;
    variant:
        | FormatType.shortDate
        | FormatType.mediumDate
        | FormatType.longDate
        | FormatType.fullDate;
}

type Props = OwnProps;

/**
 * Parses the date as local date and crops the time from the string
 * @param props
 * @constructor
 */
const DateCellComponent: FunctionComponent<Props> = (props) => {
    let date: Nullable<Date>;
    try {
        date = DateTimeUtils.eventLocalDate(props.value);
    } catch (e) {
        console.error(e);
        date = null;
    }
    if (!date) {
        return <div>--</div>;
    }
    return <div>{DateTimeUtils.newFormat(date, props.variant)}</div>;
};

export default DateCellComponent;
