import { HttpService } from "shared/http/http";
import { LocalStorageService } from "../LocalStorageService";

export interface IUploadPictureSas {
    serviceUri: string;
    sas: string;
    container: string;
    fileName: string;
}

class UploadService extends HttpService {
    constructor() {
        super(`/api/`);
    }

    async getUploadDetails(reservationId: number) {
        const companyId = LocalStorageService.getCompanyId();
        const result = await this.get(`${companyId}/upload/GetUploadDetails/null/${reservationId}`);
        if (result.status === 200) {
            return result.data as { sasData: IUploadPictureSas };
        } else {
            return null;
        }
    }

    async updateImageCount(reservationId: number) {
        const companyId = LocalStorageService.getCompanyId();
        return await this.put(`${companyId}/upload/UpdateImageCount/${reservationId}`);
    }
}
const uploadService = new UploadService();
export default uploadService;
