import React from "react";
import {
    DateTimePicker,
    DateTimeValidationError,
    LocalizationProvider,
    PickerChangeHandlerContext,
    renderTimeViewClock,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { isValid } from "date-fns";

interface Props {
    label: string;
    value: Date;
    onDateChanged: (date: Date | null) => void;
    disablePast?: boolean;
    minDateTime?: Date;
}

const DateTimePickerComponent = (props: Props) => {
    const onChangeDate = (
        value: Date | null,
        context: PickerChangeHandlerContext<DateTimeValidationError>,
    ) => {
        if (isValid(value)) {
            return props.onDateChanged(value);
        }
        return props.onDateChanged(null);
    };
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
                label={props.label}
                value={props.value}
                viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                }}
                onChange={onChangeDate}
                slotProps={{
                    textField: {
                        size: "small",
                    },
                }}
                disablePast={props.disablePast}
                minDateTime={props.minDateTime}
            />
        </LocalizationProvider>
    );
};

export default DateTimePickerComponent;
