import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Button, Stack, TextField } from "@mui/material";
import { IContactInfo } from "shared/models/ContactInfo";
import UserSelector from "./UserSelector";
import { useReservationModalContext } from "./context/ReservationModalProvider";
import useGlobal from "shared/hooks/GlobalHook";
import ContactForm from "shared/components/ContactInfo/ContactForm";
import { useAppSelector } from "store/Store";

interface Props {
    closePanel?: () => void;
}

interface Information {
    contact: Partial<IContactInfo>;
    comment: string;
    userId: string | null;
}
const EditInformation = (props: Props) => {
    const { reservation, undoPressed, setIsValid, updateReservationData } =
        useReservationModalContext();
    const isFirstLoad = useRef(true);
    const [isInformationPasted, setInformationPasted] = useState<boolean>(false);
    const [isUndoPressed, setIsUndoPressed] = useState<boolean>(false);
    const [information, setInformation] = useState<Information>({
        contact: {
            firstName: reservation?.firstName!,
            lastName: reservation?.lastName!,
            email: reservation?.email!,
            phone: reservation?.phone!,
        },
        comment: reservation?.comment ?? "",
        userId: reservation?.userId ?? null,
    });
    const { globalState, togglePasteContactInfo } = useGlobal();
    const shoppingCart = useAppSelector((s) => s.shoppingCart.cart);

    useEffect(() => {
        return () => {
            setInformationPasted(false);
            togglePasteContactInfo();
        };
        //eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (shoppingCart && shoppingCart.reservations.length > 0) {
            setInformation({
                ...information,
                contact: {
                    firstName: shoppingCart.reservations[0].contact.firstName,
                    lastName: shoppingCart.reservations[0].contact.lastName,
                    email: shoppingCart.reservations[0].contact.email,
                    phone: shoppingCart.reservations[0].contact.phone,
                },
            });
            setInformationPasted(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shoppingCart]);

    useEffect(() => {
        if (!isFirstLoad.current) {
            setInformation({
                ...information,
                contact: {
                    ...information.contact,
                    firstName: reservation?.firstName!,
                    lastName: reservation?.lastName!,
                    email: reservation?.email!,
                    phone: reservation?.phone!,
                },
                comment: reservation?.comment!,
            });
            setInformationPasted(true);
        }
        //eslint-disable-next-line
    }, [globalState.isContactInformationPasted]);

    useEffect(() => {
        updateReservationData({
            firstName: information.contact.firstName,
            lastName: information.contact.lastName,
            email: information.contact.email,
            phone: information.contact.phone,
            comment: information.comment,
            userId: information.userId,
        });
        //eslint-disable-next-line
    }, [information]);

    useEffect(() => {
        //this will force the contact form to undo the unsaved changes from contact
        if (!isFirstLoad.current) {
            if (shoppingCart && shoppingCart.reservations.length > 0) {
                setInformation({
                    ...information,
                    contact: {
                        firstName: shoppingCart.reservations[0].contact.firstName,
                        lastName: shoppingCart.reservations[0].contact.lastName,
                        email: shoppingCart.reservations[0].contact.email,
                        phone: shoppingCart.reservations[0].contact.phone,
                    },
                });
            } else {
                setInformation({
                    contact: {
                        firstName: reservation?.firstName ?? "",
                        lastName: reservation?.lastName ?? "",
                        email: reservation?.email ?? "",
                        phone: reservation?.phone ?? "",
                    },
                    comment: reservation?.comment ?? "",
                    userId: reservation?.userId ?? null,
                });
            }
            setIsUndoPressed(!isUndoPressed);
            setInformationPasted(false);
        }
        isFirstLoad.current = false;
    }, [undoPressed]);

    const addComment = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setInformation({ ...information, comment: ev.target.value });
    };

    const addContact = (contact: Partial<IContactInfo>, isValid: boolean) => {
        setInformation({ ...information, contact: { ...contact } });
        setIsValid(isValid);
    };

    const addUser = (userId: string | null) => {
        if (userId) {
            setInformation({ ...information, userId });
        }
    };

    const close = () => {
        if (props.closePanel) {
            props.closePanel();
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid xs={12}>
                {/* validate paste */}
                <ContactForm
                    addContactInfo={addContact}
                    outsideContactInfo={information.contact}
                    isInformationPasted={isInformationPasted}
                    undo={isUndoPressed}
                />
            </Grid>
            <Grid xs={12}>
                <TextField
                    id="reservation-comment"
                    label="Comment"
                    name="comment"
                    multiline
                    fullWidth
                    minRows={1}
                    value={information.comment || ""}
                    onChange={addComment}
                />
            </Grid>
            {reservation && reservation.reservationId === -1 && (
                <Grid xs={12}>
                    <UserSelector handleUserSelected={addUser} />
                </Grid>
            )}
            <Grid xs={12}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <div data-cy="SaveInformation">
                        <Stack spacing={2} direction="row">
                            <Button size="small" onClick={() => close()}>
                                Close
                            </Button>
                        </Stack>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};

export default EditInformation;
