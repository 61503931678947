import { useState } from "react";
import { RateTicketPerson } from "shared/models/Rates";
import { buildTicketPersons, createTicketPersonsArray } from "./../business/Rates";
import reservationService from "shared/services/ReservationService";
import { validateRequiredFields } from "shared/business/RequiredFields";
import { useReservationModalContext } from "../../modules/Reservation/context/ReservationModalProvider";
import { NotificationsType } from "./useNotification";
import { ReservationDetails } from "shared/models/Reservation";
import { useQueryClient } from "@tanstack/react-query";
import useGlobal from "./GlobalHook";
import { ManageErrorMessage } from "shared/services/ErrorService";
import { useShoppingCartSlice } from "../../store/ShoppingCart/useShoppingCartSlice";
import { getShoppingCartNotifications } from "../../modules/ShoppingCart/ShoppingCartNotifications";
import { useShoppingCartProvider } from "../../modules/ShoppingCart/ShoppingCartProvider";
import useConfirmationDialog from "shared/components/ConfirmationDialog";

const useReservation = () => {
    const {
        reservation,
        canSave,
        setCanSave,
        updateReservationData,
        undoChanges,
        showNotification,
        haveChanges,
        setHaveChanges,
        isValid,
    } = useReservationModalContext();
    const { clearReservationPanelPayload, setOpenPanel, addReservationPanelPayload } = useGlobal();
    const { addExistingReservation, addNewReservationToCart } = useShoppingCartSlice();
    const { toggleShoppingCartModal } = useShoppingCartProvider();

    const queryClient = useQueryClient();
    const [loading, setLoading] = useState(false);
    const { handleOpen, dialogContext } = useConfirmationDialog();
    const updateTickets = (rateId: number, amount: number, quantity: number) => {
        const ticketPersons = buildTicketPersons(
            rateId,
            quantity,
            reservation?.ticketEdits!,
            amount,
        );
        updateReservationData(ticketPersons);
    };

    const getTicketPersons = (): RateTicketPerson[] => {
        const ticketPersons: RateTicketPerson[] = createTicketPersonsArray(
            reservation!.ticketEdits,
        );
        return ticketPersons;
    };

    const updateTicketAmount = (rateId: number, amount: number) => {
        if (reservation !== null) {
            const updatedTickets = reservation.ticketEdits.map((item) => {
                if (item.rateId === rateId) {
                    return {
                        ...item,
                        rate: amount,
                    };
                }
                return { ...item };
            });
            updateReservationData({ ticketEdits: updatedTickets });
        }
    };

    const removeTicket = (rateId: number, ticketPersonId: number) => {
        if (reservation !== null) {
            const updatedTickets = reservation.ticketEdits.map((item) => {
                if (item.rateId === rateId) {
                    return {
                        ...item,
                        tickets: item.tickets - 1,
                        ticketPersons:
                            item.ticketPersons?.filter(
                                (x) => x.ticketPersonId !== ticketPersonId,
                            ) || [],
                    };
                }
                return { ...item };
            });
            updateReservationData({ ticketEdits: updatedTickets });
        }
    };

    const saveReservation = async () => {
        setLoading(true);
        try {
            const data = await reservationService.SaveReservation(
                reservation as ReservationDetails,
            );
            updateReservationData(data);
            addReservationPanelPayload(data.reservationId);
            showNotification({
                message: "Reservation saved",
                type: NotificationsType.success,
            });
            queryClient.invalidateQueries({
                queryKey: ["event-date-details"],
            });
            queryClient.removeQueries({
                queryKey: ["reservation-details"],
            });
            queryClient.invalidateQueries({
                queryKey: ["calendar-by-event"],
            });
            setLoading(false);
            setHaveChanges(false);
            handleOpen(
                "This action requires confirmation",
                "Would you like to add the reservation to the shopping cart?",
                "No",
                [
                    {
                        onAction: () => addToCart(data),
                        buttonText: "Yes",
                    },
                ],
            );
            clearReservationPanelPayload();
        } catch (error) {
            const error_message = ManageErrorMessage(error, 0);
            showNotification({
                message: error_message,
                type: NotificationsType.warning,
            });
            setLoading(false);
        }
    };
    const addToCart = async (resevationDetails: ReservationDetails) => {
        setLoading(true);
        const addToCartNotifications = getShoppingCartNotifications("AddReservation");
        if (resevationDetails) {
            if (resevationDetails.reservationId > 0) {
                try {
                    await addExistingReservation(resevationDetails.reservationId);
                    showNotification(addToCartNotifications.success);
                    toggleShoppingCartModal(true);
                    setOpenPanel(false);
                } catch {
                    showNotification(addToCartNotifications.error);
                } finally {
                    setLoading(false);
                }
            } else {
                try {
                    await addNewReservationToCart(resevationDetails);
                    showNotification(addToCartNotifications.success);
                    toggleShoppingCartModal(true);
                    setOpenPanel(false);
                } catch (e) {
                    showNotification(addToCartNotifications.error);
                } finally {
                    setLoading(false);
                }
            }
        }
    };

    const validateReservation = () => {
        let isValidLastname = false;
        let isValidTickets = false;

        if (reservation && reservation.lastName !== null && reservation.lastName !== "") {
            isValidLastname = true;
        }

        if (
            reservation &&
            reservation.ticketEdits &&
            reservation.ticketEdits.some((x) => x.tickets > 0)
        ) {
            let ticketsPersons = getTicketPersons();
            let ticketValidations: boolean[] = [];
            ticketsPersons.forEach((tp) => {
                let isTicketValid = validateRequiredFields(
                    tp.ticket,
                    reservation.ticketRequiredFields,
                );
                ticketValidations.push(isTicketValid);
            });
            isValidTickets = !ticketValidations.some((x) => x === false);
        }

        if (reservation && reservation.reservationId > 0) {
            isValidTickets = true;
        }

        setCanSave(isValidLastname && isValidTickets);
    };

    const updateTicketPerson = (ticket: RateTicketPerson) => {
        if (reservation !== null) {
            const ticketFromReservation = reservation.ticketEdits.map((x) => {
                let internalRates = { ...x };
                if (internalRates.rateId === ticket.rateId) {
                    //looking for the corresponding rate
                    internalRates.ticketPersons =
                        internalRates.ticketPersons?.map((tp) => {
                            if (tp.ticketPersonId === ticket.ticket.ticketPersonId) {
                                tp = ticket.ticket;
                                tp.answersJson = JSON.stringify(ticket.parsedAnswers);
                            }
                            return tp;
                        }) || [];
                }
                return internalRates;
            });
            updateReservationData({ ticketEdits: ticketFromReservation });
        }
    };

    return {
        reservation,
        canSave,
        loading,
        undoChanges,
        updateTickets,
        getTicketPersons,
        updateTicketAmount,
        removeTicket,
        saveReservation,
        validateReservation,
        updateTicketPerson,
        haveChanges,
        addToCart,
        dialogContext,
        isValid,
    };
};

export default useReservation;
