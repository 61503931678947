import React, { FunctionComponent } from "react";
import SlidePanel from "../../../shared/components/SlidePanel";
import { useIndexicTheme } from "../../../theme/useIndexicTheme";
import { Typography } from "@mui/material";
import ApplyCouponField from "../components/ApplyCouponField";
import { CouponSearch } from "./CouponSearch";
interface CouponsContentProps {
    closePanel: () => void;
}

const CouponsContent = (props: CouponsContentProps) => {
    const theme = useIndexicTheme();
    return (
        <div
            style={{
                height: "100vh",
            }}
        >
            <div
                style={{
                    background: theme.palette.grey["100"],
                    padding: "0.5rem 1rem",
                }}
            >
                <Typography variant={"inputLabel"} gutterBottom>
                    Apply coupon
                </Typography>
                <ApplyCouponField />
            </div>
            <CouponSearch />
        </div>
    );
};
interface CouponsProps {
    open: boolean;
    onClose: () => void;
}
const CouponsDialog: FunctionComponent<CouponsProps> = (props) => {
    return (
        <SlidePanel
            title={"Add coupon"}
            relativeWidth={90}
            padding={"0"}
            open={props.open}
            onClose={props.onClose}
            children={<CouponsContent closePanel={props.onClose} />}
        />
    );
};
export default CouponsDialog;
