import React from 'react';

import { IBaseQuestionInputProps } from '../Question';
import { FormGroup, RadioGroup } from '@mui/material';
import { QuestionSingleChoice } from 'shared/models/QuestionTypes';
import SelectionList from './SelectionList';

export interface ISingleChoiceQuestionProps extends IBaseQuestionInputProps {
    question: QuestionSingleChoice;
    selected: any;
    onSelectionChange: Function
}
export function SingleChoiceQuestion(props:ISingleChoiceQuestionProps) {
    return (
        <FormGroup>
            <RadioGroup
              value={props.value}
              onChange={props.onChange}
              onBlur={props.onBlur}
            >
              <SelectionList
                answers={props.question.answers}
                multiselect={false}
                selected={props.selected}
                onSelectionChange={props.onSelectionChange}
              />
            </RadioGroup>
          </FormGroup>
    )
}
