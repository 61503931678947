import { Theme } from "@mui/material/styles";
import BadgeOverrides from "./BadgeOverrides";
import ButtonOverrides from "./ButtonOverrides";
import CardContentOverrides from "./CardContentOverrides";
import ChipOverrides from "./ChipOverrides";
import IconButtonOverrides from "./IconButtonOverrides";
import LinearProgressOverrides from "./LinearProgressOverrides";
import LinkOverrides from "./LinkOverrides";
import ListItemIconOverrides from "./ListItemIconOverrides";
import TabOverrides from "./TabOverrides";
import TableCellOverrides from "./TableCellOverrides";
import TabsOverrides from "./TabsOverrides";
import Typography from "./Typography";
import LoadingButtonOverrides from "./LoadingButtonOverrides";
import DividerOverrides from "./DividerOverrides";
import AlertOverrides from "./AlertOverrides";

export default function ComponentsOverrides(theme: Theme) {
    return {
        ...ButtonOverrides(theme),
        ...LoadingButtonOverrides(theme),
        ...BadgeOverrides(theme),
        ...CardContentOverrides(theme),
        ...ChipOverrides(theme),
        ...IconButtonOverrides(theme),
        ...LinearProgressOverrides(theme),
        ...LinkOverrides(theme),
        ...ListItemIconOverrides(theme),
        ...TabOverrides(theme),
        ...TableCellOverrides(theme),
        ...TabsOverrides(theme),
        ...DividerOverrides(theme),
        ...Typography(theme),
        ...AlertOverrides(theme),
    };
}
