import { HttpService, QueryParam, createQueryParams } from "shared/http/http";
import { BulkMessageData, CannedMsg } from "shared/models/Messages";
import { TextMessage, ITextMessageCountSummary, ITextMessageList } from "shared/models/SignalR";

class MessageService extends HttpService {
    constructor() {
        super("/portal/Msg");
    }

    async getUnreadMessageCount(): Promise<number> {
        const result = await this.get<number>(`UnreadMessageCount`);
        return result.data;
    }

    async getTextMessages(startDate: Date | null, endDate: Date | null): Promise<ITextMessageList> {
        const params: QueryParam[] = [];

        if (startDate) {
            params.push({ key: "startDate", value: startDate.toDateString() });
        }
        if (endDate) {
            params.push({ key: "endDate", value: endDate.toDateString() });
        }
        const paramsUri = createQueryParams(params);
        const result = await this.get<ITextMessageList>(`Messages${paramsUri}`);
        return result.data;
    }

    async getTextMessagesByPhone(phoneNumber: string): Promise<TextMessage[]> {
        const params: QueryParam[] = [{ key: "phoneNumber", value: phoneNumber }];
        const paramsUri = createQueryParams(params);
        const result = await this.get<Array<TextMessage>>(`GetConversation${paramsUri}`);
        return result.data;
    }

    // Set an array of messages as read
    async setMessagesRead(smsSids: string[]): Promise<any> {
        if (!smsSids || smsSids.length === 0) {
            return;
        }
        const result = await this.post(`SetMessageRead`, smsSids);
        return result.data;
    }

    async setAllMessagesRead(): Promise<number> {
        const result = await this.get<number>(`SetAllMessagesRead`);
        return result.data;
    }

    async sendMessage(message: string, phoneNumber: string): Promise<TextMessage> {
        const params: QueryParam[] = [
            { key: "message", value: message },
            { key: "phoneNumber", value: phoneNumber },
        ];
        const paramsUri = createQueryParams(params);
        const result = await this.get<TextMessage>(`SendTextMessage${paramsUri}`);
        return result.data;
    }

    async startConversation(phoneNumber: string): Promise<number> {
        const params: QueryParam[] = [{ key: "phoneNumber", value: phoneNumber }];
        const paramsUri = createQueryParams(params);
        const result = await this.get<number>(`StartConversation${paramsUri}`);
        return result.data;
    }

    async endConversation(phoneNumber: string): Promise<number> {
        const params: QueryParam[] = [{ key: "phoneNumber", value: phoneNumber }];
        const paramsUri = createQueryParams(params);
        const result = await this.get<number>(`EndConversation${paramsUri}`);
        return result.data;
    }

    async getTextMessageCountSummary(
        startDate: Date | null,
        endDate: Date | null,
    ): Promise<ITextMessageCountSummary> {
        const params: QueryParam[] = [];
        if (startDate) {
            params.push({ key: "startDate", value: startDate.toDateString() });
        }
        if (endDate) {
            params.push({ key: "endDate", value: endDate.toDateString() });
        }
        const paramsUri = createQueryParams(params);
        const result = await this.get<ITextMessageCountSummary>(`MessageCountSummary${paramsUri}`);
        return result.data;
    }
    async getCannedMessages(): Promise<CannedMsg[]> {
        const result = await this.get<Array<CannedMsg>>(`CannedMessages`);
        return result.data;
    }
    async saveCannedMessages(msg: CannedMsg) {
        const result = await this.post<CannedMsg[]>(`CannedMessages`, msg);
        return result.data;
    }
    async deleteCannedMessages(msg: CannedMsg) {
        const result = await this.post<CannedMsg[]>(`DeleteCannedMessage`, msg);
        return result.data;
    }
    async sendBulkMessages(data: BulkMessageData) {
        const result = await this.post<BulkMessageData>(`BulkMessaging`, data);
        return result.data;
    }
}

const messageService = new MessageService();
export default messageService;
