import GppBadIcon from "@mui/icons-material/GppBad";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import GppGoodIcon from "@mui/icons-material/GppGood";
import ShieldIcon from "@mui/icons-material/Shield";
import { Badge, Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IconActionButton } from "shared/components/IconActionButton";
import {
    IRentalInsurance,
    RentalInsuranceStatus,
    getRentalInsuranceName,
    getRentalInsuranceStatusColor,
} from "shared/models/RentalInsurance";
import DialogComponent from "shared/components/DialogComponent";
import reservationService from "shared/services/ReservationService";
import { DateTimeUtils, FormatType } from "shared/utils/DateTimeUtils";
import { NotificationsType } from "shared/hooks/useNotification";
import { useReservationModalContext } from "modules/Reservation/context/ReservationModalProvider";
import { BiteWiseUtils } from "shared/utils/BitwiseUtils";
interface RentalInsuranceDisplayProps {
    rentalInsuranceCount: number;
    rentalInsuranceStatus: RentalInsuranceStatus;
    reservationId: number;
}

const getIconRentalInsurance = (rentalInsuranceStatus: number) => {
    const color = getRentalInsuranceStatusColor(rentalInsuranceStatus);

    switch (BiteWiseUtils.getMostSignificantBite(rentalInsuranceStatus, RentalInsuranceStatus)) {
        case RentalInsuranceStatus.Initiated:
            return <GppBadIcon sx={{ color: color }} />;
        case RentalInsuranceStatus.DocumentsCompleted:
            return <GppMaybeIcon sx={{ color: color }} />;
        case RentalInsuranceStatus.RentalCompleted:
            return <GppGoodIcon sx={{ color: color }} />;
        case RentalInsuranceStatus.RentalStarted:
            return <ShieldIcon sx={{ color: color }} />;
        default:
            <></>;
    }
};

const RentalInsuranceDisplay = (props: RentalInsuranceDisplayProps) => {
    const [open, setOpen] = useState(false);
    return (
        <React.Fragment>
            <Box>
                <Badge
                    overlap="rectangular"
                    color="primary"
                    badgeContent={
                        props.rentalInsuranceCount > 1 ? props.rentalInsuranceCount : null
                    }
                >
                    <IconActionButton
                        onClick={() => setOpen(true)}
                        icon={getIconRentalInsurance(props.rentalInsuranceStatus)}
                    />
                </Badge>
            </Box>
            <DialogComponent
                DialogText={`Rental Insurance ${getRentalInsuranceName(
                    props.rentalInsuranceStatus,
                )} #${props.reservationId}`}
                open={open}
                handleClose={() => setOpen(false)}
            >
                <RentalInsuranceDetail reservationId={props.reservationId} />
            </DialogComponent>
        </React.Fragment>
    );
};

const RentalInsuranceDetail = ({ reservationId }: { reservationId: number }) => {
    const [rentalInsuranceDetails, setRentalInsuranceDetails] = useState<IRentalInsurance[]>([]);
    const [loading, setLoading] = useState(true);
    const { showNotification } = useReservationModalContext();

    useEffect(() => {
        getRentalInsurance();
        // eslint-disable-next-line
    }, [reservationId]);

    const getRentalInsurance = async () => {
        try {
            const result = await reservationService.getRentalInsurance(reservationId.toString());
            setRentalInsuranceDetails(result);
            setLoading(false);
        } catch (error) {
            showNotification({
                message: "An error ocurred trying to get the rental insurance",
                type: NotificationsType.error,
            });
        }
    };

    return (
        <div
            style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                overflow: "hidden",
            }}
        >
            {loading ? (
                <CircularProgress />
            ) : (
                <div>
                    {rentalInsuranceDetails.map((rentalInsuranceDetail, idx: number) => {
                        return (
                            <div
                                key={idx}
                                style={{
                                    borderBottom: "0.063rem solid var(--grey-300)",
                                    marginBottom: ".75rem",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    minWidth: "27rem",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            flexDirection: "row",
                                        }}
                                    >
                                        {rentalInsuranceDetail.isRequired ? (
                                            <IconActionButton
                                                icon={getIconRentalInsurance(
                                                    rentalInsuranceDetail.status,
                                                )}
                                                size="small"
                                            />
                                        ) : (
                                            <IconActionButton icon={<></>} size="small" />
                                        )}
                                        <div>
                                            <Typography variant="subtitle2">
                                                {" Insured Name: "}{" "}
                                                {rentalInsuranceDetail.insuredName}
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                {"Insurance Booking: "}
                                                {rentalInsuranceDetail.insuranceBookingId}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                                <Typography variant="subtitle2">
                                    {DateTimeUtils.newFormat(
                                        DateTimeUtils.parse(rentalInsuranceDetail.ud),
                                        FormatType.long,
                                    )}
                                </Typography>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};
export default RentalInsuranceDisplay;
