import { parsePhoneNumber } from "libphonenumber-js";
import { MathEx } from "./MathEx";
import { isValid } from "date-fns";
import { DateTimeUtils } from "./DateTimeUtils";
import { TimeType } from "../models/Report";

export const displayPhoneNumber = (phone: string) => {
    try {
        return parsePhoneNumber(phone).formatNational();
    } catch (e) {
        return phone;
    }
};

export const getPhoneUri = (phone: string) => {
    try {
        return parsePhoneNumber(phone).getURI();
    } catch (e) {
        return null;
    }
};

export const formatCurrency = (param: number): string => {
    const num = Number(param);
    if (!isNaN(num)) {
        const currency = MathEx.formatCurrency(Math.abs(num));
        if (num < 0) {
            return `(${currency})`;
        }
        return `${currency}`;
    }
    return "--";
};
export const formatCurrencyCell = (value: string | number) => formatCurrency(Number(value));
export const formatDateCell = (value: string) => {
    const initiatedDate = new Date(value as string);
    if (!isValid(initiatedDate)) return "--";
    return DateTimeUtils.format(initiatedDate, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
    });
};
export const parseISOStrByTimeType = (isoStr: string, timeType: TimeType): Date | null => {
    try {
        let date: Date;
        if (timeType === TimeType.LOCAL) {
            date = DateTimeUtils.UTCStringToLocalDate(isoStr);
        } else {
            date = DateTimeUtils.eventLocalDate(isoStr);
        }
        return date;
    } catch (e) {
        console.error(e);
        return null;
    }
};
