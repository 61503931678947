import React from "react";
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { MenuItems } from "./menuItems";
import { useMatch, useNavigate } from "react-router-dom";
import { useIndexicTheme } from "../../../theme/useIndexicTheme";
import { alpha } from "@mui/material/styles";

const NavigationItem = (props: MenuItems) => {
    const navigation = useNavigate();
    const theme = useIndexicTheme();
    const match = useMatch(props.path);
    const active = match !== null;
    const handleAction = () => {
        navigation(`${props.path}`);
    };
    return (
        <ListItem disablePadding>
            <ListItemButton
                className={"nav-item"}
                onClick={handleAction}
                disabled={!props.active}
                sx={{
                    backgroundColor: active ? alpha(theme.palette.primary.bright, 0.3) : "",
                }}
            >
                <ListItemIcon
                    sx={{
                        color: active ? theme.palette.primary.main : "",
                    }}
                >
                    {props.icon}
                </ListItemIcon>
                <ListItemText
                    primary={props.name}
                    primaryTypographyProps={{
                        variant: "idReservation",
                        sx: {
                            color: active ? theme.palette.primary.main : "",
                        },
                    }}
                />
            </ListItemButton>
        </ListItem>
    );
};

export default NavigationItem;
