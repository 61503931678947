import {
    Alert,
    Badge,
    Box,
    CircularProgress,
    ImageList,
    ImageListItem,
    LinearProgress,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import Webcam from "react-webcam";
import CameraIcon from "@mui/icons-material/Camera";
import SaveIcon from "@mui/icons-material/Save";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import { BlobServiceClient } from "@azure/storage-blob";
import CameraFrontTwoToneIcon from "@mui/icons-material/CameraFrontTwoTone";
import CameraRearTwoToneIcon from "@mui/icons-material/CameraRearTwoTone";
import { NotificationsType } from "shared/hooks/useNotification";
import uploadService, { IUploadPictureSas } from "shared/services/api/UploadService";
import "./Camera.css";
import { useReservationModalContext } from "modules/Reservation/context/ReservationModalProvider";
import DialogComponent, { DialogHooks } from "shared/components/DialogComponent";
import cameraService from "shared/services/CameraService";
import { IconActionButton } from "shared/components/IconActionButton";
import { useQueryClient } from "@tanstack/react-query";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

interface Props {
    reservationId: number;
}
interface CameraDialogProps {
    reservationId: number;
    disabled: boolean;
}
interface IPictureDispay {
    photoCount: number;
    reservationId: number;
}
enum FacingMode {
    USER = "user",
    ENVIROMENT = "environment",
}

const WebCamComponent = (props: Props) => {
    const queryClient = useQueryClient();
    const [camIsAvailable, setAvailable] = useState(false);
    const [image, setImage] = useState<string | null>(null);
    const [switchCamera, setSwitchCamera] = useState<FacingMode>(FacingMode.USER);
    const [multipleCameras, setMultipleCameras] = useState<boolean>(false);
    const [uploading, setUploading] = useState<boolean>(false);
    const [isCameraLoading, setIsCameraLoading] = useState(true);
    const { showNotification } = useReservationModalContext();
    useEffect(() => {
        verifyCameraAccess();
        // eslint-disable-next-line
    }, []);

    const videoConstraints = useMemo(() => {
        return {
            video: {
                width: { min: 576, ideal: 1280, max: 1920 },
                height: { min: 480, ideal: 720, max: 1080 },
            },
            facingMode: switchCamera,
        };
    }, [switchCamera]);

    const handleCameraLoaded = () => {
        setIsCameraLoading(false);
    };
    const verifyCameraAccess = async () => {
        //@ts-ignore
        navigator.mediaDevices
            .enumerateDevices()
            .then((devices) => {
                const cameras = devices
                    .filter((d) => d.label.length > 0 && d.kind === "videoinput")
                    .map((d, i) => d.deviceId);
                if (
                    cameras.length === 0 &&
                    devices.filter((d) => d.kind === "videoinput").length > 0
                ) {
                    navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
                        stream.getTracks().forEach((track) => track.stop());
                        setAvailable(false);
                        verifyCameraAccess();
                    });
                } else {
                    setMultipleCameras(cameras.length > 1);
                    setAvailable(true);
                }
            })
            .catch((error) => {
                setAvailable(false);
            });
    };

    const retrievedImage = (image: string | null) => {
        setImage(image);
    };

    const converToBlob = async () => {
        const canvas: any = document.getElementById("webcam_image");
        const canvasURL = canvas.toDataURL();
        const base64Response = await fetch(`${canvasURL}`);
        return await base64Response.blob();
    };

    const createWaterMark = async (imageUrl: string) => {
        const img = new Image();
        const canvas: any = document.getElementById("webcam_image");
        const ctx = canvas.getContext("2d");
        img.src = imageUrl;
        canvas.style = "display : none";
        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;
        ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight);

        const ts = new Date().toUTCString();
        ctx.font = "18px arial, helvetica";
        ctx.fillStyle = "#FFF";
        ctx.globalCompositeOperation = "difference";
        ctx.fillText(ts, 5, 25);
    };

    const save = async () => {
        setUploading(true);
        if (image !== null) {
            let blobInfo: IUploadPictureSas;
            await createWaterMark(image!);
            const data = await uploadService.getUploadDetails(props.reservationId);
            if (data !== null) {
                blobInfo = data.sasData;
                const blob: any = await converToBlob();
                if (blob) {
                    const blobService = new BlobServiceClient(
                        blobInfo!.serviceUri + "/?" + blobInfo!.sas,
                    );
                    const customBlockSize =
                        image.length > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
                    const containerClient = blobService.getContainerClient(blobInfo!.container);
                    const blockBlobClient = containerClient.getBlockBlobClient(blobInfo!.fileName);

                    const options = {
                        blockSize: customBlockSize,
                        blobHTTPHeaders: { blobContentType: "image/jpeg" },
                    };
                    await blockBlobClient
                        .uploadData(blob, options)
                        .then(() => {
                            showNotification({
                                message: "Image uploaded",
                                type: NotificationsType.success,
                            });
                            setUploading(false);
                            queryClient.refetchQueries({ queryKey: ["reservation-details"] });
                            setImage(null);
                        })
                        .catch((error) => {
                            showNotification({
                                message: "There was an error uploading the image",
                                type: NotificationsType.error,
                            });
                            setUploading(false);
                        });
                    await uploadService.updateImageCount(props.reservationId);
                } else {
                    showNotification({
                        message: "There was an error trying to get the image from the camera",
                        type: NotificationsType.error,
                    });
                    setUploading(false);
                }
            }
        }
    };

    const toggleSwitchCamera = () => {
        if (switchCamera === FacingMode.USER) {
            setSwitchCamera(FacingMode.ENVIROMENT);
        } else {
            setSwitchCamera(FacingMode.USER);
        }
    };
    return (
        <Grid container spacing={2}>
            <Grid md={12}>
                {!camIsAvailable ? (
                    <Alert
                        variant="outlined"
                        title="We are trying to connect to your cammera."
                        severity="warning"
                    >
                        Please connect or give access to your camera.
                    </Alert>
                ) : image === null ? (
                    <>
                        {isCameraLoading && <LinearProgress />}
                        <Webcam
                            audio={false}
                            screenshotFormat="image/jpeg"
                            height="100%"
                            width="100%"
                            videoConstraints={videoConstraints}
                            onUserMedia={handleCameraLoaded}
                        >
                            {/* @ts-ignore */}
                            {({ getScreenshot }) => (
                                <div className="camera-actions">
                                    <div
                                        className="photo-button"
                                        onClick={() => {
                                            retrievedImage(getScreenshot());
                                        }}
                                    >
                                        <div className="circle"></div>
                                        <div className="ring"></div>
                                    </div>
                                    {multipleCameras ? (
                                        <div
                                            className="switch-camera"
                                            onClick={() => toggleSwitchCamera()}
                                        >
                                            {switchCamera === FacingMode.USER ? (
                                                <CameraRearTwoToneIcon
                                                    fontSize="large"
                                                    style={{ color: "white" }}
                                                />
                                            ) : (
                                                <CameraFrontTwoToneIcon
                                                    fontSize="large"
                                                    style={{ color: "white" }}
                                                />
                                            )}
                                        </div>
                                    ) : null}
                                </div>
                            )}
                        </Webcam>
                    </>
                ) : (
                    <React.Fragment>
                        {uploading && <LinearProgress />}
                        <img alt="Preview" src={image} className="preview" />
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-evenly",
                                    gap: 2,
                                }}
                            >
                                <div
                                    className="btnControlls"
                                    onClick={() => [setImage(null), setIsCameraLoading(true)]}
                                >
                                    <p className="btnText">Retake</p>
                                    <div className="secondaryButton retake">
                                        <p className="btnText2">
                                            <CameraIcon />{" "}
                                        </p>
                                    </div>
                                </div>
                                <div className="btnControlls" onClick={() => save()}>
                                    <p className="btnText">Save</p>
                                    <div className="secondaryButton save">
                                        <p className="btnText2">
                                            <SaveIcon />{" "}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <canvas id="webcam_image"></canvas>
                    </React.Fragment>
                )}
            </Grid>
        </Grid>
    );
};

const CameraDialog = (props: CameraDialogProps) => {
    const [open, handleOpen, handleClose] = DialogHooks();
    return (
        <React.Fragment>
            <IconActionButton
                disabled={props.disabled}
                icon={<CameraIcon />}
                onClick={() => handleOpen()}
                label="Camera"
                size="small"
            />
            <DialogComponent DialogText="Capture photo" open={open} handleClose={handleClose}>
                <WebCamComponent reservationId={props.reservationId} />
            </DialogComponent>
        </React.Fragment>
    );
};

const PictureDisplayCount = ({ photoCount, reservationId }: IPictureDispay) => {
    const [open, setOpen] = useState(false);
    if (photoCount === 0) {
        return null;
    }
    return (
        <React.Fragment>
            <Box>
                <Badge overlap="rectangular" badgeContent={photoCount} color="primary">
                    <IconActionButton
                        color="primary"
                        onClick={() => setOpen(true)}
                        icon={<PhotoLibraryIcon />}
                        label="Pictures"
                        size="small"
                    />
                </Badge>
            </Box>
            <DialogComponent
                DialogText={`Reservation Pictures #${reservationId}`}
                open={open}
                handleClose={() => setOpen(false)}
            >
                <SingleLineImageList reservationId={reservationId} />
            </DialogComponent>
        </React.Fragment>
    );
};

const SingleLineImageList = ({ reservationId }: { reservationId: number }) => {
    const [imageData, setImages] = useState<string[]>([]);
    const [loading, setLoading] = useState(true);
    const { showNotification } = useReservationModalContext();

    useEffect(() => {
        getBlobs(reservationId);
        // eslint-disable-next-line
    }, []);

    const getBlobs = async (reservationId: number) => {
        try {
            const data = await cameraService.GetListBlobs(reservationId.toString());
            setImages(data || []);
            setLoading(false);
        } catch (error) {
            showNotification({
                message: "An error ocurred trying to get the pictures",
                type: NotificationsType.warning,
            });
        }
    };
    return (
        <div
            style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-around",
                overflow: "hidden",
            }}
        >
            {loading ? (
                <CircularProgress />
            ) : (
                <ImageList
                    rowHeight={180}
                    style={{
                        width: 500,
                        height: 450,
                    }}
                    cols={2.5}
                >
                    {imageData.map((item) => (
                        <ImageListItem style={{ width: "50%" }} key={item}>
                            <img alt="uploaded" src={item} />
                        </ImageListItem>
                    ))}
                </ImageList>
            )}
        </div>
    );
};
export { CameraDialog, WebCamComponent, PictureDisplayCount };
