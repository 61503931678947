import React from "react";
import { QuestionAnswer } from "shared/models/QuestionTypes";
import Answer from "./Answer";
import Grid from "@mui/material/Unstable_Grid2";

export interface AnswersDisplayProps {
    answers?: QuestionAnswer[];
}

const AnswersDisplay = (props: AnswersDisplayProps) => {
    const answers = props.answers || [];

    if (answers.length < 1) {
        return null;
    }

    return (
        <Grid container direction="column">
            <Grid xs={12}>
                {answers.map((a, idx) => (
                    <Answer key={`${a.questionId}-${idx}`} question={a.questionText} answer={a.answer} />
                ))}
            </Grid>
        </Grid>
    );
};

export default AnswersDisplay;
