import React, { useState, useEffect } from "react";
import useReservation from "shared/hooks/ReservationHook";
import { RateQuestionGroup, RateTicketPerson } from "shared/models/Rates";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import TicketCard from "./TicketCard";
import { QuestionBase } from "shared/models/QuestionTypes";
import { useReservationModalContext } from "../../../modules/Reservation/context/ReservationModalProvider";

export interface RateQuestionParsed extends RateQuestionGroup {
    questions: QuestionBase[];
}

const TicketsDisplay = () => {
    const { reservation } = useReservationModalContext();
    const { getTicketPersons, removeTicket } = useReservation();
    const [ticketList, setTicketList] = useState<RateTicketPerson[]>([]);

    useEffect(() => {
        const tickets = getTicketPersons();
        if (tickets) {
            setTicketList(tickets);
        }
        // eslint-disable-next-line
    }, [reservation?.ticketEdits]);

    return (
        <Grid container spacing={2}>
            {ticketList.map((item, index) => (
                <Grid xs={12} sm={6} key={`${item.ticket.ticketPersonId}-${index}`}>
                    <TicketCard information={item} removeTicket={removeTicket} />
                </Grid>
            ))}
        </Grid>
    );
};

export default TicketsDisplay;
