import { CompanyUserSchema } from "shared/models/Company.schema";
import { HttpService, createUrl } from "../http/http";
import { ICompanyUser } from "shared/models/Company";
import { IUpdateUserPermission, IUser } from "shared/models/User";
import { UserSchema, UsersSchema } from "shared/models/User.schema";
import { ValidateSchema } from "shared/utils/ZodValidations";

class UsersService extends HttpService {
    constructor() {
        super("/portal/user");
    }

    async getCompanyUser() {
        const uri = createUrl([]);
        const result = await this.get<ICompanyUser>(uri);
        return ValidateSchema<ICompanyUser>(result.data, CompanyUserSchema);
    }

    getCompanyUserByGiud(userGuid: string) {
        const uri = createUrl([userGuid]);
        return this.get<ICompanyUser>(uri);
    }

    async updateCompanyUser(user: ICompanyUser) {
        const uri = createUrl([]);
        const result = await this.put<ICompanyUser>(uri, user);
        return ValidateSchema<ICompanyUser>(result.data, CompanyUserSchema);
    }

    async getAllUsers() {
        const uri = createUrl(["Users"]);
        const result = await this.put<Array<IUser>>(uri, {});
        return ValidateSchema<Array<IUser>>(result.data, UsersSchema);
    }

    updateUser(user: IUser) {
        const uri = createUrl([user.userId]);
        return this.put<IUser>(uri, user);
    }

    async updateUserSystemReportAccess(systemAccess: number, reportAccess: number, userId: string) {
        const uri = createUrl(["Access", userId]);

        const result = await this.put<IUser, IUpdateUserPermission>(uri, {
            systemAccess,
            reportAccess,
        });

        return ValidateSchema<IUser>(result.data, UserSchema);
    }

    async updateUserActiveStatus(userId: string) {
        const uri = createUrl(["Active", userId]);
        const result = await this.put<IUser>(uri, {});
        return result;
    }
}

const userService = new UsersService();
export default userService;
