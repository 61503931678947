import { Theme } from "@mui/material/styles";

const LoadingButtonOverrides: any = (theme: Theme) => {
    //We return any because this is a lab component
    return {
        MuiLoadingButton: {
            styleOverrides: {
                root: {
                    color: theme.palette.success,
                    "&.Mui-disabled": {
                        backgroundColor: "var(--dark-aqua-main)",
                        color: "#fff",
                        opacity: "50%",
                    },
                },
                contained: {
                    "&.Mui-disabled": {
                        backgroundColor: "var(--dark-aqua-main)",
                        color: "#fff",
                        opacity: "50%",
                    },
                },
            },
        },
    } as any;
};
export default LoadingButtonOverrides;
