// Copied from aResDB/Models/Roles.cs
export enum SystemAccess {
    None = 0,
    ReservationView = 1 << 0,
    ReservationAdd = 1 << 1,
    ReservationModify = 1 << 2,
    ReservationDelete = 1 << 3,
    Refund = 1 << 4,
    EventEdit = 1 << 5,
    PersonnelManage = 1 << 6,
    ResourcesManage = 1 << 7,
    CouponsManage = 1 << 8,
    CompanyEditFinancials = 1 << 9,
    CompanyEditNotFinancials = 1 << 10,
    AddDeleteEventDates = 1 << 11,
    UpdateEventDates = 1 << 12,
    WebsiteManage = 1 << 13,
    AdhocPriceAdjustments = 1 << 14,
    UserAccess = 1 << 30,
    SysAdmin = -1, // All bits on
}

export type SystemAccessFields =
    | "None"
    | "ReservationView"
    | "ReservationAdd"
    | "ReservationModify"
    | "ReservationDelete"
    | "Refund"
    | "EventEdit"
    | "PersonnelManage"
    | "ResourcesManage"
    | "CouponsManage"
    | "CompanyEditFinancials"
    | "CompanyEditNotFinancials"
    | "AddDeleteEventDates"
    | "UpdateEventDates"
    | "WebsiteManage"
    | "AdhocPriceAdjustments"
    | "UserAccess"
    | "SysAdmin";

export type ISystemAccess = Record<SystemAccessFields, boolean>;
type SystemAccessField = Record<SystemAccessFields, string>;

export const systemAccessLabels: SystemAccessField = {
    None: "None",
    ReservationView: "View Reservations",
    ReservationAdd: "Add Reservations",
    ReservationModify: "Modify Reservations",
    ReservationDelete: "Delete Reservations",
    WebsiteManage: "Modify Website Manage",
    Refund: "Refund",
    EventEdit: "Edit Events",
    PersonnelManage: "Manage Personnel",
    ResourcesManage: "Manage Resources",
    CouponsManage: "Manage Coupons",
    CompanyEditFinancials: "Edit company financial information",
    CompanyEditNotFinancials: "Edit company non financial information",
    UserAccess: "Manage User Access",
    AddDeleteEventDates: "Add/Delete Events",
    UpdateEventDates: "Update Events",
    AdhocPriceAdjustments: "Adhoc Price Adjustments",
    SysAdmin: "Full Company Administration",
};

export enum ReportAccess {
    None = 0,
    Transaction = 1 << 1,
    DailyReservations = 1 << 2,
    CashSummary = 1 << 3,
    OutstandingBalances = 1 << 4,
    Partner = 1 << 5,
    SalesByDay = 1 << 6,
    CouponUsage = 1 << 7,
    EmailAddresses = 1 << 8,
    HowHeardReport = 1 << 9,
    RefundReason = 1 << 10,
    Delivery = 1 << 11,
    TicketTypeSummary = 1 << 12,
    ReservationsByEmployee = 1 << 13,
    PayoutReport = 1 << 14,
    AssignedPersonnel = 1 << 15,
    Commission = 1 << 16,
    CashCategory = 1 << 17,
    Tips = 1 << 18,
    ReportAdmin = -1, // All bits on
}
